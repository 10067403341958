import React from "react";
const Badge = ({ count, children, style,color, numStyle }) => {
  const  capitalizeFirstLetter = (string) => {
        if (string) {
            var newStr = string.replace(/_/g, " ");
            return newStr?.charAt(0).toUpperCase() + newStr.slice(1).toLowerCase();
        }
    };

    return (
      <div style={{ display: "flex", ...style }}>
        <div>{children}</div>
        <div
          style={{
            alignSelf: "flex-start",
            marginLeft: 6,
            backgroundColor: color,
            padding: "0px 10px",
            borderRadius: 9.5,
            color:"white"
          }}
        >
          <span className="label" style={{ padding: 0, ...numStyle }}>
            {capitalizeFirstLetter(count)}
          </span>
        </div>
      </div>
    );
  };
  
  export default Badge;