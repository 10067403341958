import { useHistory, useEffect, fetchClient, apiUrl, React } from "allImport";
import { useStyles } from "style/Liststyle.js";
import { convertUTCDateToLocalDate } from "Common/CommonFunction";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
  SUB_ADMIN_PERMISSION,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";

import TablePageLayout from "layout/TablePageLayout";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import { useDispatch, useSelector } from "react-redux";
import Actions from "redux/actions/filterActions";
import TableActions from "redux/actions/tableActions";
import CommonTable from "components/CommonComponent/Filter/CommonTable/CommonTable";

var url = new URL(`${apiUrl.viewDepartments}`);
var AS = [];
var searchValue = "";
var departmentIDs = [];
export default function DepartmentList() {
  const history = useHistory();

  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const [data, setAllData] = React.useState({});
  const [totalPage, setTotalPage] = React.useState("");
  const [count, setCount] = React.useState("");
  const [departmentSearch, setDepartmentSearch] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [loader, setLoader] = React.useState(false);
  const [pageColor, setPageColor] = React.useState(null);

  const handleChangePage = (event, value) => {
    setPageColor(value)
    setPage(value);
  };

  function createData(ColA, ColB, ColC, ColD) {
    return { ColA, ColB, ColC, ColD };
  }

  const colmConversion = (arr) => {
    console.log("department array-------", arr);
    AS = [];
    for (var index = 0; index < arr.length; index++) {
      AS.push(
        createData(
          arr[index] && arr[index].id,
          arr[index] && arr[index].name,
          arr[index] &&
            convertUTCDateToLocalDate(
              new Date(arr[index].created_at)
            ).toLocaleDateString("en-IN"),
          arr[index] && arr[index].status
        )
      );

      departmentIDs.push(arr[index] && arr[index].id);
    }
    processDataList();
  };

  const handleChangeFilter = (e) => {
    setDepartmentSearch(e.target.value);
    searchValue = e.target.value;
    const url = `${apiUrl.viewDepartments}?search=${searchValue}`;
    fetchClient.get(url).then((res) => {
      setCount(res && res.data && res.data.data && res.data.data.count);
      const DepartmentsArray =
        res && res.data && res.data.data && res.data.data.results;

      colmConversion(DepartmentsArray);
      setTotalPage(res && res.data && res.data.data && res.data.data.pages);
    });
  };
  const { searchData } = useSelector((store) => store.search);


  useEffect(()=>{
    setPageColor(1)
    setPage(1);
  },[searchData])

  // const clear = () => {
  //   setDepartmentSearch("");
  //   searchValue = "";
  //   fetchClient.get(url).then((res) => {
  //     setCount(res && res.data && res.data.data && res.data.data.count);
  //     const DepartmentsArray =
  //       res && res.data && res.data.data && res.data.data.results;
  //     setTotalPage(res && res.data && res.data.data && res.data.data.pages);
  //     colmConversion(DepartmentsArray);
  //   });
  // };

  const getPermissionsForSubAdmin = () => {
    let permission = {};
    if (role === "sub-admin") {
      let allPermissions = StorageManager.getJSON(SUB_ADMIN_PERMISSION);
      permission.view = allPermissions.includes("VIEW_DEPARTMENT");
      permission.create = allPermissions.includes("CREATE_DEPARTMENT");
      permission.edit = allPermissions.includes("EDIT_DEPARTMENT");
      permission.delete = allPermissions.includes("DELETE_DEPARTMENT");
    }
    return permission;
  };

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  useEffect(() => {
    let isCanclled = false;
    const handleChange = async () => {
      await timeout(200);
      if (!isCanclled) {
        fetchClient
          .get(
            `${apiUrl.viewDepartments}?${page ? `&page=${page}` : " "}${
              searchData ? `&search=${searchData}` : ""
            }`
          )
          .then((res) => {
            setTotalPage(
              res && res.data && res.data.data && res.data.data.pages
            );
            setCount(res && res.data && res.data.data && res.data.data.count);
            const DepartmentsArray =
              res && res.data && res.data.data && res.data.data.results;
            let SUB_ADMIN_PERMISSION = localStorage.getItem(
              "SUB_ADMIN_PERMISSION"
            );

            const result = res.data.data;
            const count = result.count;
            const departmentData = result.results.map((item) => {
              return {
                0: item.id,
                1: item.name,
                2: convertUTCDateToLocalDate(
                  new Date(item.created_at)
                ).toLocaleDateString("en-IN"),

                3: item.status,

                editAction: {
                  editpath: `/${role}/department/edit`,
                  departmentName: item.name,
                  departmentID: item.id,
                  allow: SUB_ADMIN_PERMISSION
                    ? SUB_ADMIN_PERMISSION.includes("EDIT_DEPARTMENT")
                    : true,
                },
              };
            });

            dispatch({
              type: TableActions.SET_TABLE_ROW,
              data: departmentData,
              tableHeading: "Department",
              profile: `/${role}/department/view`,
              count: count,
            });
            dispatch({
              type: TableActions.SET_PAGINATION,
              data: { pages: result.pages, count: result.count },
            });

            setCount(result.count);

            // colmConversion(DepartmentsArray);
            setLoader(true);
          });
      }
    };
    handleChange();
    return () => {
      isCanclled = true;
    };
  }, [page, searchData]);
  const processDataList = () => {
    const permissions = getPermissionsForSubAdmin();
    const data = {
      columnnames: {
        col1: "ID",
        col2: "Department",
        col3: "Date Added",
        col4: "Status",
        col5: "",
        col6: "",
        col7: "",
        col8: "Action",
      },
      TableType: "Department",

      List: AS,
      ListKey: Object.keys(AS),
      EditPath: `/${role}/department/edit`,
      profile: `/${role}/department/view`,
      permissions: permissions,
      departmentIDs: departmentIDs,
    };

    setAllData(data);
  };

  useEffect(() => {
    const allowActionColumn =
      localStorage.getItem("ROLE") === "SUB-ADMIN" &&
      localStorage.getItem("SUB_ADMIN_PERMISSION") &&
      !localStorage.getItem("SUB_ADMIN_PERMISSION").includes("EDIT_DEPARTMENT");

    dispatch({
      type: TableActions.SET_TABLE_COLUMN_HEADING,
      data: [
        "ID",
        "Department",
        "Date Added",
        "Status",
        !allowActionColumn && "Action",
      ],
    });
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: Actions.SET_FILTER, count: 1, data: null });
  }, []);
  let SUB_ADMIN_PERMISSION = localStorage.getItem("SUB_ADMIN_PERMISSION");

  return (
    <>
      <HeaderWithBreadcrumb heading="Departments" />

      <TablePageLayout
        heading="Departments"
        loader={loader}
        page={page}
        ButtonText="Add Department"
        handleChangePage={handleChangePage}
        count={count}
        allowCreate={
          SUB_ADMIN_PERMISSION
            ? SUB_ADMIN_PERMISSION.includes("CREATE_DEPARTMENT")
            : true
        }
        buttonActionPath={`/${role}/department/create`}
        handleChangeFilter={(e) => handleChangeFilter(e)}
        candidateFilter={{
          item: "null",
          // item: [levels],
          // handleChangeExamLevel: handleChangeFilter("exam_level"),
          // handleChangeAttemptLevel: handleChangeFilter("attempt_level"),
          // handleChangeDepartmentLevel: handleChangeFilter("department"),
          type: "department",
        }}
      >
        <CommonTable Count={totalPage} handleChangePage={handleChangePage} pageColor={pageColor}/>
      </TablePageLayout>
    </>
  );
}
