import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Input,
  InputLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";

import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "32px",
    color: "#383838",
  },
  icons: {
    width: "20px",
    height: "20px",
  },
  divider: {
    height: "26px",
  },
  IconButton: {
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#FFF",
    },
  },
  filledButton: {
    height: "2.5rem",
  },
  fullWidthDivider: {
    margin: "16px 0px",
  },
  label: {
    fontFamily: "Mulish",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "18px",
    color: "#383838",
    // marginBottom: "10px",
  },
  topbarButtonWrapper: {
    ["@media (max-width:600px)"]: {
      // eslint-disable-line no-useless-computed-key
      marginTop: "0.5rem",
      display: "flex",
      justifyContent: "space-between",
    },
  },
}));

const PageTopBar = ({ heading, children }) => {
  const classes = useStyles();
  const [activeSearch, setActiveSearch] = useState(false);
  const handleSearch = () => {
    setActiveSearch(!activeSearch);
  };

  return (
    <>
      <Grid justifyContent="space-between" container>
        <Grid
          container
          item
          xs={4}
          sm={6}
          md={7}
          lg={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <InputLabel className={classes.label}>{heading}</InputLabel>
        </Grid>

        <Grid
          container
          item
          sm
          spacing={2}
          className={classes.topbarButtonWrapper}
          justifyContent="flex-end"
          alignItems="center"
        >
          {children}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.fullWidthDivider} />
      </Grid>
    </>
  );
};

export default PageTopBar;
