import {
  CssBaseline,
  Box,
  Topbar,
  Sidebar,

  React,
  Emptyscreen,
} from "allImport";
import { useStyles } from "style/Liststyle.js";

export default function Questionsbank() {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      
      <Emptyscreen />
    
    </main>
  );
}
