import {
  useEffect,
  useHistory,
  React,
  fetchClient,
  apiUrl,
  fetchPost,
} from "allImport";

// import { useStyles } from "style/Editstyle.js";
import "style/style.scss";
import { FormikProvider } from "formik";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import { useStyles as useStyles1 } from "style/Liststyle";
import { Container, Grid, InputLabel, TextField } from "@material-ui/core";
import BasicInput from "components/Form/InputBoxes/BasicInput";

import { useFormik } from "formik";
import { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { withSnackbar } from "notistack";

import { makeStyles } from "@material-ui/core/styles";

import CustomButton from "components/Button/CustomButton";

import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";

import { VacancyInitialValues } from "components/Form/InitialValues/VacancyInitialValues";
import VacancyValidation from "components/Form/validations/VacancyValidation";
import { useDispatch } from "react-redux";
import SelectQuestsionAction from "redux/actions/selectQuestionAction";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1);",
    },
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 5,
      paddingRight: "100px",
      width: "100%",
    },
  },
  label: {
    fontFamily: "Mulish",
    fontWeight: "600px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#383838",
  },
}));

function Createexam(props) {
  const classes2 = useStyles();
  const classes1 = useStyles1();
  const adminID = localStorage.getItem("ADMIN_ID");
  const [departmentList, setDepartmentList] = useState([]);
  const experienceLevel = ["senior","associate" ,"junior", "internship"];
  const dispatch = useDispatch();

  const history = useHistory();
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;

  // Get Data on Initial Page Load
  useEffect(() => {
    dispatch({
      type: SelectQuestsionAction.RESET_QUESTION,
    });
    if (
      !StorageManager.get(API_TOKEN) &&
      !StorageManager.get(ADMIN_ID) &&
      !StorageManager.get(ADMIN_NAME) &&
      !StorageManager.get(LOGOUT_TOKEN)
    ) {
      history.push("/login");
    }

    fetchClient.get(`${apiUrl.deptList}?page_size=1000`).then((res) => {
      console.log(res.data.data.results);
      setDepartmentList(res.data.data.results);
    });
  }, []);

  const handleSubmit = (values) => {
    const data = {
      exam_name: values.vacancyTitle,
      experience_level: values.experienceLevel,
      created_by: adminID,
      duration: 10,
      department: values.department,
      status: "unpublish",
    };

    if (!data.department) {
      props.enqueueSnackbar("Please select a department", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        TransitionComponent: Zoom,
        autoHideDuration: 3000,
      });
      return;
    }
    fetchPost
      .post(apiUrl.createExam, data)
      .then((res) => {
        history.replace({
          pathname: `/${role}/exam/question`,
          state: {
            ExamID: res.data.data.id,
            Department: values.department,
            Level: values.experienceLevel,
          },
        });
      })
      .catch((error) => {
        props.enqueueSnackbar(error.response.data.error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Zoom,
          autoHideDuration: 3000,
        });
      });
  };

  const formik = useFormik({
    initialValues: VacancyInitialValues,
    validationSchema: VacancyValidation,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Container
      container
      maxWidth="xl"
      className={classes1.container}
      style={{ height: "calc(100vh - 120px)", maxHeight: "auto" }}
    >
      <HeaderWithBreadcrumb
        heading="Create Exam"
        breadCrumb
        breadCrumb1="Exam"
        breadCrumb2="Create Exam"
        link={`/${role}/exam`}
      />

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <BasicInput
              label="Exam Name*"
              placeholder="Enter exam title"
              type="vacancyTitle"
              values={formik.values.vacancyTitle}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.vacancyTitle}
              errors={formik.errors.vacancyTitle}
            />

            <Grid item xs={12} lg={4} md={6}>
              <InputLabel className={classes2.label}>Department*</InputLabel>

              <Autocomplete
                classes={classes2}
                name="department"
                id="department"
                disableClearable
                value={departmentList?.find((e) => e.id)}
                options={departmentList}
                getOptionLabel={(option) => option.name}
                handleChange={formik.handleChange}
                onChange={(department, value) =>
                  formik.setFieldValue("department", value.id) &&
                  formik.handleChange
                }
                renderInput={(params) => (
                  <TextField
                    placeholder="Enter department*"
                    name="department"
                    id="department"
                    {...params}
                    variant="outlined"
                    error={Boolean(
                      formik.touched.department && formik.errors.department
                    )}
                    helperText={
                      formik.touched.department && formik.errors.department
                    }
                    touched={formik.touched.department}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="normal"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={4} md={6}>
              <InputLabel className={classes2.label}>
                Experience Level*
              </InputLabel>

              <Autocomplete
                classes={classes2}
                name="experienceLevel"
                id="experienceLevel"
                disableClearable
                options={experienceLevel}
                getOptionLabel={(option) => option}
                handleChange={formik.handleChange}
                onChange={(experienceLevel, value) =>
                  formik.setFieldValue("experienceLevel", value) &&
                  formik.handleChange
                }
                renderInput={(params) => (
                  <TextField
                    defaultValue="asd"
                    placeholder="Enter experience Level"
                    name="experienceLevel"
                    id="experienceLevel"
                    {...params}
                    variant="outlined"
                    error={Boolean(
                      formik.touched.experienceLevel &&
                        formik.errors.experienceLevel
                    )}
                    helperText={
                      formik.touched.experienceLevel &&
                      formik.errors.experienceLevel
                    }
                    touched={formik.touched.experienceLevel}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="normal"
                  />
                )}
              />
            </Grid>

            <Grid container item xs={12} spacing={3}>
              <Grid item sx={3}>
                <CustomButton
                  // type="cancel"
                  Buttontype="cancel"
                  label="Cancel"
                />
              </Grid>

              <Grid item sx={3}>
                <CustomButton type="submit" Buttontype="save" label="Next" />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Container>
  );
}

export default withSnackbar(Createexam);
