import {
  Zoom,
  fetchUpdate,
  apiUrl,
  useHistory,
  InputAdornment,
  VisibilityOff,
  Visibility,
  IconButton,
  Formik,
  Field,
  Yup,
  Form,
  Grid,
  makeStyles,
  Button,
  Typography,
  React,
} from "allImport";
import { TextField } from "formik-material-ui";
import { commonStyle } from "commonStyle.js";
import { withSnackbar } from "notistack";
import LeftBox from "../Login/LeftBox";
import { Box, InputLabel } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),

  changepass_heading: {
    fontFamily: "Mulish",
    fontSize: "50px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "63px",
    width: "435px",
    height: "63px",

    color: "#18273B",
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
  buttonbox: {
    paddingTop: "32px",
  },
  button: {
    color: "#FFFFFF",
    background: " #D9001D",
    height: "52px",
    width: "157px",
    left: "0px",
    top: "0px",
    borderRadius: "4px",
  },

  newpasswordlabel: {
    marginTop: "320 px",
    color: "#444444",
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: " 400",
    lineHeight: " 24px",
    letterSpacing: "0.5px",
    textAlign: "left",
  },
  passwordlabel: {
    marginTop: "32px",
    color: "#444444",
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: " 400",
    lineHeight: " 24px",
    letterSpacing: "0.5px",
    textAlign: "left",
  },

  inputfeedback: {
    marginTop: "10px",
  },

  form: {
    marginTop: "32px",
  },
}));

const Resetpassword = (props) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
  const handleMouseDownPassword1 = () => setShowPassword1(!showPassword1);
  const history = useHistory();
  return (
    <Grid container>
      <LeftBox
        heading="Welcome Back!"
        message="Update your password and log in to continue recruiting."
      />
      <Grid container item md={8} justifyContent="center" alignItems="center">
        <Box>
          <Typography variant="h3" className={classes.changepass_heading}>
            Reset Password
          </Typography>

          <Formik
            initialValues={{ new_password: "", retype_password: "" }}
            onSubmit={(values, { resetForm, setSubmitting }) => {
              var data = JSON.stringify({
                new_password: values.new_password,
                retype_password: values.retype_password,
              });

              fetchUpdate
                .patch(`${apiUrl.resetPassword}/${props.match.params.id}`, data)

                .then((response) => {
                  props.enqueueSnackbar(response.data.data.message, {
                    variant: "success",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                    TransitionComponent: Zoom,
                  });

                  history.push("/login");
                })
                .catch((error) => {
                  console.log(error);
                });
              resetForm();
            }}
            validationSchema={Yup.object().shape({
              new_password: Yup.string()
                .required("No password provided.")
                .min(7, "Password is too short - should be 7 chars minimum.")
                .max(35, "Password is too long - should be 35 chars maximum.")
                .matches(/(?=.*[0-9])/, "Password must contain a number."),
              retype_password: Yup.string()
                .oneOf([Yup.ref("new_password")], "Password does not match")
                .required("Required"),
            })}
          >
            {(props) => {
              const {
                touched,
                errors,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
              } = props;
              return (
                <Form onSubmit={handleSubmit} className={classes.form}>
                  {/* <label
                    htmlFor="password"
                    className={classes.newpasswordlabel}
                  >
                    New Password*
                  </label>{" "} */}
                  <InputLabel
                    htmlFor="password"
                    className={classes.newpasswordlabel}
                  >
                    New Password*
                  </InputLabel>
                  <Field
                    className={classes.textFieldResetPassword}
                    autofocus
                    id="password"
                    autoComplete="off"
                    placeholder="Enter Your Password"
                    name="new_password"
                    value={values.new_password}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.new_password ? errors.new_password : ""}
                    error={touched.new_password && Boolean(errors.new_password)}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                  <br />
                  <InputLabel
                    htmlFor="password"
                    className={classes.newpasswordlabel}
                  >
                    Re-enter New Password*
                  </InputLabel>
                  <Field
                    className={classes.textFieldResetPassword}
                    id="confirmpassword"
                    autoComplete="off"
                    placeholder="Re-Enter Your Password"
                    name="retype_password"
                    value={values.retype_password}
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    helperText={
                      touched.retype_password ? errors.retype_password : ""
                    }
                    error={
                      touched.retype_password && Boolean(errors.retype_password)
                    }
                    onChange={handleChange}
                    type={showPassword1 ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword1}
                          >
                            {showPassword1 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                  <div className={classes.buttonbox}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={!isValid}
                      className={
                        errors
                          ? classes.resetPasswordButton
                          : classes.disabledresetPasswordButton
                      }
                    >
                      Reset Password
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(Resetpassword);
