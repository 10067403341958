
import "style/style.scss";
import {DialogContent,Dialog,MuiDialogTitle,IconButton,withStyles, React,Button,Typography,CloseIcon,SuccessDialog} from "allImport";
import { withSnackbar } from 'notistack';
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  alertText: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "30px",
    letterSpacing: "0.15000000596046448px",
    textAlign: "center",
    paddingBottom: "41px",
    marginLeft:'8px',
   
    marginTop:'40px'
  },
  filledButton: {
    background: "#D9001D",
    borderRadius: "4px",
    textTransform: "capitalize",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.15px",
    color: "#FFFFFF",
    fontFamily: "Mulish",
    width: "140px",
    height: "50px",
   
  },
  outlinedButton: {
    background: "#fafafa",
    color: "#D9001D",
    border: "1px solid",
    borderRadius: "4px",
    textTransform: "capitalize",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.15px",
    fontFamily: "Mulish",
    width: "140px",
    height: "50px",
   
    marginRight: "-54px",
  },
  dialogSize:{
    height:'250px',
    Width: "400px"
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


 const Alert = withStyles(styles)((props) => {
  const [open, setOpen] = React.useState(true); 
  const { classes } = props;
  
  const handleClick = () => {
    props.action();
    
    setOpen(false);
  }

  return (
    <div>
      {!open ? ((props.goback===undefined || props.goback===null) &&<SuccessDialog type="profile" heading={props.successText} />) :
      
      <Dialog   onClose={() => props.cancel()} aria-labelledby="customized-dialog-title" open={open} classes={{paper:classes.dialogSize}}>
        <DialogTitle id="customized-dialog-title" onClose={() => props.cancel()} />
        <DialogContent >
          <Typography>
            <div className={classes.alertText}>{props.heading}</div>
          </Typography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="button_pop_up" style={{ paddingRight: "80px" }}>
              <Button onClick={() => props.cancel()} className={classes.outlinedButton} variant="contained" color="primary">
                Cancel
              </Button>
            </div>
            <div className="button_pop_up">
              <Button onClick={() => handleClick()} className={classes.filledButton} variant="contained" color="primary">
                {props.actionName}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>}
    </div>
  );
});
export default withSnackbar(Alert);