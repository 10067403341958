import React, { useState, useEffect } from "react";

import {
  withSnackbar,
  Pagination,
  DeleteAlert,
  MenuItem,
  Select,
  OutlinedInput,
  CancelRoundedIcon,
  fetchClient,
  apiUrl,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  useHistory,
  CssBaseline,
  Box,
  Container,
  Grid,
  Paper,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  FormControl,
  Topbar,
  Sidebar,
  SearchIcon,
  Emptyscreen,
} from "allImport";
import { useStyles } from "style/Liststyle.js";
import { capitalize, convertUTCDateToLocalDate } from "Common/CommonFunction";
import { useDebounce } from "hooks";
import "style/style.scss";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
  SUB_ADMIN_PERMISSION,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";

import { API, ENDPOINTS } from "../../../api/apiService";
import TablePageLayout from "layout/TablePageLayout";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import { useDispatch, useSelector } from "react-redux";
import Actions from "redux/actions/filterActions";
import TableActions from "redux/actions/tableActions";
import CommonTable from "components/CommonComponent/Filter/CommonTable/CommonTable";

const QUESTION_TYPE = [
  { label: "Question Type", value: "question_type" },
  { label: "Subjective", value: "subjective" },
  { label: "Objective", value: "objective" },
];

const QUESTION_STATUS = [
  { label: "Status", value: "question_status" },
  { label: "Publish", value: "publish" },
  { label: "Unpublish", value: "unpublish" },
  { label: "Draft", value: "draft" },
];

const EXAM_LEVEL = [
  { label: "Exam Level", value: "experience_level" },
  { label: "Senior", value: "senior" },
  { label: "Associate", value: "associate" },
  { label: "Junior", value: "junior" },
  { label: "Internship", value: "internship" },
];

const queryString = require("query-string");

var AS = [];
var questionIDs = [];

const QuestionList = (props) => {
  const [totalPages, setTotalPages] = useState();
  useEffect(() => {
    fetchClient.get(`${apiUrl.createCategory}?has_question=1`).then((res) => {
      const resp = res.data;
      if (resp.success) {
        const result = resp.data;
        const res = result.results;
        setTotalPages(result.pages);

        let temp = res.map((item) => ({
          value: item.id,
          label: item.name,
          id: item.id,
        }));
        temp.unshift({
          label: "Category",
          value: "question_category",
        });
        setCat(temp);
      }
    });
  }, []);

  const classes = useStyles();
  const history = useHistory();
  const [loader, setLoader] = React.useState(true);
  const [open, setOpen] = React.useState([]);
  const [totalPage, setTotalPage] = React.useState("");
  const [count, setCount] = React.useState(null);
  const [Arr, setArr] = React.useState([]);
  const [questionSelect, setquestionSelect] = React.useState(false);
  const [id, setID] = React.useState("");
  const examiner_dept = localStorage.getItem("EXAMINER_DEPT");
  const [permission, setPermission] = React.useState({});
  // const dispatch = useDispatch();
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const [cat, setCat] = useState([]);
  console.log("examiner dept--->", examiner_dept);
  const [departments, setDepartments] = useState([]);
  const [filter, setFilter] = React.useState({
    page: 1,
    page_size: 5,
    search: "",
    question_type: 0,
    question_status: 0,
    experience_level: 0,
    department: 0,
    role: role,
    examiner_dept: "",
    category_filter: 0,
  });
  const debouncedSearchTerm = useDebounce(filter.search, 500);
  const [pageColor, setPageColor] = React.useState(null);
  const { dataArray } = useSelector((store) => store.filter);
  const { searchData } = useSelector((store) => store.search);
  const { reset } = useSelector((store) => store.table);

  const options1 = queryString.stringify({
    ...dataArray,
  });

  console.log("data--->", options1);

  const queryBuilder = (url, options) => {
    const options1 = queryString.stringify({
      ...dataArray,
    });

    console.log("data--->", options1);

    if (options === undefined || !options) return url;

    return `${url}?${options.page ? `&page=${options.page}` : ""}${
      searchData ? `&search=${searchData}` : ""
    }${
      options.role !== "examiner"
        ? ""
        : options.examiner_dept !== ""
        ? `&department=${options.examiner_dept}`
        : ""
    }&${options1}`;
  };

  const handleChangeFilter = (key) => (event) => {
    let tempData = { ...filter };
    tempData[key] = event.target.value;
    tempData["page"] = 1;
    setFilter(tempData);
  };

  const handleChange = (e) => {
    var search = e.target.value;

    if (search === "") {
      // fetchRecord();
    } else {
      fetchClient
        .get(`${apiUrl.filterCategory}?question_category=` + search)
        .then((res) => {
          console.log("------this is res-----------", res);
          var CategoryList =
            res && res.data && res.data.data && res.data.data.results;
          setTotalPage(res && res.data && res.data.data && res.data.data.pages);
          setCount(res && res.data && res.data.data && res.data.data.count);
          colmConversion(CategoryList);
        });
    }
  };

  const handleChangePage = (event, page) => {
    dispatch({
      type: TableActions.SET_TABLE_ROW,
      count: null,
    });
    setFilter({ ...filter, page });
    setPageColor(page);
  };

  useEffect(() => {
    setFilter({ ...filter, page: 1 });
    setPageColor(1);
  }, [dataArray, searchData]);

  function createData(
    ColA,
    ColB,
    ColC,
    ColD,
    ColE,
    ColF,
    ColG,
    ColH,
    ColI,
    ColJ,
    ColK
  ) {
    return { ColA, ColB, ColC, ColD, ColE, ColF, ColG, ColH, ColI, ColJ, ColK };
  }

  const colmConversion = (arr) => {
    console.log("-----------this array", arr);
    AS = [];
    let isExpanded = [];
    for (var index = 0; index < arr.length; index++) {
      console.log("this bad idea", arr[index].question_category);
      AS.push(
        createData(
          arr[index] && arr[index].id,
          arr[index] && arr[index].question_name,
          arr[index].question_status,
          arr[index] && arr[index].department.name,
          arr[index] && arr[index].experience_level,
          arr[index].question_category === null
            ? "----"
            : arr[index].question_category.name,
          arr[index] &&
            new Date(arr[index].created_at).toLocaleDateString("en-IN"),
          arr[index] && arr[index].time_duration,
          arr[index] && arr[index].ongoing_exam,
          arr[index] && arr[index].question_type
        )
      );
      questionIDs.push(arr[index] && arr[index].id);
      isExpanded.push(false);
    }
    console.log("this is my as ----------", AS);
    setArr(AS);
    setOpen(isExpanded);
  };

  const handleExpand = (index) => {
    let temp = open;
    temp[index] = !temp[index];
    setOpen([...temp]);
  };

  const handleEdit = (row) => {
    console.log(row, "data");
    history.push({
      pathname: `/${role}/question-bank/questions/edit`,
      state: {
        questionID: row.ColA,
        questionLevel: row.ColE,
        timeDuration: row.ColH,
        Department: row.ColD,
        questionStatus: row.ColC,
        questionType: row.ColJ,
        questionCategory: row.ColF,
        questionCategoryID: row.ColK,
      },
    });
  };

  const DeleteQuestion = (row) => {
    console.log("row", row.ColA);
    setID(row.ColA);

    !row.ColI
      ? setquestionSelect(!questionSelect)
      : props.enqueueSnackbar("Question can not be remove Exam is going on", {
          variant: "error",
          autoHideDuration: 3000,
        });
  };

  const fetchRecord = async () => {
    try {
      setLoader(true);
      const query = queryBuilder(ENDPOINTS.QUESTION, filter);
      console.log("query", query);
      const resp = await API.get(query);
      if (resp.success) {
        const result = resp.data;
        console.log("marketing", result);
        let SUB_ADMIN_PERMISSION =
          localStorage.getItem("SUB_ADMIN_PERMISSION") || false;

        setTotalPages(result.pages);
        console.log("datattt", result.results);
        const count1 = result.count;
        const examinerData = result.results.map((item) => {
          return {
            0: "dropdownIcon",
            1: item.question_name,
            2:
              item.question_category !== null
                ? item.question_category.name
                : "---",
            3: item.question_status,
            4: item.department.name,
            5: item.question_type,
            6: item.experience_level,
            7: convertUTCDateToLocalDate(
              new Date(item.updated_at)
            ).toLocaleDateString("en-IN"),
            // 2: item.designation,
            // 3: item.department,
            // 4: convertUTCDateToLocalDate(
            //   new Date(item.updated_at)
            // ).toLocaleDateString("en-IN"),

            assignExam: {
              link: "abc",
              candidateId: "abc",
              candidateName: "abc",
              deptId: "abc",
            },
            editAction: {
              editpath: `/${role}/question-bank/questions/edit`,

              questionID: item.id,
              questionLevel: item.experience_level,
              timeDuration: item.time_duration,
              Department: item.department.name,
              questionStatus: item.question_status,
              questionType: item.question_type,
              questionCategory:
                item.question_category !== null
                  ? item.question_category.name
                  : "---",
              questionCategoryID:
                item.question_category !== null
                  ? item.question_category.id
                  : "---",
              allow: SUB_ADMIN_PERMISSION
                ? SUB_ADMIN_PERMISSION.includes("EDIT_EXAMINER")
                : true,
            },
            deleteAction: {
              actionID: item.id,
              deleteName: item.full_name,
              tableType: "Questions",
              allow: SUB_ADMIN_PERMISSION
                ? SUB_ADMIN_PERMISSION.includes("DELETE_EXAMINER")
                : true,
            },
          };
        });
        dispatch({
          type: TableActions.SET_TABLE_ROW,
          data: examinerData,
          tableHeading: "Questions",
          profile: `/${role}/examiner/profile`,
          count: count1,
        });
        dispatch({
          type: TableActions.SET_PAGINATION,
          data: { pages: result.pages, count: result.count },
        });
        setTotalPage(result.pages);
        // setCount(result.count);
        // colmConversion(result.results);

        // setRecord(result.results);
      }
    } catch (e) {
      // const msg =
      //   typeof e.data.error?.message === "string"
      //     ? e.data.error?.message
      //     : e.data.error?.message[0];
      // props.enqueueSnackbar(
      //   msg || "Error fetching question list. Please try again",
      //   {
      //     variant: "error",
      //     autoHideDuration: 3000,
      //   }
      // );
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  const fetchDepartmentList = async () => {
    try {
      const resp = await API.get(
        `${ENDPOINTS.DEPARTMENT_LIST}?has_question=1&page_size=1000`
      );
      if (resp.success) {
        const result = resp.data;
        dispatch({
          type: TableActions.SET_TABLE_ROW,

          count: count,
        });
        const res = result.results;
        // setDeptlist(res);
        let temp = res.map((item) => ({
          value: item.id,
          label: item.name,
          id: item.id,
        }));
        temp.unshift({
          label: "Department",
          value: "department",
        });
        setDepartments(temp);
      }
    } catch (e) {
      const msg =
        typeof e.data.error?.message === "string"
          ? e.data.error?.message
          : e.data.error?.message[0];
      props.enqueueSnackbar(
        msg || "Error fetching department list. Please try again",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    }
  };

  useEffect(() => {
    fetchDepartmentList();
  }, []);

  // Hide and Show Top Bar
  // useEffect(() => {
  //   let scrollPos = 0;
  //   const listenToScroll = () => {
  //     const winScroll =
  //       document.querySelector("#main-content")?.scrollTop ||
  //       document.documentElement.scrollTop;
  //     if (winScroll <= 50) {
  //       setTopBarVisible(true);
  //     } else {
  //       setTopBarVisible(false);
  //     }
  //     scrollPos =
  //       document.querySelector("#main-content")?.scrollTop ||
  //       document.documentElement.scrollTop;
  //   };
  //   document
  //     .querySelector("#main-content")
  //     ?.addEventListener("scroll", listenToScroll);
  //   return () =>
  //     document
  //       .querySelector("#main-content")
  //       ?.removeEventListener("scroll", listenToScroll);
  // }, []);

  // useEffect(() => {
  //   fetchRecord();
  //   // window.scroll(0, 0);
  // }, [
  //   filter.question_type,
  //   filter.question_status,
  //   filter.experience_level,
  //   filter.department,
  //   filter.page,
  //   filter.category_filter,

  //   debouncedSearchTerm,
  // ]);
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  useEffect(() => {
    let isCanclled = false;
    const handleChange = async () => {
      await timeout(200);
      if (!isCanclled) {
        fetchRecord();
      }
    };
    handleChange();
    return () => {
      isCanclled = true;
    };
  }, [dataArray, filter, searchData, reset]);

  const dispatch = useDispatch();
  useEffect(() => {
    const allowActionColumn =
      localStorage.getItem("ROLE") === "SUB-ADMIN" &&
      localStorage.getItem("SUB_ADMIN_PERMISSION") &&
      !localStorage.getItem("SUB_ADMIN_PERMISSION").includes("EDIT_EXAMINER") &&
      !localStorage.getItem("SUB_ADMIN_PERMISSION").includes("DELETE_EXAMINER");
    dispatch({
      type: TableActions.SET_TABLE_COLUMN_HEADING,
      data: [
        "",
        "Questions",
        "Category",
        "Status",
        "Department",
        "Question Type",
        "Question Level",
        "Date Added",
        !allowActionColumn && "Action",
      ],
    });
  }, []);
  useEffect(() => {
    dispatch({
      type: Actions.SET_FILTER,
      count: 3,
      data: [EXAM_LEVEL, QUESTION_TYPE, departments, QUESTION_STATUS, cat],
    });
  }, [EXAM_LEVEL, QUESTION_TYPE, departments, QUESTION_STATUS, cat]);
  let SUB_ADMIN_PERMISSION = localStorage.getItem("SUB_ADMIN_PERMISSION");

  return (
    <>
      <HeaderWithBreadcrumb heading="Question Bank" />

      {questionSelect ? (
        <DeleteAlert
          type="Question"
          heading="Are you sure you want to Delete this Question ? "
          id={id}
        />
      ) : null}
      {console.log("cat------>", cat)}
      <TablePageLayout
        heading="Questions"
        loader={!loader}
        ButtonText="New Question"
        buttonCategoryText="Create Category"
        buttonActionPath={`/${role}/question-bank/questions/add`}
        allowCreate={
          SUB_ADMIN_PERMISSION
            ? SUB_ADMIN_PERMISSION.includes("CREATE_QUESTION_BANK")
            : true
        }
        handleChangeFilter={handleChangeFilter("search")}
        count={count}
        handleChangePage={handleChangePage}
        totalPage={totalPage}
        candidateFilter={{
          item: [EXAM_LEVEL, QUESTION_TYPE, departments, QUESTION_STATUS, cat],
          handleChangeExamLevel: handleChangeFilter("experience_level"),
          handleChangeTypeLevel: handleChangeFilter("question_type"),
          handleChangeDepartmentLevel: handleChangeFilter("department"),
          handleChangeStatusLevel: handleChangeFilter("question_status"),
          handleChangeCategoryLevel: (e) => handleChange(e),

          type: "questionlist",
        }}
      >
        <CommonTable
          Count={totalPages}
          handleChangePage={handleChangePage}
          pageColor={pageColor}
        />
        {/* <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="sticky table">
            <TableHead>
              <TableRow
                style={{ height: "60px" }}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell />
                <TableCell align="left">Questions</TableCell>
                <TableCell align="left">Category</TableCell>
                <TableCell align="left">Status</TableCell>
                {role === "examiner" ? null : (
                  <TableCell align="left">Department</TableCell>
                )}
                <TableCell align="left">Question Type</TableCell>
                <TableCell align="left">Question Level</TableCell>
                <TableCell align="left">Date Added</TableCell>
                <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Arr.map((row, index) => (
                <TableRow
                  style={{ height: "60px" }}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  key={row.ColA}
                >
                  <TableCell>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleExpand(index)}
                      >
                        {open[index] ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </div>
                  </TableCell>
                  <TableCell align="left" className={classes.table_cell_text}>
                    <div
                      className={!open[index] ? classes.collapseRow : ""}
                      style={{ maxWidth: 260 }}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: (
                            row.ColB?.charAt(3).toUpperCase() +
                            row.ColB.slice(4)
                          ).replace(
                            "<img",
                            `<img style="max-width: 350px; margin-left:-75px; border-radius: 4px; border: 1px solid #0000001A"`
                          ),
                        }}
                      ></span>
                    </div>
                  </TableCell>

                  <TableCell align="left" className={classes.table_cell_text}>
                    {row.ColF}
                  </TableCell>
                

                  {row.ColC === "publish" ? (
                    <TableCell align="left">
                      <div
                        style={{
                          backgroundColor: "rgba(0, 207, 46, 0.05)",
                          border: "1px solid #00CF2E",
                          padding: "4px 10px 4px 10px",
                          width: "120px",
                          borderRadius: "4px",
                          textAlign: "center",
                        }}
                      >
                        {capitalize(row && row.ColC)}
                      </div>
                    </TableCell>
                  ) : row.ColC === "draft" ? (
                    <TableCell align="left">
                      <div
                        style={{
                          backgroundColor: "rgba(255, 74, 74, 0.05)",
                          padding: "5px",
                          width: "120px",
                          border: "1px solid #FFA34F",
                          textAlign: "center",
                          borderRadius: "4px",
                        }}
                      >
                        {capitalize(row && row.ColC)}
                      </div>
                    </TableCell>
                  ) : (
                    <TableCell align="left">
                      <div
                        style={{
                          backgroundColor: "rgba(168, 168, 168, 0.05)",
                          padding: "5px",
                          width: "120px",
                          border: "1px solid #A8A8A8",
                          textAlign: "center",
                          borderRadius: "4px",
                        }}
                      >
                        {capitalize(row && row.ColC)}
                      </div>
                    </TableCell>
                  )}

                  {role === "examiner" ? null : (
                    <TableCell align="left" className={classes.table_cell_text}>
                      {capitalize(row.ColD)}
                    </TableCell>
                  )}
                  <TableCell align="left" className={classes.table_cell_text}>
                    {capitalize(row.ColJ)}
                  </TableCell>
                  <TableCell align="left" className={classes.table_cell_text}>
                    {capitalize(row.ColE)}
                  </TableCell>
                  <TableCell align="left" className={classes.table_cell_text}>
                    {capitalize(row.ColG)}
                  </TableCell>
                  <TableCell align="right" className={classes.table_cell_text}>
                    <div style={{ display: "flex" }}>
                      {role === "sub-admin" && !permission.edit ? null : (
                        <img
                          onClick={() => handleEdit(row)}
                          src="/assets/Icons/editIcon.svg"
                          alt=""
                        />
                      )}
                      {role === "sub-admin" && !permission.delete ? null : (
                        <img
                          onClick={() => {
                            DeleteQuestion(row);
                          }}
                          className={classes.deleteAction}
                          src="/assets/Icons/deleteIcon.svg"
                          alt=""
                        />
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
      </TablePageLayout>
    </>
  );
};
export default withSnackbar(QuestionList);
