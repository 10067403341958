import React, { useState, useEffect } from "react";
import { FormLabel, IconButton, InputLabel } from "@material-ui/core";
import {
  useHistory,
  Zoom,
  Link,
  OutlinedInput,
  FormControl,
  MenuItem,
  SuccessDialog,
  Divider,
  apiUrl,
  fetchPost,
  clsx,
  Radio,
  RadioGroup,
  FormControlLabel,
  CssBaseline,
  Box,
  Container,
  Grid,
  makeStyles,
  Paper,
  Button,
  TextField,
  Typography,
  Topbar,
  Sidebar,
  Checkbox,
  fetchClient,
} from "allImport";
import { withSnackbar } from "notistack";
import ChipInput from "material-ui-chip-input";
import { capitalize } from "Common/CommonFunction";
import { commonStyle } from "commonStyle.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import "style/content-styles.css";
import _ from "lodash";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import { Autocomplete } from "@material-ui/lab";

import { API, ENDPOINTS } from "../../../api/apiService";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import CustomButton from "components/Button/CustomButton";
import PageTopBar from "components/PageTopBar/PageTopBar";

import DividerIcon from "../../../components/TableTopBar/assets/divider.svg";

var obj1 = [];

const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1);",
    },
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 5,
      paddingRight: "100px",
      width: "100%",
    },
  },
  label: {
    fontFamily: "Mulish",
    fontWeight: "600px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#383838",
  },
  question_label: {
    fontFamily: "Open Sans",
    width: "681px",
    height: "30px",
    marginBottom: "7px",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "21px",
  },
  select_style: {
    marginTop: "12px",
    width: "307px",
  },
  option_label: {
    fontFamily: "Open Sans",
    marginLeft: "30px",
    marginTop: "7px",
    width: "651px",
    height: "30px",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "21px",
  },
  options_style: {
    display: "flex",
    width: "745px",
  },
  question_header: {
    fontFamily: "Mulish",
    fontStyle: "SemiBold",
    fontSize: 20,
    fontWeight: 600,
  },
  question_type: {
    paddingLeft: 22,
  },
  radio_grp: {
    marginTop: -5,
  },
  editor_style: {
    marginLeft: "30px",
    marginTop: "7px",
    width: "651px",
  },

  time_label: {
    width: "27px",
    height: "23px",
    borderRadius: "4px",
  },
  text_label: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "19px",
  },
  total_marks: {
    marginLeft: 28,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  dividerWrapper: {
    ["@media (max-width:1330px)"]: {
      // eslint-disable-line no-useless-computed-key
      display: "none",
    },
  },
  tag_label: {
    display: "flex",
    marginTop: "9px",
    marginBottom: "36px",
    marginLeft: "28px",
  },
  checkbox_style: {
    height: "18px",
    width: "18px",
    verticalAlign: "middle",
    marginTop: "13px",
    marginRight: "-19px",
    marginLeft: "0px",
  },
  button_style: {
    marginLeft: "30px",
    marginTop: "10px",
  },
  exam_name_label: {
    fontFamily: "Open Sans",
    width: "681px",
    height: "30px",
    marginBottom: "7px",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "21px",
    paddingTop: "32px",
    paddingLeft: "32px",
  },
  experience_level_label: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: "0.10000000149011612px",
    textAlign: "left",
    paddingTop: "32px",
    paddingLeft: "32px",
    height: "32px",
  },
  exam_name_box: {
    paddingTop: "45.37px",
    paddingLeft: "32px",
  },
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1);",
    },
  },

  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 5,
      paddingRight: "100px",
      width: "100%",
    },
  },
  label: {
    fontFamily: "Mulish",
    fontWeight: "900px",
    fontSize: "18px",
    lineHeight: "18px",
    color: "#383838",
    marginBottom: "10px",
  },
}));

const AddNewQuestion = (props) => {
  const classes = useStyles();
  const classes1 = useStyles1();

  const history = useHistory();
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const examiner_dept = localStorage.getItem("EXAMINER_DEPT")
    ? localStorage.getItem("EXAMINER_DEPT")
    : null;
  const [value0, setValue0] = React.useState("");
  const [deptLists, setDeptlist] = React.useState([]);
  const [dept, setDept] = React.useState();
  const [count, setCount] = React.useState(0);
  const [Level, setLevel] = React.useState("senior");
  const [t1, setT1] = React.useState("");
  const [t2, setT2] = React.useState("");
  const [t3, setT3] = React.useState("");
  const [tag, settag] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [publish, setPublish] = React.useState(false);
  const [draft, setDraft] = React.useState(false);
  const [correct_Count, setCorrect_count] = React.useState(0);
  const [obj, setobj] = React.useState({});
  const [tagList, setTaglist] = React.useState([]);
  const [topbarVisible, setTopBarVisible] = React.useState(true);
  const [jobList, setJobList] = useState([]);
  const [value, setValue] = useState("");

  const [questionType, setQuestionType] = useState("objective");
  const [totalMarks, setTotalMarks] = useState("");
  const [checked, setCheckBoxChecked] = useState(false);
  const [checkBox, setcheckBox] = useState([true, false, false,false]);

  const handleQuestionTypeChange = (event) => {
    console.log("value--->", event.target.value);

    setCheckBoxChecked((checked) => !checked);
    setQuestionType(event.target.value);
  };

  const handleIncrement = () => {
    setCount((prevcount) => prevcount + 1);
    const obja = _.cloneDeep(obj);
    obja[_.uniqueId()] = { answer_text: "", is_correct: false };

    setobj(obja);
  };
  const checkbox = (e, id) => {
    e.target.checked
      ? setCorrect_count((prevcount) => prevcount + 1)
      : setCorrect_count((prevcount) => prevcount - 1);
    const obja = _.cloneDeep(obj);
    obja[id].is_correct = e.target.checked;
    setobj(obja);
  };

  // const tagChange = (e) => {
  //   if (e.target.value) {
  //     fetchClient.get(`${apiUrl.tag}?search=` + e.target.value).then((res) => {
  //       setTaglist(res && res.data && res.data.data);
  //     });
  //   } else {
  //     setTaglist([]);
  //   }
  // };
  console.log("-----------------------------value-------------", value);
  const handleAddChip = (chip) => {
    setTaglist([]);
    const obj = { tag_name: chip };
    if (!tag.includes(chip)) {
      setTags([...tags, obj]);

      settag([...tag, chip]);
    }
  };

  const handleDeleteChip = (chip, index) => {
    tags.splice(index, 1);
    tag.splice(index, 1);
    settag([...tag]);
    setTags([...tags]);
  };

  function handleRemove(id) {
    setCount((prevcount) => prevcount + 1);
    console.log(id);
    const obja = _.cloneDeep(obj);
    delete obja[id];

    setobj(obja);
    console.log("array", obja);
  }

  function handleCkEditor(data, i) {
    obj1 = _.cloneDeep(obj);

    obj1[i] = { answer_text: data, is_correct: false };
    setobj(obj1);

    console.log("ckeditorarr", obj);
  }

  const handleDraft = () => {
    if (questionType === "objective") {
      Draft();
    } else if (questionType === "subjective") {
      handleSubjectiveDraft();
    }
  };

  const handleSendTags = async (ID, type) => {
    try {
      console.log("inside tags");
      const resp = await API.post(`${ENDPOINTS.QUESTION}${ID}/tag/`, tags);
      if (resp.success) {
        if (type === "draft") {
          setDraft(true);
        } else if (type === "publish") {
          setPublish(true);
        }
      }
    } catch (e) {
      const msg =
        typeof e.data.error?.message === "string"
          ? e.data.error?.message
          : e.data.error?.message[0];
      props.enqueueSnackbar(msg || "Error saving tags. Please try again", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      // setLoader(false);
    }
  };

  const handleSubjectiveDraft = async () => {
    try {
      const payload = {
        question_name: value0,
        department: dept ? dept : examiner_dept,
        experience_level: Level ? Level : "senior",
        question_status: "draft",
        question_type: "subjective",
        total_marks: Number(totalMarks),
        time_duration: Number(t1) * 3600 + Number(t2) * 60 + Number(t3),
        question_category: value,
      };
      const resp = await API.post(ENDPOINTS.QUESTION, payload);
      if (resp.success) {
        const result = resp.data;
        handleSendTags(result.id, "draft");
      }
    } catch (e) {
      const msg =
        typeof e.data.error?.message === "string"
          ? e.data.error?.message
          : e.data.error?.message[0];
      props.enqueueSnackbar(msg || "Error saving question. Please try again", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      // setLoader(false);
    }
  };

  const handlePublish = () => {
    if (questionType === "objective") {
      Publish();
    } else if (questionType === "subjective") {
      handleSubjectivePublish();
    }
  };

  const handleSubjectivePublish = async () => {
    try {
      const payload = {
        question_name: value0,
        department: dept ? dept : examiner_dept,
        experience_level: Level,
        question_status: "publish",
        question_type: "subjective",
        total_marks: Number(totalMarks),
        time_duration: Number(t1) * 3600 + Number(t2) * 60 + Number(t3),
        question_category: value,
      };
      const resp = await API.post(ENDPOINTS.QUESTION, payload);
      if (resp.success) {
        const result = resp.data;
        handleSendTags(result.id, "publish");
      }
    } catch (e) {
      const msg =
        typeof e.data.error?.message === "string"
          ? e.data.error?.message
          : e.data.error?.message[0];
      props.enqueueSnackbar(
        msg || "Error publishing question. Please try again",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    } finally {
      // setLoader(false);
    }
  };

  const Publish = () => {
    const data1 = {
      question_name: value0,
      department: dept ? dept : examiner_dept,
      experience_level: Level,
      question_status: "publish",
      question_type: "objective",
      time_duration: Number(t1) * 3600 + Number(t2) * 60 + Number(t3),
      question_category: value,
    };

    count >= 2 && correct_Count > 0
      ? fetchPost
          .post(apiUrl.createQuestion, data1)
          .then((res) => {
            const id = res.data.data.id;
            fetchPost.post(apiUrl.addTag + id + "/tag/", tags);
            fetchPost
              .post(apiUrl.createOption + id + "/answers", Object.values(obj))
              .then((res) => {
                setPublish(true);
              })
              .catch((error) => {
                console.log(error);
                setPublish(false);
                props.enqueueSnackbar("option field may not be blank", {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  TransitionComponent: Zoom,
                  autoHideDuration: 5000,
                  resumeHideDuration: 2000,
                });
              });
          })
          .catch((error) => {
            setPublish(false);
            if (error.response.status === 404) {
              props.enqueueSnackbar(
                "Oops! Something went wrong. We are working on fixing the problem.",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  TransitionComponent: Zoom,
                  autoHideDuration: 5000,
                  resumeHideDuration: 2000,
                }
              );
            } else {
              props.enqueueSnackbar(error.response.data.error.message, {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                TransitionComponent: Zoom,
                autoHideDuration: 5000,
                resumeHideDuration: 2000,
              });
            }
          })
      : props.enqueueSnackbar(
          "Please Add Atleast Two Options And Select Atleast One Correct Answer",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Zoom,
            autoHideDuration: 5000,
            resumeHideDuration: 2000,
          }
        );
  };

  const handleImageTextInput = (editor, field) => {
    var data = editor.getData();
    var s = "";
    if (data.match(/<img[^>]+src="?([^"\s]+)"?\s*>/g)) {
      editor.isReadOnly = true;
      var data1 = data.matchAll(/<img[^>]+src="?([^"\s]+)"?\s*>/g);
      data1 = [...data1];
      data1.map((s1) => (s += s1));
      data = s.split(",")[0];
    }
    handleCkEditor(data, field);
  };

  const Draft = () => {
    const data1 = {
      question_name: value0,
      department: dept ? dept : examiner_dept,
      experience_level: Level,
      question_status: "draft",
      question_type: "objective",
      time_duration: Number(t1) * 3600 + Number(t2) * 60 + Number(t3),
      question_category: value,
    };
    count >= 2 && correct_Count > 0
      ? fetchPost
          .post(apiUrl.createQuestion, data1)
          .then((res) => {
            const id = res.data.data.id;
            fetchPost.post(apiUrl.addTag + id + "/tag/", tags);
            fetchPost
              .post(apiUrl.createOption + id + "/answers", Object.values(obj))
              .then((res) => {
                setDraft(true);
              })
              .catch((error) => {
                setDraft(true);
                props.enqueueSnackbar("option field may not be blank", {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  TransitionComponent: Zoom,
                  autoHideDuration: 5000,
                  resumeHideDuration: 2000,
                });
              });
          })
          .catch((error) => {
            setDraft(false);
            if (error.response.status === 404) {
              props.enqueueSnackbar(
                "Oops! Something went wrong. We are working on fixing the problem.",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  TransitionComponent: Zoom,
                  autoHideDuration: 5000,
                  resumeHideDuration: 2000,
                }
              );
            } else {
              props.enqueueSnackbar(error.response.data.error.message, {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                TransitionComponent: Zoom,
                autoHideDuration: 5000,
                resumeHideDuration: 2000,
              });
            }
          })
      : props.enqueueSnackbar(
          "Please Add Atleast Two Options And Select Atleast One Correct Answer",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Zoom,
            autoHideDuration: 5000,
            resumeHideDuration: 2000,
          }
        );
  };
  useEffect(() => {
    fetchClient.get(`${apiUrl.deptList}?page_size=1000`).then((res) => {
      setDeptlist(res && res.data && res.data.data && res.data.data.results);
      console.log("--------------dept------------", res.data.data.results);
    });
    fetchClient.get(`${apiUrl.createCategory}`).then((res) => {
      setJobList(res.data.data.results);
      console.log("----------This is blah blah--------", res.data.data.results);
    });
  }, []);

  // Hide and Show Top Bar
  useEffect(() => {
    let scrollPos = 0;
    const listenToScroll = () => {
      const winScroll =
        document.querySelector("#main-content")?.scrollTop ||
        document.documentElement.scrollTop;
      if (winScroll <= 50) {
        setTopBarVisible(true);
      } else {
        setTopBarVisible(false);
      }
      scrollPos =
        document.querySelector("#main-content")?.scrollTop ||
        document.documentElement.scrollTop;
    };
    document
      .querySelector("#main-content")
      ?.addEventListener("scroll", listenToScroll);
    return () =>
      document
        .querySelector("#main-content")
        ?.removeEventListener("scroll", listenToScroll);
  }, []);

  const onAddLevel = (e) => {
    const value = e.target.value;
console.log("valuesd",value)
    if (value === "senior") {
      setcheckBox([true, false, false,false]);
      setLevel(value);
    }
    if(value==="associate"){
      setcheckBox([false, true, false,false]);
      setLevel(value);

    }
    if (value === "junior") {
      setcheckBox([false, false, true,false]);

      setLevel(value);
    }
    if (value === "internship") {
      setcheckBox([false, false, false,true]);

      setLevel(value);
    }
 
  };

  return (
    <Container
      container
      maxWidth="xl"
      className={classes.container}
      style={{
        height: "calc(100vh - 120px)",
      }}
    >
      <HeaderWithBreadcrumb
        heading="Add New Question"
        breadCrumb
        breadCrumb1="Question"
        breadCrumb2="Add new Question"
        link={`/${role}/question-bank/questions`}
      />
      {publish ? (
        <SuccessDialog
          heading="Question Published"
          path={`/${role}/question-bank/questions`}
        />
      ) : null}
      {draft ? (
        <SuccessDialog
          heading="Question Saved In Draft"
          path={`/${role}/question-bank/questions`}
        />
      ) : null}

      <PageTopBar heading="Question">
        <Grid item>
          <CustomButton
            type="button"
            Buttontype="submit"
            label="Save Draft"
            handleButtonClick={handleDraft}
          />
        </Grid>
        <Grid item>
          <CustomButton
            handleButtonClick={handlePublish}
            type="submit"
            Buttontype="save"
            label="Publish"
          />
        </Grid>
      </PageTopBar>
      <Grid
        container
        style={{
          height: "100%",
          padding: "10px 0px 120px 0px",
        }}
      >
        {/* Choose Department */}
        <Grid container>
          {role === "examiner" ? null : (
            <Grid item xs={10} sm={6} md={4} style={{ paddingRight: "20px" }}>
              <InputLabel className={classes1.label}>Department*</InputLabel>

              <Autocomplete
                className={classes1}
                style={{ marginBottom: "20px" }}
                disableClearable
                input={<OutlinedInput />}
                value={dept}
                options={deptLists}
                // style={{ marginTop: "10px", width: "400px" }}
                getOptionLabel={(option) => capitalize(option.name) || ""}
                defaultValue={dept}
                onChange={(e, newValue) =>
                  newValue ? setDept(newValue.id) : null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="medium"
                    placeholder="Select department"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={10} sm={6} md={4} style={{ paddingRight: "20px" }}>
            <InputLabel className={classes1.label}>Category</InputLabel>

            <Autocomplete
              className={classes1}
              style={{ marginBottom: "20px" }}
              disableClearable
              input={<OutlinedInput />}
              // value={value}
              options={jobList}
              // style={{ marginTop: "10px", width: "300px" }}
              getOptionLabel={(option) => capitalize(option.name) || ""}
              onChange={(e, newValue) =>
                newValue ? setValue(newValue.id) : null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="medium"
                  placeholder="Select category"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
        {/* Question Type */}

        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="left"
          justify="left"
        >
          <Grid>
            <InputLabel className={classes1.label}>Question Level</InputLabel>
            <Grid container>
              <Grid item xs={6} md="auto">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={checkBox[0]}
                      onChange={onAddLevel}
                    />
                  }
                  label="Senior"
                  value="senior"
                />
              </Grid>
              <Grid item xs={6} md="auto">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={checkBox[1]}
                      onChange={onAddLevel}
                    />
                  }
                  label="Associate"
                  value="associate"
                />
              </Grid>
              <Grid item xs={6} md="auto">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={checkBox[2]}
                      onChange={onAddLevel}
                    />
                  }
                  label="Junior"
                  value="junior"
                />
              </Grid>
              <Grid item xs={6} md="auto">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={checkBox[3]}
                      onChange={onAddLevel}
                    />
                  }
                  label="Internship"
                  value="internship"
                />
              </Grid>
            </Grid>
            {/* <RadioGroup
            value={Level}
            onChange={(e) => {
              setLevel(e.target.value);
            }}
            aria-label="gender"
            name="gender1"
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "0 0 0 2%",
            }}
          >
            <FormControlLabel
              value="senior"
              control={<Checkbox />}
              label="Senior"
            />
            <FormControlLabel
              value="junior"
              control={<Checkbox />}
              label="Junior"
            />
            <FormControlLabel
              value="internship"
              control={<Checkbox />}
              label="Internship"
            />
          </RadioGroup> */}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="left"
          justify="left"
          style={{ paddingTop: "17px" }}
        >
          <InputLabel className={classes1.label}>Question Type</InputLabel>

          <Grid container>
            <Grid item xs={6} md="auto">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={!checked}
                    onChange={handleQuestionTypeChange}
                  />
                }
                label="Objective"
                value="objective"
              />
            </Grid>
            <Grid item xs={6} md="auto">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={checked}
                    onChange={handleQuestionTypeChange}
                  />
                }
                label="Subjective"
                value="subjective"
              />
            </Grid>

            {/* </RadioGroup> */}
          </Grid>
          <Divider style={{ marginBottom: "20px" }} />
          <Grid container>
            <Grid item xs={12}>
              <InputLabel
                className={classes1.label}
                style={{ marginBottom: "20px" }}
              >
                Add Question
              </InputLabel>
            </Grid>
            <Grid item xs={10} sm={8} md={7}>
              <CKEditor
                className="ck-content"
                editor={Editor}
                config={{
                  simpleUpload: {
                    uploadUrl: apiUrl.ckEditorImageUpload,
                    withCredentials: true,
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "API_TOKEN"
                      )}`,
                    },
                  },
                }}
                data=""
                onReady={(editor) => {}}
                onChange={(event, editor) => {
                  var data = editor.getData();
                  setValue0(data);
                }}
              />
            </Grid>
          </Grid>
          <Divider style={{ marginTop: "23px", marginBottom: "23pxx  " }} />
        </Grid>
        {/* Answers */}
        {questionType === "objective" ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            align="left"
            justify="left"
          >
            <Grid container>
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <InputLabel className={classes1.label}>Add Answer</InputLabel>
              </Grid>
              {Object.keys(obj).map((field, index) => {
                return (
                  <div style={{ display: "flex" }} key={index}>
                    <div
                      className={clsx("option_wrapper", classes.options_style)}
                    >
                      <label className="container">
                        <input
                          type="checkbox"
                          checked={obj[field].is_correct}
                          onChange={(e) => {
                            checkbox(e, field);
                          }}
                        />
                        <span className="mark"></span>
                      </label>
                      <div className={classes.editor_style}>
                        <CKEditor
                          id={field}
                          className="ck-content"
                          editor={Editor}
                          config={{
                            simpleUpload: {
                              uploadUrl: apiUrl.ckEditorImageUpload,
                              withCredentials: true,
                              headers: {
                                Authorization: `Bearer ${localStorage.getItem(
                                  "API_TOKEN"
                                )}`,
                              },
                            },
                          }}
                          data={obj[field].answer_text}
                          onReady={(editor) => {}}
                          onChange={(event, editor) => {
                            handleImageTextInput(editor, field);
                          }}
                          onBlur={(event, editor) => {}}
                          onFocus={(event, editor) => {}}
                        />
                      </div>
                      <div key={field}>
                        <Button onClick={() => handleRemove(field)}>
                          <img src="/assets/Icons/cross_icon.svg" alt="" />
                        </Button>
                      </div>
                    </div>
                    <div>
                      {" "}
                      {obj[field].is_correct ? (
                        <h4
                          style={{
                            width: "140px",

                            fontSize: "20px",
                            color: "#D9001D",
                          }}
                        >
                          correct answer
                        </h4>
                      ) : null}
                    </div>
                  </div>
                );
              })}
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    handleIncrement();
                  }}
                  style={{ marginTop: "20px" }}
                >
                  <img
                    src="/assets/Icons/add_icon.svg"
                    alt=""
                    style={{ marginRight: "10px" }}
                  />
                  <span className={classes.page_subheading}>Add Option</span>
                </Button>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: "23px" }} />
          </Grid>
        ) : null}

        <Grid container xs={12} style={{ padding: "20px 0px" }}>
          <Grid component={Box} direction="row" container item lg={3} xs={12}>
            <Grid container style={{ padding: "20px 0px" }}>
              <InputLabel className={classes1.label}>Timer</InputLabel>
            </Grid>
            <Grid container>
              <input
                type="number"
                min="0"
                max="12"
                style={{ width: "auto" }}
                onChange={(e) => {
                  setT1(e.target.value);
                }}
                value={t1 ? Math.min(23, t1) : null}
                className={classes.time_label}
              />
              <span style={{ marginLeft: "5px", marginRight: "6px" }}>h :</span>
              <input
                type="number"
                min="0"
                max="59"
                style={{ width: "auto" }}
                onChange={(e) => {
                  setT2(e.target.value);
                }}
                value={t2 ? Math.min(59, t2) : null}
                className={classes.time_label}
              />
              <span style={{ marginLeft: "5px", marginRight: "6px" }}>m :</span>
              <input
                type="number"
                min="0"
                max="59"
                style={{ width: "auto" }}
                onChange={(e) => {
                  setT3(e.target.value);
                }}
                value={t3 ? Math.min(59, t3) : null}
                className={classes.time_label}
              />
              <span style={{ marginLeft: "5px" }}>s</span>
            </Grid>
          </Grid>
          {questionType === "subjective" ? (
            <Grid
              container
              alignItems="center"
              justifyContent="space-evenly"
              item
              sm={4}
              xs={12}
            >
              {/* <IconButton disableRipple className={classes.dividerWrapper}>
                <img src={DividerIcon} alt="divider" />
              </IconButton> */}
              <Grid container style={{ margin: "20px 0" }}>
                <InputLabel className={classes1.label}>Total Marks</InputLabel>
              </Grid>
              <Grid container>
                <input
                  type="number"
                  min="0"
                  max="100"
                  style={{ width: "auto" }}
                  onChange={(e) => {
                    setTotalMarks(e.target.value);
                  }}
                  value={totalMarks ? Math.min(100, totalMarks) : null}
                  className={classes.time_label}
                />
              </Grid>
            </Grid>
          ) : null}

          {/* <IconButton disableRipple className={classes.dividerWrapper}>
            <img src={DividerIcon} alt="divider" />
          </IconButton> */}

          <Grid
            container
            // justifyContent="space-between"
            spacing={3}
            alignItems="center"
            item
            xs={12}
            lg="auto"
          >
            <Grid
              alignItems="center"
              item
              xs="auto"
              style={{ marginTop: "30px" }}
            >
              <InputLabel className={classes1.label}>Tags</InputLabel>
            </Grid>
            <Grid container alignItems="center" item="auto">
              <ChipInput
                disableUnderline
                placeholder="Type here"
                alwaysShowPlaceholder={true}
                delayBeforeAdd={true}
                // onKeyUp={(e) => tagChange(e)}
                value={tag}
                onAdd={(chip) => handleAddChip(chip)}
                onDelete={(chip, index) => handleDeleteChip(chip, index)}
              />
            </Grid>
            {tagList.map((qw) => (
              <MenuItem
                value={qw.tag_name}
                onClick={(e) => {
                  handleAddChip(qw.tag_name);
                }}
              >
                {qw.tag_name}
              </MenuItem>
            ))}
          </Grid>
        </Grid>
        <Divider style={{ marginTop: "20px" }} />
      </Grid>
    </Container>
  );
};
export default withSnackbar(AddNewQuestion);
