import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";

import { commonStyle } from "commonStyle.js";

import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),
}));

const LeftBox = ({ heading, message }) => {
  const classes = useStyles();

  return (
    <Box
      item
      xs={4}
      sm={4}
      md={4}
      lg={4}
      // padding={2}
      component={Grid}
      display={{ xs: "none", md: "block" }}
    >
      <Box className={classes.leftBoxWrapper} p={2}>
        <Box
          className={classes.leftbox}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box p={6} sx={{ flex: "1" }}>
            <AnimatePresence>
              <motion.div
                drag
                dragConstraints={{
                  top: -10,
                  left: -10,
                  right: 10,
                  bottom: 10,
                }}
                // whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  duration: 0.3,
                  // type: "spring",
                }}
                exit={{ opacity: 0 }}
              >
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Box sx={{ height: "50px !important" }}>
                    <img
                      src="/assets/Icons/Fmw_logo.png"
                      alt="nickelfoxlogo"
                      loading="lazy"
                      style={{
                        width: "inherit",
                        height: "inherit",
                        transition: "width 2s",
                      }}
                    />
                  </Box>
                </Link>
              </motion.div>
            </AnimatePresence>
          </Box>
          <Box p={6} sx={{ flex: "4" }}>
            <AnimatePresence>
              <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{
                  delay: 0.4,
                  type: "spring",
                }}
              >
                <Typography className={classes.leftbigtext}>
                  {heading}
                </Typography>
              </motion.div>
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  delay: 0.6,
                  type: "spring",
                }}
              >
                <Typography className={classes.lefttext}>{message}</Typography>
              </motion.div>
            </AnimatePresence>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LeftBox;
