import Actions from "../actions/tableActions";

let initialState = {
  columnHeading: [],
  columnData: [],
  pages: null,
  count: null,
  tableHeading: "",
  profile: "",
  assignExam: {},
  count: null,
  pageNumber: null,
};

const tableReducer2 = (state = initialState, action) => {
  console.log("columnData123", state.columnData);
  switch (action.type) {
    case Actions.SET_TABLE_COLUMN_HEADING:
      return {
        ...initialState,
        columnHeading: action.data,
      };

    case Actions.SET_TABLE_ROW:
      return {
        ...state,
        columnData: action.data,
        tableHeading: action.tableHeading,
        profile: action.profile,
        count: action.count,
        pageNumber: action.pageNumber,
      };

    default:
      return state;
  }
};

export default tableReducer2;
