import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import AppReducer from "./reducers/appReducer";
import pageHeaderReducer from "./reducers/pageHeaderReducer";
import filterReducer from "./reducers/filterReducer";
import tableReducer from "./reducers/tableReducer";
import permissionReducer from "./reducers/permissionReducer";
import searchReducer from "./reducers/searchReducer";
import popupReducer from "./reducers/popupReducer";
import candidateStatusReducer from "./reducers/candidateStatusReducer";
import selectQuestionReducer from "./reducers/selectQuestionReducer";
import tableReducer2 from "./reducers/tableReducer2";

const PersistConfig = {
  key: "root",
  storage,
  whitelist: ["app"],
};

const AllReducer = {
  app: AppReducer,
  pageHeader: pageHeaderReducer,
  filter: filterReducer,
  table: tableReducer,
  table2: tableReducer2,
  permission: permissionReducer,
  search: searchReducer,
  popupRedux: popupReducer,
  candidateStatusReducer: candidateStatusReducer,
  selectQuestionReducer: selectQuestionReducer,
};

const rootReducer = combineReducers(AllReducer);
const persistedReducer = persistReducer(PersistConfig, rootReducer);

const store = createStore(persistedReducer);
export const persistor = persistStore(store);
export default store;
