import {
  Paper,
  useHistory,
  useEffect,
  fetchClient,
  apiUrl,
  CssBaseline,
  Topbar,
  Sidebar,
  React
} from "allImport";
import Commonprofile from "components/Commonprofile/Commonprofile";
import useStyles from "style/Profilestyle.js";
import { capitalize } from "Common/CommonFunction";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
  SUB_ADMIN_PERMISSION
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import CommonProfileComponent from "components/Commonprofile/CommonProfileComponent";
export default function Jobprofile(props) {
  const classes = useStyles();
  const [jobProfileDetails, setJobProfileDetails] = React.useState([]);
  const [jobProfileDetails1, setJobProfileDetails1] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const history = useHistory();
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const jobID =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.profileId;
  const getPermissionsForSubAdmin = () => {
    let permission = {};
    if (role === "sub-admin") {
      let allPermissions = StorageManager.getJSON(SUB_ADMIN_PERMISSION);
      permission.edit = allPermissions.includes("EDIT_JOB");
      permission.delete = allPermissions.includes("DELETE_JOB");
    }
    return permission;
  };

  useEffect(() => {
    if (
      !StorageManager.get(API_TOKEN) &&
      !StorageManager.get(ADMIN_ID) &&
      !StorageManager.get(ADMIN_NAME) &&
      !StorageManager.get(LOGOUT_TOKEN)
    ) {
      history.push("/login");
    }
    fetchClient.get(`${apiUrl.jobProfile}${jobID}/profile/`).then((res) => {
      setLoader(true);
      setJobProfileDetails(res && res.data && res.data.data);
      setJobProfileDetails1(
        res && res.data && res.data.data && res.data.data.department
      );
    });
  }, []);
  console.log("jobProfileDetails1", jobProfileDetails);
  const data = {
    MainHeading: jobProfileDetails.job_title,
    data: [
      ["Title", jobProfileDetails.job_title],
      ["Department", jobProfileDetails1.name],
      ["Exaperience Level", capitalize(jobProfileDetails.job_level)],
      ["Status", capitalize(jobProfileDetails.status)],
      ["ID", jobID]
    ],

    editAction: {
      editpath: `/${role}/job/edit`,
      jobID: jobProfileDetails1.id,
      jobDepts: jobProfileDetails1.name,
      jobTitle: jobProfileDetails.job_title,
      jobLevel: jobProfileDetails.job_level,
      allow: SUB_ADMIN_PERMISSION
        ? SUB_ADMIN_PERMISSION.includes("EDIT_JOB")
        : true
    },
    editPath: `/${role}/job/edit`,
    tableType: "Job",
    jobID: jobID,
    home: `/${role}/job`,
    permissions: getPermissionsForSubAdmin()
  };

  return (
    <main className={classes.content}>
      {loader ? (
        <CommonProfileComponent data={data} />
      ) : (
        <Paper
          className={classes.paper}
          style={{
            height: "450px",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "200px"
          }}
        >
          <img
            src="/assets/Icons/nickelfox_loader.gif"
            width="100px"
            height="100px"
            alt="nickelfoxlogo"
          />
        </Paper>
      )}
    </main>
  );
}
