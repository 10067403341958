import {
  useHistory,
  useEffect,
  fetchClient,
  apiUrl,
  React,
  useState,
} from "allImport";
import { useStyles } from "style/Liststyle.js";
import { convertUTCDateToLocalDate } from "Common/CommonFunction";

import TablePageLayout from "layout/TablePageLayout";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import { useDispatch, useSelector } from "react-redux";
import Actions from "redux/actions/filterActions";
import { API, ENDPOINTS } from "../../../api/apiService";
import TableActions from "redux/actions/tableActions";
import CommonTable from "components/CommonComponent/Filter/CommonTable/CommonTable";
import { Pages } from "@material-ui/icons";
const queryString = require("query-string");
var url = new URL(`${apiUrl.viewSubadmins}`);
var AS = [];
var searchValue = "";
var jobIDs = [];
export default function SubadminList() {
  const classes = useStyles();
  const history = useHistory();
  const [data, setAllData] = React.useState({});
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const ADMIN_ID = localStorage.getItem("ADMIN_ID")
    ? localStorage.getItem("ADMIN_ID").toLowerCase()
    : null;
  const [deptSelectionVal, setDept] = React.useState("");
  const [totalPage, setTotalPage] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [count, setCount] = React.useState("");
  const [subadminSearch, setSubadminSearch] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [topbarVisible, setTopBarVisible] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [page2, setPage2] = useState("");
  const [pageColor, setPageColor] = React.useState(null);
  const { searchData } = useSelector((store) => store.search);
  const { reset } = useSelector((store) => store.table);

  const { dataArray } = useSelector((store) => store.filter);
  const handleChangePage = (event, value) => {
    console.log("paaagess", page);
    let url = `${apiUrl.viewSubadmins}?page=` + value + `&${page2}`;
    url += subadminSearch === "" ? "" : `&search=${subadminSearch}`;
    url += status === "" ? "" : `&status=${status}`;
    url += deptSelectionVal === "" ? "" : `&department=${deptSelectionVal}`;
    fetchClient.get(url).then((res) => {
      var SubadminsList =
        res && res.data && res.data.data && res.data.data.results;
      setCount(res && res.data && res.data.data && res.data.data.count);
      // colmConversion(SubadminsList);
    });
    setPageColor(value);
    setPage(value);
  };

  const handleChangeFilter = (e) => {
    setStatus("");
    setDept("");
    setSubadminSearch(e.target.value);
    searchValue = e.target.value;
    fetchClient
      .get(`${apiUrl.viewSubadmins}?search=` + searchValue)
      .then((res) => {
        var subadminsList =
          res && res.data && res.data.data && res.data.data.results;
        setTotalPage(res && res.data && res.data.data && res.data.data.pages);
        setCount(res && res.data && res.data.data && res.data.data.count);
        // colmConversion(subadminsList);
      });
  };

  const subadminFilterSelection = (e, type) => {
    setSubadminSearch("");
    if (type === "Status") {
      setStatus(e.target.value);
      if (e.target.value !== "") {
        url.searchParams.set("status", e.target.value);
      } else {
        url.searchParams.delete("status");
      }
    }
    if (type === "departmentSelect") {
      setDept(e.target.value);

      if (e.target.value !== "") {
        url.searchParams.set("department", e.target.value);
      } else {
        url.searchParams.delete("department");
      }
    }

    fetchClient.get(url).then((res) => {
      var subadminsList =
        res && res.data && res.data.data && res.data.data.results;
      setCount(res && res.data && res.data.data && res.data.data.count);
      setTotalPage(res && res.data && res.data.data && res.data.data.pages);
      // colmConversion(subadminsList);
    });
  };

  useEffect(() => {
    fetchClient.get(`${apiUrl.viewSubadmins}`).then((res) => {
      setTotalPage(res && res.data && res.data.data && res.data.data.pages);
      setCount(res && res.data && res.data.data && res.data.data.count);
      const SubadminsArray =
        res && res.data && res.data.data && res.data.data.results;
      // colmConversion(SubadminsArray);
      setLoader(true);
    });
  }, []);

  useEffect(() => {
    setPageColor(1);
    setPage(1);
  }, [dataArray, searchData]);

  const EXAM_STATUS = [
    { label: "Status", value: "status" },
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    let isCanclled = false;
    const handleChange = async () => {
      await timeout(200);
      if (!isCanclled) {
        fetchRecord();
      }
    };
    handleChange();
    return () => {
      isCanclled = true;
    };
  }, [page, reset]);

  useEffect(() => {
    let isCanclled = false;
    const handleChange = async () => {
      await timeout(200);
      if (!isCanclled) {
        fetchRecord();
        setPage(1);
      }
    };
    handleChange();
    return () => {
      isCanclled = true;
    };
  }, [dataArray, searchData]);

  const queryBuilder = (url) => {
    const options1 = queryString.stringify({
      ...dataArray,
    });

    setPage2(options1);

    return `${url}?&${options1}${searchData ? `&search=${searchData}` : ""}${
      page ? `&page=${page}` : " "
    }`;
  };

  useEffect(() => {
    console.log(
      localStorage.getItem("SUB_ADMIN_PERMISSION"),
      "SUB_ADMIN_PERMISSION"
    );
    const allowActionColumn =
      localStorage.getItem("ROLE") === "SUB-ADMIN" &&
      localStorage.getItem("SUB_ADMIN_PERMISSION") &&
      !localStorage.getItem("SUB_ADMIN_PERMISSION").includes("EDIT_SUBADMIN") &&
      !localStorage.getItem("SUB_ADMIN_PERMISSION").includes("DELETE_SUBADMIN");
    dispatch({
      type: TableActions.SET_TABLE_COLUMN_HEADING,
      data: [
        "ID",
        "Name",
        "Status",
        "Date Added",
        !allowActionColumn && "Action",
      ],
    });
  }, []);

  const fetchRecord = async () => {
    try {
      setLoader(true);

      const query = queryBuilder(ENDPOINTS.SUBADMIN);
      console.log("query", query);
      const resp = await API.get(query);

      if (resp.success) {
        const result = resp.data;
        const count = result.count;
        console.log("respooop", result.results);
        const subadminData = result.results.map((item) => {
          return {
            0: item.id,
            1: item.full_name,
            2: item.status,
            3: convertUTCDateToLocalDate(
              new Date(item.created_at)
            ).toLocaleDateString("en-IN"),

            editAction: {
              editpath: `/${role}/subadmins/edit-subadmin`,
              SubAdminID: item.id,
              SubAdminName: item.full_name,
              SubAdminEmail: item.email,
              SubAdminDesignation: item.designation,
              allow: SUB_ADMIN_PERMISSION
                ? SUB_ADMIN_PERMISSION.includes("EDIT_SUBADMIN") &&
                  ADMIN_ID !== item.id
                : true,
            },
            deleteAction: {
              actionID: item.id,
              tableType: "Sub-Admin",
              allow: SUB_ADMIN_PERMISSION
                ? SUB_ADMIN_PERMISSION.includes("DELETE_SUBADMIN")
                : true,
            },
       
          };
        });

        dispatch({
          type: TableActions.SET_TABLE_ROW,
          data: subadminData,
          tableHeading: "Sub Admin",
          profile: `/${role}/subadmins/profile`,
          count: count,
        });
        dispatch({
          type: TableActions.SET_PAGINATION,
          data: { pages: result.pages, count: result.count },
        });

        setTotalPage(result.pages);
        setCount(result.count);
        // colmConversion(result.results);
      }
    } finally {
      setLoader(true);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: Actions.SET_FILTER, count: 1, data: [EXAM_STATUS] });
  }, []);

  // Hide and Show Top Bar
  let SUB_ADMIN_PERMISSION = localStorage.getItem("SUB_ADMIN_PERMISSION");
  console.log("macros", SUB_ADMIN_PERMISSION);

  return (
    <>
      <HeaderWithBreadcrumb heading="Sub Admins" />
      <TablePageLayout
        heading="Sub-Admins"
        loader={loader}
        page={page}
        ButtonText="Add Sub-Admin"
        handleChangePage={handleChangePage}
        count={count}
        allowCreate={
          SUB_ADMIN_PERMISSION
            ? SUB_ADMIN_PERMISSION.includes("CREATE_SUBADMIN")
            : true
        }
        buttonActionPath={`/${role}/subadmins/create-subadmin`}
        handleChangeFilter={(e) => handleChangeFilter(e)}
        candidateFilter={{
          type: "subadmin",
          subadminStatus: { status },
          subadminStatusSelection: (e) => subadminFilterSelection(e, "Status"),
        }}
      >
        <CommonTable
          handleChangePage={handleChangePage}
          Count={totalPage}
          count={count}
          pageColor={pageColor}
        />
      </TablePageLayout>
    </>
  );
}
