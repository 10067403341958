import {
  useEffect,
  React,
  FormControlLabel,
  withStyles,
  Checkbox,
  apiUrl,
  fetchClient,
  fetchPost,
  Divider,
  SuccessDialog,
  useState,
} from "allImport";
import {
  Box,
  Container,
  Grid,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import { FormikProvider, useFormik } from "formik";
import { SubadminInitialValues } from "components/Form/InitialValues/SubadminInitialValues";
import SubadminValidation from "components/Form/validations/SubadminValidation";
import BasicInput from "components/Form/InputBoxes/BasicInput";
import CustomButton from "components/Button/CustomButton";
const useStyles = makeStyles((theme) => ({
  permissionLabel: {
    color: "#383838",
    fontWeight: "800",
    fontSize: "16px",
  },
  container: {
    // marginTop: "48px",
    // marginRight: 15,
    // paddingBottom: theme.spacing(4),
    border: "1px solid #E5E5E5",
    padding: "16px 16px 16px 16px",
    borderRadius: "8px",
    width: "auto !important",
  },
  containerBox: {
    // marginTop: "48px",
    // marginRight: 15,
    // paddingBottom: theme.spacing(4),
    border: "1px solid #E5E5E5",
    padding: "16px 16px 0 16px",
    borderRadius: "8px",
    width: "auto !important",
    marginTop: "20px",
  },
}));
const permissionData = [
  {
    Permissionlabel: "Candidate",
    data: [
      {
        label: "View Profile",
        value: "VIEW_CANDIDATE",
        onChangeData: [
          "CREATE_CANDIDATE",
          "EDIT_CANDIDATE",
          "DELETE_CANDIDATE",
          "GENERATE_EXAM_LINK",
          "HIRE_REJECT_CANDIDATE",
        ],
      },
      {
        label: "Create",
        value: "CREATE_CANDIDATE",
        onChangeData: "VIEW_CANDIDATE",
      },
      {
        label: "Edit",
        value: "EDIT_CANDIDATE",
        onChangeData: "VIEW_CANDIDATE",
      },
      {
        label: "Delete",
        value: "DELETE_CANDIDATE",
        onChangeData: "VIEW_CANDIDATE",
      },
      {
        label: "Generate Exam Link",
        value: "GENERATE_EXAM_LINK",
        onChangeData: "VIEW_CANDIDATE",
      },
      {
        label: "Qualify/Disqualify",
        value: "HIRE_REJECT_CANDIDATE",
        onChangeData: "VIEW_CANDIDATE",
      },
    ],
  },
  {
    Permissionlabel: "Examiner",
    data: [
      {
        label: "View Profile",
        value: "VIEW_EXAMINER",
        onChangeData: ["CREATE_EXAMINER", "EDIT_EXAMINER", "DELETE_EXAMINER"],
      },
      {
        label: "Create",
        value: "CREATE_EXAMINER",
        onChangeData: "VIEW_EXAMINER",
      },
      {
        label: "Edit",
        value: "EDIT_EXAMINER",
        onChangeData: "VIEW_EXAMINER",
      },
      {
        label: "Delete",
        value: "DELETE_EXAMINER",
        onChangeData: "VIEW_EXAMINER",
      },
    ],
  },
  {
    Permissionlabel: "Exam",
    data: [
      {
        label: "View Profile",
        value: "VIEW_EXAM",
        onChangeData: ["CREATE_EXAM", "EDIT_EXAM", "DELETE_EXAM"],
      },
      {
        label: "Create",
        value: "CREATE_EXAM",
        onChangeData: "VIEW_EXAM",
      },
      {
        label: "Edit",
        value: "EDIT_EXAM",
        onChangeData: "VIEW_EXAM",
      },
      {
        label: "Delete",
        value: "DELETE_EXAM",
        onChangeData: "VIEW_EXAM",
      },
    ],
  },
  {
    Permissionlabel: "Question Bank",
    data: [
      {
        label: "View Profile",
        value: "VIEW_QUESTION_BANK",
        onChangeData: [
          "CREATE_QUESTION_BANK",
          "EDIT_QUESTION_BANK",
          "DELETE_QUESTION_BANK",
        ],
      },
      {
        label: "Create",
        value: "CREATE_QUESTION_BANK",
        onChangeData: "VIEW_QUESTION_BANK",
      },
      {
        label: "Edit",
        value: "EDIT_QUESTION_BANK",
        onChangeData: "VIEW_QUESTION_BANK",
      },
      {
        label: "Delete",
        value: "DELETE_QUESTION_BANK",
        onChangeData: "VIEW_QUESTION_BANK",
      },
    ],
  },
  {
    Permissionlabel: "Category",
    data: [
      {
        label: "View Profile",
        value: "VIEW_CATEGORY",
        onChangeData: ["CREATE_CATEGORY", "EDIT_CATEGORY", "DELETE_CATEGORY"],
      },
      {
        label: "Create",
        value: "CREATE_CATEGORY",
        onChangeData: "VIEW_CATEGORY",
      },
      {
        label: "Edit",
        value: "EDIT_CATEGORY",
        onChangeData: "VIEW_CATEGORY",
      },
      {
        label: "Delete",
        value: "DELETE_CATEGORY",
        onChangeData: "VIEW_CATEGORY",
      },
    ],
  },
  {
    Permissionlabel: "Results",
    data: [
      {
        label: "View Profile",
        value: "VIEW_RESULT",
        onChangeData: ["PASS_FAIL_CANDIDATE"],
      },
      {
        label: "Qualify/Disqualify",
        value: "PASS_FAIL_CANDIDATE",
        onChangeData: "VIEW_RESULT",
      },
    ],
  },
  {
    Permissionlabel: "Vacancies",
    data: [
      {
        label: "View Profile",
        value: "VIEW_JOB",
        onChangeData: ["CREATE_JOB", "EDIT_JOB", "DELETE_JOB"],
      },
      {
        label: "Create",
        value: "CREATE_JOB",
        onChangeData: "VIEW_JOB",
      },
      {
        label: "Edit",
        value: "EDIT_JOB",
        onChangeData: "VIEW_JOB",
      },
      {
        label: "Delete",
        value: "DELETE_JOB",
        onChangeData: "VIEW_JOB",
      },
    ],
  },
  {
    Permissionlabel: "Department",
    data: [
      {
        label: "View Profile",
        value: "VIEW_DEPARTMENT",
        onChangeData: [
          "CREATE_DEPARTMENT",
          "EDIT_DEPARTMENT",
          "DELETE_DEPARTMENT",
        ],
      },
      {
        label: "Create",
        value: "CREATE_DEPARTMENT",
        onChangeData: "VIEW_DEPARTMENT",
      },
      {
        label: "Edit",
        value: "EDIT_DEPARTMENT",
        onChangeData: "VIEW_DEPARTMENT",
      },
    ],
  },
  {
    Permissionlabel: "Sub Admins",
    data: [
      {
        label: "View Profile",
        value: "VIEW_SUBADMIN",
        onChangeData: [
          "CREATE_SUBADMIN",
          "EDIT_SUBADMIN",
          "DELETE_SUBADMIN",
          "GIVE_PERMISSIONS",
        ],
      },
      {
        label: "Create",
        value: "CREATE_SUBADMIN",
        onChangeData: "VIEW_SUBADMIN",
      },
      {
        label: "Edit",
        value: "EDIT_SUBADMIN",
        onChangeData: "VIEW_SUBADMIN",
      },
      {
        label: "Delete",
        value: "DELETE_SUBADMIN",
        onChangeData: "VIEW_SUBADMIN",
      },
      // {
      //   label: "Give Permission",
      //   value: "GIVE_PERMISSIONS",
      //   onChangeData: "VIEW_SUBADMIN",
      // },
    ],
  },
];
export default function CreateSubadmin(props) {
  console.log("propasss", props);
  const [subAdminToggle, setSubAdminToggle] = useState(false);
  const [subAdminPermissions, setsubAdminPermissions] = useState([]);
  const [allowedSubAdminPermissions, setAllowedSubAdminPermissions] = useState(
    {}
  );
  const [permissionLists, setpermissionlist] = useState([]);
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const classes = useStyles();
  const data = {
    MainHeading: "Add Sub Admin",
    MainSubheading1: "Sub Admins Lobby",
    MainSubheading2: "Add Subadmin",
    PaperHeading: "Personal Details",
    Type: "Subadmin",
    Labels: {
      label1: "Full Name",
      label3: "Email ID ",
      label4: "Designation",
      label5: "Department",
    },
    DetailBox: {
      box1: "",
      box3: "",
      box4: "",
    },
    Placeholder: {
      placeholder1: "Enter full name",
      placeholder3: "Enter registered email id",
      placeholder4: "Enter designation",
    },
    Button: {
      button1: "Cancel",
      button2: "Save",
    },
    height: "312px",
    field: "Add",
    fieldType: "addSubadmin",
    home: `/${role}/subadmins`,
  };
  const formik = useFormik({
    initialValues: SubadminInitialValues,
    validationSchema: SubadminValidation,
    onSubmit: (values) => {
      const data = {
        full_name: values.full_name,
        email: values.email,
        designation: values.designation,
        permission: subAdminPermissions,
      };
      console.log("daatgggg", data);
      fetchPost
        .post(`${apiUrl.addSubadmin}`, data)
        .then((resp) => setSubAdminToggle(true))
        .catch((error) => {
          if (error.response.data.code === 404) {
            // props.enqueueSnackbar(
            //   "Oops! Something went wrong. We are working on fixing the problem.",
            //   {
            //     variant: "error",
            //     autoHideDuration: 3000,
            //   }
            // );
          } else if (error.response.data.error) {
            error.response.data.error.message.forEach((message) => {
              console.log("oopppp", message);
              alert(message);
              // props.enqueueSnackbar(message, {
              //   variant: "error",
              //   autoHideDuration: 3000,
              // });
            });
          }
          setSubAdminToggle(false);
        });
    },
  });
  useEffect(() => {
    const initializePermissions = (allPermissions) => {
      if (data.fieldType === "editSubadmin") {
        let array = [];
        for (let p of props.data.Permissions) {
          for (let data of allPermissions) {
            if (data.permission === p) {
              array.push(data.id);
            }
          }
        }
        setsubAdminPermissions([...array]);
        let allowedPermissions = { ...allowedSubAdminPermissions };
        props.data.Permissions.map((e) => {
          allowedPermissions[e] = true;
        });
        setAllowedSubAdminPermissions(allowedPermissions);
      }
    };
    fetchClient.get(`${apiUrl.permission}`).then((res) => {
      console.log("apiUrl.permission", apiUrl.permission);
      setpermissionlist(res && res.data && res.data.data);
      initializePermissions(res.data.data);
    });
  }, []);
  const Checkboxblack = withStyles({
    root: {
      "& .MuiSvgIcon-root": {
        fill: "white",
        height: "15px",
        width: "15px",
        "&:hover": {
          backgroundColor: "white",
        },
      },
      "&$checked": {
        "& .MuiIconButton-label": {
          position: "relative",
          zIndex: 0,
          border: "2px solid black",
        },
        "& .MuiIconButton-label:after": {
          content: '""',
          height: 15,
          width: 15,
          position: "absolute",
          backgroundColor: "black",
          border: "2px solid white",
          zIndex: -1,
        },
      },
      "&:not($checked) .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
        border: "2px solid black",
      },
      "&:not($checked) .MuiIconButton-label:after": {
        content: '""',
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "white",
        zIndex: -1,
      },
    },
    checked: {},
  })(Checkbox);
  const removeSubAdminPermission = (e, permissionNames) => {
    let permissions = [...subAdminPermissions];
    let allowedPermissions = { ...allowedSubAdminPermissions };
    let permissionsDataSelected = permissionLists.find(
      (data) => data.permission === e.target.value
    );
    let permissionDataOther = [];
    permissionNames.map((permissionName) => {
      permissionDataOther.push(
        permissionLists.find((data) => data.permission === permissionName)
      );
    });
    if (permissions.includes(permissionsDataSelected.id)) {
      permissions.splice(permissions.indexOf(permissionsDataSelected.id), 1);
      allowedPermissions[permissionsDataSelected.permission] = false;
      permissionDataOther.map((data) => {
        if (permissions.includes(data.id)) {
          permissions.splice(permissions.indexOf(data.id), 1);
          allowedPermissions[data.permission] = false;
        }
      });
    } else {
      permissions.push(permissionsDataSelected.id);
      allowedPermissions[permissionsDataSelected.permission] = true;
    }
    setsubAdminPermissions([...permissions]);
    setAllowedSubAdminPermissions({ ...allowedPermissions });
  };
  const addSubAdminPermission = (e, permissionName) => {
    console.log("iiiiioooooooooo", permissionName);
    let permissions = [...subAdminPermissions];
    let allowedPermissions = { ...allowedSubAdminPermissions };
    let permissionsData = [];
    permissionsData.push(
      permissionLists.find((data) => data.permission === e.target.value)
    );
    permissionsData.push(
      permissionLists.find((data) => data.permission === permissionName)
    );
    if (permissions.includes(permissionsData[0].id)) {
      permissions.splice(permissions.indexOf(permissionsData[0].id), 1);
      allowedPermissions[permissionsData[0].permission] = false;
    } else {
      permissions.push(permissionsData[0].id);
      allowedPermissions[permissionsData[0].permission] = true;
      if (!allowedPermissions[permissionsData[1].permission]) {
        permissions.push(permissionsData[1].id);
        allowedPermissions[permissionsData[1].permission] = true;
      }
    }
    setsubAdminPermissions([...permissions]);
    setAllowedSubAdminPermissions({ ...allowedPermissions });
  };
  return (
    <Container
      container
      maxWidth="xl"
      style={{ height: "calc(100vh - 120px)", maxHeight: "auto" }}
    >
      <HeaderWithBreadcrumb
        heading="Add New Sub-Admin"
        breadCrumb
        breadCrumb1="Subadmin"
        breadCrumb2="Add new Sub-Admin"
        link={`/${role}/subadmins`}
      />
      {subAdminToggle ? (
        <SuccessDialog
          type="field"
          heading="Sub Admin Added"
          path={`/${role}/subadmins`}
        />
      ) : null}
      <Grid container item style={{ height: "100%", overflowY: "scroll" }}>
        <Grid className={classes.container} container item xs={12}>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={10}>
                <BasicInput
                  label="Full Name*"
                  placeholder="Enter Full Name"
                  type="full_name"
                  values={formik.values.full_name}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  touched={formik.touched.full_name}
                  errors={formik.errors.full_name}
                />

                <BasicInput
                  label="Email ID*"
                  placeholder="Enter Email ID"
                  type="email"
                  values={formik.values.email}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  touched={formik.touched.email}
                  errors={formik.errors.email}
                />
                <BasicInput
                  label="Designation*"
                  placeholder="Enter Designation"
                  type="designation"
                  values={formik.values.designation}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  touched={formik.touched.designation}
                  errors={formik.errors.designation}
                />

                <Grid container item xs={6} spacing={3}>
                  <Grid item sx={3}>
                    <CustomButton
                      // type="cancel"
                      Buttontype="cancel"
                      label="Cancel"
                    />
                  </Grid>
                  <Grid item sx={3}>
                    <CustomButton
                      type="submit"
                      Buttontype="save"
                      label="Save"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </FormikProvider>
        </Grid>
        <Grid className={classes.containerBox} xs={12}>
          <Grid
            style={{ fontWeight: 600, fontSize: "20px", lineHeight: "32px" }}
          >
            Permissions
          </Grid>
          <Box
            sx={{ mt: 3 }}
            style={{ borderBottom: "1px solid #E5E5E5" }}
          ></Box>
          {permissionData.map((item, index) => {
            return (
              <Box sx={{ mb: 5, mt: 3 }} container>
                <Grid container>
                  <label className={classes.permissionLabel}>
                    {item.Permissionlabel}
                  </label>
                </Grid>
                <Grid sx={{ mt: 2 }} container item xs={12}>
                  {item.data.map((itm, index) => {
                    return (
                      <Grid item sm={6} md={4} lg={3}>
                        <FormControlLabel
                          label={
                            <div
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "18px",
                              }}
                            >
                              {itm.label}
                            </div>
                          }
                          control={
                            <Checkboxblack
                              checked={
                                allowedSubAdminPermissions[itm.value]
                                  ? "checked"
                                  : ""
                              }
                              onChange={(e) =>
                                index == 0
                                  ? removeSubAdminPermission(
                                      e,
                                      itm.onChangeData
                                    )
                                  : addSubAdminPermission(e, itm.onChangeData)
                              }
                              value={itm.value}
                            />
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            );
          })}
        </Grid>
      </Grid>
    </Container>
  );
}
