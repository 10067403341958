import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";

const cardStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "auto",
    width: "40%",
    flexDirection: "column",
  },
  cardWrapper: {
    border: "1px solid #E5E5E5",
    borderRadius: "5px",
    transition: "border 0.5s",
    width: "100%",

    "&:hover": {
      backgroundColor: "#FCF1F0",
      border: "1px solid #DA3C20",
    },
    // padding: "50px",
    // backgroundColor: "red",

    // margin: "16px",
  },
  upperBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    margin: "10px 15px",
    paddingBottom: "5px",
    borderBottom: "1px solid #E5E5E5",
  },
  downBox: {
    padding: "0px 25px 25px 25px",
  },
  h1: {
    color: "#D9001D",
    fontSize: "2rem",
    fontWeight: "bold",
    fontFamily: "Mulish",
  },
  text: {
    fontFamily: "Mulish",
    height: "20px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#383838",
  },
}));

const DashboardCard = ({
  icon,
  text,
  link,
  role,
  notification,
  count,
  children,
}) => {
  const classes = cardStyle();

  return (
    <Grid
      container
      item
      xs={12}
      sm={6}
      md={6}
      lg={3}
      key="root-card"
      style={{ height: "190px" }}
    >
      {/* <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      > */}
      <Grid className={classes.cardWrapper}>
        <Link
          to={{ pathname: `/${role}/${link}` }}
          style={{ textDecoration: "none" }}
        >
          <Grid className={classes.upperBox}>
            <img
              src={icon}
              alt="Avatar"
              style={{ width: "20px", marginRight: "15px" }}
            />
            <p className={classes.text}>{text}</p>
          </Grid>
          <Grid className={classes.downBox}>
            {children ? (
              { children }
            ) : (
              <Typography variant="h1" className={classes.h1}>
                {count}
              </Typography>
            )}
          </Grid>
        </Link>
      </Grid>
      {/* </motion.div> */}
    </Grid>
  );
};

export default DashboardCard;
