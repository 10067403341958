import React, { useState, useEffect, useLayoutEffect } from "react";
import { Box, DeleteAlert } from "allImport";
import "style/style.scss";
import { useDebounce } from "hooks";

import { API, ENDPOINTS } from "../../../api/apiService";
import { withSnackbar } from "notistack";
const queryString = require("query-string");
import TablePageLayout from "layout/TablePageLayout";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import Actions from "redux/actions/filterActions";
import TableActions from "redux/actions/tableActions";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "components/CommonComponent/Filter/CommonTable/CommonTable";
import {
  Button,
  Grid,
  MenuItem,
  Modal,
  Popover,
  Typography,
  makeStyles
} from "@material-ui/core";
import CommonModal from "components/CommonModal/CommonModal";
import CustomButton from "components/Button/CustomButton";
import Dropzone from "react-dropzone";
import AcceptMaxFiles from "components/DropZone";
const EXAM_LEVEL = [
  { label: "Exam Level", value: "exam_level" },
  { label: "Senior", value: "senior" },
  { label: "Associate", value: "associate" },
  { label: "Junior", value: "junior" },
  { label: "Internship", value: "internship" }
];
const EXAM_STATUS = [
  { label: "Exam Status", value: "candidate_status" },
  { label: "Link Sent", value: "LINK_SENT" },
  { label: "Exam Attempted", value: "EXAM_ATTEMPTED" },
  { label: "Exam Submitted", value: "EXAM_SUBMITTED" },
  { label: "Passed", value: "PASSED" },
  { label: "Qualify", value: "QUALIFY" },
  { label: "Disqualify", value: "DISQUALIFY" }
];

const CandidateList = (props) => {
  const [loader, setLoader] = React.useState(false);
  const [pageColor, setPageColor] = React.useState(null);

  const [deptLists, setDeptlist] = React.useState([]);

  const { dataArray } = useSelector((store) => store.filter);
  const { reset } = useSelector((store) => store.table);

  const { candidateStatus } = useSelector(
    (store) => store.candidateStatusReducer
  );

  console.log(candidateStatus, "jjjkkkkk");
  const { searchData } = useSelector((store) => store.search);
  console.log("searchData90", searchData);

  const [totalPage, setTotalPage] = React.useState("");
  const [count, setCount] = React.useState("");
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const classes = useStyles();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [id, setid] = useState(null);
  const [Name, setName] = useState(null);
  var [Type, setType] = useState(null);
  const [levels, setLevels] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const menuItemClick = (value) => {
    setIsModalOpen(true);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const newID = open ? "simple-popover" : undefined;
  const [candidates, setCandidates] = useState({
    all_candidates: 0,
    all_hired: 0,
    all_rejected: 0
  });

  const [filter, setfilter] = useState({
    page: 1,
    page_size: 5,
    search: "",
    is_hired: 0,
    candidate_status: 0
  });

  const debouncedSearchTerm = useDebounce(searchData, 500);

  const handlePage = (event, page) => {
    dispatch({
      type: TableActions.SET_TABLE_ROW,
      count: null
    });
    setfilter({ ...filter, page });
    setPageColor(page);
  }; // filter
  const handleChangefilter = (key) => (event) => {
    let tempData = { ...filter };
    tempData[key] = searchData;
    tempData["page"] = 1;
    setfilter(tempData);
  };

  const fetchRecord = async () => {
    try {
      setLoader(true);

      const query = queryBuilder(ENDPOINTS.CANDIDATE_LIST, filter);
      const resp = await API.get(query);
      if (resp.success) {
        let SUB_ADMIN_PERMISSION = localStorage.getItem("SUB_ADMIN_PERMISSION");

        const result = resp.data;
        const count = result.count;
        const candidateData = resp.data.results.map((item) => {
          const hiredStatus = item.is_hired;
          return {
            0: item.attempt_level,
            1: item.id,
            2: item.user.full_name,
            3: item.examiner.examiner_name,
            4: item.job,
            5: item.exam.exam_level || "---",
            6: item.latest_status.candidate_status || "---",
            7:
              hiredStatus === true || hiredStatus === false
                ? hiredStatus === true
                  ? "Qualified"
                  : "Disqualified"
                : "---",
            hire_comment: item.hire_comment,
            assignExam: {
              link: `/${role}/candidate/examlink`,
              candidateId: item.id,
              candidateName: item.user.full_name,
              deptId: item.examiner.examiner_department_id,
              allow: SUB_ADMIN_PERMISSION
                ? SUB_ADMIN_PERMISSION.includes("GENERATE_EXAM_LINK")
                : true
            },
            editAction: {
              editpath: `/${role}/candidate/edit`,
              DeptID: item.examiner.examiner_department_id,
              candidateExaminer: item.examiner.examiner_name,
              candidateID: item.id,
              candidateName: item.user.full_name,
              candidateEmail: item.user.email,
              candidateJob: item.job,
              candidateNo: item.user.phone_number,
              candidateExperience: item.experience,
              allow: SUB_ADMIN_PERMISSION
                ? SUB_ADMIN_PERMISSION.includes("EDIT_CANDIDATE")
                : true,
              additionalInfo: item.additional_info
            },
            deleteAction: {
              actionID: item.id,
              deleteName: item.user.full_name,
              tableType: "Candidate",
              allow: SUB_ADMIN_PERMISSION
                ? SUB_ADMIN_PERMISSION.includes("DELETE_CANDIDATE")
                : true
            }
          };
        });

        dispatch({
          type: TableActions.SET_TABLE_ROW,
          data: candidateData,
          tableHeading: "Candidates",
          profile: `/${role}/candidate/profile`,
          count: count
        });
        dispatch({
          type: TableActions.SET_PAGINATION,
          data: { pages: result.pages, count: result.count }
        });
        setTotalPage(result.pages);
        setCount(result.count);
      }
    } catch (e) {
      const msg =
        typeof e.data.error?.message === "string"
          ? e.data.error?.message
          : e.data.error?.message[0];
      props.enqueueSnackbar(
        msg || "Error fetching candidate list. Please try again",
        {
          variant: "error",
          autoHideDuration: 3000
        }
      );
    } finally {
      setLoader(false);
    }
  };

  const fetchCandidateCount = async () => {
    try {
      setLoader(true);
      const resp = await API.get(ENDPOINTS.CANDIDATE_COUNT);
      if (resp.success) {
        const result = resp.data;
        setCandidates(result);
      }
    } catch (e) {
      const msg =
        typeof e.data.error?.message === "string"
          ? e.data.error?.message
          : e.data.error?.message[0];
      props.enqueueSnackbar(
        msg || "Error fetching candidate count. Please try again",
        {
          variant: "error",
          autoHideDuration: 3000
        }
      );
    } finally {
      setLoader(false);
    }
  };

  // Set level list filter
  const fetchLevelList = async () => {
    try {
      const resp = await API.get(ENDPOINTS.ATTEMPT_LEVEL);
      if (resp.success) {
        const result = resp.data;
        const res = result.attempt_levels;
        let temp = res.map((item, idx) => ({
          value: item,
          label: item,
          id: item
        }));
        temp.unshift({
          label: "Attempt Level",
          value: "attempt_level"
        });
        setLevels(temp);
      }
    } catch (e) {
      const msg =
        typeof e.data.error?.message === "string"
          ? e.data.error?.message
          : e.data.error?.message[0];
      props.enqueueSnackbar(
        msg || "Error fetching Attempt Level list. Please try again",
        {
          variant: "error",
          autoHideDuration: 3000
        }
      );
    }
  };

  // Query builder
  const queryBuilder = (url, options) => {
    const options1 = queryString.stringify({
      ...dataArray
    });
    if (options === undefined || !options) return url;

    return `${url}?page_size=5${options.page ? `&page=${options.page}` : ""}${
      searchData ? `&search=${searchData}` : ""
    }${options.is_hired ? `&is_hired=${options.is_hired}` : ""}${
      options.candidate_status
        ? `&candidate_status=${options.candidate_status}`
        : ""
    }&${options1}&is_hired=${candidateStatus === "2" ? "" : candidateStatus}`;
  };

  // filter set department list
  const fetchDepartmentList = async () => {
    try {
      const resp = await API.get(`${ENDPOINTS.DEPARTMENT_LIST}`);
      if (resp.success) {
        const result = resp.data;
        const res = result.results;
        setDeptlist(res);
        let temp = res.map((item) => ({
          value: item.id,
          label: item.name,
          id: item.id
        }));
        temp.unshift({
          label: "Department",
          value: "department"
        });
        setDepartments(temp);
      }
    } catch (e) {
      const msg =
        typeof e.data.error?.message === "string"
          ? e.data.error?.message
          : e.data.error?.message[0];
      props.enqueueSnackbar(
        msg || "Error fetching department list. Please try again",
        {
          variant: "error",
          autoHideDuration: 3000
        }
      );
      const role = localStorage.getItem("ROLE")
        ? localStorage.getItem("ROLE").toLowerCase()
        : null;
    }
  };

  useLayoutEffect(() => {
    fetchDepartmentList();
    fetchLevelList();
    fetchCandidateCount();
  }, []);

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useLayoutEffect(() => {
    let isCanclled = false;
    const handleChange = async () => {
      await timeout(500);
      if (!isCanclled) {
        fetchRecord();
      }
    };
    handleChange();
    return () => {
      isCanclled = true;
    };
  }, [filter, candidateStatus, dataArray, reset, refetch]);

  useEffect(() => {
    console.log(reset, "reset");
  }, [reset]);

  useEffect(() => {
    setfilter({ ...filter, page: 1 });
    setPageColor(1);
  }, [dataArray, debouncedSearchTerm]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: Actions.SET_FILTER,
      data: [EXAM_LEVEL, levels, EXAM_STATUS]
    });
  }, [levels]);
  useEffect(() => {
    // Condition not allowing columns if there are no action permission available inside for Sub-admin and examiner
    const allowActionColumn =
      localStorage.getItem("ROLE") === "EXAMINER" ||
      (localStorage.getItem("ROLE") === "SUB-ADMIN" &&
        localStorage.getItem("SUB_ADMIN_PERMISSION") &&
        !localStorage
          .getItem("SUB_ADMIN_PERMISSION")
          .includes("EDIT_CANDIDATE") &&
        !localStorage
          .getItem("SUB_ADMIN_PERMISSION")
          .includes("GENERATE_EXAM_LINK") &&
        !localStorage
          .getItem("SUB_ADMIN_PERMISSION")
          .includes("DELETE_CANDIDATE"));

    dispatch({
      type: TableActions.SET_TABLE_COLUMN_HEADING,
      data: [
        "Level",
        "ID",
        "Name",
        "Examiner",
        "Job Assosiated",
        "Exam Level",
        "Exam Status",
        "Candidate Status",
        !allowActionColumn && "Action"
      ]
    });
  }, []);
  let SUB_ADMIN_PERMISSION = localStorage.getItem("SUB_ADMIN_PERMISSION");
  const popOverItems = [
    <MenuItem key="One" onClick={menuItemClick.bind(null, "import")}>
      <Typography style={{ fontSize: "1rem", fontWeight: 600, padding: 1 }}>
        Import Csv
      </Typography>
    </MenuItem>
  ];
  const csvWrapper = () => {
    return (
      <Box>
        <img
          src="/assets/Icons/export-27.svg"
          style={{ height: "1.5rem", marginLeft: "1rem", cursor: "pointer" }}
          onClick={handleClick}
        />
        <Popover
          id={newID}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          PaperProps={{
            style: { boxShadow: "rgba(0, 0, 0, 0.3) 0 2px 10px" }
          }}
        >
          {popOverItems.map((item) => (
            <Box key={item?.key}>{item}</Box>
          ))}
        </Popover>
      </Box>
    );
  };
  const handlePopUpClose = () => {
    setIsModalOpen(false);
  };
  const refetcher = () => {
    setRefetch((prev) => !prev);
    handlePopUpClose();
  };
  return (
    <>
      <HeaderWithBreadcrumb heading="Candidates" />

      <TablePageLayout
        heading="Candidates"
        subheading={{
          hired: candidates.all_hired,
          rejected: candidates.all_rejected,
          all: candidates.all_candidates
        }}
        loader={!loader}
        totalPage={totalPage}
        ButtonText="Add Candidate"
        allowCreate={
          SUB_ADMIN_PERMISSION
            ? SUB_ADMIN_PERMISSION.includes("CREATE_CANDIDATE")
            : true
        }
        buttonActionPath={`/${role}/candidate/add`}
        count={count}
        handleChangefilterisHired={handleChangefilter}
        handleChangeFilter={handleChangefilter("search")}
        handleChangePage={handlePage}
        hell="hell"
        candidateFilter={{
          item: "candidate"
        }}
        csvWrapper={csvWrapper}
      >
        <CommonTable
          Count={totalPage}
          handleChangePage={handlePage}
          pageColor={pageColor}
        />
      </TablePageLayout>
      <Modal open={isModalOpen} className={classes.modalContainer}>
        <Grid container className={classes.modalContent}>
          <Grid item xs={12}>
            <Typography className={classes.modalHeading}>
             Import and Invite Candidates
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <AcceptMaxFiles
              toast={props.enqueueSnackbar}
              classes={classes}
              handleClose={handlePopUpClose}
              handleRefetch={refetcher}
            />
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default withSnackbar(CandidateList);
const useStyles = makeStyles({
  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none"
  },
  modalContent: {
    backgroundColor: "#fff",
    height: "fit-content",
    width: "28rem",
    borderRadius: 8,
    padding: "2rem"
  },
  modalHeading: {
    fontSize: 26,
    fontWeight: 700,
    textAlign: "center",
    paddingTop: 8
  },
  footer: {
    backgroundColor: "#F5F6FA",
    width: "100%",
    padding: "23px 0px",
    display: "flex",
    justifyContent: "center",
    borderRadius: 16
  },
  uploadContainer: {
    // marginLeft: "6%",
    marginBottom: 10,
    // marginRight: "6%",
    border: "1px solid #e4e4e4",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 160,
    height: 160,
    padding: 16
  },

  supportedFiles: {
    color: "#82889C",
    fontFamily: "Mulish",
    fontSize: "13px",
    letterSpacing: "0",
    lineHeight: "16px"
  },
  supportedFiles1: {
    height: "16px",
    width: "209px",
    color: "#82889C",
    fontFamily: "Helvetica",
    fontSize: "13px",
    letterSpacing: "0",
    lineHeight: "16px",
    marginTop: "15px",
    marginLeft: 51
  },
  ahref: {
    color: "#434966"
  },
  uploadFilesTxt: {
    // marginLeft: "6%",
    fontSize: 14
  },
  placeholderDoc: {
    height: 24,
    width: 250,
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px dashed #DDDFE6",
    maxWidth: "unset !important",
    minWidth: "unset !important"
  },
  dragdropTxt: {
    fontSize: 13,
    fontWeight: 600
  },
  pButton: {
    // background: "#090930",
    height: 40,
    marginLeft: 15,
    padding: "0px 40px"
  },
  validateButton: {
    boxSizing: "border-box",
    height: "54.61px",
    width: "128px",
    border: "0.61px solid #FF0083",
    borderRadius: "8px",
    color: "#FF0083"
  },
  filesupportedTxt: {
    marginBottom: "8px"
  },
  tButton: {
    marginRight: 20
  },
  dropzoneAllignment: {
    marginTop: 18,
    marginLeft: 15
  },
  Note: {
    fontSize: 12
  },
  validationErrorContainer: {
    // marginLeft: "7%",
    maxHeight: 210,
    overflow: "scroll",
    width: "91%",
    overflowX: "hidden"
  },
  plsReviewTxt: {
    color: "red",
    fontWeight: "bold"
  },
  errorEntriesContainer: {
    marginTop: 8
  },
  errorEntries: {
    fontSize: 13,
    color: "#686C85"
  },
  btns: {
    ["@media (max-width:1024px) and (min-width:720px)"]: {
      height: 48
    }
  },
  uploadMain: {
    padding: 32
  },
  img: {
    marginLeft: 8
  }
});
