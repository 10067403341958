import React, { useLayoutEffect } from "react";
import { Button, Box, CardMedia } from "@material-ui/core";
import styles from "./LandingPage.module.scss";
import Table from "./Images/LandingInfo.svg";
import Behance from "./Images/Behance.svg";
import Facebook from "./Images/facebook.svg";
import Instagram from "./Images/instagram.svg";
import LinkedIn from "./Images/linkedin.svg";
import Twitter from "./Images/twitter.svg";
import Frame from "./Images/LandingMain.svg";
import MobileFrame from "./Images/MobileLanding.svg";
import Logo from "./Images/frshMindLogo.svg";
import AboutNickelFox from "./Images/Company.svg";
import MobileText from "./Images/MobileText.svg";
import { MobileStepper } from "@material-ui/core";
import { autoPlay } from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import { Link } from "react-router-dom";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import { useHistory } from "allImport";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    imgPath: Table
  },
  {
    imgPath: Table
  },
  {
    imgPath: Table
  },
  {
    imgPath: Table
  },
  {
    imgPath: Table
  },
  {
    imgPath: Table
  }
];

function LandingPage() {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const history = useHistory();
  useLayoutEffect(() => {
    if (
      StorageManager.get(API_TOKEN) &&
      StorageManager.get(ADMIN_ID) &&
      StorageManager.get(ADMIN_NAME) &&
      StorageManager.get(LOGOUT_TOKEN)
    ) {
      history.push(`/${role}/dashboard`);
    } else {
      StorageManager.clearStore();
      history.push("/login");
    }
  }, []);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const socialMedia = [
    // { id: 1, logoIcon: Behance, link: "https://www.behance.net/accountb22f" },

    {
      id: 2,
      logoIcon: Facebook,
      link: "https://www.facebook.com/NickelfoxStudio/"
    },
    {
      id: 3,
      logoIcon: LinkedIn,
      link: "https://www.linkedin.com/company/nickelfox/mycompany/"
    },
    {
      id: 4,
      logoIcon: Instagram,
      link: "https://www.instagram.com/nickelfoxstudio/"
    },
    { id: 5, logoIcon: Twitter, link: "https://twitter.com/nickelfoxstudio" }
  ];
  return (
    <Box className={styles.outer_box}>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Box className={styles.top_bar}>
          <Box className={styles.logo_box}>
            <Box className={styles.logo_container}>
              <CardMedia
                className={styles.logo_img}
                component="img"
                image={Logo}
                alt="img"
              />
            </Box>
            {/* <Box className={styles.icon_text}>FRESH MIND WAVES HR PORTAL</Box> */}
          </Box>
          <Box>
            <Link to="/login" style={{ textDecoration: "none" }}>
              <Button
                className={styles.sign_in}
                variant="text"
                style={{ marginRight: "20px" }}
              >
                SIGN IN
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        className={styles.outer_box_small}
        sx={{ display: { xs: "block", sm: "none" } }}
      >
        <Box className={styles.top_bar}>
          <Box className={styles.logo_box}>
            <Box className={styles.logo_container}>
              <CardMedia
                className={styles.logo_img}
                component="img"
                image={Logo}
                alt="img"
              />
            </Box>
            {/* <Box className={styles.icon_text}>FRESH MIND WAVES x HR PORTAL</Box> */}
          </Box>
          <Box className={styles.login_box}>
            <Link to="/login" style={{ textDecoration: "none" }}>
              <Button variant="text">SIGN IN</Button>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Box className={styles.landing_screen}>
          <Box className={styles.overview_container}>
            <CardMedia
              className={styles.overview_img}
              component="img"
              image={Frame}
              alt="img"
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <Box className={styles.landing_screen}>
          <Box className={styles.overview_container}>
            <CardMedia
              className={styles.overview_img}
              component="img"
              image={MobileFrame}
              alt="img"
            />
            <CardMedia
              className={styles.overview_img}
              component="img"
              image={MobileText}
              alt="img"
            />
          </Box>
          <Box className={styles.btn_box}>
            <Link to="/login" style={{ textDecoration: "none" }}>
              <Button color="primary" variant="contained">
                SIGN IN
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Box className={styles.introduction_box}>
          <Box className={styles.intro_content_box}>
            <Box className={styles.ques}> What is Hiring Portal?</Box>
            <Box className={styles.ans}>
              At Fresh-Mind-Wave our digital experts ensure best user-centric
              digital solutions. We thrive to create solutions that have top
              notch ui/ux designs and software development standards, suitable
              for best web products & best iOS & Android apps, crafted with love
              and passion.
            </Box>
          </Box>

          <Box className={styles.company_img_container}>
            <Box style={{ width: "140%" }}>
              {/* <AutoPlaySwipeableViews
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                <Box
                  component="img"
                  src={step[0].imgPath}
                  alt={step[0].label}
                />
              </AutoPlaySwipeableViews> */}

              {/* <MobileStepper
                steps={maxSteps}
                style={{ backgroundColor: "#F8F8F8" }}
                variant="progress"
                position="absolute"
                activeStep={activeStep}
              /> */}
              <CardMedia
                className={styles.company_img}
                component="img"
                image={Table}
                alt="img"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <Box className={styles.introduction_box}>
          <Box className={styles.intro_content_box}>
            <Box className={styles.ques}>What is Hiring Portal?</Box>
            <Box className={styles.company_img_container}>
              <CardMedia
                className={styles.company_img}
                component="img"
                image={Table}
                alt="img"
              />
            </Box>
            <Box className={styles.ans}>
              At Fresh-Mind-Wave our digital experts ensure best user-centric
              digital solutions. We thrive to create solutions that have top
              notch ui/ux designs and software development standards, suitable
              for best web products & best iOS & Android apps, crafted with love
              and passion.
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Box className={styles.about_container}>
          <Box className={styles.about_img_container}>
            <CardMedia
              className={styles.about_img}
              component="img"
              image={AboutNickelFox}
              alt="img"
            />
          </Box>
          <Box className={styles.content_box}>
            <Box className={styles.ques_box}>About Fresh-Mind-Wave</Box>
            <Box className={styles.ans_box}>
              At Fresh-Mind-Wave our digital experts ensure best user-centric
              digital solutions. We thrive to create solutions that have top
              notch ui/ux designs and software development standards, suitable
              for best web products & best iOS & Android apps, crafted with love
              and passion.
            </Box>
            <Box className={styles.btn_container}>
              <Button
                href="https://nickelfox.com/"
                color="primary"
                variant="outlined"
              >
                Learn More
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <Box className={styles.about_container}>
          <Box className={styles.ques_box}>About Fresh-Mind-Wave</Box>
          <Box className={styles.about_img_container}>
            <CardMedia
              className={styles.about_img}
              component="img"
              image={AboutNickelFox}
              alt="img"
            />
          </Box>
          <Box className={styles.content_box}>
            <Box className={styles.ans_box}>
              At Fresh-Mind-Wave our digital experts ensure best user-centric
              digital solutions. We thrive to create solutions that have top
              notch ui/ux designs and software development standards, suitable
              for best web products & best iOS & Android apps, crafted with love
              and passion.
            </Box>
            <Box className={styles.btn_container}>
              <Button
                href="https://nickelfox.com/"
                variant="outlined"
                color="primary"
              >
                Learn More
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={styles.social_media_icon}>
        <Box className={styles.inner_box}>
          <Box className={styles.follow_box}>Follow Us</Box>
          <Box className={styles.social_icon}>
            {socialMedia.map((data) => (
              <Box key={data.id} className={styles.social_container}>
                <a href={data.link} target="_blank" rel="noreferrer">
                  <CardMedia
                    className={styles.social_img}
                    component="img"
                    image={data.logoIcon}
                    alt="img"
                  />
                </a>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default LandingPage;
