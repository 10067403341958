import {
  Emptyscreen,
  CircularProgress,
  useHistory,
  clsx,
  Link,
  CancelRoundedIcon,
  OutlinedInput,
  apiUrl,
  useEffect,
  fetchClient,
  Select,
  MenuItem,
  CssBaseline,
  Box,
  Container,
  Grid,
  Paper,
  IconButton,
  InputAdornment,
  TextField,
  FormControl,
  Topbar,
  Sidebar,
  React,
  SearchIcon,
  Pagination,
  useState,
} from "allImport";
import {
  MenuProps,
  MouseEnter,
  MouseLeave,
  capitalize,
} from "Common/CommonFunction";
import { useStyles } from "style/Liststyle.js";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
  SUB_ADMIN_PERMISSION,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import TablePageLayout from "layout/TablePageLayout";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import { useDispatch, useSelector } from "react-redux";
import Actions from "redux/actions/filterActions";
import { API, ENDPOINTS } from "../../../api/apiService";
import TableActions from "redux/actions/tableActions";
import CommonTable from "components/CommonComponent/Filter/CommonTable/CommonTable";

const queryString = require("query-string");

var AS = [];
var searchValue = "";

var url = new URL(`${apiUrl.viewSubmittedExams}`);
export default function ExamCandidateList(props) {
  const examiner_name = localStorage.getItem("ADMIN_ID");
  const attempt_level = [
    { label: "Attempt Level", value: "attempt_level" },
    { label: "L1", value: "L1" },
    { label: "L2", value: "L2" },
    { label: "L3", value: "L3" },
    { label: "L4", value: "L4" },
  ];

  const EXAM_LEVEL = [
    { label: "Exam Level", value: "experience_level" },
    { label: "Senior", value: "senior" },
    { label: "Junior", value: "junior" },
    { label: "Internship", value: "internship" },
  ];

  const classes = useStyles();
  const [data, setAllData] = React.useState({});
  const history = useHistory();
  const [count, setCount] = React.useState("");
  const [score, setScore] = React.useState("");
  const [deptLists, setDeptlist] = React.useState([]);
  const [deptSelectionVal, setDept] = React.useState("");
  const [totalPage, setTotalPage] = React.useState("");
  const [examLevel, setExamLevel] = React.useState("");
  const [attemptLevel, setAttemptLevel] = React.useState("");
  const [ExamCandidateSearch, setExamCandidateSearch] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [pageColor, setPageColor] = React.useState(null);
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const { searchData } = useSelector((store) => store.search);
  const [loader, setLoader] = React.useState(false);
  const handleChangePage = (event, value) => {
    setPageColor(value)
    setPage(value);
  };
  function createData(ColA, ColB, ColC, ColD, ColE, ColF, ColG, ColH, ColI) {
    return { ColA, ColB, ColC, ColD, ColE, ColF, ColG, ColH, ColI };
  }

  

  const colmConversion = (arr) => {
    AS = [];
    for (var index = 0; index < arr.length; index++) {
      AS.push(
        createData(
          arr[index] &&
            arr[index].candidate[0] &&
            arr[index].candidate[0].candidate_id,
          arr[index] &&
            arr[index].candidate[0] &&
            arr[index].candidate[0].candidate_full_name,
          arr[index] &&
            arr[index].candidate[0] &&
            arr[index].candidate[0].department_name,
          arr[index] &&
            arr[index].candidate[0] &&
            arr[index].candidate[0].exam_name,
          arr[index] &&
            arr[index].candidate[0] &&
            arr[index].candidate[0].experience_level,
          arr[index] &&
            arr[index].candidate[0] &&
            arr[index].candidate[0].attempt_level,
          arr[index] &&
            arr[index].candidate[0] &&
            arr[index].candidate[0].total_questions,

          typeof arr[index].candidate[0].percentage === "string"
            ? `${arr[index].candidate[0].percentage.toFixed(2)} %`
            : "N/A",
          arr[index] &&
            arr[index].candidate[0] &&
            arr[index].candidate[0].exam_id
        )
      );
    }
    processDataList();
  };
  const getPermissionsForSubAdmin = () => {
    let permission = {};
    if (role === "sub-admin") {
      let allPermissions = StorageManager.getJSON(SUB_ADMIN_PERMISSION);
      permission.view = allPermissions.includes("VIEW_RESULT");
      permission.create = allPermissions.includes("CREATE_RESULT");
      permission.edit = allPermissions.includes("EDIT_RESULT");
      permission.delete = allPermissions.includes("DELETE_RESULT");
    }
    return permission;
  };
  const processDataList = () => {
    const permissions = getPermissionsForSubAdmin();
    const data = {
      columnnames: {
        col1: "ID",
        col2: "Name",
        col3: "Department",
        col4: "Exam Name",
        col5: "Exam Level",
        col6: "Attempt Level",
        col7: "Total Questions",
      },
      TableType: "ExamCandidate",
      List: AS,
      permissions: permissions,
      ListKey: Object.keys(AS),
      profile: `/${role}/submitted-exam/result`,
    };
    setAllData(data);
  };

  const handleChangeFilter = (e) => {
    setScore("");
    setDept("");
    setExamLevel("");
    setAttemptLevel("");
    setExamCandidateSearch(e.target.value);
    url.searchParams.delete("percentage");
    url.searchParams.delete("department");
    url.searchParams.delete("experience_level");
    url.searchParams.delete("attempt_level");
    searchValue = e.target.value;
    url.searchParams.set("search", searchValue);
    if (role === "examiner") {
      url.searchParams.set("examiner", examiner_name);
    }
    fetchClient.get(url).then((res) => {
      const examcandidatelist =
        res && res.data && res.data.data && res.data.data.results;
      setCount(res && res.data && res.data.data && res.data.data.count);
      colmConversion(examcandidatelist);
    });
  };

  const ExamCandidateFilterSelection = (e, type) => {
    url.searchParams.delete("search");
    setExamCandidateSearch("");
    if (type === "Score") {
      setScore(e.target.value);
      if (e.target.value !== "") {
        url.searchParams.set("percentage", e.target.value);
      } else {
        url.searchParams.delete("percentage");
      }
    }

    if (type === "department") {
      setDept(e.target.value);
      if (e.target.value !== "") {
        url.searchParams.set("department", e.target.value);
      } else {
        url.searchParams.delete("department");
      }
    }
    if (type === "ExamLevel") {
      setExamLevel(e.target.value);
      if (e.target.value !== "") {
        url.searchParams.set("experience_level", e.target.value);
      } else {
        url.searchParams.delete("experience_level");
      }
    }
    if (type === "AttemptLevel") {
      setAttemptLevel(e.target.value);
      if (e.target.value !== "") {
        url.searchParams.set("attempt_level", e.target.value);
      } else {
        url.searchParams.delete("attempt_level");
      }
    }
    if (role === "examiner") {
      url.searchParams.set("examiner", examiner_name);
    }
    fetchClient.get(url).then((res) => {
      setTotalPage(res && res.data && res.data.data && res.data.data.pages);
      const examcandidatelist =
        res && res.data && res.data.data && res.data.data.results;
      setCount(res && res.data && res.data.data && res.data.data.count);
      colmConversion(examcandidatelist);
    });
  };
  useEffect(() => {
    let url = new URL(`${apiUrl.viewSubmittedExams}`);
    if (role === "examiner") {
      url.searchParams.set("examiner", examiner_name);
    }
    fetchClient.get(url).then((res) => {
      setLoader(true);
      setTotalPage(res && res.data && res.data.data && res.data.data.pages);
      const examcandidatelist =
        res &&
        res.data &&
        res.data.data &&
        res.data.data.results &&
        res.data.data.results;
      setCount(res && res.data && res.data.data && res.data.data.count);
      colmConversion(examcandidatelist);
    });
    fetchClient
      .get(`${apiUrl.deptList}?has_submitted_exam=1&page_size=10000`)
      .then((resp) => {
        const rest = resp.data;
        if (rest.success) {
          const result = rest.data;
          const res = result.results;
          // setDeptlist(res);
          let temp = res.map((item) => ({
            value: item.id,
            label: item.name,
            id: item.id,
          }));
          temp.unshift({
            label: "Department",
            value: "department",
          });
          setDeptlist(temp);
        }

        // setDeptlist(res && res.data && res.data.data && res.data.data.results);
      });
  }, []);


  const { dataArray } = useSelector((store) => store.filter);
  const [filter, setfilter] = useState({
    page: 1,
  });

  useEffect(()=>{

    setPageColor(1)
    setPage(1);
  },[searchData,dataArray])

  const options1 = queryString.stringify({
    ...dataArray,
  });

  console.log("data--->", options1);

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    let isCanclled = false;
    const handleChange = async () => {
      await timeout(200);
      if (!isCanclled) {
        fetchRecord();
      }
    };
    handleChange();
    return () => {
      isCanclled = true;
    };
  }, [dataArray, page, searchData]);

  const queryBuilder = (url, options) => {
    const options1 = queryString.stringify({
      ...dataArray,
    });

    console.log("bannana", options);

    console.log("9999", "abc");
    return `${url}?${options ? `&page=${options}` : " "}&${options1}${
      searchData ? `&search=${searchData}` : ""
    }`;
  };

  const fetchRecord = async () => {
    try {
      setLoader(true);

      const query = queryBuilder(ENDPOINTS.SUBMITTED, page);

      const resp = await API.get(query);

      console.log("kkkk", query);
      if (resp.success) {
        const result = resp.data;
        const count = result.count;
        const submittedData = result.results.map((item) => {
          console.log("iiattet", item);
          return {
            0: item.candidate[0].candidate_id,
            1: item.candidate[0].candidate_full_name,
            2: item.candidate[0].department_name,
            3: item.candidate[0].exam_name,
            4: item.candidate[0].experience_level,
            5: item.candidate[0].attempt_level,
            6: item.candidate[0].total_questions.toString(),
            examId: item.candidate[0].exam_id,
            candidateID: item.candidate[0].candidate_id,
          };
        });

        dispatch({
          type: TableActions.SET_TABLE_ROW,
          data: submittedData,
          tableHeading: "Submitted Exams",
          profile: `/${role}/submitted-exam/result`,
          count: count,
        });
        dispatch({
          type: TableActions.SET_PAGINATION,
          data: { pages: result.pages, count: result.count },
        });

        setTotalPage(result.pages);
        // setCount(result.count);
        // colmConversion(result.results);
      }
    } finally {
      // catch (e) {
      //   const msg =
      //     typeof e.data.error?.message === "string"
      //       ? e.data.error?.message
      //       : e.data.error?.message[0];
      //   props.enqueueSnackbar(
      //     msg || "Error fetching examiner list. Please try again",
      //     {
      //       variant: "error",
      //       autoHideDuration: 3000,
      //     }
      //   );
      // }
      setLoader(true);
    }
  };

  useEffect(() => {
    dispatch({
      type: TableActions.SET_TABLE_COLUMN_HEADING,
      data: [
        "ID",
        "Name",
        "Department",
        "Exam Name",
        "Exam Level",
        "Attempt Level",
        "Total Question",
      ],
    });
    return () => {
      dispatch({
        type: TableActions.RESET_TABLE_COLUMN_HEADING,
      });
    };
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: Actions.SET_FILTER,
      count: 3,
      data: [deptLists, EXAM_LEVEL, attempt_level],
    });
  }, [deptLists]);

  return (
    <>
      <HeaderWithBreadcrumb heading="Submitted Exams" />

      <TablePageLayout
        heading="Submitted Exams"
        loader={loader}
        totalPage={totalPage}
        page={page}
        handleChangePage={handleChangePage}
        count={count}
        handleChangeFilter={(e) => handleChangeFilter(e)}
        candidateFilter={{
          type: "submittedexam",
          examlevel: { examLevel },
          examLevelSelection: (e) =>
            ExamCandidateFilterSelection(e, "ExamLevel"),

          deptSelectionVal: { deptSelectionVal },
          deptList: { deptLists },
          examDeptSelection: (e) =>
            ExamCandidateFilterSelection(e, "department"),

          attempt_level: { attempt_level },
          attemptLevel: { attemptLevel },
          examAttemptSelection: (e) =>
            ExamCandidateFilterSelection(e, "AttemptLevel"),
        }}
      >
        <CommonTable Count={totalPage} handleChangePage={handleChangePage} pageColor={pageColor}/>
      </TablePageLayout>
    </>
  );
}
