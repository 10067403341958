import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, Topbar, Sidebar } from "allImport";
import { Button, Grid, Typography } from "@material-ui/core";
import PermissionActions from "redux/actions/permissionActions";
import useVH from "react-viewport-height";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    maxheight: "auto",
  },
  contentHeader: {
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    padding: "20px",
    ["@media (max-width:1030px)"]: {
      // eslint-disable-line no-useless-computed-key
      padding: "5px 15px",
    },
  },
  footerWrapper: {
    position: "absolute",
    display: "flex",
    bottom: "20px",
    left: "50%",
  },
  footerButton: {
    color: "#D1001C",

    textTransform: "none",
    fontSize: "12px",
  },
  sidebarWrapper: {
    padding: "10px 0px 10px 10px ",
    ["@media (max-width:1030px)"]: {
      // eslint-disable-line no-useless-computed-key
      display: "none",
    },
  },
});

const PrivateLayout = (props) => {
  const vh = useVH();
  const classes = useStyles();

  const [topbarVisible, setTopBarVisible] = useState(true);

  return (
    <Grid className={classes.root}>
      <CssBaseline />
      <Grid
        className={classes.sidebarWrapper}
        style={{ height: `${100 * vh}px` }}
      >
        <Sidebar />
      </Grid>
      <Grid container direction="column" className={classes.contentHeader}>
        {topbarVisible ? <Topbar /> : null}
        <Grid style={{ backgroundColor: "#fff" }} item>
          {props.children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(PrivateLayout);
