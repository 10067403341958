import {
  withSnackbar,
  Grid,
  makeStyles,
  Button,
  Typography,
  React,
} from "allImport";
import { commonStyle } from "commonStyle.js";
import LeftBox from "../Login/LeftBox";
import { Box, IconButton, InputLabel } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { KeyboardBackspace } from "@material-ui/icons";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),
  forgotpassword_heading: {
    fontFamily: "Mulish",
    fontSize: "50px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "63px",
    width: "450px",
    height: "63px",
    color: "#18273B",
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
  buttonbox: {
    paddingTop: "3.55vh",
  },

  emaillabel: {
    marginTop: "2rem",
    color: "#444444",
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: " 400",
    lineHeight: " 24px",
    letterSpacing: "0.5px",
    textAlign: "left",
  },

  discription: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    // fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: "0.10000000149011612px",
    textAlign: "left",
    color: "#A8A8A8",
    marginTop: "2.66vh",
    height: "64px",
    width: "500px",
  },

  form: {
    marginTop: "32px",
  },
}));
const ResetLinkSent = (props) => {
  const classes = useStyles();
  const [loader, setLoader] = React.useState(false);
  const [seconds, setSeconds] = React.useState(30);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (seconds === 25) {
        clearInterval(interval);
        return;
      } else {
        setSeconds((seconds) => seconds - 1);
      }
    }, 1000);
  }, []);

  const history = useHistory();
  return (
    <>
      <Grid container wrap="nowrap">
        <LeftBox
          heading="Can’t Recollect your Password?"
          message=" We have got you covered! Enter your registered ID and then check
            your mail for the Password Reset Link."
        />
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={8}
          justifyContent="center"
          alignItems="center"
          style={{ height: "100vh" }}
        >
          <Box
            sx={{
              width: { xs: "80%", sm: "70%", md: "70%", lg: "50%" },
            }}
          >
            <Box container flexDirection="row">
              <Box
                item
                sx={{
                  marginLeft: "-70px",
                  marginTop: "6px",
                  position: "absolute",
                }}
              >
                <IconButton
                  onClick={() => {
                    history.goBack();
                  }}
                  aria-label="delete"
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}
                >
                  <KeyboardBackspace
                    style={{ color: "#18273B" }}
                    size="large"
                  />
                </IconButton>
              </Box>
              <Box item>
                <Typography variant="h3" className={classes.loginHeading}>
                  Resent Link Sent
                </Typography>
              </Box>
            </Box>

            <Typography className={classes.signin_description}>
              We have sent you a mail to reset your password. Please check your
              inbox or spam folder for the mail. If not recieved you can,
            </Typography>
            <Typography className={classes.signin_description}>
              {seconds > 0 ? (
                `Reset your password again after ${seconds} seconds.`
              ) : (
                <Link
                  style={{
                    color: "#d9001d",
                    textDecoration: "none",
                    fontSize: "16px",
                  }}
                  to="/forgot-password"
                >
                  Reset password again
                </Link>
              )}{" "}
            </Typography>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button
                style={{ marginTop: "2rem" }}
                type="submit"
                variant="contained"
                className={classes.button}
              >
                {loader ? <div className="loader"></div> : "LOGIN"}
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default withSnackbar(ResetLinkSent);
