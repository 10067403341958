const TableActions = {
  SET_TABLE_COLUMN_HEADING: "SET_TABLE_COLUMN_HEADING",
  GET_TABLE_COLUMN_HEADING: "GET_TABLE_COLUMN_HEADING",
  RESET_TABLE_COLUMN_HEADING: "RESET_TABLE_COLUMN_HEADING",
  SET_TABLE_ROW: "SET_TABLE_ROW",
  GET_TABLE_ROW: "GET_TABLE_ROW",
  SET_PAGINATION: "SET_PAGINATION",
  GET_PAGINATION: "GET_PAGINATION",
};

export default TableActions;
