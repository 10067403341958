import "style/style.scss";
import {
  DialogContent,
  apiUrl,
  fetchDelete,
  Dialog,
  MuiDialogTitle,
  IconButton,
  withStyles,
  React,
  Button,
  Typography,
  CloseIcon,
  DeleteDialog,
  useHistory,
} from "allImport";
import { withSnackbar } from "notistack";
import { useBool } from "context/BoolContext";

const role = localStorage.getItem("ROLE")?localStorage.getItem("ROLE").toLowerCase():null;


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  alertText: {
    fontFamily: "Open Sans",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "30px",
    letterSpacing: "0.15000000596046448px",
    textAlign: "center",
    paddingBottom: "41px",
    margin: "auto",
    marginTop: "40px",
  },
  filledButton: {
    background: "#D9001D",
    borderRadius: "4px",
    textTransform: "capitalize",
    fontStyle: "normal",
    // fontWeight: "bold",
    fontSize: "1rem",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.15px",
    color: "#FFFFFF",
    fontFamily: "Mulish",
    width: "120px",
    height: "50px",
  },
  outlinedButton: {
    background: "#fafafa",
    color: "#D9001D",
    border: "1px solid",
    borderRadius: "4px",
    textTransform: "capitalize",
    fontStyle: "normal",
    fontSize: "1rem",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.15px",
    fontFamily: "Mulish",
    width: "120px",

    height: "50px",

    marginRight: "-54px",
  },
  dialogSize: {
    height: "285px",
    margin: "0px",
    maxWidth: "95vw",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DeleteAlert = withStyles(styles)((props) => {
  const { selects, setSelects, setSelect } = useBool();
  const [open, setOpen] = React.useState(true);

  const history = useHistory()

  const { classes } = props;
  const handleClose = () => {
    props.setDeletePopup(false);
    setOpen(false);
    setSelect(false);
    if (props.handleCloseModal) {
      props.handleCloseModal();
    }
  };

  console.log("this is my id", props.type);

  const Delete = (id) => {
    console.log("this is my id", id);
    setOpen(false);
    props.type === "Candidate"
      ? fetchDelete
          .delete(`${apiUrl.deleteCandidate}` + id)
          .then((res) => {
            setSelects(true);
          })
          .catch((error) => {
            props.setDeletePopup(false);
            setSelect(false);
            if (error.response) {
              if (error.response.data.code === 404) {
                props.enqueueSnackbar(
                  "Oops! Something went wrong. We are working on fixing the problem.",
                  {
                    variant: "error",
                    autoHideDuration: 3000,
                  }
                );
              } else if (error.response.data.error) {
                error.response.data.error.message.forEach((message) => {
                  props.enqueueSnackbar(message, {
                    variant: "erroTesting_finalteam_1111r",
                    autoHideDuration: 3000,
                  });
                });
              }
            } else {
              props.enqueueSnackbar(
                "Oops! Something went wrong. Please check your network connection and try again",
                {
                  variant: "error",
                }
              );
            }
          })
      : props.type === "Vacancy"
      ? fetchDelete
          .delete(`${apiUrl.deleteJob}` + id)
          .then((res) => {
            setSelects(true);
          })
          .catch((error) => {
            props.setDeletePopup(false);
            setSelect(false);
            if (error.response) {
              if (error.response.data.code === 404) {
                props.enqueueSnackbar(
                  "Oops! Something went wrong. We are working on fixing the problem.",
                  {
                    variant: "error",
                    autoHideDuration: 3000,
                  }
                );
              } else if (error.response.data.error) {
                error.response.data.error.message.forEach((message) => {
                  props.enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: 3000,
                  });
                });
              }
            } else {
              props.enqueueSnackbar(
                "Oops! Something went wrong. Please check your network connection and try again",
                {
                  variant: "error",
                }
              );
            }
          })
      : props.type === "Department"
      ? fetchDelete
          .delete(`${apiUrl.viewDepartments}` + id)
          .then((res) => {
            setSelects(true);
          })
          .catch((error) => {
            props.setDeletePopup(false);
            setSelect(false);
            if (error.response) {
              if (error.response.data.code === 404) {
                props.enqueueSnackbar(
                  "Oops! Something went wrong. We are working on fixing the problem.",
                  {
                    variant: "error",
                    autoHideDuration: 3000,
                  }
                );
              } else if (error.response.data.error) {
                error.response.data.error.message.forEach((message) => {
                  props.enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: 3000,
                  });
                });
              }
            } else {
              props.enqueueSnackbar(
                "Oops! Something went wrong. Please check your network connection and try again",
                {
                  variant: "error",
                }
              );
            }
          })
      : props.type === "Examiner"
      ? fetchDelete
          .delete(`${apiUrl.deleteExaminer}/` + id)
          .then((res) => {
            setSelects(true);
          })
          .catch((error) => {
            props.setDeletePopup(false);
            setOpen(false);
            setSelect(false);
            if (error.response) {
              if (error.response.data.code === 404) {
                props.enqueueSnackbar(
                  "Oops! Something went wrong. We are working on fixing the problem.",
                  {
                    variant: "error",
                    autoHideDuration: 3000,
                  }
                );
              } else if (error.response.data.error) {
                error.response.data.error.message.forEach((message) => {
                  props.enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: 3000,
                  });
                });
              }
            } else {
              props.enqueueSnackbar(
                "Oops! Something went wrong. Please check your network connection and try again",
                {
                  variant: "error",
                }
              );
            }
          })
      : props.type === "Exam"
      ? fetchDelete
          .delete(`${apiUrl.deleteExam}` + id)
          .then((res) => {
            setSelects(true);
          })
          .catch((error) => {
            props.setDeletePopup(false);
            setSelect(false);
            if (error.response) {
              if (error.response.data.code === 404) {
                props.enqueueSnackbar(
                  "Oops! Something went wrong. We are working on fixing the problem.",
                  {
                    variant: "error",
                    autoHideDuration: 3000,
                  }
                );
              } else if (error.response.data.error) {
                error.response.data.error.message.forEach((message) => {
                  props.enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: 3000,
                  });
                });
                history.push(`/${role}/`);
                history.push(`/${role}/exam`);
              }
            } else {
              props.enqueueSnackbar(
                "Oops! Something went wrong. Please check your network connection and try again",
                {
                  variant: "error",
                }
              );
            }
          })
      : props.type === "Questions"
      ? fetchDelete
          .delete(`${apiUrl.deleteQuestion}` + id)
          .then((res) => {
            setSelects(true);
          })
          .catch((error) => {
            props.setDeletePopup(false);
            setOpen(false);
            setSelect(false);
            if (error.response) {
              if (error.response.data.code === 404) {
                props.enqueueSnackbar(
                  "Oops! Something went wrong. We are working on fixing the problem.",
                  {
                    variant: "error",
                    autoHideDuration: 3000,
                  }
                );
              } else if (error.response.data.error) {
                error.response.data.error.message.forEach((message) => {
                  props.enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: 3000,
                  });
                });
              }
            } else {
              props.enqueueSnackbar(
                "Oops! Something went wrong. Please check your network connection and try again",
                {
                  variant: "error",
                }
              );
            }
          })
      : props.type === "Sub-Admin"
      ? fetchDelete
          .delete(`${apiUrl.deleteSubAdmin}/${id}/`)
          .then((res) => {
            setSelects(true);
          })
          .catch((error) => {
            props.setDeletePopup(false);
            setOpen(false);
            setSelect(false);
            if (error.response) {
              if (error.response.data.code === 404) {
                props.enqueueSnackbar(
                  "Oops! Something went wrong. We are working on fixing the problem.",
                  {
                    variant: "error",
                    autoHideDuration: 3000,
                  }
                );
              } else if (error.response.data.error) {
                error.response.data.error.message.forEach((message) => {
                  props.enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: 3000,
                  });
                });
              }
            } else {
              props.enqueueSnackbar(
                "Oops! Something went wrong. Please check your network connection and try again",
                {
                  variant: "error",
                }
              );
            }
          })
      : //  This is category deletion

      props.type === "Category"
      ? fetchDelete
          .delete(`${apiUrl.createCategory}/${id}`)
          .then((res) => {
            setSelects(true);
          })
          .catch((error) => {
            props.setDeletePopup(false);
            setOpen(false);
            setSelect(false);
            if (error.response) {
              if (error.response.data.code === 404) {
                props.enqueueSnackbar(
                  "Oops! Something went wrong. We are working on fixing the problem.",
                  {
                    variant: "error",
                    autoHideDuration: 3000,
                  }
                );
              } else if (error.response.data.error) {
                error.response.data.error.message.forEach((message) => {
                  props.enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: 3000,
                  });
                });
              }
            } else {
              props.enqueueSnackbar(
                "Oops! Something went wrong. Please check your network connection and try again",
                {
                  variant: "error",
                }
              );
            }
          })
      : window.location.reload(true);
  };

  return (
    <div>
      {selects ? (
        <DeleteDialog
          setDeletePopup={props.setDeletePopup}
          de={props.de}
          type={props.type}
          id={props.id}
        />
      ) : null}

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paper: classes.dialogSize }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} />
        <DialogContent>
          <Typography>
            <div className={classes.alertText}>{props.heading}</div>
          </Typography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="button_pop_up" style={{ paddingRight: "80px" }}>
              <Button
                onClick={() => handleClose()}
                className={classes.outlinedButton}
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
            </div>
            <div className="button_pop_up">
              <Button
                onClick={() => Delete(props.id)}
                className={classes.filledButton}
                variant="contained"
                color="primary"
              >
                Delete
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
});
export default withSnackbar(DeleteAlert);
