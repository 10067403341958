import axios from "axios";
import baseUrls from "../constant/constant";

const fetchClient = () => {
  const defaultOptions = {
    baseURL: "",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("API_TOKEN");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return instance;
};

export default fetchClient();
