import {makeStyles} from  "allImport"
import {commonStyle} from "commonStyle.js"
const useStyles = makeStyles((theme) => ({
   ...commonStyle(theme),
    goBackURL: {
      cursor: "pointer",
    },
    
    editIconProfile: {
     
      transform: 'translateY(18px)',
      height:'18px',
      width:'18px',
      marginLeft: "19px",
       marginRight: "28px",
       marginTop:'13px'
    },
  
    deleteIconProfile: {
      
      transform: 'translateY(18px)',
      height:'18px',
      width:'18px',
    
      
    },
    profile_heading: {
      fontFamily: 'Mulish',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '32px',
      lineHeight: '50px',
      letterSpacing: '0.25px',
      color: '#18273B',
    },
   
    ProfileHeader:{
        display:"flex",
        justifyContent:"flex-start"
    },
    examiners_select_exam_heading : {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0.1px',
      color: 'rgba(0, 0, 0, 0.3)',
      fontFamily: 'Mulish',
      paddingTop: '32px',
      paddingLeft: '32px',
    },
    select_exam_container: {
      paddingLeft: '32px',
      marginTop: '-148px',
    },
    profile_detail_label: {
      fontFamily: "Open sans",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "20px",
      letterSpacing: "0.15000000596046448px",
      textAlign: "left",
      color: "#000000",
      height: "20px",
    },
    profile_detail_content: {
      fontFamily: "Open sans",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "20px",
      letterSpacing: "0.15000000596046448px",
      textAlign: "left",
      color: "#000000",
      height: "20px",
      marginTop: "8px",
      textTransform: "capitalize",
    },
    LinkButton: {
      background: '#D9001D',
      borderRadius: '4px',
      textTransform: 'capitalize',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '20px',
      textAlign: 'center',
      letterSpacing: '0.15px',
      color: '#FFFFFF',
      fontFamily: 'Mulish',
      width: '208px',
      height: '45px',
      transform: 'translateY(10px)',
     
    },
    paper_heading: {
      fontStyle: "normal",
      fontWeight: "800",
      fontSize: "20px",
      lineHeight: "32px",
      letterSpacing: "0.1px",
      color: "#000000",
      fontFamily: "Mulish",
      paddingTop: "28px",
      paddingLeft: "24px",
     
    },
    divider_position:
  {
    marginLeft:'24px',
    marginRight:'33px',
    marginTop:'10px'
  },
ButtonPosition:{
  display:'flex',
  justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
},
  
    
  }));
  export default useStyles;
 