// import {
//   Emptyscreen,
//   clsx,
//   fetchUpdate,
//   Pagination,
//   fetchDelete,
//   MenuItem,
//   Select,
//   OutlinedInput,
//   CancelRoundedIcon,
//   useEffect,
//   fetchClient,
//   apiUrl,
//   KeyboardArrowDownIcon,
//   KeyboardArrowUpIcon,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Link,
//   useHistory,
//   CssBaseline,
//   Box,
//   Container,
//   Grid,
//   Paper,
//   Button,
//   IconButton,
//   InputAdornment,
//   TextField,
//   FormControl,
//   Topbar,
//   Sidebar,
//   useState,
//   React,
//   SearchIcon,
// } from "allImport";
// import { useStyles } from "style/Liststyle.js";
// import { capitalize, MenuProps } from "Common/CommonFunction";
// import { useLocation } from "react-router-dom";
// import "style/style.scss";
// import {
//   ADMIN_ID,
//   API_TOKEN,
//   LOGOUT_TOKEN,
//   ADMIN_NAME,
// } from "Storage/StorageKeys.js";
// import StorageManager from "Storage/StorageManager.js";
// import { withSnackbar } from "notistack";
// import { InputLabel } from "@material-ui/core";
// var AS = [];
// var searchValue = "";
// var url = new URL(`${apiUrl.viewQuestions}`);

// const ExamquestionList = (props) => {
//   const ExamID = props.location.state.ExamID;
//   const qIDS = props.location.state.questionIDs;

//   const classes = useStyles();
//   const location = useLocation();
//   const history = useHistory();
//   const role = localStorage.getItem("ROLE")
//     ? localStorage.getItem("ROLE").toLowerCase()
//     : null;
//   const [topbarVisible, setTopBarVisible] = React.useState(true);
//   const [open, setOpen] = React.useState([]);
//   const [totalPage, setTotalPage] = React.useState("");
//   const [level, setLevel] = React.useState("");
//   const [count, setCount] = React.useState("");
//   const [questionSearch, setQuestionSearch] = React.useState("");
//   const [cat, setCat] = useState([]);
//   const [Arr, setArr] = React.useState([]);
//   var [Questionids, setQuestionIDs] = React.useState([]);
//   console.log(props.location.state, "esko search kar");
//   const department = localStorage.getItem("EXAMINER_DEPT")
//     ? localStorage.getItem("EXAMINER_DEPT")
//     : location.state.Department;
//   console.log("department---------->", department);
//   const [page, setPage] = React.useState(1);

//   var handleOnload = (id) => {
//     let tempQuestionIDs = Questionids;
//     if (tempQuestionIDs.indexOf(id) > -1) {
//       return true;
//     } else {
//       return false;
//     }
//   };

//   const handleChange = (id) => {
//     console.log("---------------id----", id);
//     let tempQuestionIDs = Questionids;
//     if (tempQuestionIDs.some((item) => item === id)) {
//       const index = tempQuestionIDs.indexOf(id);
//       tempQuestionIDs.splice(index, 1);

//       fetchDelete.delete(
//         `${apiUrl.removeExamQuestion}` + ExamID + "/question/map",
//         { data: { question: [id] } }
//       );
//     } else {
//       tempQuestionIDs.push(id);
//     }
//     setQuestionIDs(tempQuestionIDs);
//   };

//   const handleChangePage = (event, value) => {
//     if (Questionids) {
//       const data = {
//         question: Questionids,
//       };
//       fetchUpdate.patch(
//         `${apiUrl.mapQuestion}` + ExamID + "/question/map/",
//         data
//       );
//     }
//     let url = `${apiUrl.viewQuestions}?question_status=publish&page=` + value;

//     url += level === "" ? "" : `&experience_level=${level}`;

//     url += questionSearch === "" ? "" : `&search=${questionSearch}`;
//     url += department === "" ? "" : `&department=${department}`;

//     fetchClient.get(url).then((res) => {
//       var QuestionsList =
//         res && res.data && res.data.data && res.data.data.results;
//       setCount(res && res.data && res.data.data && res.data.data.count);
//       setTotalPage(res && res.data && res.data.data && res.data.data.pages);
//       colmConversion(QuestionsList);
//     });
//     setPage(value);
//   };

//   function createData(ColA, ColB, ColC, ColD, ColE, ColF, ColG) {
//     return { ColA, ColB, ColC, ColD, ColE, ColF, ColG };
//   }

//   const colmConversion = (arr) => {
//     console.log("56", Questionids);
//     AS = [];
//     console.log("array", arr);
//     for (var index = 0; index < arr.length; index++) {
//       AS.push(
//         createData(
//           arr[index] && arr[index].question_name,
//           arr[index] && arr[index].experience_level,

//           arr[index] &&
//             new Date(arr[index].created_at).toLocaleDateString("en-IN"),
//           arr[index] && arr[index].tags,
//           arr[index] && arr[index].question_type,
//           arr[index].question_category === null
//             ? "----"
//             : arr[index].question_category.name,
//           arr[index] && arr[index].id
//         )
//       );
//     }

//     setArr(AS);
//   };

//   const filterQuestions = (e) => {
//     setLevel("");
//     setQuestionSearch(e.target.value);
//     searchValue = e.target.value;
//     fetchClient
//       .get(
//         `${apiUrl.viewQuestions}?search=` +
//           searchValue +
//           `&question_status=publish&&department=` +
//           department
//       )
//       .then((res) => {
//         setCount(res && res.data && res.data.data && res.data.data.count);
//         setTotalPage(res && res.data && res.data.data && res.data.data.pages);
//         var QuestionsList =
//           res && res.data && res.data.data && res.data.data.results;
//         colmConversion(QuestionsList);
//       });
//   };

//   const categoryFilterSelection = (e) => {
//     setLevel(e.target.value);
//     setQuestionSearch("");

//     var search = e.target.value;

//     if (search == "") {
//       url.searchParams.delete("experience_level");
//       fetchClient
//         .get(url + `?question_status=publish&department=` + department)
//         .then((res) => {
//           var QuestionsList =
//             res && res.data && res.data.data && res.data.data.results;
//           setTotalPage(res && res.data && res.data.data && res.data.data.pages);
//           setCount(res && res.data && res.data.data && res.data.data.count);
//           colmConversion(QuestionsList);
//         });
//     } else {
//       fetchClient
//         .get(
//           `${apiUrl.filterCategory}?question_status=publish&department=${department}&question_category=` +
//             search
//         )
//         .then((res) => {
//           console.log("------this is res-----------", res);
//           var CategoryList =
//             res && res.data && res.data.data && res.data.data.results;
//           setTotalPage(res && res.data && res.data.data && res.data.data.pages);
//           setCount(res && res.data && res.data.data && res.data.data.count);
//           colmConversion(CategoryList);
//         });
//     }
//   };

//   const questionFilterSelection = (e) => {
//     setLevel(e.target.value);
//     setQuestionSearch("");
//     if (e.target.value !== "") {
//       url.searchParams.set("experience_level", e.target.value);
//       fetchClient
//         .get(url + `&question_status=publish&department=` + department)
//         .then((res) => {
//           var QuestionsList =
//             res && res.data && res.data.data && res.data.data.results;
//           setTotalPage(res && res.data && res.data.data && res.data.data.pages);
//           setCount(res && res.data && res.data.data && res.data.data.count);
//           colmConversion(QuestionsList);
//         });
//     } else {
//       url.searchParams.delete("experience_level");
//       fetchClient
//         .get(url + `?question_status=publish&department=` + department)
//         .then((res) => {
//           var QuestionsList =
//             res && res.data && res.data.data && res.data.data.results;
//           setTotalPage(res && res.data && res.data.data && res.data.data.pages);
//           setCount(res && res.data && res.data.data && res.data.data.count);
//           colmConversion(QuestionsList);
//         });
//     }
//   };

//   const handleExpand = (index) => {
//     let temp = open;
//     temp[index] = !temp[index];
//     setOpen([...temp]);
//   };

//   const clear = () => {
//     setQuestionSearch("");
//     fetchClient
//       .get(
//         `${apiUrl.viewQuestions}?question_status=publish&department=` +
//           department
//       )
//       .then((res) => {
//         var QuestionsList =
//           res && res.data && res.data.data && res.data.data.results;
//         setTotalPage(res && res.data && res.data.data && res.data.data.pages);
//         setCount(res && res.data && res.data.data && res.data.data.count);
//         colmConversion(QuestionsList);
//       });
//   };

//   useEffect(() => {
//     if (
//       !StorageManager.get(API_TOKEN) &&
//       !StorageManager.get(ADMIN_ID) &&
//       !StorageManager.get(ADMIN_NAME) &&
//       !StorageManager.get(LOGOUT_TOKEN)
//     ) {
//       history.push("/login");
//     }
//     let questionids = [];
//     setQuestionIDs(qIDS ? questionids.concat(qIDS) : questionids);

//     fetchClient.get(`${apiUrl.createCategory}?has_question=1`).then((res) => {
//       setCat(res.data.data.results);
//       console.log("-----------res------", res.data.data.results);
//     });

//     fetchClient
//       .get(
//         `${apiUrl.viewQuestions}?question_status=publish&department=` +
//           department
//       )
//       .then((res) => {
//         setTotalPage(res && res.data && res.data.data && res.data.data.pages);
//         setCount(res && res.data && res.data.data && res.data.data.count);
//         const questionList =
//           res && res.data && res.data.data && res.data.data.results;
//         colmConversion(questionList);
//       });
//   }, []);
//   const mapQuestion = () => {
//     const data = {
//       question: Questionids,
//     };
//     if (Questionids.length > 0) {
//       fetchUpdate
//         .patch(`${apiUrl.mapQuestion}` + ExamID + "/question/map/", data)
//         .then((res) => {
//           history.push({
//             pathname: `/${role}/exam/question/select-question`,
//             state: {
//               ExamID: res.data.data.exam.id,
//               ExamName: res.data.data.exam.exam_name,
//             },
//           });
//         });
//     } else {
//       props.enqueueSnackbar("Please Add Atleast One Question", {
//         variant: "error",
//         autoHideDuration: 3000,
//       });
//     }
//   };

//   return (
//     <main className={classes.content} id="main-content">
//       <Container maxWidth="xl" className={classes.container}>
//         <Grid container xs={12}>
//           <Grid item xs={12} sm={6} align="left" justify="left">
//             <div className={classes.page_heading} style={{ width: "140%" }}>
//               Question Bank - Select Questions
//             </div>
//           </Grid>
//           <Grid item xs={12} sm={6} align="right" justify="right">
//             <div className="button_invite_examiner">
//               <div className="button_invite_examiner">
//                 {count === 0 ? (
//                   <Link
//                     to={{
//                       pathname: `/${role}/question-bank/questions/add`,
//                     }}
//                   >
//                     <Button
//                       className={classes.filledButton}
//                       variant="contained"
//                       color="primary"
//                     >
//                       Create Question
//                     </Button>
//                   </Link>
//                 ) : (
//                   <Button
//                     onClick={mapQuestion}
//                     className={classes.filledButton}
//                     variant="contained"
//                     color="primary"
//                   >
//                     Next
//                   </Button>
//                 )}
//               </div>
//             </div>
//           </Grid>
//         </Grid>
//         <Grid container xs={12}>
//           <Grid item xs={12} sm={6} align="left" justify="left">
//             <div className={classes.page_subheading}>
//               <Link
//                 className={clsx(classes.page_subheading, classes.LinkStyle)}
//                 to={{ pathname: `/${role}/exam` }}
//               >
//                 Exams Home
//               </Link>{" "}
//               <span className="spc"></span> &gt; <span className="spc"></span>{" "}
//               <Link
//                 className={clsx(classes.page_subheading, classes.LinkStyle)}
//                 to={{ pathname: `/${role}/exam/create` }}
//               >
//                 {" "}
//                 Create Exam{" "}
//               </Link>
//               <span className="spc"></span> &gt; <span className="spc"></span>{" "}
//               Choose Question
//             </div>
//           </Grid>
//         </Grid>

//         <Grid item lg={12} xl={12} md={12} sm={12}>
//           <Grid
//             // style={{ backgroundColor: "pink" }}
//             container
//             className={classes.allExam_container}
//             lg={12}
//             xl={12}
//           >
//             <Grid
//               item
//               lg={3}
//               xl={3}
//               md={3}
//               sm={3}
//               align="left"
//               justify="left"
//               style={{ display: "flex", alignItems: "center" }}
//             >
//               <div className={classes.all_examiners_heading}>
//                 {" "}
//                 All Questions
//               </div>
//               <div className={classes.examiner_nos}>{count}</div>
//             </Grid>

//             <Grid
//               item
//               lg={6}
//               xl={6}
//               md={3}
//               sm={3}
//               align="center"
//               justify="center"
//             >
//               <TextField
//                 // type="search"
//                 placeholder="Search"
//                 id="outlined-basic"
//                 onChange={(e) => filterQuestions(e)}
//                 variant="outlined"
//                 value={questionSearch}
//                 className={classes.searchExaminerfield}
//                 InputProps={{
//                   startAdornment: (
//                     <InputAdornment>
//                       <IconButton>
//                         <SearchIcon />
//                       </IconButton>
//                     </InputAdornment>
//                   ),
//                   endAdornment: questionSearch && (
//                     <IconButton
//                       aria-label="toggle password visibility"
//                       onClick={clear}
//                     >
//                       <CancelRoundedIcon />
//                     </IconButton>
//                   ),
//                 }}
//               />
//             </Grid>
//             <Grid
//               item
//               lg={3}
//               xl={3}
//               md={6}
//               sm={6}
//               style={{
//                 paddingRight: "24px",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "space-between",
//               }}
//             >
//               <div className={classes.examinerDept}>
//                 <FormControl
//                   style={{
//                     textAlign: "center",
//                     marginTop: "-16px",
//                     width: "142px",
//                   }}
//                 >
//                   <label style={{ textAlign: "left" }}>Category</label>
//                   <Select
//                     id="demo-customized-select-native"
//                     value={cat.id}
//                     onChange={(e) => categoryFilterSelection(e)}
//                     // MenuProps={MenuProps}
//                     input={<OutlinedInput style={{ height: "48px" }} />}
//                   >
//                     <MenuItem value="">Category</MenuItem>

//                     {cat.map((cat) => (
//                       <MenuItem value={cat.id}>{cat.name}</MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </div>
//               <div className={classes.examinerDept}>
//                 <FormControl
//                   style={{
//                     textAlign: "center",
//                     marginTop: "-16px",
//                     width: "142px",
//                   }}
//                 >
//                   {/* <InputLabel htmlFor="name-multiple">Division</InputLabel> */}
//                   <label style={{ textAlign: "left" }}>Level</label>

//                   <Select
//                     id="demo-customized-select-native"
//                     // displayEmpty
//                     value={level}
//                     onChange={(e) => questionFilterSelection(e)}
//                     MenuProps={MenuProps}
//                     input={<OutlinedInput style={{ height: "48px" }} />}
//                   >
//                     <MenuItem value="">Level</MenuItem>
//                     <MenuItem value="senior">Senior</MenuItem>
//                     <MenuItem value="junior">Junior</MenuItem>
//                     <MenuItem value="internship">Internship</MenuItem>
//                   </Select>
//                 </FormControl>
//               </div>
//             </Grid>
//           </Grid>

//           {count === 0 ? (
//             <Emptyscreen />
//           ) : (
//             <Grid container xs={12}>
//               {/* <div className={classes.examiners_table_wrapper}> */}
//               <TableContainer style={{ height: "63vh" }} component={Paper}>
//                 <Table className={classes.table} aria-label="simple table">
//                   <TableHead>
//                     <TableRow
//                       style={{ height: "60px" }}
//                       sx={{
//                         "&:last-child td, &:last-child th": {
//                           border: 0,
//                         },
//                       }}
//                     >
//                       <TableCell style={{ width: "0px" }} />
//                       <TableCell />
//                       <TableCell align="left">Questions</TableCell>
//                       <TableCell align="left">Category</TableCell>
//                       <TableCell align="left">Level</TableCell>
//                       <TableCell align="left">Question Type</TableCell>

//                       <TableCell align="left">Date Added</TableCell>
//                       <TableCell align="left">Tag</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {console.log("values------------", Arr)}
//                     {Arr.map((row, index) => (
//                       <TableRow key={row.name}>
//                         <TableCell
//                           style={{
//                             paddingTop: "9px",
//                             paddingBottom: "9px",
//                             paddingLeft: "24px",
//                           }}
//                           component="th"
//                           scope="row"
//                         >
//                           {/* <Checkbox value="checkbox" style={{color:'black'}}/> */}
//                           <label className="container">
//                             <input
//                               key={`exam-ques-${row.id}`}
//                               type="checkbox"
//                               defaultChecked={handleOnload(row.id)}
//                               onChange={() => {
//                                 console.log("------r-", row.ColG),
//                                   handleChange(row.ColG);
//                               }}
//                             />
//                             <span className="mark"></span>
//                           </label>
//                         </TableCell>
//                         <TableCell>
//                           <div
//                             style={{
//                               width: "100%",
//                               height: "100%",
//                             }}
//                           >
//                             <IconButton
//                               aria-label="expand row"
//                               size="small"
//                               onClick={() => handleExpand(index)}
//                             >
//                               {open[index] ? (
//                                 <KeyboardArrowUpIcon />
//                               ) : (
//                                 <KeyboardArrowDownIcon />
//                               )}
//                             </IconButton>
//                           </div>
//                         </TableCell>
//                         <TableCell align="left">
//                           <div
//                             className={
//                               !open[index] ? classes.collapseRowExam : ""
//                             }
//                             style={{ maxWidth: 440 }}
//                           >
//                             <span
//                               dangerouslySetInnerHTML={{
//                                 __html: row.ColA.replace(
//                                   "<img",
//                                   `<img style="max-width: 350px; border-radius: 4px; border: 1px solid #0000001A"`
//                                 ),
//                               }}
//                             ></span>
//                           </div>
//                         </TableCell>
//                         <TableCell align="left">
//                           {capitalize(row.ColF)}
//                         </TableCell>
//                         <TableCell align="left">
//                           {capitalize(row.ColB)}
//                         </TableCell>
//                         <TableCell align="left">
//                           {capitalize(row.ColE)}
//                         </TableCell>
//                         <TableCell align="left">{row.ColC}</TableCell>
//                         <TableCell align="left">
//                           <div className={classes.tagsContainer}>
//                             {row.ColD.map((i) => (
//                               <div className={classes.tag}>{i.tag}</div>
//                             ))}
//                           </div>
//                         </TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//               {/* </div> */}
//             </Grid>
//           )}

//           <div className={classes.pagination}>
//             <Pagination
//               count={totalPage}
//               page={page}
//               onChange={handleChangePage}
//             />
//           </div>
//         </Grid>
//       </Container>
//     </main>
//   );
// };
// export default withSnackbar(ExamquestionList);

import React, { useState, useEffect } from "react";

import {
  withSnackbar,
  DeleteAlert,
  fetchClient,
  apiUrl,
  useHistory,
  fetchUpdate,
} from "allImport";
import { useStyles } from "style/Liststyle.js";
import { capitalize, convertUTCDateToLocalDate } from "Common/CommonFunction";
import { useDebounce } from "hooks";
import "style/style.scss";

import { API, ENDPOINTS } from "../../../api/apiService";
import TablePageLayout from "layout/TablePageLayout";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import { useDispatch, useSelector } from "react-redux";
import Actions from "redux/actions/filterActions";
import TableActions from "redux/actions/tableActions";
import CommonTable from "components/CommonComponent/Filter/CommonTable/CommonTable";
import SelectQuestsionAction from "redux/actions/selectQuestionAction";

const QUESTION_TYPE = [
  { label: "Question Type", value: "question_type" },
  { label: "Subjective", value: "subjective" },
  { label: "Objective", value: "objective" },
];

const QUESTION_STATUS = [
  { label: "Status", value: "question_status" },
  { label: "Publish", value: "publish" },
  { label: "Unpublish", value: "unpublish" },
  { label: "Draft", value: "draft" },
];

const EXAM_LEVEL = [
  { label: "Exam Level", value: "experience_level" },
  { label: "Senior", value: "senior" },
  { label: "Junior", value: "junior" },
  { label: "Internship", value: "internship" },
];

const queryString = require("query-string");

var AS = [];
var questionIDs = [];

const ExamquestionList = (props) => {

  const ExamID = props.location.state.ExamID;
  console.log("propppp", props);

  const department = localStorage.getItem("EXAMINER_DEPT")
    ? localStorage.getItem("EXAMINER_DEPT")
    : props.location.state.Department;
  const [totalPages, setTotalPages] = useState();
  useEffect(() => {
    fetchClient.get(`${apiUrl.createCategory}?has_question=1&experience_level=${props.location.state.Level}&department=${props.location.state.Department}`).then((res) => {
      const resp = res.data;
      if (resp.success) {
        const result = resp.data;
        const res = result.results;
        setTotalPages(result.pages);

        let temp = res.map((item) => ({
          value: item.id,
          label: item.name,
          id: item.id,
        }));
        temp.unshift({
          label: "Category",
          value: "question_category",
        });
        setCat(temp);
      }
    });
  }, []);
  const { questionIdList } = useSelector(
    (store) => store.selectQuestionReducer
  );

  const classes = useStyles();
  const history = useHistory();
  const [loader, setLoader] = React.useState(true);
  const [totalPage, setTotalPage] = React.useState("");
  const [count, setCount] = React.useState(null);
  const [questionSelect, setquestionSelect] = React.useState(false);
  const [id, setID] = React.useState("");
  const examiner_dept = localStorage.getItem("EXAMINER_DEPT");
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const [cat, setCat] = useState([]);
  console.log("examiner dept--->", examiner_dept);
  const [departments, setDepartments] = useState([]);

  const [filter, setFilter] = React.useState({
    page: 1,
    page_size: 5,
    search: "",
    question_type: 0,
    question_status: 0,
    experience_level: 0,
    department: 0,
    role: role,
    examiner_dept: "",
    category_filter: 0,
  });
  const debouncedSearchTerm = useDebounce(filter.search, 500);

  const { dataArray } = useSelector((store) => store.filter);
  const { searchData } = useSelector((store) => store.search);

  // Select question check state
  const [selectQuestionCheck, setSelectQuestionCheck] = useState([]);

  const options1 = queryString.stringify({
    ...dataArray,
  });

  console.log("data--->", options1);

  const queryBuilder = (url, options) => {
    const options1 = queryString.stringify({
      ...dataArray,
    });

    console.log("data--->", options1);

    if (options === undefined || !options) return url;

    return `${url}?${options.page ? `&page=${options.page}` : ""}${
      searchData ? `&search=${searchData}` : ""
    }${
      options.role !== "examiner"
        ? ""
        : options.examiner_dept !== ""
        ? `&department=${options.examiner_dept}`
        : ""
    }&${options1}`;
  };

  const handleChangeFilter = (key) => (event) => {
    let tempData = { ...filter };
    tempData[key] = event.target.value;
    tempData["page"] = 1;
    setFilter(tempData);
  };

  const handleChange = (e) => {
    var search = e.target.value;

    if (search === "") {
      // fetchRecord();
    } else {
      fetchClient
        .get(`${apiUrl.filterCategory}?question_category=` + search)
        .then((res) => {
          console.log("Martyni", res);
          var CategoryList =
            res && res.data && res.data.data && res.data.data.results;
          setTotalPage(res && res.data && res.data.data && res.data.data.pages);
          setCount(res && res.data && res.data.data && res.data.data.count);
          colmConversion(CategoryList);
        });
    }
  };

  const handleCheckboxClick = (e, id) => {
    if (e.target.checked) {
      dispatch({
        type: SelectQuestsionAction.SET_SELECT_QUESTION,
        data: id,
      });
    } else {
      dispatch({
        type: SelectQuestsionAction.UNSET_SELECT_QUESTION,
        data: id,
      });
    }
  };

  const handleChangePage = (event, page) => {
    dispatch({
      type: TableActions.SET_TABLE_ROW,
      count: null,
    });
    setFilter({ ...filter, page });
  };
  console.log("dataArrrayyy", dataArray);
  
  useEffect(() => {
    // fetchClient.get(`${apiUrl.createCategory}?has_question=1&experience_level=${props.location.state.Level}&department=${props.location.state.Department}`).then((resp) => {
    //   if (resp.success) {
    //     const result = resp.data;
    //     const res = result.results;
    //     setTotalPages(result.pages);

    //     let temp = res.map((item) => ({
    //       value: item.id,
    //       label: item.name,
    //       id: item.id,
    //     }));
    //     temp.unshift({
    //       label: "Category",
    //       value: "question_category",
    //     });
    //     setCat(temp); 
    //   }
    // });

    fetchClient
      .get(
        `${apiUrl.viewQuestions}?${
          filter.page ? `&page=${filter.page}` : ""
        }&${options1}&experience_level=${
          props.location.state.Level
        }&question_status=publish&department=` + department
      )
      .then((resp) => {
        const result = resp.data.data;
        console.log("marketing", result);
        let SUB_ADMIN_PERMISSION =
          localStorage.getItem("SUB_ADMIN_PERMISSION") || false;

        setTotalPages(result.pages);
        console.log("datattt", result.results);
        const count1 = result.count;
        const examinerData = result.results.map((item) => {
          console.log(item.tags, "Amerenta");
          return {
            0: "checkbox",
            1: "dropdownIcon",
            2: item.question_name,
            3:
              item.question_category !== null
                ? item.question_category.name
                : "---",
            4: item.experience_level,
            5: item.question_type,
            6: convertUTCDateToLocalDate(
              new Date(item.updated_at)
            ).toLocaleDateString("en-IN"),
            7: item.tags,
            id: item.id,

            assignExam: {
              link: "abc",
              candidateId: "abc",
              candidateName: "abc",
              deptId: "abc",
            },
            editAction: {
              editpath: `/${role}/examiner/edit-profile`,
              // examinerName: item.full_name,
              // examinerEmail: item.email,
              // examinerNo: item.phone_number,
              // examinerDept: item.department,
              // examinerDesignation: item.designation,
              // examinerID: item.id,
              allow: SUB_ADMIN_PERMISSION
                ? SUB_ADMIN_PERMISSION.includes("EDIT_EXAMINER")
                : true,
            },
            deleteAction: {
              actionID: item.id,
              deleteName: item.full_name,
              tableType: "Questions",
              allow: SUB_ADMIN_PERMISSION
                ? SUB_ADMIN_PERMISSION.includes("DELETE_EXAMINER")
                : true,
            },
          };
        });
        dispatch({
          type: TableActions.SET_TABLE_ROW,
          data: examinerData,
          tableHeading: "All Questions",
          profile: `/${role}/examiner/profile`,
          count: count1,
          pageNumber: filter.page,
        });
        dispatch({
          type: TableActions.SET_PAGINATION,
          data: { pages: result.pages, count: result.count },
        });
        setTotalPage(result.pages);
      });
  }, [filter, questionIdList, dataArray]);

  const mapQuestion = () => {
    const data = {
      question: questionIdList,
    };
    if (questionIdList.length > 0) {
      fetchUpdate
        .patch(`${apiUrl.mapQuestion}` + ExamID + "/question/map/", data)
        .then((res) => {
          history.push({
            pathname: `/${role}/exam/question/select-question`,
            state: {
              ExamID: res.data.data.exam.id,
              ExamName: res.data.data.exam.exam_name,
              Level: props.location.state.Level,
            },
          });
        });
    } else {
      props.enqueueSnackbar("Please Add Atleast One Question", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const fetchDepartmentList = async () => {
    try {
      const resp = await API.get(
        `${ENDPOINTS.DEPARTMENT_LIST}?has_question=1&page_size=1000`
      );
      if (resp.success) {
        const result = resp.data;
        dispatch({
          type: TableActions.SET_TABLE_ROW,

          count: count,
        });
        const res = result.results;
        // setDeptlist(res);
        let temp = res.map((item) => ({
          value: item.id,
          label: item.name,
          id: item.id,
        }));
        temp.unshift({
          label: "Department",
          value: "department",
        });
        setDepartments(temp);
      }
    } catch (e) {
      const msg =
        typeof e.data.error?.message === "string"
          ? e.data.error?.message
          : e.data.error?.message[0];
      props.enqueueSnackbar(
        msg || "Error fetching department list. Please try again",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    }
  };

  useEffect(() => {
    fetchDepartmentList();
  }, []);

  // useEffect(() => {
  //   fetchRecord();
  // }, [dataArray, filter, searchData]);

  const dispatch = useDispatch();
  useEffect(() => {
    // const allowActionColumn =
    //   localStorage.getItem("ROLE") === "SUB-ADMIN" &&
    //   localStorage.getItem("SUB_ADMIN_PERMISSION") &&
    //   !localStorage.getItem("SUB_ADMIN_PERMISSION").includes("EDIT_EXAMINER") &&
    //   !localStorage.getItem("SUB_ADMIN_PERMISSION").includes("DELETE_EXAMINER");
    dispatch({
      type: TableActions.SET_TABLE_COLUMN_HEADING,
      data: [
        "checkbox",
        "",
        "Questions",
        "Category",
        "Level",
        "Question Type",
        "Date Added",
        "Tags",
      ],
    });
  }, []);
  useEffect(() => {
    dispatch({
      type: Actions.SET_FILTER,
      count: 3,
      data: [QUESTION_TYPE, cat],
    });
  }, [QUESTION_TYPE, cat]);

  return (
    <>
      <HeaderWithBreadcrumb heading="Question Bank" />

      {questionSelect ? (
        <DeleteAlert
          type="Question"
          heading="Are you sure you want to Delete this Question ? "
          id={id}
        />
      ) : null}
      {console.log("cat------>", cat)}
      <TablePageLayout
        heading="Questions"
        loader={!loader}
        ButtonText="Next"
        buttonCategoryText="Create Category"
        // buttonActionPath={`/${role}/question-bank/questions/add`}
        buttonFunction={mapQuestion}
        allowCreate={true}
        handleChangeFilter={handleChangeFilter("search")}
        count={count}
        handleChangePage={handleChangePage}
        totalPage={totalPage}
        candidateFilter={{
          item: [EXAM_LEVEL, QUESTION_TYPE, departments, QUESTION_STATUS, cat],
          handleChangeExamLevel: handleChangeFilter("experience_level"),
          handleChangeTypeLevel: handleChangeFilter("question_type"),
          handleChangeDepartmentLevel: handleChangeFilter("department"),
          handleChangeStatusLevel: handleChangeFilter("question_status"),
          handleChangeCategoryLevel: (e) => handleChange(e),

          type: "questionlist",
        }}
      >
        <CommonTable
          Count={totalPages}
          handleChangePage={handleChangePage}
          handleCheckboxClick={handleCheckboxClick}
          selectQuestionCheck={selectQuestionCheck}
        />
      </TablePageLayout>
    </>
  );
};
export default withSnackbar(ExamquestionList);
