import {
  useHistory,
  InputAdornment,
  VisibilityOff,
  Visibility,
  IconButton,
  Formik,
  Field,
  Yup,
  Form,
  Grid,
  makeStyles,
  Button,
  Typography,
  React,
  Zoom,
  fetchPost,
  apiUrl,
} from "allImport";
import { askForPermissioToReceiveNotifications } from "pushNotification";
import { TextField } from "formik-material-ui";
import AppDispatcher from "redux/dispatchers/appDispatcher";
import "style/style.scss";
import {
  EXAMINER_DEPT,
  ROLE,
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
  SUB_ADMIN_PERMISSION,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import { commonStyle } from "commonStyle.js";
import { withSnackbar } from "notistack";
import Loader from "components/Loader/Loader";
import { Box, InputLabel, Slide } from "@material-ui/core";
import LeftBox from "./LeftBox";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import PermissionActions from "redux/actions/permissionActions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),

  signin_button_box: {
    paddingTop: "5.33vh",
  },
  signin_mail_label: {
    color: "#444444",
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: " 400",
    lineHeight: " 24px",
    letterSpacing: "0.5px",
    textAlign: "left",
  },
  inputlabel: {
    marginTop: "2.6vh",
    color: "#444444",
    fontFamily: "Mulish",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: " 600",
    lineHeight: " 24px",
    letterSpacing: "0.5px",
    textAlign: "left",
  },

  inputfeedback: {
    marginTop: "10px",
  },

  signin_checkbox: {
    display: "flex",
    width: "19px",
    height: "18px",
    padding: "0px",
    backgroundColor: "white",
    paddingTop: "3.55vh",
  },

  signin_checkbox_line: {
    display: "flex",
  },
  signin_form: {
    marginTop: "3.5vh",
  },
  signin_rememberme: {
    paddingLeft: "8px",
    paddingTop: "3.55vh",
    fontSize: "14px",
  },
  rememberMeLabel: {
    color: "#000",

    cursor: "pointer",
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    tickSize: {
      transform: "scale(1.5)",
    },
  },
  signin_forgotpassword: {
    color: "#000",

    cursor: "pointer",
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    "&:hover": {
      color: "#D9001D",
    },
    tickSize: {
      transform: "scale(1.5)",
    },
  },
}));
const LogIn = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loader, setLoader] = React.useState(false);
  const [Checked, setChecked] = React.useState(false);
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const [showPassword, setShowPassword] = React.useState(false);
  const [deviceToken, setDeviceToken] = React.useState("123");
  const history = useHistory();
  const togglePasswordVisiblity = () => {
    setShowPassword(showPassword ? false : true);
  };

  const setupFirebase = async () => {
    let deviceToken = await askForPermissioToReceiveNotifications();
    if (deviceToken) {
      setDeviceToken(deviceToken);
    }
  };
  const HandleRememberme = () => {
    setChecked(!Checked);
  };

  React.useLayoutEffect(() => {
    setupFirebase();
    if (
      StorageManager.get(API_TOKEN) &&
      StorageManager.get(ADMIN_ID) &&
      StorageManager.get(ADMIN_NAME) &&
      StorageManager.get(LOGOUT_TOKEN)
    ) {
      history.push(`/${role}/dashboard`);
    } else {
      StorageManager.clearStore();
      history.push("/login");
    }
  }, []);

  return loader ? (
    <Loader />
  ) : (
    <Grid container justifyContent="space-between" wrap="nowrap">
      {/* Common Left Box */}
      <LeftBox heading="We’ve been Expecting you" message="" />

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={8}
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Box
          sx={{
            width: { xs: "80%", sm: "70%", md: "70%", lg: "50%" },
          }}
        >
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Typography variant="h3" className={classes.loginHeading}>
                Sign In
              </Typography>
              <Typography className={classes.signin_description}></Typography>
              <Formik
                initialValues={{ email: "", password: "" }}
                onSubmit={(values, { resetForm }) => {
                  let data = JSON.stringify({
                    email: values.email,
                    password: values.password,
                    device: {
                      device_token: deviceToken,
                      device_type: "WEB",
                      is_safari: 0,
                    },
                  });
                  fetchPost
                    .post(`${apiUrl.login}`, data)
                    .then((res) => {
                      if (res.data.success) {
                        const { refresh, access } = res.data.data.tokens;
                        const { role, id, full_name, department } =
                          res.data.data;

                        dispatch({
                          type: PermissionActions.SET_PERMISSIONS,
                          data: {
                            API_TOKEN: access,
                            ROLE: role,
                            ADMIN_ID: id,
                            ADMIN_NAME: full_name,
                            LOGOUT_TOKEN: refresh,
                          },
                        });

                        localStorage.setItem("rememberMe", Checked);
                        localStorage.setItem(
                          "user",
                          Checked ? values.email : ""
                        );
                        localStorage.setItem(
                          "Password",
                          Checked ? values.password : ""
                        );
                        StorageManager.put(API_TOKEN, access);
                        StorageManager.put(ROLE, role);
                        StorageManager.put(ADMIN_ID, id);
                        StorageManager.put(ADMIN_NAME, full_name);
                        StorageManager.put(LOGOUT_TOKEN, refresh);

                        if (role === "SUB-ADMIN") {
                          StorageManager.putJSON(
                            SUB_ADMIN_PERMISSION,
                            res.data.data.permissions.permission
                          );
                          dispatch({
                            type: PermissionActions.SET_SUB_ADMIN_PERMISSION,
                            data: {
                              SUB_ADMIN_PERMISSION:
                                res.data.data.permissions.permission,
                            },
                          });
                        }
                        if (role === "EXAMINER") {
                          StorageManager.put(EXAMINER_DEPT, department);
                        }
                        AppDispatcher.setUserLoggedIn({
                          token: access,
                          user: { name: "Test", email: values.email },
                        });
                        setLoader(true);
                        history.go(`/${role.toLowerCase()}/dashboard`);
                      } else {
                        props.enqueueSnackbar("token expired.", {
                          variant: "error",
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                          },
                          TransitionComponent: Zoom,
                          autoHideDuration: 5000,
                          resumeHideDuration: 2000,
                        });
                        resetForm();
                      }
                    })
                    .catch((error) => {
                      if (!error.response) {
                        props.enqueueSnackbar("Net Disconnected", {
                          variant: "error",
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                          },
                          autoHideDuration: 3000,
                          TransitionComponent: Zoom,
                        });
                      } else {
                        props.enqueueSnackbar(
                          error.response.data.error.message,
                          {
                            variant: "error",
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "right",
                            },
                            autoHideDuration: 3000,
                            TransitionComponent: Zoom,
                          }
                        );
                      }
                      resetForm();
                    });
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Enter a valid email")
                    .required("Email is required"),
                  password: Yup.string()
                    .required("Password is Required")
                    .min(7, "Password must contain atleast 7 characters")
                    .matches(/(?=.*[0-9])/, "Password must contain a number"),
                })}
              >
                {(props) => {
                  const {
                    touched,
                    errors,
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props;
                  return (
                    <Form
                      onSubmit={handleSubmit}
                      className={classes.signin_form}
                    >
                      <InputLabel
                        htmlFor="email"
                        className={classes.inputlabel}
                      >
                        Email ID*
                      </InputLabel>
                      <Field
                        style={{
                          webkitAutofill: "0 0 0 1000px red inset",
                        }}
                        className={classes.textField1}
                        placeholder="Enter Your Email"
                        name="email"
                        type="text"
                        component={TextField}
                        value={
                          (values.email =
                            localStorage.getItem("rememberMe") === "true"
                              ? localStorage.getItem("user")
                              : values.email)
                        }
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.email ? errors.email : ""}
                        error={touched.email && Boolean(errors.email)}
                        fullWidth
                        margin="normal"
                      />
                      <InputLabel
                        htmlFor="password"
                        className={classes.inputlabel}
                      >
                        Password*
                      </InputLabel>
                      <Field
                        className={classes.textField1}
                        placeholder="Enter Your Password"
                        name="password"
                        component={TextField}
                        value={
                          (values.password =
                            localStorage.getItem("rememberMe") === "true"
                              ? localStorage.getItem("Password")
                              : values.password)
                        }
                        variant="outlined"
                        onBlur={handleBlur}
                        helperText={touched.password ? errors.password : ""}
                        error={touched.password && Boolean(errors.password)}
                        onChange={handleChange}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={togglePasswordVisiblity}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        margin="normal"
                      />
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ marginTop: "20px" }}
                      >
                        <Grid item sx={{ alignItems: "center" }}>
                          <div class="form-group">
                            <input type="checkbox" id="html" />
                            <label for="html">
                              <Typography
                                className={classes.rememberMeLabel}
                                component="span"
                              >
                                Remember me
                              </Typography>
                            </label>
                          </div>
                        </Grid>
                        <Grid item>
                          <Link
                            to="/forgot-password"
                            style={{ textDecoration: "none" }}
                            className={classes.signin_forgotpassword}
                          >
                            <span sx={{ cursor: "pointer" }}>
                              Forgot Password?
                            </span>
                          </Link>
                        </Grid>
                      </Grid>
                      <div className={classes.signin_button_box}>
                        <Button
                          type="submit"
                          variant="contained"
                          className={classes.button}
                        >
                          SIGN IN
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </motion.div>
          </AnimatePresence>
        </Box>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(LogIn);
