import {
  Paper,
  useHistory,
  Grid,
  React,
  fetchClient,
  apiUrl,
  useEffect,
  useState,
  FormControlLabel,
  Checkbox,
  withStyles,
  Box,
} from "allImport";
import Commonprofile from "components/Commonprofile/Commonprofile";
import useStyles from "style/Profilestyle.js";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
  SUB_ADMIN_PERMISSION,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import CommonProfileComponent from "components/Commonprofile/CommonProfileComponent";
export default function SubadminProfile(props) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [loader, setLoader] = React.useState(false);
  const history = useHistory();
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const [topbarVisible, setTopBarVisible] = React.useState(true);

  const getPermissionsForSubAdmin = () => {
    let permission = {};
    if (role === "sub-admin") {
      let allPermissions = StorageManager.getJSON(SUB_ADMIN_PERMISSION);
      permission.edit = allPermissions.includes("EDIT_SUBADMIN");
      permission.delete = allPermissions.includes("DELETE_SUBADMIN");
    }
    return permission;
  };

  const Checkboxblack = withStyles({
    root: {
      "& .MuiSvgIcon-root": {
        fill: "white",
        height: "15px",
        width: "15px",
        "&:hover": {
          backgroundColor: "white",
        },
      },
      "&$checked": {
        "& .MuiIconButton-label": {
          position: "relative",
          zIndex: 0,
          border: "2px solid black",
        },
        "& .MuiIconButton-label:after": {
          content: '""',
          height: 15,
          width: 15,
          position: "absolute",
          backgroundColor: "black",
          border: "2px solid white",
          zIndex: -1,
        },
      },
      "&:not($checked) .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
        border: "2px solid black",
      },
      "&:not($checked) .MuiIconButton-label:after": {
        content: '""',
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "white",
        zIndex: -1,
      },
    },
    checked: {},
  })(Checkbox);

  const setSubAdminData = (subAdminData) => {
    console.log(
      "subAdminData.permission.permission",
      subAdminData.permission.permission
    );
    const data1 = {
      MainHeading: `Profile - ${subAdminData.full_name}`,
      MainSubheading1: "Sub-Admins Lobby",
      MainSubheading2: "Sub Admin Profile",
      PaperHeading: " Personal Details",
      data: [
        ["Full Name", subAdminData.full_name],

        ["Designation", subAdminData.designation],

        ["Department", subAdminData.department],
        ["Email ID ", subAdminData.email],
      ],
      Labels: {
        label1: "Full Name",
        label3: "Designation",
        label4: "Email ID ",
        label5: "Department",
      },
      DetailBox: {
        box1: subAdminData.full_name,
        box3: subAdminData.designation,
        box4: subAdminData.email,
        box5: subAdminData.department,
      },
      Permissions: subAdminData.permission.permission,
      IsActive: subAdminData.status,
      home: `/${role}/subadmins`,
      EditPath: `/${role}/subadmins/edit-subadmin`,
      ProfileType: "Subadmin",
      tableType: "Subadmin",
      SubAdminID: subAdminData.id,
      permissions: getPermissionsForSubAdmin(),
      type: true,
    };
    setData(data1);
  };

  useEffect(() => {
    fetchClient
      .get(`${apiUrl.viewSubAdmin}/${props.location.state.profileId}/`)
      .then((res) => {
        setSubAdminData(res.data.data);
        setLoader(true);
      });
  }, []);

  return (
    <main className={classes.content}>
      {loader ? (
        <>
          <CommonProfileComponent data={data} />
        </>
      ) : (
        <Paper
          className={classes.paper}
          style={{
            height: "450px",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "200px",
          }}
        >
          <img
            src="/assets/Icons/nickelfox_loader.gif"
            width="100px"
            height="100px"
            alt="nickelfoxlogo"
          />
        </Paper>
      )}
    </main>
  );
}
