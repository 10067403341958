import Actions from "../actions/filterActions";

let initialState = {
  countFilter: 0,
  filter: {},
  dataArray: {},
  filterSelectCount: [],
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_FILTER:
      return {
        ...initialState,
        countFilter: action.count,
        filter: action.data,
      };

    case Actions.GET_FILTER:
      return {
        ...state,
        dataArray: {
          ...state.dataArray,
          [action.key]: state.filterSelectCount[action.key]
            ? [...state.dataArray[action.key], action.data]
            : [action.data],
        },
        filterSelectCount: {
          ...state.filterSelectCount[action.key],
          [action.key]: true,
        },
      };

    // remove
    case Actions.REMOVE_FILTER:
      let remove = action.data;
      const filteredData = state.dataArray[action.key].filter((item) => {
        return item !== remove;
      });
      console.log("aser", state.dataArray);

      return {
        ...state,
        dataArray: {
          ...state.dataArray,
          [action.key]: filteredData,
        },
      };

    case Actions.CLEAR_FILTER:
      return {
        ...state,
        dataArray: {},
        filterSelectCount: [],
      };

    default:
      return state;
  }
};

export default filterReducer;
