import {
  Paper,
  useHistory,
  useEffect,
  fetchClient,
  apiUrl,
  CssBaseline,
  Topbar,
  Sidebar,
  React,
} from "allImport";
import Commonprofile from "components/Commonprofile/Commonprofile";
import useStyles from "style/Profilestyle.js";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
  SUB_ADMIN_PERMISSION,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import Loader from "components/Loader/Loader";
import { Button, Grid } from "@material-ui/core";
import CommonProfileComponent from "components/Commonprofile/CommonProfileComponent";
export default function CandidateProfile(props) {
  console.log("prrrooppsss", props);
  const classes = useStyles();
  const history = useHistory();
  const [loader, setLoader] = React.useState(false);
  const [candidateProfileDetails, setCandidateProfile] = React.useState([]);
  const [candidateProfileDetails1, setCandidateProfile1] = React.useState([]);
  const [candidateProfileDetails2, setCandidateProfile2] = React.useState([]);
  const [latest_status, set_latest_status] = React.useState([]);
  const [examStatus, setExamstatus] = React.useState([]);
  const [examTimelineData, setExamTimelineData] = React.useState([]);
  const [topbarVisible, setTopBarVisible] = React.useState(true);
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const candidateID =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.profileId;

  const getPermissionsForSubAdmin = () => {
    let permission = {};
    if (role === "sub-admin") {
      let allPermissions = StorageManager.getJSON(SUB_ADMIN_PERMISSION);
      permission.edit = allPermissions.includes("EDIT_CANDIDATE");
      permission.delete = allPermissions.includes("DELETE_CANDIDATE");
      permission.hire_reject = allPermissions.includes("HIRE_REJECT_CANDIDATE");
      permission.generateLink = allPermissions.includes("GENERATE_EXAM_LINK");
    }
    return permission;
  };

  var prevScrollpos = window.pageYOffset;

  useEffect(() => {
    fetchClient.get(`${apiUrl.candidateProfile}${candidateID}/`).then((res) => {
      setCandidateProfile(res && res.data && res.data.data);
      setCandidateProfile1(
        res && res.data && res.data.data && res.data.data.user
      );
      setCandidateProfile2(
        res && res.data && res.data.data && res.data.data.examiner
      );
      setExamstatus(
        res && res.data && res.data.data && res.data.data.exam_status
      );
      set_latest_status(
        res && res.data && res.data.data && res.data.data.latest_status
      );
      setExamTimelineData(
        res && res.data && res.data.data && res.data.data.exam_status
      );
      setLoader(true);
    });
  }, []);

  console.log("candidateProfileDetails", candidateProfileDetails);

  const data = {
    MainHeading: candidateProfileDetails1.full_name,
    MainSubheading1: "Candidate Lobby",
    MainSubheading2: "Candidate Profile",
    PaperHeading: " Candidate Details",
    data: [
      ["Full Name", candidateProfileDetails1.full_name],
      ["Contact Number", candidateProfileDetails1.phone_number],
      ["Email ID", candidateProfileDetails1.email],
      ["Examiner", candidateProfileDetails2.examiner_name],
      ["Job Associated", candidateProfileDetails.job],
      ["Department", candidateProfileDetails2.examiner_department],
      [
        "Status",
        latest_status.candidate_status ? latest_status.candidate_status : "-",
      ],
      ["Current Exam Level", candidateProfileDetails.attempt_level],

      [
        "Resume",
        candidateProfileDetails.resume ? (
          <Button
            variant="outlined"
            color="primary"
            href={candidateProfileDetails.resume}
          >
            Download
          </Button>
        ) : (
          "N/A"
        ),
      ],
      ["Added By", candidateProfileDetails1.added_by],


      [
        "Additional Info",
        candidateProfileDetails.additional_info
          ? candidateProfileDetails.additional_info
          : "N.A.",
      ],
    ],

    editAction: {
      DeptID: candidateProfileDetails2.examiner_department,
      candidateExaminer: candidateProfileDetails2.examiner_name,
      candidateID: candidateProfileDetails1.id,
      candidateName: candidateProfileDetails1.full_name,
      candidateEmail: candidateProfileDetails1.email,
      candidateJob: candidateProfileDetails.job,
      candidateNo: candidateProfileDetails1.phone_number,
      candidateExperience: candidateProfileDetails.experience,
      allow: SUB_ADMIN_PERMISSION
        ? SUB_ADMIN_PERMISSION.includes("EDIT_CANDIDATE")
        : true,
    },

    home: `/${role}/candidate`,
    button1: "View Gallery",
    button2: "View Result",
    EditPath: `/${role}/candidate/edit`,
    tableType: "Candidate",
    candidateProfileDetails: candidateProfileDetails,
    examTimelineData: examTimelineData,
    candidateID: candidateID,
    exam_level: latest_status.candidate_status,
    permissions: getPermissionsForSubAdmin(),
    candidateExperience: candidateProfileDetails.experience,
    candidateUserData: candidateProfileDetails1,
    examStatus: examStatus,
    resume: candidateProfileDetails.resume,
    latest_status: latest_status.candidate_status,
    candidateName: candidateProfileDetails1.full_name,
    candidateDept: candidateProfileDetails2.examiner_department_id,
    hiring_status: candidateProfileDetails.is_hired,
  };

  console.log(data);

  return (
    <main className={classes.content} id="main-content">
      {loader ? (
        <CommonProfileComponent data={data} />
      ) : (
        <Grid container justifyContent="center">
          <Loader />
        </Grid>
      )}
    </main>
  );
}
