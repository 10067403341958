import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  Zoom,
} from "@material-ui/core";
import { convertUTCDateToLocalDate } from "Common/CommonFunction";
import { commonStyle } from "commonStyle";
import React from "react";
import { useHistory, apiUrl, withSnackbar, fetchPost } from "allImport";

const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),

  root: {
    padding: "10px",
    "& .MuiStepIcon-active": { color: "black" },

    "& .MuiStepIcon-completed": { color: "black" },
    display: "flex",

    justifyContent: "flex-start",
    overflow: "scroll",
    ["@media (max-width:1030px)"]: {
      // eslint-disable-line no-useless-computed-key
      width: "92vw",
    },
    ["@media (max-width:600px)"]: {
      // eslint-disable-line no-useless-computed-key
      width: "84vw",
      // paddingRight: "0px",
    },
  },

  container: {
    padding: "32px",
  },

  topContainer: {
    margin: "10px 0px",
  },

  heading: {
    fontFamily: "Mulish",

    fontSize: "20px",

    fontStyle: "normal",

    fontWeight: "600",

    lineHeight: "32px",

    letterSpacing: "0.10000000149011612px",

    color: "#000",
  },

  resendLinkButton: {
    height: 23,

    width: 107,

    display: "flex",

    justifyContent: "center",

    alignItems: "center",

    fontFamily: "Mulish",

    fontsize: "12px",

    fontStyle: "normal",

    fontWeight: "700",

    lineHeight: "20px",

    letterSpacing: "0.15000000596046448px",

    color: "#FFFFFF",

    backgroundColor: "#D9001D",

    borderRadius: "4px",

    cursor: "pointer",
  },

  timelineContainer: {
    width: "100%",

    minWidth: 650,
  },

  stepperContainer: {
    width: "100%",

    paddingRight: 195,

    boxSizing: "border-box",

    marginTop: 20,
  },

  stepper: {
    display: "flex",

    height: "4px",

    width: "100%",

    justifyContent: "space-between",

    backgroundColor: "#0000004D",

    position: "relative",
  },

  stepperProgress: {
    position: "absolute",

    height: "100%",

    width: "33.33%",

    backgroundColor: "#000000",
  },

  stepContainer: {
    height: 16,

    width: 16,

    borderRadius: "50%",

    position: "relative",

    top: "-6px",

    backgroundColor: "#ffffff",
  },

  step: {
    height: 16,

    width: 16,

    borderRadius: "50%",
  },

  stepActive: {
    backgroundColor: "#000000",
  },

  stepInactive: {
    backgroundColor: "#0000004D",
  },

  timelineStatusContainer: {
    display: "flex",

    justifyContent: "space-between",

    width: "100%",

    paddingRight: 20,
  },

  timelineStatus: {
    marginRight: 10,

    width: 130,
  },

  statusInactive: {
    color: "#0000004D",
  },

  timelineStatusName: {
    fontFamily: "Open Sans",

    fontSize: "20px",

    fontStyle: "normal",

    fontWeight: "700",

    lineHeight: "20px",

    letterSpacing: "0.15000000596046448px",

    marginTop: 26,

    textTransform: "lowercase",

    textTransform: "capitalize",
  },

  timelineStatusDate: {
    fontFamily: "Mulish",

    fontSize: "14px",

    fontStyle: "normal",

    fontWeight: "400",

    lineHeight: "20px",

    letterSpacing: "0px",

    marginTop: 50,
  },

  buttonContainer: {
    width: "100%",

    display: "flex",

    justifyContent: "center",

    marginTop: 84,
  },

  viewResultButton: {
    border: "1px solid #D9001D",

    borderRadius: 4,

    width: 208,

    height: 45,

    fontFamily: "Mulish",

    fontSize: "18px",

    fontStyle: "normal",

    fontWeight: "700",

    lineHeight: "20px",

    letterSpacing: "0.15000000596046448px",

    color: "#D9001D",

    display: "flex",

    justifyContent: "center",

    alignItems: "center",

    cursor: "pointer",
  },

  green: {
    color: "#29CB1B",
  },

  red: {
    color: "#D9001D",
  },

  copyExamLink: {
    position: "absolue",

    textDecoration: "underline",

    cursor: "pointer",

    color: "red",

    height: 40,
  },
  arrow: {
    width: 34,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    borderRadius: 40,
    border: "2px solid #EFEFEF",
    cursor: "pointer",
  },
}));

function StepperTimeLine(props) {
  const history = useHistory();

  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  console.log("MYPROPS", props);
  let candidateExamUrl = process.env.REACT_APP_CANDIDATE_URL
    ? process.env.REACT_APP_CANDIDATE_URL
    : "https://candidate.dev.nhp.foxlabs.in";

  const classes = useStyles();

  function tConvert(time) {
    // Check correct time format and split into components

    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct

      time = time.slice(1); // Remove full string match value

      time[5] = +time[0] < 12 ? " AM " : " PM "; // Set AM/PM

      time[0] = +time[0] % 12 || 12; // Adjust hours
    }

    return time.join("");
  }

  const viewResult = (rt) => {
    history.replace({
      pathname: `/${role}/submitted-exam/result`,

      state: {
        profileId: props.candidateID,

        ExamID: props.data[0].detail.exam_id,

        AttemptDate: props.data[0].created_at,
      },
    });
  };


  const SendExamLink = () => {
    const data1 = {
      candidate: props.candidateID,
      exam: props.data[0]?.detail.exam_id,
      exam_link:
        candidateExamUrl +
        `/candidate/examlink/` +
        props.data[0].detail.exam_id +
        "/" +
        props.candidateID,
    };
    fetchPost.post(`${apiUrl.resendLink}`, data1)
           .then((res) => {
          props.enqueueSnackbar(`Exam link has been resent`, {
            variant: res.data.success ? "success" : "error",

            anchorOrigin: {
              vertical: "top",

              horizontal: "right",
            },

            TransitionComponent: Zoom,

            autoHideDuration: 3000,
          });
          window.location.reload()
        })

        .catch(() => {
          props.enqueueSnackbar("Failed to send email", {
            variant: "error",

            anchorOrigin: {
              vertical: "top",

              horizontal: "right",
            },

            TransitionComponent: Zoom,

            autoHideDuration: 5000,

            resumeHideDuration: 2000,
          });
        })

      

    // else {
    //   setResendLinkText("Sending...");

    //   // const data1 = {
    //   //   candidate: props.candidateID,

    //   //   exam: data.ExamID,

    //   //   exam_link: data.examLinkSent.examLink,

    //   //   // login_link: candidateExamUrl + `/candidate/login/`,
    //   // };

    //   // fetchPost.post(`${apiUrl.resendLink}`, data1)

    //     // .then((res) => {
    //     //   props.enqueueSnackbar(`Exam link has been resent`, {
    //     //     variant: res.data.success ? "success" : "error",

    //     //     anchorOrigin: {
    //     //       vertical: "top",

    //     //       horizontal: "right",
    //     //     },

    //     //     TransitionComponent: Zoom,

    //     //     autoHideDuration: 3000,
    //     //   });
    //     // })

    //     // .catch(() => {
    //     //   props.enqueueSnackbar("Failed to send email", {
    //     //     variant: "error",

    //     //     anchorOrigin: {
    //     //       vertical: "top",

    //     //       horizontal: "right",
    //     //     },

    //     //     TransitionComponent: Zoom,

    //     //     autoHideDuration: 5000,

    //     //     resumeHideDuration: 2000,
    //     //   });
    //     // })

    //     // .finally(() => {
    //     //   setResendLinkText("Resend Link");
    //     // });
    // }
  };

  return (
    <Grid container item style={{ overflow: "scroll" }} xs={12}>
      <Grid item xs={12} alignItems="center" className={classes.topContainer}>
        <div className={classes.heading}>{props.value} Exam Timeline</div>
      </Grid>

      <Divider className={classes.divider_position} />

      <Grid item xs={12} sx={{ userSelect: "none" }}>
        <Stepper
          className={classes.root}
          activeStep={props.data.length}
          alternativeLabel
        >
          {props.data.map((data, index) => (
            <Step expanded={true} key={data.id} style={{ minWidth: "25ch" }}>
              <StepLabel style={{}}>
                {data.status.split("_").join(" ")}

                <br />

                {convertUTCDateToLocalDate(new Date(data.created_at))
                  .toString()

                  .split(" ")

                  .slice(0, 4)

                  .join(" ")

                  .toLocaleString()}

                <br />

                {tConvert(
                  convertUTCDateToLocalDate(new Date(data.created_at))
                    .toString()

                    .split(" ")

                    .slice(4, 5)

                    .join(" ")

                    .toLocaleString()
                )}

                {data.status === "LINK_EXPIRED" ? (
                  <div
                    className={classes.copyExamLink}
                    onClick={() => SendExamLink()}
                  >
                    Resend Link
                  </div>
                ) : null}


                {data.status === "EXAM_SUBMITTED" ? (
                  <div
                    className={classes.copyExamLink}
                    onClick={() => viewResult()}
                  >
                    View Result
                  </div>
                ) : null}
              </StepLabel>
              : null
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Divider className={classes.divider_position} />
    </Grid>
  );
}

export default withSnackbar(StepperTimeLine);
