import React, { useEffect, useState } from "react";
import "./mobileSidebar.css";
import "style/style.scss";
import {
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { commonStyle } from "commonStyle";
import { useHistory } from "react-router-dom";
import mobileSidebarback from "./mobileSidebarBack.png";
import useVH from "react-viewport-height";

const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),
  root: {
    display: "flex",
    ["@media (max-width:1030px)"]: {
      // eslint-disable-line no-useless-computed-key
      display: "none",
    },
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "52.02px",
    paddingTop: "23.72px",
    height: "100px",
    marginBottom: "14px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },

  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    // height: "100vh",
    overflow: "auto",
  },

  anchorLinksSidebar: {
    textDecoration: "none",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  // fixedHeight: {
  //   height: 240,
  // },
  ListItemText: {
    color: "#FFFFFF",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "2rem",
    lineHeight: "16px",
    letterSpacing: "0.4px",
  },
  sidebar_nickelfoxlogo: {
    width: "70%",
  },
}));

const ToggleSidebar = () => {
  const vh = useVH();
  var role = localStorage.getItem("ROLE").toLowerCase();
  let history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [optionValue, setOption] = useState("dashboard");
  const [isOpen, setIsopen] = useState(false);
  const [SUB_ADMIN_PERMISSION, setSUB_ADMIN_PERMISSION] = useState();
  useEffect(() => {
    setSUB_ADMIN_PERMISSION(localStorage.getItem("SUB_ADMIN_PERMISSION"));
    console.log(localStorage.getItem("SUB_ADMIN_PERMISSION"), "astrex");
  }, []);
  const sideBarItems = [
    {
      name: "Dashboard",
      path: "dashboard",
      activeIcon: "/assets/Icons/active_dash.svg",
      inactiveIcon: "/assets/Icons/dashboard_inactive.svg",
      allow: true,
    },
    {
      name: "Candidates",
      path: "candidate",
      activeIcon: "/assets/Icons/active_candidates.svg",
      inactiveIcon: "/assets/Icons/candidates_inactive.svg",
      allow:
        role === "admin" ||
        role === "examiner" ||
        (SUB_ADMIN_PERMISSION &&
          SUB_ADMIN_PERMISSION.includes("VIEW_CANDIDATE"))
          ? true
          : false,
    },

    {
      name: "Examiner",
      path: "examiner",
      activeIcon: "/assets/Icons/active_examiner.svg",
      inactiveIcon: "/assets/Icons/examiner_inactive.svg",
      roleRestriction: role,
      allow:
        role === "admin" ||
        role === "examiner" ||
        (SUB_ADMIN_PERMISSION && SUB_ADMIN_PERMISSION.includes("VIEW_EXAMINER"))
          ? true
          : false,
    },
    {
      name: "Exam",
      path: "exam",
      activeIcon: "/assets/Icons/active_exam.svg",
      inactiveIcon: "/assets/Icons/inactive_exam.svg",
      allow:
        role === "admin" ||
        role === "examiner" ||
        (SUB_ADMIN_PERMISSION && SUB_ADMIN_PERMISSION.includes("VIEW_EXAM"))
          ? true
          : false,
    },
    {
      name: "Question Bank",
      path: "question-bank/questions",
      activeIcon: "/assets/Icons/active_question_bank.svg",
      inactiveIcon: "/assets/Icons/questionBank_inactive.svg",
      allow:
        role === "admin" ||
        role === "examiner" ||
        (SUB_ADMIN_PERMISSION &&
          SUB_ADMIN_PERMISSION.includes("VIEW_QUESTION_BANK"))
          ? true
          : false,
    },
    {
      name: "Category List",
      path: "category",
      activeIcon: "/assets/Icons/active_question_bank.svg",
      inactiveIcon: "/assets/Icons/questionBank_inactive.svg",
      allow: true,
    },
    {
      name: "Submitted Exams",
      path: "submitted-exam",
      activeIcon: "/assets/Icons/active_results.svg",
      inactiveIcon: "/assets/Icons/results_inactive.svg",

      allow:
        role === "admin" ||
        role === "examiner" ||
        (SUB_ADMIN_PERMISSION && SUB_ADMIN_PERMISSION.includes("VIEW_RESULT"))
          ? true
          : false,
    },
    {
      name: "Vacancy",
      path: "job",
      activeIcon: "/assets/Icons/active_examiner.svg",
      inactiveIcon: "/assets/Icons/examiner_inactive.svg",
      roleRestriction: role,
      allow:
        role === "admin" ||
        role === "examiner" ||
        (SUB_ADMIN_PERMISSION && SUB_ADMIN_PERMISSION.includes("VIEW_JOB"))
          ? true
          : false,
    },

    {
      name: "Department",
      path: "department",
      activeIcon: "/assets/Icons/active_departments.svg",
      inactiveIcon: "/assets/Icons/active_departments.svg",
      roleRestriction: role,
      allow:
        role === "admin" ||
        role === "examiner" ||
        (SUB_ADMIN_PERMISSION &&
          SUB_ADMIN_PERMISSION.includes("VIEW_DEPARTMENT"))
          ? true
          : false,
    },
    {
      name: "SubAdmins",
      path: "subadmins",
      activeIcon: "/assets/Icons/active_admins.svg",
      inactiveIcon: "/assets/Icons/sub_admin_inactive.svg",
      roleRestriction: role,
      allow:
        role === "admin" ||
        role === "examiner" ||
        (SUB_ADMIN_PERMISSION && SUB_ADMIN_PERMISSION.includes("VIEW_SUBADMIN"))
          ? true
          : false,
    },
  ];
  useEffect(() => {
    if (window.location.pathname.includes(`/${role}/dashboard`)) {
      setOption(`/${role.toLowerCase()}/dashboard`);
    } else if (window.location.pathname.includes(`/${role}/candidate`)) {
      setOption(`/${role}/candidate`);
    } else if (
      window.location.pathname.includes(`/${role}/examiner`) &&
      localStorage.getItem("ROLE") !== "EXAMINER"
    ) {
      setOption(`/${role}/examiner`);
    } else if (window.location.pathname.includes(`/${role}/exam`)) {
      setOption(`/${role.toLocaleLowerCase()}/exam`);
    } else if (
      window.location.pathname.includes(`/${role}/question-bank/questions`)
    ) {
      setOption(`/${role}/question-bank/questions`);
    } else if (window.location.pathname.includes(`/${role}/submitted-exam`)) {
      setOption(`/${role}/submitted-exam`);
    } else if (window.location.pathname.includes(`/${role}/job`)) {
      setOption(`/${role}/job`);
    } else if (window.location.pathname.includes(`/${role}/subadmins`)) {
      setOption(`/${role}/subadmins`);
    } else if (window.location.pathname.includes(`/${role}/department`)) {
      setOption(`/${role}/department`);
    } else if (window.location.pathname.includes(`/${role}/category`)) {
      setOption(`/${role}/category`);
    }
  }, []);
  const activeStateOption = (val) => {
    history.push({
      pathname: val,
    });
    setOption(val);
  };

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };
  return (
    <>
      <div className="container-fluid mt-3">
        <nav className="navbar navbar-expand-lg navbar-light ">
          <div className="container-fluid p-2">
            <div className="form-inline ml-auto">
              <div onClick={ToggleSidebar}>
                <div
                  class={[
                    "hamburger-menu ",
                    isOpen && ", hamburger-menu-hover",
                  ]}
                >
                  <div class="bar-top"></div>
                  <div class="bar-middle"></div>
                  <div class="bar-bottom"></div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div className={`sidebar ${isOpen == true ? "active" : ""}`}>
          <div className="sd-header">
            <img
              src="/assets/Icons/Fmw_logo.png"
              alt="nickelfoxlogo"
              style={{ padding: "10px 0px 10px 10px" }}
              className={classes.sidebar_nickelfoxlogo}
            />
            <div
              onClick={ToggleSidebar}
              style={{
                position: "absolute",
                right: "3px",
                top: "10px",
                cursor: "pointer",
              }}
            >
              <img src={mobileSidebarback} width="20px" alt="back-button" />
            </div>
          </div>
          <Divider
            style={{
              border: "1px solid rgba(255, 255, 255, 0.1)",

              // marginBottom: "21px",
            }}
          />
          <div
            style={{
              minHeight: "90vh",
              maxHeight: "99vh",
              overflowY: "scroll",
              paddingBottom: "220px",
            }}
          >
            {sideBarItems.map((item) => {
              return item.roleRestriction !== "examiner" && item.allow ? (
                <div
                  key={item?.name}
                  className={
                    optionValue === `/${role}/${item?.path?.toLowerCase()}`
                      ? "side_button_wrapper activeSelection"
                      : "side_button_wrapper"
                  }
                  onClick={() =>
                    activeStateOption(`/${role.toLowerCase()}/${item?.path}`)
                  }
                >
                  <ListItem button className="sidebar_button">
                    <ListItemIcon>
                      <img
                        src={
                          optionValue === `/${role}/${item?.path}`
                            ? item?.activeIcon
                            : item?.inactiveIcon
                        }
                        alt={item?.name}
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={{ primary: classes.listItemText }}
                      primary={item?.name}
                    />
                  </ListItem>
                </div>
              ) : null;
            })}
          </div>
        </div>
        <div
          className={`sidebar-overlay ${isOpen == true ? "active" : ""}`}
          onClick={ToggleSidebar}
        ></div>
      </div>
    </>
  );
};

export default ToggleSidebar;
