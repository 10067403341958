import React from "react";
import { commonStyle } from "commonStyle.js";
import { clsx, makeStyles } from "allImport";
const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),
  container: {
    borderRadius: "25px",
    width: 30,
    height: 16,
    display: "flex",
    alignItems: "center",
    padding: 2,
    marginRight: 10,
    transition: "all 0.5s",
  },
  checked_container: {
    justifyContent: "flex-end",
    border: "2px solid #D9001D",
  },
  unchecked_container: {
    justifyContent: "flex-start",
    border: "2px solid #0000004F",
  },
  toggle_switch: {
    borderRadius: "50%",
    width: 10,
    height: 10,
    transition: "all 0.5s",
  },
  checked: {
    backgroundColor: "#D9001D",
  },
  unchecked: {
    backgroundColor: "#0000004F",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "0.15000000596046448px",
  },
  label_checked: {
    fontStyle: "normal",
    color: "#D9001D",
  },
  label_unchecked: {
    fontStyle: "normal",
    color: "#0000004F",
  },
}));

export default function ToggleButton({ label, isChecked, handleClick }) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={clsx(classes.container, isChecked ? classes.checked_container : classes.unchecked_container)} onClick={() => handleClick()}>
        <div className={clsx(classes.toggle_switch, isChecked ? classes.checked : classes.unchecked)} />
      </div>
      <div className={clsx(classes.label, isChecked ? classes.label_checked : classes.label_unchecked)}>{label}</div>
    </div>
  );
}
