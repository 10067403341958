import {
  useEffect,
  useHistory,
  React,
  fetchClient,
  apiUrl,
  fetchPost,
  SuccessDialog,
} from "allImport";

import "style/style.scss";
import { FormikProvider } from "formik";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import { useStyles as useStyles1 } from "style/Liststyle";
import { Container, Grid, InputLabel, TextField } from "@material-ui/core";
import CandidateValidation from "components/Form/validations/CandidateValidation";
import BasicInput from "components/Form/InputBoxes/BasicInput";

import { useFormik } from "formik";
import { capitalize } from "Common/CommonFunction";
import { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { withSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Button/CustomButton";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1);",
    },
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 5,
      paddingRight: "100px",
      width: "100%",
    },
  },
  label: {
    fontFamily: "Mulish",
    fontWeight: "600px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#383838",
  },
}));

function EditCandidate(props) {
  console.log("porpiii", props.location.state);
  const classes2 = useStyles();
  const classes1 = useStyles1();

  const [jobList, setJobList] = useState([]);
  const [examinerList, setExaminerList] = useState([]);
  const [candidateToggle, setCandidateToggle] = useState(false);

  const [box5, setBox5] = useState("");
  const [box6, setBox6] = useState("");

  const history = useHistory();
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;

  useEffect(() => {
    console.log("-------------------1111111", props.location.state);
  }, []);

  console.log("examinerList", box5);
  // Get Data on Initial Page Load
  useEffect(() => {
    if (
      !StorageManager.get(API_TOKEN) &&
      !StorageManager.get(ADMIN_ID) &&
      !StorageManager.get(ADMIN_NAME) &&
      !StorageManager.get(LOGOUT_TOKEN)
    ) {
      history.push("/login");
    }
    fetchClient
      .get(`${apiUrl.jobList}?page_size=1000&status=publish`)
      .then((res) => {
        setJobList(res.data.data.results);
        setBox5(
          res.data.data.results?.find(
            (e) => e.job_title === props.location.state.candidateJob
          )
        );
      });
  }, []);
  console.log("pppprropss", box5);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: props.location.state.candidateName,
      email: props.location.state.candidateEmail,
      jobAssociated: props.location.state.candidateJob,
      contactNumber: props.location.state.candidateNo,
      totalExperience: props.location.state.candidateExperience,
      examinerList: props.location.state.candidateExaminer,
      additionalInfo: props.location.state.additionalInfo,
    },
    validationSchema: CandidateValidation,
    onSubmit: (values) => {
      console.log("valueessss", values, "this is box ", box5.id);
      const data = {
        full_name: values.name,
        email: values.email,
        phone_number: values.contactNumber,
        department: props.location.state.DeptID,
        job: box5.id ? box5.id : values.jobAssociated,
        examiner: box6 ? box6.id : values.examinerList,
        experience: values.totalExperience,
        additional_info: values.additionalInfo,
      };
      fetchPost
        .patch(
          `${apiUrl.editCandidate}${props.location.state.candidateID}/`,
          data
        )

        .then((res) => {
          setCandidateToggle(true);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.code === 404) {
              props.enqueueSnackbar(
                "Oops! Something went wrong. We are working on fixing the problem.",
                {
                  variant: "error",
                  autoHideDuration: 3000,
                }
              );
            } else if (error.response.data.error) {
              error.response.data.error.message.forEach((message) => {
                props.enqueueSnackbar(message, {
                  variant: "error",
                  autoHideDuration: 3000,
                });
              });
            }
          } else {
            props.enqueueSnackbar(
              "Oops! Something went wrong. Please check your network connection and try again",
              {
                variant: "error",
              }
            );
          }
        });
    },
  });

  console.log("setExaminerList", examinerList);

  const handleJobChange = (newValue) => {
    setBox5(false);
    console.log("newValue", newValue);
    fetchClient
      .get(`${apiUrl.jobProfile}${newValue.id}/profile/`)
      .then((res) => {
        const dept = res.data.data.department.id;
        fetchClient
          .get(`${apiUrl.examinerList}?page_size=1000&department=${dept}`)
          .then((res) => {
            setExaminerList(
              res && res.data && res.data.data && res.data.data.results
            );
          });
      });
  };

  useEffect(() => {
    if (box5)
      fetchClient.get(`${apiUrl.jobProfile}${box5.id}/profile/`).then((res) => {
        const dept = res.data.data.department.id;
        fetchClient
          .get(`${apiUrl.examinerList}?page_size=1000&department=${dept}`)
          .then((res) => {
            console.log("listofalldata", res.data.data);
            setBox6(
              res.data.data.results?.find(
                (e) => e.full_name === props.location.state.candidateExaminer
              )
            );
            setExaminerList(
              res && res.data && res.data.data && res.data.data.results
            );
          });
      });
  }, [box5]);

  return (
    <Container
      container
      maxWidth="xl"
      className={classes1.container}
      style={{ height: "calc(100vh - 120px)", maxHeight: "auto" }}
    >
      <HeaderWithBreadcrumb
        heading="Edit Candidate"
        breadCrumb
        breadCrumb1="Candidates"
        breadCrumb2="Edit Candidate"
        link={`/${role}/candidate`}
      />

      {candidateToggle ? (
        <SuccessDialog
          type="field"
          heading="Candidate Details Updated Successfully"
          path={`/${role}/candidate`}
        />
      ) : null}
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <BasicInput
              label="Full Name*"
              placeholder="Enter candidate's full name"
              type="name"
              values={formik.values.name}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.name}
              errors={formik.errors.name}
            />
            <BasicInput
              label="Email ID*"
              placeholder="Enter candidate's email"
              type="email"
              values={formik.values.email}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.email}
              errors={formik.errors.email}
              disabled={true}
            />
            <BasicInput
              label="Contact Number*"
              placeholder="Enter candidate's contact number"
              type="contactNumber"
              values={formik.values.contactNumber}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.contactNumber}
              errors={formik.errors.contactNumber}
            />
            <Grid item xs={12} lg={4} md={6}>
              <InputLabel className={classes2.label}>
                Job Associated*
              </InputLabel>

              <Autocomplete
                classes={classes2}
                name="jobAssociated"
                id="jobAssociated"
                disableClearable
                value={examinerList?.find((e) => e.id)}
                defaultValue={{
                  job_title: formik.values.jobAssociated,
                }}
                options={jobList}
                getOptionLabel={(option) => option.job_title}
                handleChange={formik.handleChange}
                onChange={(jobAssociated, value) =>
                  formik.setFieldValue("jobAssociated", value.id) &&
                  handleJobChange(value) &&
                  formik.handleChange
                }
                renderInput={(params) => (
                  <TextField
                    placeholder="Enter job associated*"
                    name="jobAssociated"
                    id="jobAssociated"
                    {...params}
                    variant="outlined"
                    error={Boolean(
                      formik.touched.jobAssociated &&
                        formik.errors.jobAssociated
                    )}
                    helperText={
                      formik.touched.jobAssociated &&
                      formik.errors.jobAssociated
                    }
                    touched={formik.touched.jobAssociated}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="normal"
                  />
                )}
              />

              {/* </FormControl> */}
            </Grid>

            <BasicInput
              label="Total Experience In Years*"
              placeholder="Enter total experience"
              type="totalExperience"
              values={formik.values.totalExperience}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.totalExperience}
              errors={formik.errors.totalExperience}
            />
            <Grid item xs={12} lg={4} md={6}>
              <InputLabel className={classes2.label}>Examiner*</InputLabel>
              <Autocomplete
                classes={classes2}
                disableClearable
                value={examinerList?.find((e) => e.id)}
                defaultValue={{
                  full_name: formik.values.examinerList,
                }}
                options={jobList ? examinerList : "Not defined"}
                getOptionLabel={(option) => capitalize(option.full_name) || ""}
                onChange={(examinerList, value) =>
                  formik.setFieldValue("examinerList", value.id) &&
                  setBox6(false) &&
                  formik.handleChange
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Enter examiner"
                    error={Boolean(
                      formik.touched.examinerList && formik.errors.examinerList
                    )}
                    helperText={
                      formik.touched.examinerList && formik.errors.examinerList
                    }
                    touched={formik.touched.examinerList}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="normal"
                  />
                )}
              />
            </Grid>

            <BasicInput
              componentType="additionalInfo"
              label="Additional Info"
              placeholder="Additional Info"
              name="additionalInfo"
              type="textfield"
              values={formik.values.additionalInfo}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.additionalInfo}
              errors={formik.errors.additionalInfo}
            />

            <Grid container item xs={12} lg={12} md={12} spacing={3}>
              <Grid item sx={3}>
                <CustomButton
                  type="button"
                  Buttontype="cancel"
                  label="Cancel"
                />
              </Grid>
              <Grid item sx={3}>
                <CustomButton type="submit" Buttontype="save" label="Save" />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Container>
  );
}

export default withSnackbar(EditCandidate);
