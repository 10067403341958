import {
  Box,
  Grid,
  InputLabel,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";
import {
  CloseIcon,
  useHistory,
  apiUrl,
  withSnackbar,
  fetchPost,
  fetchClient,
  Autocomplete
} from "allImport";

import React, { useLayoutEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import BulkInvite from "./Invite_Template.csv";
import CustomButton from "components/Button/CustomButton";
import { capitalize } from "Common/CommonFunction";
const ExamLevel = ["Senior", "Associate", "Junior", "Internship"];

const dropzoneStyle = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  // padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column"
  // marginTop: "20px"
};
const acceptedType = ".csv";
const url = new URL(`${apiUrl.viewExams}`);
function AcceptMaxFiles(props) {
  const { toast, classes, handleClose, handleRefetch } = props;
  const [files, setFiles] = useState([]);
  const [data, setFormData] = useState(null);
  const [requiredPercentage, setRequiredPercentage] = useState("");
  const [job, setJob] = useState("");
  const [examineer, setExamineer] = useState("");
  const [examId, setExamId] = useState("");
  const [examList, setExamsList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [percentError, setPercentError] = useState(false);
  const [examError, setExamError] = useState(false);
  const [jobError, setJobError] = useState(false);
  const [examineerError, setExamineerError] = useState(false);
  const [fileError, setFilesError] = useState(false);
  const [errorObject, setErrorObject] = useState({
    percentError: false,
    jobError: false,
    examineerError: false,
    examError: false
  });
  const [examinerList, setExaminerList] = useState([]);
  let candidateExamUrl = process.env.REACT_APP_CANDIDATE_URL
    ? process.env.REACT_APP_CANDIDATE_URL
    : "https://candidate.dev.nhp.foxlabs.in";
  const autoCompleteStyles = useAutoCompleteStyles();
  useLayoutEffect(() => {
    getExamList();
    getJobList();
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        const fileData = fileRejections[0];
        if (fileData?.file?.type === "") {
          if (fileData?.file?.path?.includes(acceptedType)) {
            setFiles(fileData);
          }
        } else {
          const message = fileData?.errors[0]?.message;
          toast(message, {
            variant: "error",
            autoHideDuration: 3000
          });
        }
      } else {
        setFiles(acceptedFiles[0]);
        setFilesError(false);

      }
    },
    accept: { "text/csv": [".csv"] }
  });
  const handleRemove = () => {
    setFiles([]);
  };
  const acceptedFileItems = (
    <Box
      key={files.path}
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
        paddingY: "2rem"
      }}
    >
      <Typography>{files.path}</Typography>
      <CloseIcon onClick={handleRemove} />
    </Box>
  );

  const getExamList = () => {
    fetchClient.get(url).then((res) => {
      setExamsList(res.data.data.results);
    });
  };
  const getJobList = () => {
    fetchClient
      .get(`${apiUrl.jobList}?page_size=1000&status=publish`)
      .then((res) => {
        setJobList(res.data.data.results);
      });
  };
  const handleJobChange = (newValue) => {
    fetchClient
      .get(`${apiUrl.jobProfile}${newValue.id}/profile/`)
      .then((res) => {
        const dept = res.data.data.department.id;
        fetchClient
          .get(`${apiUrl.examinerList}?page_size=1000&department=${dept}`)
          .then((res) => {
            setExaminerList(
              res && res.data && res.data.data && res.data.data.results
            );
          });
      });
  };

  const handleResume = () => {
    const errors = getErrors();

    const loginLink = `${candidateExamUrl}/candidate/login`;
    const examLink = `${candidateExamUrl}/candidate/examlink`;
    const formData = new FormData();
    formData.append("file", files);
    formData.append("pass_percentage", requiredPercentage);
    formData.append("exams", examId);
    formData.append("job", job);
    formData.append("examiner", examineer);
    formData.append("login_link", loginLink);
    formData.append("exam_link_prefix", examLink);
    if (!errors) {
      fetchPost
        .post(`${apiUrl.bulkInvite}`, formData)
        .then((res) => {
          handleRefetch();
        })
        .catch((error) => {
          toast("Error Inviting Members", {
            variant: "error",
            autoHideDuration: 3000
          });
        });
    }
  };
  const getErrors = (key) => {
    let error = false;
    if (
      !requiredPercentage ||
      !examId ||
      !job ||
      !examineer ||
      files?.length === 0
    ) {
      error = true;
      if (!requiredPercentage) {
        setPercentError(true);
      }
      if (!examId) {
        setExamError(true);
      }
      if (!job) {
        setJobError(true);
      }
      if (!examineer) {
        setExamineerError(true);
      }
      if (files?.length === 0) {
        setFilesError(true);
      }
    } else {
      setFilesError(false);
    }
    return error;
  };
  const handleBlur = (key) => {
    if (!requiredPercentage && key === "percentage") {
      setPercentError(true);
    }
    if (!examId && key === "examId") {
      setExamError(true);
    }
    if (!job && key === "job") {
      setJobError(true);
    }
    if (!examineer && key === "examineer") {
      setExamineerError(true);
    }
  };
  const handleChange = (e, key) => {
    switch (key) {
      case "Percent":
        setRequiredPercentage(e);
        setPercentError(false);
        break;
      case "Job":
        setJob(e?.id);
        setJobError(false);
        break;
      case "examineer":
        setExamineer(e?.id);
        setExamineerError(false);
        break;
      case "exams":
        const idArray = e.map((item) => item?.id);
        const idString = idArray.toString();
        setExamId(idString);
        setExamError(false);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
      }}
    >
      <Grid container gap={2}>
        <Grid item xs={12}>
          <InputLabel style={{ padding: "0.5rem 0" }}>
            Required Percentage*
          </InputLabel>
          <TextField
            style={{ width: "100%" }}
            label="Percentage Required*"
            placeholder="Enter percentage"
            name="percentage"
            type="number"
            error={percentError}
            onBlur={() => {
              handleBlur("percentage");
            }}
            helperText={percentError && "Required"}
            values={requiredPercentage}
            onChange={(e) => handleChange(e?.target?.value, "Percent")}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} style={{ padding: "0.5rem 0" }}>
          <InputLabel>Job Name*</InputLabel>
          <Autocomplete
            classes={autoCompleteStyles}
            name="jobName"
            id="jobName"
            value={jobList.id}
            options={jobList}
            getOptionLabel={(option) => option.job_title}
            handleChange={handleChange}
            onBlur={() => {
              handleBlur("job");
            }}
            onChange={(vacancy, value) => {
              handleChange(value, "Job");
              handleJobChange(value);
            }}
            disableClearable
            renderInput={(params) => (
              <TextField
                placeholder="Select Exam Name"
                name="jobName"
                id="jobName"
                {...params}
                error={jobError}
                helperText={jobError && "Required"}
                variant="outlined"
                margin="normal"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: "0.5rem 0" }}>
          <InputLabel>Examineer Name*</InputLabel>
          <Autocomplete
            classes={autoCompleteStyles}
            name="examineerName"
            id="examineerName"
            value={examinerList.id}
            onBlur={() => {
              handleBlur("examineer");
            }}
            options={examinerList}
            getOptionLabel={(option) => capitalize(option.full_name) || ""}
            handleChange={handleChange}
            onChange={(vacancy, value) => handleChange(value, "examineer")}
            disableClearable
            renderInput={(params) => (
              <TextField
                placeholder="Select Exam Name"
                name="jobName"
                id="jobName"
                error={examineerError}
                helperText={examineerError && "Required"}
                {...params}
                variant="outlined"
                margin="normal"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: "0.5rem 0" }}>
          <InputLabel>Exam Name*</InputLabel>
          <Autocomplete
            classes={autoCompleteStyles}
            multiple
            name="examName"
            id="examName"
            value={examList.id}
            options={examList}
            onBlur={() => {
              handleBlur("examId");
            }}
            getOptionLabel={(option) => option.exam_name}
            handleChange={handleChange}
            onChange={(vacancy, value) => {
              handleChange(value, "exams");
            }}
            disableClearable
            renderInput={(params) => (
              <TextField
                placeholder="Select Exam Name"
                name="examName"
                id="examName"
                error={examError}
                helperText={examError && "Required"}
                {...params}
                variant="outlined"
                margin="normal"
              />
            )}
          />
        </Grid>
      </Grid>
      <section className="container" style={dropzoneStyle}>
        {files?.length === 0 ? (
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>Click here to select files</p>
          </div>
        ) : (
          acceptedFileItems
        )}
      </section>
      {fileError && (
        <InputLabel style={{ color: "#ff0000" }}>
          Kindly upload a file
        </InputLabel>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          paddingY: "2rem"
        }}
      >
        <Typography className={classes.supportedFiles}>
          File Supported : csv
        </Typography>
        <a href={BulkInvite} className={classes.ahref} download="Bulk Invite">
          Download sample csv
        </a>
      </Box>
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "space-around" }}
      >
        <CustomButton
          type="submit"
          Buttontype={"save"}
          label={"Invite Candidates"}
          handleButtonClick={handleResume}
        />
        <CustomButton
          type="submit"
          Buttontype={""}
          label={"Cancel"}
          handleButtonClick={handleClose}
        />
      </Box>
    </Box>
  );
}

export default AcceptMaxFiles;
const useAutoCompleteStyles = makeStyles((theme) => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 5,
      paddingRight: "100px",
      width: "100%"
    }
  },
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1);"
    }
  },
  label: {
    fontFamily: "Mulish",
    fontWeight: "600px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#383838"
  }
}));
