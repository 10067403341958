import {
  useHistory,
  useEffect,
  fetchClient,
  apiUrl,
  React,
  useState,
} from "allImport";
import { useStyles } from "style/Liststyle.js";

import TablePageLayout from "layout/TablePageLayout";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import Actions from "redux/actions/filterActions";
import { useDispatch, useSelector } from "react-redux";
import { API, ENDPOINTS } from "api/apiService";
import TableActions from "redux/actions/tableActions";
import CommonTable from "components/CommonComponent/Filter/CommonTable/CommonTable";
import { convertUTCDateToLocalDate } from "Common/CommonFunction";
const queryString = require("query-string");

export default function Joblist() {
  const classes = useStyles();
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const [deptLists, setDeptlist] = useState([]);
  const [deptSelectionVal, setDept] = useState("");
  const [totalPage, setTotalPage] = useState("");
  const [level, setLevel] = useState("");
  const [status, setStatus] = useState("");
  const [count, setCount] = useState("");
  const [loader, setLoader] = useState(false);
  const [page1, setPage1] = useState("");
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [filter, setfilter] = useState({
    page: 1,
    page_size: 5,
    search: "",
    is_hired: 0,
    candidate_status: 0,
  });
  const [pageColor, setPageColor] = React.useState(null);
  const { searchData } = useSelector((store) => store.search);
  const { reset } = useSelector((store) => store.table);

  const handleChangePage = (event, page) => {
    // let url = `${apiUrl.viewJobs}?page=` + value + `&${page1}`;
    // url += status === "" ? "" : `&status=${status}`;
    // url += deptSelectionVal === "" ? "" : `&department=${deptSelectionVal}`;
    // url += level === "" ? "" : `&job_level=${level}`;
    // url += examSearch === "" ? "" : `&search=${examSearch}`;
    // fetchClient.get(url).then((res) => {
    //   var JobsList = res && res.data && res.data.data && res.data.data.results;
    //   colmConversion(JobsList);
    // });
    // setPage(value);
    console.log("hjelllooo", page);
    setPage(page);
    dispatch({
      type: TableActions.SET_TABLE_ROW,
      count: null,
    });
    setfilter({ ...filter, page });
    setPageColor(page);
  };

  useEffect(() => {
    fetchClient.get(`${apiUrl.viewJobs}`).then((res) => {
      console.log(res);
      setTotalPage(res && res.data && res.data.data && res.data.data.pages);
      setCount(res && res.data && res.data.data && res.data.data.count);
      const JobsArray =
        res && res.data && res.data.data && res.data.data.results;
      // colmConversion(JobsArray);
      setLoader(true);
      console.log(JobsArray);
    });

    fetchClient
      .get(`${apiUrl.deptList}?has_vacancy=1&page_size=1000`)
      .then((resp) => {
        // setDeptlist(res && res.data && res.data.data && res.data.data.results);

        const rest = resp.data;
        if (rest.success) {
          const result = rest.data;
          const res = result.results;
          // setDeptlist(res);
          let temp = res.map((item) => ({
            value: item.id,
            label: item.name,
            id: item.id,
          }));
          temp.unshift({
            label: "Department",
            value: "department",
          });
          setDeptlist(temp);
        }
      });
  }, []);

  // const processDataList = () => {
  //   const permissions = getPermissionsForSubAdmin();
  //   const data = {
  //     columnnames: {
  //       col1: "ID",
  //       col2: "Job Title",
  //       col3: "Department",
  //       col4: "Job Level",
  //       col5: "Status",
  //       col6: "Date Added",

  //       col8: "Action",
  //     },
  //     TableType: "Job",
  //     List: AS,
  //     ListKey: Object.keys(AS),
  //     EditPath: `/${role}/job/edit`,
  //     profile: `/${role}/job/profile`,
  //     permissions: permissions,
  //     jobIDs: jobIDs,
  //     jobExperience: jobExperience,
  //   };

  //   setAllData(data);
  // };

  const QUESTION_STATUS = [
    { label: "Status", value: "status" },
    { label: "Publish", value: "publish" },
    { label: "Unpublish", value: "unpublish" },
    { label: "Draft", value: "draft" },
  ];

  const EXAM_LEVEL = [
    { label: "Exam Level", value: "job_level" },
    { label: "Senior", value: "senior" },
    { label: "Associate", value: "associate" },
    { label: "Junior", value: "junior" },
    { label: "Internship", value: "internship" },
  ];

  const { dataArray } = useSelector((store) => store.filter);
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    let isCanclled = false;
    const handleChange = async () => {
      await timeout(200);
      if (!isCanclled) {
        fetchRecord();
      }
    };
    handleChange();
    return () => {
      isCanclled = true;
    };
  }, [dataArray, page, searchData, reset]);

  const queryBuilder = (url, options) => {
    const options1 = queryString.stringify({
      ...dataArray,
    });

    setPage1(options1);
    console.log(page, "iii");

    return `${url}?${options.page ? `&page=${options.page}` : " "}&${options1}${
      searchData ? `&search=${searchData}` : ""
    }`;
  };

  useEffect(() => {
    setfilter({ ...filter, page: 1 });
    setPageColor(1);
  }, [dataArray, searchData]);

  useEffect(() => {
    const allowActionColumn =
      localStorage.getItem("ROLE") === "SUB-ADMIN" &&
      localStorage.getItem("SUB_ADMIN_PERMISSION") &&
      !localStorage.getItem("SUB_ADMIN_PERMISSION").includes("EDIT_JOB") &&
      !localStorage.getItem("SUB_ADMIN_PERMISSION").includes("DELETE_JOB");
    dispatch({
      type: TableActions.SET_TABLE_COLUMN_HEADING,
      data: [
        "ID",
        "Job Title",
        "Department",
        "Job Level",
        "Status",
        "Date Added",
        !allowActionColumn && "Action",
      ],
    });
  }, []);

  const fetchRecord = async () => {
    try {
      setLoader(true);

      const query = queryBuilder(ENDPOINTS.JOB, filter);
      console.log("query", query);
      const resp = await API.get(query);
      console.log("respuuu", resp);
      if (resp.success) {
        let SUB_ADMIN_PERMISSION = localStorage.getItem("SUB_ADMIN_PERMISSION");

        const result = resp.data;
        const count = result.count;
        const vacancyData = result.results.map((item) => {
          console.log("lllll", item);
          return {
            0: item.id,
            1: item.job_title,
            2: item.department.name,
            3: item.job_level,
            4: item.status,
            5: convertUTCDateToLocalDate(
              new Date(item.updated_at)
            ).toLocaleDateString("en-IN"),

            editAction: {
              editpath: `/${role}/job/edit`,
              jobID: item.id,
              jobDepts: item.department.name,
              jobTitle: item.job_title,
              jobLevel: item.job_level,
              allow: SUB_ADMIN_PERMISSION
                ? SUB_ADMIN_PERMISSION.includes("EDIT_JOB")
                : true,
            },
            deleteAction: {
              actionID: item.id,
              tableType: "Vacancy",
              allow: SUB_ADMIN_PERMISSION
                ? SUB_ADMIN_PERMISSION.includes("DELETE_JOB")
                : true,
            },
          };
        });
        dispatch({
          type: TableActions.SET_TABLE_ROW,
          data: vacancyData,
          tableHeading: "Vacancy",
          profile: `/${role}/job/profile`,
          count: count,
        });
        dispatch({
          type: TableActions.SET_PAGINATION,
          data: { pages: result.pages, count: result.count },
        });

        setTotalPage(result.pages);
        setCount(result.count);
        // colmConversion(result.results);
      }
    } finally {
      setLoader(true);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: Actions.SET_FILTER,
      count: 3,
      data: [QUESTION_STATUS, EXAM_LEVEL, deptLists],
    });
  }, [deptLists]);

  let SUB_ADMIN_PERMISSION = localStorage.getItem("SUB_ADMIN_PERMISSION");

  return (
    <>
      <HeaderWithBreadcrumb heading="Vacancies" />

      <TablePageLayout
        heading="Vacancies"
        loader={loader}
        ButtonText="Create Vacancy"
        handleChangePage={handleChangePage}
        totalPage={totalPage}
        count={count}
        allowCreate={
          SUB_ADMIN_PERMISSION
            ? SUB_ADMIN_PERMISSION.includes("CREATE_JOB")
            : true
        }
        buttonActionPath={`/${role}/job/create`}
        handleChangeFilter={(e) => handleChangeFilter(e)}
        candidateFilter={{
          item: [status, level, deptLists],
          type: "vacancy",
          examStatus: { status },
          examStatusSelection: (e) => jobFilterSelection(e, "jobStatus"),

          deptSelectionVal: { deptSelectionVal },
          deptList: { deptLists },
          examDeptSelection: (e) => jobFilterSelection(e, "departmentSelect"),

          level: { level },

          examLevelSelection: (e) => jobFilterSelection(e, "levelSelect"),
        }}
      >
        <CommonTable Count={totalPage} handleChangePage={handleChangePage} />
      </TablePageLayout>
    </>
  );
}
