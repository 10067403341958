import React from "react";

function Error404() {
  return (
    <div>
      <h1>404</h1>
      <h3>Page not found</h3>
    </div>
  );
}

export default Error404;
