import * as Yup from "yup";

const AdminValidation = Yup.object().shape({
  full_name : Yup.string()
    .required("Required")
    .min(2, "Too Short!")
    .max(50, "Too Long!"),

  designation : Yup.string().required("Required"),

});

export default AdminValidation;
