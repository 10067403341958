import Actions from "redux/actions/pageHeaderAction";

let initialState = {
  header: "",
};
const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_PAGE_HEADER:
      return {
        header: action.data,
      };

    default:
      return state;
  }
};

export default AppReducer;
