import { Box, Grid, Typography, Link } from "@material-ui/core";
import React from "react";

import LandingLogo from "./Images/NickelfoxLogo.svg";
import LandingInfo from "./Images/Frame.svg";
import LandingTable from "./Images/Filled Lobby.svg";

function LandingPage() {
  return (
    <Grid
      container
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container xs={12} item>
        <Grid style={{ padding: "17px" }} container xs={12} lg={6}>
          <img
            src={LandingLogo}
            alt="nickelfoxlogo"
            loading="lazy"
            // style={{
            //   transition: "width 2s",
            //   margin: "23px 0 0 55px",
            //   width: "23.77px",
            //   height: "33.45px",
            // }}
          />
          <Typography
            style={{
              margin: "29px 0 0 16px",
              fontSize: "15.87px",
              letterSpacing: "0.44px",
              lineHeight: "19.44px",
              fontWeight: "700",
              height: "33.45px",
            }}
          >
            NICKELFOX x HR PORTAL
          </Typography>
        </Grid>
        <Grid
          lg={6}
          xs={12}
          justifyContent="flex-end"
          style={{ display: "flex" }}
        >
          <Typography
            style={{
              margin: "20px 9.35% 0 9.34%",
              fontSize: "15px",
              letterSpacing: "0.44px",
              lineHeight: "19.44px",
              fontWeight: "700",
              height: "33.45px",
              cursor: "pointer",
            }}
          >
            <Link
              to={{
                pathname: "/courses",
              }}
            >
              Login
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid item style={{ overflow: "scroll", height: "100vh" }} container>
        <Grid justifyContent="center" style={{ width: "100%" }} xs={12}>
          <img
            src={LandingInfo}
            alt="nickelfoxlogo"
            loading="lazy"
            style={{
              transition: "width 2s",
              width: "100%",
            }}
          />
        </Grid>
        <Grid container item style={{ backgroundColor: "green" }}>
          <Grid
            item
            justifyContent="center"
            style={{ backgroundColor: "red", padding: "120px" }}
            container
            xs={6}
          >
            <Grid
              style={{
                backgroundColor: "pink",
              }}
              item
            >
              <Typography
                style={{
                  margin: "29px 0 0 16px",
                  fontSize: "32px",
                  letterSpacing: "0.44px",
                  lineHeight: "19.44px",
                  fontWeight: "700",
                  height: "33.45px",
                }}
              >
                What is Hiring Portal?
              </Typography>
              <Typography
                style={{
                  margin: "29px 0 0 16px",
                  fontSize: "15px",
                  letterSpacing: "0.44px",
                  lineHeight: "19.44px",
                  fontWeight: "400",
                  width: "50%",
                }}
              >
                At Nickelfox our digital experts ensure best user-centric
                digital solutions. We thrive to create solutions that have top
                notch ui/ux designs and software development standards, suitable
                for best web products & best iOS & Android apps, crafted with
                love and passion.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            style={{ backgroundColor: "yellow", padding: "120px" }}
            item
            xs={6}
          >
            <img
              src={LandingTable}
              alt="nickelfoxlogo"
              loading="lazy"
              style={{
                transition: "width 2s",
                width: "50%",
              }}
            />
          </Grid>
        </Grid>
        {/*  <Grid item style={{ backgroundColor: "green" }}>
        abc
      </Grid>
      <Grid container justifyContent="center" item style={{ backgroundColor: "pink",display:"flex",flexDirection:""}}>
      <Grid item>abc</Grid>
        <Grid style={{backgroundColor:"pink"}} xs={2} justifyContent="space-between" container item>
          <Grid
          
            style={{
              backgroundColor: "white",
              height: "40px",
              width: "40px",
              borderRadius: "30px",
            }}
          >
            <img
              src={Ball}
              alt="nickelfoxlogo"
              loading="lazy"
              style={{
                transition: "width 2s",
                width: "100%",
              }}
            />
          </Grid>
          <Grid
            style={{
              backgroundColor: "white",
              height: "40px",
              width: "40px",
              borderRadius: "30px",
              display: "flex",
              justifyContent:"center"
            }}
          >
            <img
              src={Facebook}
              alt="nickelfoxlogo"
              loading="lazy"
              style={{
                transition: "width 2s",
                width: "45%",
              }}
            />
          </Grid>{" "}
          <Grid
            style={{
              backgroundColor: "white",
              height: "40px",
              width: "40px",
              borderRadius: "30px",

            }}
          >
            <img
              src={Instagram}
              alt="nickelfoxlogo"
              loading="lazy"
              style={{
                transition: "width 2s",
                width: "100%",
              }}
            />
          </Grid>{" "}
          <Grid
            style={{
              backgroundColor: "white",
              height: "40px",
              width: "40px",
              borderRadius: "30px",
              display: "flex",
              justifyContent:"center"
            }}
          >
            <img
              src={Twitter}
              alt="nickelfoxlogo"
              loading="lazy"
              style={{
                transition: "width 2s",
                width: "100%",
              }}
            />
          </Grid>{" "}
          <Grid
            style={{
              backgroundColor: "white",
              height: "40px",
              width: "40px",
              borderRadius: "30px",
              display: "flex",
              justifyContent:"center"
            }}
          >
            <img
              src={Be}
              alt="nickelfoxlogo"
              loading="lazy"
              style={{
                transition: "width 2s",
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
      </Grid> */}
      </Grid>
    </Grid>
  );
}

export default LandingPage;
