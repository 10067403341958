import React, { useEffect, useState, memo, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Skeleton } from "@material-ui/lab"; //4
import Alert from "@material-ui/lab/Alert"; //5
import { Link } from "react-router-dom";
import ActionComponent from "./ActionComponent";
import useVH from "react-viewport-height";

import {
  DeleteAlert,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  fetchPost,
  apiUrl,
  SuccessDialog,
} from "allImport";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import { capitalize } from "Common/CommonFunction";
import Emptyscreens from "components/Emptyscreen/emptyscreen";
import PopupData from "./PopupData";
import SelectQuestsionAction from "redux/actions/selectQuestionAction";
import { Interweave } from "interweave";
var browserWidth = window.innerWidth;
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    minHeight: 150,
    width: "calc(100vw - 330px)",
    // height: "calc(100vh - 256px)",
    ["@media (max-width:1030px)"]: {
      // eslint-disable-line no-useless-computed-key
      width: "calc(100vw - 52px)",
      // height: "calc(100vh - 320px)",
    },
  },
  tableCell: {
    fontSize: "14px",
  },
  collapseTableCell: {
    display: "flex",
    marginBottom: "0px",
    fontSize: "14px",
    maxWidth: 660,
    transition: "all 0.2s ease",
  },
  closedTableCell: {
    fontSize: "14px",
    maxWidth: 360,
    overflow: "hidden",
    height: "50px !important",
    transition: "all 0.2 ease",
  },
  openCollapseButton: {
    backgroundColor: "rgba(217, 0, 29, 0.7)",
    color: "#fff",
    "&:hover": {
      background: "rgba(217, 0, 29, 0.7)",
    },
  },
  footerWrapper: {
    position: "absolute",
    left: "40%",
    ["@media (max-width:1030px)"]: {
      display: "none",
    },
  },
  footerButton: {
    color: "#D1001C",
    textTransform: "none",
    fontSize: "12px",
  },
  mobileAddButton: {
    display: "none",
    ["@media (max-width:1030px)"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      position: "absolute",
      bottom: "70px",
      right: "50px",
      backgroundColor: "#D9001D",
      textDecoration: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "27px",
      fontWeight: "bold",
      zIndex: "5",
      borderRadius: "50%",
      height: "50px",
      width: "50px",
      color: "#fff",
    },
  },
  publish: {
    backgroundColor: "rgba(0, 207, 46, 0.05)",
    border: "1px solid rgba(0, 207, 46, 1)",
    padding: "5px 5px",
    textAlign: "center",
    borderRadius: "4px",
  },
  unpublish: {
    backgroundColor: "rgba(255, 74, 74, 0.05)",
    border: "1px solid rgba(255, 74, 74, 1)",
    padding: "5px 5px",
    textAlign: "center",
    borderRadius: "4px",
  },
  draft: {
    backgroundColor: "rgba(255, 163, 79, 0.05)",
    border: "1px solid rgba(255, 163, 79, 1)",
    padding: "5px 5px",
    textAlign: "center",
    borderRadius: "4px",
  },
});
function CommonTable(
  {
    Count,
    handleChangePage,
    handleCheckboxClick,
    selectQuestionCheck,
    pageColor,
  },
  props
) {
  const role = localStorage.getItem("ROLE").toLowerCase();
  const vh = useVH();
  const [changeText, setChangeText] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const matches = useMediaQuery("(min-width:800px)");
  const [open, setOpen] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const dispatch = useDispatch();
  const viewPortheight = `${100 * vh}px`;
  const handleCollapse = (index) => {
    const update = open.map((itm, indexx) => {
      return indexx === index ? !itm : itm;
    });
    setOpen(update);
  };
  const [id, setID] = useState("");
  const [tableType, setTableType] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [resendLink, setResendLink] = useState(false);
  const {
    columnHeading,
    columnData,
    tableHeading,
    profile,
    count,
    pageNumber,
  } = useSelector((store) => store.table);
  const { questionIdList, selectAll, selectedQuestionpage } = useSelector(
    (store) => store.selectQuestionReducer
  );
  console.log(selectedQuestionpage, "renderentart");
  const classes = useStyles();
  const handleDelete = (id, tableType, deleteName) => {
    setID(id);
    setTableType(tableType);
    setDeletePopup(true);
    setDeleteName(deleteName);
  };

  const handleSubAdminLink = (id) => {
    const data = {
      id: id,
      // "ccf1a4c0-747e-419c-8f93-1c7b1075b627"
    };
    console.log("data", id);
    fetchPost
      .post(`${apiUrl.resendLinkSubAdmin}`, data)
      .then((res) => {
        if (res.data.success) {
          console.log("response", res.data.success);

          setResendLink(true);
        }
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response) {
          if (error.response.data.code === 404) {
          } else if (error.response.data.error) {
            error.response.data.error.message.forEach((message) => {});
          }
        }
      });
  };

  useEffect(() => {
    console.log(changeText, "changeText");
  }, [changeText]);

  const checkAllQuestions = (e) => {
    const temp = columnData.map((item) => {
      return item.id;
    });
    if (!e.target.checked) {
      dispatch({
        type: SelectQuestsionAction.UNSET_SELECT_ALL_QUESTION,
        data: [...temp],
        selectAll: true,
        uncheckedPage: pageNumber,
      });
    } else {
      dispatch({
        type: SelectQuestsionAction.SET_SELECT_ALL_QUESTION,
        data: [...temp],
        selectAll: true,
        checkedPage: pageNumber,
      });
    }
  };
  return (
    <>
      {resendLink ? (
        <SuccessDialog
          type="field"
          heading="Invite Sent Successfully"
          path={`/${role}/subadmins`}
          setResendLink={() => setResendLink(false)}
        />
      ) : null}
      {deletePopup ? (
        <DeleteAlert
          type={tableType}
          de="pe"
          heading={`Are you sure you want to delete this ${tableType}?`}
          id={id}
          setDeletePopup={setDeletePopup}
        />
      ) : null}

      <TableContainer
        className={classes.container}
        style={{
          height: `calc(${viewPortheight} - 260px)`,
          ["@media (max-width:1030px)"]: {
            // eslint-disable-line no-useless-computed-key
            width: "calc(100vw - 52px)",
            height: `calc(${viewPortheight} - 350px)`,
          },
        }}
      >
        {/* <Link waves="light" className={classes.mobileAddButton}>
            +
          </Link> */}
        <Table stickyHeader aria-label="sticky table" style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              {columnHeading.map((column) => (
                <TableCell
                  className={classes.tableCell}
                  key={column}
                  align={column === "Action" ? "center" : "left"}
                  style={{ minWidth: column.minWidth }}
                >
                  {column === "checkbox" ? (
                    <Checkbox
                      checked={
                        selectedQuestionpage
                          ? selectedQuestionpage.includes(pageNumber)
                          : null
                      }
                      color="primary"
                      size="small"
                      onClick={(e) => {
                        checkAllQuestions(e);
                      }}
                    />
                  ) : (
                    column
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ position: "relative" }}>
            {count && columnData !== [] ? (
              columnData.map((row, indexx) => {
                console.log("coliumnnn", row);
                return (
                  <>
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={indexx}
                      style={{ height: "40px" }}
                    >
                      {columnHeading.map((item, index) => {
                        const checkBoxId = row.id;
                        return (tableHeading === "Candidates" && index === 1) ||
                          (tableHeading !== "Candidates" &&
                            tableHeading !== "Questions" &&
                            tableHeading !== "All Questions" &&
                            index === 0) ? (
                          <TableCell className={classes.tableCell}>
                            <Link
                              style={{
                                color: "#D9001D",
                              }}
                              to={{
                                pathname: profile,
                                state: {
                                  profileId: row[index],
                                  ExamID: row.examId || row[index],
                                  ExamName: row[1],
                                  Level: row[2],
                                },
                              }}
                            >
                              {row[index].slice(3, 8)}
                            </Link>
                          </TableCell>
                        ) : item === "Action" ? (
                          <ActionComponent
                            latestStatus={row[6]}
                            tableHeading={tableHeading}
                            assignExam={row.assignExam}
                            editAction={row.editAction}
                            deleteAction={row.deleteAction}
                            handleDelete={handleDelete}
                            handleSubAdminLink={handleSubAdminLink}
                          />
                        ) : (tableHeading === "Questions" && index === 0) ||
                          (tableHeading === "All Questions" && index === 1) ? (
                          <TableCell>
                            <IconButton
                              className={
                                open[indexx] ? classes.openCollapseButton : ""
                              }
                              aria-label="expand row"
                              size="small"
                              onClick={() => handleCollapse(indexx)}
                            >
                              {open[indexx] ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                        ) : tableHeading === "All Questions" && // All questions index 0 checkboxes
                          index === 0 ? (
                          <TableCell
                            className={classes.tableCell}
                            style={{ width: "30px" }}
                          >
                            <Checkbox
                              checked={
                                questionIdList
                                  ? questionIdList.includes(checkBoxId)
                                  : null
                              }
                              defaultChecked={
                                questionIdList
                                  ? questionIdList.includes(checkBoxId)
                                  : null
                              }
                              onClick={(e) => {
                                handleCheckboxClick(e, checkBoxId);
                              }}
                              color="primary"
                              size="small"
                            />
                          </TableCell>
                        ) : (tableHeading === "All Questions" && index === 2) ||
                          (tableHeading === "Questions" && index === 1) ? (
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                              className={
                                open[indexx]
                                  ? classes.collapseTableCell
                                  : classes.closedTableCell
                              }
                              noWrap
                            >
                              <Interweave
                                content={row[index].replaceAll(
                                  "<img",
                                  `<img style="max-width: 350px; margin-left:-35px; border-radius: 4px; border: 1px solid #0000001A"`
                                )}
                              />
                            </div>
                          </TableCell>
                        ) : (
                          <TableCell className={classes.tableCell}>
                            <AnimatePresence>
                              <motion.div
                                key="modal"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0, x: -300 }}
                              >
                                <Grid>
                                  {row[index] === "publish" ||
                                  row[index] === "Disqualified" ||
                                  row[index] === "Qualified" ||
                                  row[index] === "unpublish" ||
                                  row[index] === "draft" ||
                                  row[index] === "Active" ||
                                  row[index] === "Inactive" ? (
                                    <Box
                                      className={
                                        row[index] === "publish" ||
                                        row[index] === "Qualified" ||
                                        row[index] === "Active"
                                          ? classes.publish
                                          : row[index] === "unpublish" ||
                                            row[index] === "Disqualified" ||
                                            row[index] === "Inactive"
                                          ? classes.unpublish
                                          : row[index] === "draft"
                                          ? classes.draft
                                          : undefined
                                      }
                                    >
                                      {
                                        <>
                                          {changeText[indexx] === indexx &&
                                          item === "Candidate Status" &&
                                          row.hire_comment != null &&
                                          row.hire_comment != "" ? (
                                            <span style={{ color: "#000" }}>
                                              View Comment
                                            </span>
                                          ) : (
                                            capitalize(row[index])
                                          )}
                                          <Grid
                                            style={{
                                              display: "flex",
                                              position: "relative",
                                              justifyContent: "center",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {item === "Candidate Status" &&
                                            row.hire_comment != null &&
                                            row.hire_comment != "" ? (
                                              <PopupData
                                                setChangeText={setChangeText}
                                                hoverIndex={indexx}
                                                data={row.hire_comment}
                                              />
                                            ) : null}
                                          </Grid>
                                        </>
                                      }
                                    </Box>
                                  ) : (
                                    capitalize(row[index])
                                  )}
                                </Grid>
                              </motion.div>
                            </AnimatePresence>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </>
                );
              })
            ) : count === 0 ? (
              <Emptyscreens />
            ) : (
              Array(10)
                .fill("Empty Array")
                .map((row, indexx) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={indexx}
                      style={{ height: "50px" }}
                    >
                      {columnHeading.map((item, index) => {
                        return (
                          <TableCell className={classes.tableCell}>
                            {/* <AnimatePresence>
                                <motion.div
                                  key={item}
                                  initial={{ y: 300, opacity: 0 }}
                                  animate={{ y: 0, opacity: 1 }}
                                  exit={{ y: -300, opacity: 0 }}
                                > */}
                            <Skeleton
                              style={{
                                height: "15px",
                              }}
                            />
                            {/* </motion.div>
                              </AnimatePresence> */}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justifyContent="flex-end">
        <Grid
          item
          container
          justifyContent="flex-end"
          xs={12}
          style={{
            padding: "10px 0px",
            borderTop: "1px solid #E5E5E5",
            position: "relative",
          }}
        >
          <Grid className={classes.footerWrapper}>
            <Typography
              variant="body2"
              style={{ fontSize: "12px" }}
              color="textSecondary"
              align="center"
            >
              {"Copyright © "}
              <Button
                className={classes.footerButton}
                href="https://www.freshmindwave.com/"
              >
                Fresh Mind Wave
              </Button>
              {new Date().getFullYear()}
            </Typography>
          </Grid>
          <Pagination
            hidePrevButton={matches ? false : true}
            hideNextButton={matches ? false : true}
            size={browserWidth < 1030 ? "small" : "medium"}
            count={Count}
            page={pageColor}
            onChange={handleChangePage}
          />
        </Grid>
      </Grid>
    </>
  );
}
export default memo(CommonTable);
