import * as Yup from "yup";

const VacancyValidation = Yup.object().shape({
  vacancyTitle: Yup.string()
    .required("Required")
    .min(2, "Too Short!")
    .max(50, "Too Long!"),

  experienceLevel: Yup.string().required("Required"),
  department: Yup.string().required("Required"),
});

export default VacancyValidation;
