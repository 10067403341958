import * as Yup from "yup";
const passwordregex =
/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{3,}$/;
const ChangepasswordValidation = Yup.object().shape({
  oldpassword: Yup.string()
    .matches(passwordregex,"Password should contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.")
    .required("Required"),

    newpassword: Yup.string().matches(passwordregex,"Password should contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.").required("Required"),
    changepassword: Yup.string().when("newpassword", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("newpassword")],
        "New password and Confirm Password need to be the same"
      ).required("Required"),
    })
});

export default ChangepasswordValidation;