import { Grid, IconButton } from "@material-ui/core";
import DividerIcon from "./assets/divider.svg";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import Actions from "redux/actions/candidateStatusAction";

const SubHeading = ({ data, title, count }) => {
  const dispatch = useDispatch();

  const handleFilter = () => {
    dispatch({
      type: Actions.STATUS_FILTER,
      data: title === "Qualified" ? "1" : title === "Disqualified"  ?  "0" : "2",
    });
  };
  return (
    <>
      <div
        onClick={handleFilter}
        style={{
          cursor: "pointer",
          // padding: "5px 10px",
          // border: "1px solid #00CF2E",
          // borderRadius: "5px",
          // backgroundColor: "#F2FDF5",
        }}
      >
        {(count || count === 0) && title + " "}
        <span
          style={
            title === "Qualified"
              ? { color: "#00CF2E", fontWeight: "700" }
              : title === "Disqualified"
              ? { color: "#D9001D", fontWeight: "700" }
              : { color: "#383838", fontWeight: "700" }
          }
        >
          {count || count === 0 ? (
            data
          ) : (
            <Skeleton style={{ width: "100px" }} variant="text" />
          )}
        </span>
      </div>
      <Grid
        item
        container
        md={1}
        xs={1}
        component={Grid}
        justifyContent="center"
      >
        {title === "Qualified"&& (
          <IconButton disableRipple>
            <img src={DividerIcon} style={{ height: "15px" }} alt="filter" />
          </IconButton>
        )}
        {title === "Disqualified"&& (
          <IconButton disableRipple>
            <img src={DividerIcon} style={{ height: "15px" }} alt="filter" />
          </IconButton>
        )}
      </Grid>
    </>
  );
};

export default SubHeading;
