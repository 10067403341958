import SelectQuestsionAction from "redux/actions/selectQuestionAction";

let initialState = {
  questionIdList: [],
  selectAll: false,
  selectedQuestionpage: [],
};

const selectQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SelectQuestsionAction.SET_SELECT_QUESTION:
      return {
        ...state,
        questionIdList: [...state.questionIdList, action.data],
      };
    case SelectQuestsionAction.UNSET_SELECT_QUESTION:
      return {
        ...state,
        questionIdList: state.questionIdList.filter((item, index) => {
          return item !== action.data;
        }),
        selectAll: false,
      };
    case SelectQuestsionAction.SET_SELECT_ALL_QUESTION:
      const updateQuestionIdList = action.data.filter((item) => {
        return !state.questionIdList.includes(item);
      });
      return {
        ...state,
        questionIdList: [...state.questionIdList, ...updateQuestionIdList],
        selectAll: action.selectAll,
        selectedQuestionpage: [
          ...state.selectedQuestionpage,
          action.checkedPage,
        ],
      };
    case SelectQuestsionAction.UNSET_SELECT_ALL_QUESTION:
      const removeQuestionIdList = state.questionIdList.filter((item) => {
        return !action.data.includes(item);
      });
      const updatedPage = state.selectedQuestionpage.filter((item) => {
        return item !== action.uncheckedPage;
      });
      return {
        ...state,
        questionIdList: [...removeQuestionIdList],
        selectAll: action.selectAll,
        selectedQuestionpage: [...updatedPage],
      };
    case SelectQuestsionAction.RESET_QUESTION:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default selectQuestionReducer;
