import { React, Typography, Link, Button } from "allImport";

function copyright() {
  return (
    <footer style={{ textAlign: "center", flexShrink: 0 }} className="footer">
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Button
          style={{ color: "#D1001C", textTransform: "none" }}
          href="https://www.nickelfox.com"
        >
          Nickelfox
        </Button>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </footer>
  );
}

export default copyright;
