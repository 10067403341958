import {
  useHistory,
  Emptyscreen,
  CancelRoundedIcon,
  OutlinedInput,
  apiUrl,
  useEffect,
  fetchClient,
  Select,
  MenuItem,
  Container,
  Grid,
  Paper,
  IconButton,
  InputAdornment,
  TextField,
  FormControl,
  React,
  SearchIcon,
  Pagination,
} from "allImport";
import { MenuProps, MouseEnter, MouseLeave } from "Common/CommonFunction";
import { useStyles } from "style/Liststyle.js";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
  SUB_ADMIN_PERMISSION,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import "react-multi-date-picker/styles/colors/red.css";
import DatePicker from "react-multi-date-picker";
import { useDispatch } from "react-redux";
import Actions from "redux/actions/filterActions";
import TableActions from "redux/actions/tableActions";

var url = new URL(`${apiUrl.viewSubmittedExams}`);
var AS = [];
var searchValue = "";
export default function SubmittedExamlist() {
  const classes = useStyles();
  var [dates, setDates] = React.useState([]);
  const history = useHistory();
  const [data, setAllData] = React.useState({});
  const [count, setCount] = React.useState("");
  const [deptLists, setDeptlist] = React.useState([]);
  const [deptSelectionVal, setDept] = React.useState("");
  const [totalPage, setTotalPage] = React.useState("");
  const [level, setLevel] = React.useState("");
  const [score, setScore] = React.useState("");
  const [submitExamsSearch, setSubmitExamsSearch] = React.useState("");
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const { searchData } = useSelector((store) => store.search);
  const [page, setPage] = React.useState(1);
  const [loader, setLoader] = React.useState(false);
  const handleChangePage = (event, value) => {
    fetchClient
      .get(`${apiUrl.viewSubmittedExams}?page=` + value)
      .then((res) => {
        var submittedexams =
          res && res.data && res.data.data && res.data.data.results;
        colmConversion(submittedexams);
      });
    setPage(value);
  };

  function createData(ColA, ColB, ColC, ColD, ColE, ColF, ColG) {
    return { ColA, ColB, ColC, ColD, ColE, ColF, ColG };
  }
  const colmConversion = (arr) => {
    AS = [];
    console.log("array", arr);
    for (var index = 0; index < arr.length; index++) {
      AS.push(
        createData(
          arr[index] && arr[index].exam && arr[index].exam.exam_name,
          arr[index] &&
            arr[index].exam &&
            new Date(arr[index].exam.created_at).toLocaleDateString("en-IN"),
          arr[index] && arr[index].exam && arr[index].exam.experience_level,
          arr[index] &&
            arr[index].exam &&
            arr[index].exam.candidates.join(", "),
          arr[index] && arr[index].exam && arr[index].exam.number_of_question,
          arr[index] && arr[index].exam && arr[index].exam.average_percent,
          arr[index] && arr[index].exam && arr[index].exam.exam_id
        )
      );
    }
    processDataList();
  };

  const handle = (dates) => {
    console.log("length", dates.length);
    if (dates.length === 2) {
      var d1 = new Date(dates[0]).toLocaleDateString("fr-CA");
      var d2 = new Date(dates[1]).toLocaleDateString("fr-CA");
      fetchClient
        .get(`${apiUrl.viewSubmittedExams}?from=${d1}&to=${d2}`)
        .then((res) => {
          setCount(res && res.data && res.data.data && res.data.data.count);
          const submittedexams =
            res && res.data && res.data.data && res.data.data.results;
          colmConversion(submittedexams);
        });
      dates = [];
    } else {
      fetchClient.get(`${apiUrl.viewSubmittedExams}`).then((res) => {
        setTotalPage(res && res.data && res.data.data && res.data.data.pages);
        setCount(res && res.data && res.data.data && res.data.data.count);
        const submittedexams =
          res && res.data && res.data.data && res.data.data.results;
        colmConversion(submittedexams);
      });
    }
  };

  const getPermissionsForSubAdmin = () => {
    let permission = {};
    if (role === "sub-admin") {
      let allPermissions = StorageManager.getJSON(SUB_ADMIN_PERMISSION);
      permission.view = allPermissions.includes("VIEW_RESULT");
      permission.create = allPermissions.includes("CREATE_RESULT");
      permission.edit = allPermissions.includes("EDIT_RESULT");
      permission.delete = allPermissions.includes("DELETE_RESULT");
    }
    return permission;
  };

  const processDataList = () => {
    const permissions = getPermissionsForSubAdmin();
    const data = {
      columnnames: {
        col1: "Name",
        col2: "Date Created",
        col3: "Exam Level",
        col4: "Candidates who Attempted",
        col5: "Total Questions",
        col6: "Avg. Score",
      },
      TableType: "Result",
      permissions: permissions,
      List: AS,
      ListKey: Object.keys(AS),
      profile: `/${role}/result/exam/candidate-list`,
    };
    setAllData(data);
  };
  const filterSubmittedExams = (e) => {
    setSubmitExamsSearch(e.target.value);
    searchValue = e.target.value;
    fetchClient
      .get(`${apiUrl.viewSubmittedExams}?search=` + searchValue)
      .then((res) => {
        console.log("searched", res);
        setCount(res && res.data && res.data.data && res.data.data.count);
        var submittedexams =
          res && res.data && res.data.data && res.data.data.results;

        colmConversion(submittedexams);
      });
  };
  const submitExamsFilterSelection = (e, type) => {
    if (type === "Score") {
      setScore(e.target.value);
      if (e.target.value !== "") {
        url.searchParams.set("average_percent", e.target.value);
      } else {
        url.searchParams.delete("average_percent");
      }
    }
    if (type === "departmentSelect") {
      setDept(e.target.value);
      if (e.target.value !== "") {
        url.searchParams.set("department", e.target.value);
      } else {
        url.searchParams.delete("department");
      }
    }
    if (type === "levelSelect") {
      setLevel(e.target.value);
      if (e.target.value !== "") {
        url.searchParams.set("experience_level", e.target.value);
      } else {
        url.searchParams.delete("experience_level");
      }
    }
    fetchClient.get(url).then((res) => {
      setTotalPage(res && res.data && res.data.data && res.data.data.pages);
      setCount(res && res.data && res.data.data && res.data.data.count);
      const submittedexams =
        res && res.data && res.data.data && res.data.data.results;
      colmConversion(submittedexams);
    });
  };
  useEffect(() => {
    fetchClient.get(`${apiUrl.viewSubmittedExams}`).then((res) => {
      setTotalPage(res && res.data && res.data.data && res.data.data.pages);
      setCount(res && res.data && res.data.data && res.data.data.count);
      const submittedexams =
        res && res.data && res.data.data && res.data.data.results;
      colmConversion(submittedexams);
      setLoader(true);
    });

    fetchClient.get(`${apiUrl.deptList}?page_size=1000`).then((res) => {
      setDeptlist(res && res.data && res.data.data && res.data.data.results);
    });
  }, []);
  const clear = () => {
    setSubmitExamsSearch("");
    fetchClient.get(url).then((res) => {
      setCount(res && res.data && res.data.data && res.data.data.count);
      setTotalPage(res && res.data && res.data.data && res.data.data.pages);
      const submittedexams =
        res && res.data && res.data.data && res.data.data.results;
      colmConversion(submittedexams);
    });
  };

  return (
    <main className={classes.content}>
      <Container maxWidth="xl" className={classes.container}>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <div className={classes.page_heading}>Submitted Exams</div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div className={classes.page_subheading}>Submitted Exams Lobby</div>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item lg={12} xl={12} md={12} sm={12}>
            <Paper className={classes.paper}>
              <Grid container xs={12}>
                <Grid item lg={12} xl={12}>
                  <Grid
                    container
                    className={classes.allExam_container}
                    lg={12}
                    xl={12}
                  >
                    <Grid
                      item
                      lg={3}
                      xl={3}
                      md={3}
                      sm={3}
                      align="left"
                      justify="left"
                      style={{ display: "flex" }}
                    >
                      <div className={classes.all_examiners_heading}>
                        {" "}
                        All Submitted
                      </div>
                      <div className={classes.examiner_nos}>{count}</div>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xl={4}
                      md={4}
                      sm={4}
                      align="center"
                      justify="center"
                    >
                      <div className={classes.searchExaminers}>
                        <TextField
                          placeholder="Search"
                          id="outlined-basic"
                          onChange={(e) => filterSubmittedExams(e)}
                          variant="outlined"
                          value={submitExamsSearch}
                          className={classes.searchExaminerfield}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment>
                                <IconButton>
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                            endAdornment: submitExamsSearch && (
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={clear}
                              >
                                <CancelRoundedIcon />
                              </IconButton>
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      lg={5}
                      xl={5}
                      md={5}
                      sm={5}
                      align="right"
                      justify="right"
                    >
                      <div className={classes.multiDropdown}>
                        <div style={{ marginRight: "17px" }}>
                          <div>
                            <label>
                              <img
                                src="/assets/Icons/calender_icon.svg"
                                alt=""
                              />
                              <DatePicker
                                fixMainPosition
                                range
                                calendarPosition="bottom"
                                style={{
                                  height: "0",
                                  width: "0",
                                  borderColor: "white",
                                }}
                                value={dates}
                                className="red"
                                onChange={(dateObjects) => {
                                  setDates(dateObjects);
                                  handle(dateObjects);
                                }}
                              />
                            </label>
                          </div>{" "}
                        </div>
                        {role === "EXAMINER" ? null : (
                          <FormControl
                            style={{
                              textAlign: "center",
                              marginTop: "-16px",
                              width: "149px",
                            }}
                          >
                            <Select
                              id="demo-customized-select-native"
                              displayEmpty
                              value={deptSelectionVal}
                              onChange={(e) =>
                                submitExamsFilterSelection(
                                  e,
                                  "departmentSelect"
                                )
                              }
                              MenuProps={MenuProps}
                              input={<OutlinedInput />}
                            >
                              <MenuItem value="">Department</MenuItem>
                              {deptLists &&
                                deptLists.map((row, index) => (
                                  <MenuItem key={row.id} value={row.id}>
                                    {row.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        )}
                        <FormControl
                          style={{
                            textAlign: "center",
                            marginTop: "-16px",
                            width: "122px",
                          }}
                        >
                          <Select
                            id="demo-customized-select-native"
                            displayEmpty
                            value={score}
                            onChange={(e) =>
                              submitExamsFilterSelection(e, "Score")
                            }
                            MenuProps={MenuProps}
                            input={<OutlinedInput />}
                          >
                            <MenuItem value="">Avg Score</MenuItem>
                            <MenuItem value="50">{">50"}</MenuItem>
                            <MenuItem value="70">{">70"}</MenuItem>
                            <MenuItem value="90">{">90"}</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl
                          style={{
                            textAlign: "center",
                            marginTop: "-16px",
                            width: "142px",
                          }}
                        >
                          <Select
                            id="demo-customized-select-native"
                            displayEmpty
                            value={level}
                            onChange={(e) =>
                              submitExamsFilterSelection(e, "levelSelect")
                            }
                            MenuProps={MenuProps}
                            input={<OutlinedInput />}
                          >
                            <MenuItem value="">Exam Level</MenuItem>
                            <MenuItem value="senior">Senior</MenuItem>
                            <MenuItem value="junior">Junior</MenuItem>
                            <MenuItem value="internship">Internship</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                  {loader ? (
                    <>
                      {count ? (
                        <Grid container xs={12}>
                          <div className={classes.examiners_table_wrapper}>
                            <Commontable data={data} />
                          </div>
                        </Grid>
                      ) : (
                        <Emptyscreen image="/assets/Icons/SubmitExamEmptyIcon.svg" />
                      )}
                    </>
                  ) : (
                    <Paper
                      className={classes.paper}
                      style={{
                        height: "380px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <img
                        src="/assets/Icons/nickelfox_loader.gif"
                        width="100px"
                        height="100px"
                        alt="nickelfoxlogo"
                      />{" "}
                    </Paper>
                  )}
                </Grid>
              </Grid>
            </Paper>
            <div className={classes.pagination}>
              <Pagination
                count={totalPage}
                page={page}
                onChange={handleChangePage}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}
