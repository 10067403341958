import { Paper, makeStyles } from "allImport";
import React from "react";
import { commonStyle } from "commonStyle.js";

const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),
}));

const Loader = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.loader}>
      <img
        src="/assets/Icons/nickelfox_loader.gif"
        width="100px"
        height="100px"
        alt="nickelfoxlogo"
      />
    </Paper>
  );
};

export default Loader;
