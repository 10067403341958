import { createContext, useContext, useState } from "react";

export const SearchContext = createContext();

export function useBool() {
  return useContext(SearchContext);
}
const BoolContextProvider = (props) => {
  const [selects, setSelects] = useState(false);
  const [select, setSelect] = useState(false);
  const [hireRejectVisible, setHireRejectVisible] = useState(false);
  const [topbarVisible, setTopBarVisible] = useState(true);
  return <SearchContext.Provider value={{ selects, setSelects, select, setSelect,hireRejectVisible, setHireRejectVisible,topbarVisible, setTopBarVisible }}>{props.children}</SearchContext.Provider>;
};

export default BoolContextProvider;
