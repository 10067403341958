
import * as Yup from "yup";

const SendExamLinkValidation = Yup.object().shape({
    examLevel: Yup.string().required("Required"),
    examName: Yup.string().required("Required"),
    percentage : Yup.number().required("Required").min(10, "Should be more tha 10 !")
    .max(100, "Should be less than 100 !"),
});

export default SendExamLinkValidation;
