import {
  useEffect,
  useHistory,
  React,
  fetchClient,
  apiUrl,
  fetchPost,
  SuccessDialog,
  fetchDelete,
} from "allImport";
import "style/style.scss";
import { FormikProvider } from "formik";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import { useStyles as useStyles1 } from "style/Liststyle";
import {
  Box,
  Button,
  Container,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import CandidateValidation from "components/Form/validations/CandidateValidation";
import BasicInput from "components/Form/InputBoxes/BasicInput";
import { useFormik } from "formik";
import { capitalize } from "Common/CommonFunction";
import { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { withSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Button/CustomButton";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import { CandidateInitialValues } from "components/Form/InitialValues/CandidatesInitialValues";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import candidateResume from "./resume.svg";
import removeButton from "./remove.svg";
import useVH from "react-viewport-height";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1);",
    },
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 5,
      paddingRight: "100px",
      width: "100%",
    },
  },
  label: {
    fontFamily: "Mulish",
    fontWeight: "600px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#383838",
  },
  removeButton: {
    // position: "absolute",
    marginTop: "-10px",
    marginLeft: "-10px",

    "&:hover": {
      cursor: "pointer",
    },
  },
  ResumeContainer: {
    padding: "0px 0px 20px 0px",
  },
}));

function AddCandidate(props) {
  const vh = useVH();
  const viewPortheight = `${100 * vh}px`;
  const classes2 = useStyles();
  const classes1 = useStyles1();

  const [jobList, setJobList] = useState([]);
  const [examinerList, setExaminerList] = useState([]);
  const [candidateToggle, setCandidateToggle] = useState(false);
  const [candidateNameFromResume, setCandidateNameFromResume] = useState(null);

  const [resume, setResume] = useState(null);

  const [loader, setLoader] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [resumeUrl, setResumeUrl] = useState(null);

  const history = useHistory();
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;

  const handleJobChange = (newValue) => {
    fetchClient
      .get(`${apiUrl.jobProfile}${newValue.id}/profile/`)
      .then((res) => {
        const dept = res.data.data.department.id;
        fetchClient
          .get(`${apiUrl.examinerList}?page_size=1000&department=${dept}`)
          .then((res) => {
            setExaminerList(
              res && res.data && res.data.data && res.data.data.results
            );
          });
      });
  };

  const handleRemoveResume = () => {
    setLoader(true);
    // console.log("check This", resumeUrl);
    const data = {
      resume_url: resumeUrl,
    };
    fetchDelete
      .delete(`${apiUrl.resumeCandidate}`, { data: data })
      .then((res) => {
        formik.setFieldValue("name", "");
        formik.setFieldValue("email", "");
        formik.setFieldValue("phone_number", "");
        setUploaded(null);
        setResumeUrl(null);
        setLoader(false);
      });
  };

  // Get Data on Initial Page Load
  useEffect(() => {
    if (
      !StorageManager.get(API_TOKEN) &&
      !StorageManager.get(ADMIN_ID) &&
      !StorageManager.get(ADMIN_NAME) &&
      !StorageManager.get(LOGOUT_TOKEN)
    ) {
      history.push("/login");
    }
    fetchClient
      .get(`${apiUrl.jobList}?page_size=1000&status=publish`)
      .then((res) => {
        setJobList(res.data.data.results);
      });
  }, []);

  const updatedPhoneNumber = (number) => {
    // Function to run when the number comming from server is greater than 10
    const newNum = parseInt(number);
    console.log(newNum, "Astropro");
    if (newNum.toString().length > 10) {
      return newNum.toString().slice(-10);
    } else return number;
  };

  useEffect(() => {
    if (resume !== null) {
      handleResume();
    }
  }, [resume]);

  const handleResume = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("upload", resume);

    fetchPost
      .post(`${apiUrl.resumeCandidate}`, formData)
      .then((res) => {
        formik.setFieldValue("name", res.data.full_name);
        formik.setFieldValue("email", res.data.email);
        formik.setFieldValue("phone_number", res.data.phone_number);

        setResumeUrl(res.data.resume_url);
        setLoader(false);
        setUploaded(true);
      })
      .catch((error) => {
        alert("Error Uploading Resume");
      });
  };

  const formik = useFormik({
    initialValues: {
      name: props.location.state ? props.location.state.candidateName : "",
      email: props.location.state ? props.location.state.candidateEmail : "",
      jobAssociated: "",
      contactNumber: props.location.state
        ? updatedPhoneNumber(props.location.state.candidatePhoneNumber)
        : null,
      totalExperience: null,
      additionalInfo: "",
      examinerList: "",
    },
    validationSchema: CandidateValidation,
    onSubmit: (values) => {
      const data = {
        full_name: values.name,
        email: values.email,
        phone_number: values.contactNumber,
        department: "",
        job: values.jobAssociated,
        examiner: values.examinerList,
        experience: values.totalExperience,
        additional_info: values.additionalInfo,
        resume: props.location.state
          ? props.location.state.candidateResumeUrl
          : resumeUrl,
      };
      console.log(data);
      fetchPost
        .post(`${apiUrl.addCandidate}`, data)
        .then((res) => {
          setCandidateToggle(true);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.code === 404) {
              props.enqueueSnackbar(
                "Oops! Something went wrong. We are working on fixing the problem.",
                {
                  variant: "error",
                  autoHideDuration: 3000,
                }
              );
            } else if (error.response.data.error) {
              error.response.data.error.message.forEach((message) => {
                props.enqueueSnackbar(message, {
                  variant: "error",
                  autoHideDuration: 3000,
                });
              });
            }
          } else {
            props.enqueueSnackbar(
              "Oops! Something went wrong. Please check your network connection and try again",
              {
                variant: "error",
              }
            );
          }
        });
    },
  });

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Container
          container
          maxWidth="xl"
          className={classes1.container}
          style={{ height: `calc(${viewPortheight} - 100px)` }}
        >
          <HeaderWithBreadcrumb
            heading="Add New Candidate"
            breadCrumb
            breadCrumb1="Candidates"
            breadCrumb2="Add new candidate"
            link={`/${role}/candidate`}
          />

          {candidateToggle ? (
            <SuccessDialog
              type="field"
              heading="Candidate Added Successfully"
              path={`/${role}/candidate`}
            />
          ) : null}
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <Grid
                container
                spacing={3}
                item
                xs={12}
                className={classes2.ResumeContainer}
              >
                {/* {uploaded ? (
                  <Grid container item xs={12} lg={4} md={6}>
                    <InputLabel
                      style={{
                        fontFamily: "Mulish",
                        fontWeight: "bold",
                        fontSize: "14px",
                        lineHeight: "18px",
                        marginBottom: "20px",
                        color: "#383838",
                      }}
                    >
                      Resume (Uploaded)
                    </InputLabel>
                    <Grid
                      container
                      // justifyContent="center"
                      item
                      xs={12}
                      style={{ position: "relative" }}
                    >
                      {loader ? (
                        <div className="loader"></div>
                      ) : (
                        <>
                          <Grid item xs="auto">
                            <img
                              src={candidateResume}
                              alt="candidateResume"
                              style={{
                                width: "150px",
                                border: "1px solid #E5E5E5",
                              }}
                            />
                          </Grid>
                          <Grid item xs="auto">
                            <img
                              onClick={handleRemoveResume}
                              src={removeButton}
                              alt="remove"
                              className={classes2.removeButton}
                            />
                          </Grid>
                          <Grid
                            xs
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography
                              variant="body2"
                              style={{ color: "#DA3C20" }}
                            >
                              Updating or removing resume will clear prefetched
                              fields.
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container item spacing={2}>
                    <Grid item md={6}>
                      <Button
                        // size="large"
                        variant="outlined"
                        color="primary"
                        component="label"
                      >
                        <input
                          hidden
                          name="upload-photo"
                          type="file"
                          onChange={(e) => {
                            setResume(e.target.files[0]);
                          }}
                        />
                        {loader ? (
                          <div className="loader"></div>
                        ) : (
                          "Upload Resume"
                        )}
                      </Button>
                    </Grid>
                    {
                      <Grid item md={6}>
                        <motion.div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          initial={{ x: 40 }}
                          animate={{ x: 0 }}
                          exit={{ opacity: 0, x: 40 }}
                          transition={{
                            yoyo: Infinity,
                            ease: "easeOut",
                            duration: 1.2,
                          }}
                        >
                          <Grid
                            xs={7}
                            style={{
                              padding: "5px",
                              backgroundColor: "rgba(0, 207, 46, 0.1)",
                              border: "1px solid #00CF2E",
                              borderRadius: "4px",
                            }}
                          >
                            <Typography
                              align="center"
                              variant="body2"
                              style={{ color: "rgba(56, 56, 56, 1)" }}
                            >
                              {!uploaded
                                ? "<----- Upload resume to prefetch candidate details"
                                : "Updating or removing resume will clear prefetched fields."}
                            </Typography>
                          </Grid>
                        </motion.div>
                      </Grid>
                    }
                  </Grid>
                )} */}
              </Grid>
              <Grid container spacing={3}>
                <BasicInput
                  label="Full Name*"
                  placeholder="Enter candidate's full name"
                  name="name"
                  type="text"
                  values={formik.values.name}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  touched={formik.touched.name}
                  errors={formik.errors.name}
                />
                <BasicInput
                  label="Email ID*"
                  placeholder="Enter candidate's email"
                  name="email"
                  type="email"
                  values={formik.values.email}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  touched={formik.touched.email}
                  errors={formik.errors.email}
                />
                <BasicInput
                  label="Contact Number*"
                  placeholder="Enter candidate's contact number"
                  name="contactNumber"
                  type="number"
                  values={formik.values.contactNumber}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  touched={formik.touched.contactNumber}
                  errors={formik.errors.contactNumber}
                />

                <Grid item xs={12} lg={4} md={6}>
                  <InputLabel className={classes2.label}>
                    Job Associated*
                  </InputLabel>

                  <Autocomplete
                    classes={classes2}
                    name="jobAssociated"
                    id="jobAssociated"
                    disableClearable
                    value={examinerList?.find((e) => e.id)}
                    options={jobList}
                    getOptionLabel={(option) => option.job_title}
                    handleChange={formik.handleChange}
                    onChange={(jobAssociated, value) =>
                      formik.setFieldValue("jobAssociated", value.id) &&
                      handleJobChange(value) &&
                      formik.handleChange
                    }
                    renderInput={(params) => (
                      <TextField
                        placeholder="Enter job associated*"
                        name="jobAssociated"
                        id="jobAssociated"
                        {...params}
                        variant="outlined"
                        error={Boolean(
                          formik.touched.jobAssociated &&
                            formik.errors.jobAssociated
                        )}
                        helperText={
                          formik.touched.jobAssociated &&
                          formik.errors.jobAssociated
                        }
                        touched={formik.touched.jobAssociated}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="normal"
                      />
                    )}
                  />
                </Grid>

                <BasicInput
                  label="Total Experience In Years*"
                  placeholder="Enter total experience"
                  name="totalExperience"
                  type="number"
                  values={formik.values.totalExperience}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  touched={formik.touched.totalExperience}
                  errors={formik.errors.totalExperience}
                />
                <Grid item xs={12} lg={4} md={6}>
                  <InputLabel className={classes2.label}>Examiner*</InputLabel>
                  <Autocomplete
                    classes={classes2}
                    disableClearable
                    value={examinerList?.find((e) => e.id)}
                    options={jobList ? examinerList : "Not defined"}
                    getOptionLabel={(option) =>
                      capitalize(option.full_name) || ""
                    }
                    onChange={(examinerList, value) =>
                      formik.setFieldValue("examinerList", value.id)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Enter examiner"
                        error={Boolean(
                          formik.touched.examinerList &&
                            formik.errors.examinerList
                        )}
                        helperText={
                          formik.touched.examinerList &&
                          formik.errors.examinerList
                        }
                        touched={formik.touched.examinerList}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="normal"
                      />
                    )}
                  />
                </Grid>
                <BasicInput
                  componentType="additionalInfo"
                  label="Additional Info"
                  placeholder="Additional Info"
                  name="additionalInfo"
                  type="textfield"
                  values={formik.values.additionalInfo}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  touched={formik.touched.additionalInfo}
                  errors={formik.errors.additionalInfo}
                />

                <Grid container item xs={12} spacing={3}>
                  <Grid item sx={3}>
                    <CustomButton
                      type="button"
                      Buttontype="cancel"
                      label="Cancel"
                    />
                  </Grid>
                  <Grid item sx={3}>
                    <CustomButton
                      type="submit"
                      Buttontype="save"
                      label="&nbsp;Save &nbsp;"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </FormikProvider>
        </Container>
      </motion.div>
    </AnimatePresence>
  );
}

export default withSnackbar(AddCandidate);
