import { useHistory, useEffect, fetchClient, apiUrl, React } from "allImport";
import { API, ENDPOINTS } from "api/apiService";
import { useStyles } from "style/Liststyle.js";
import { convertUTCDateToLocalDate } from "Common/CommonFunction";

import StorageManager from "Storage/StorageManager.js";
var AS = [];
var searchValue = "";
var examIDs = [];
var url = new URL(`${apiUrl.viewExams}`);
import TablePageLayout from "layout/TablePageLayout";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import { stat } from "fs";
import { useDispatch, useSelector } from "react-redux";
import Actions from "redux/actions/filterActions";
import { useState } from "react";
import TableActions from "redux/actions/tableActions";
import CommonTable from "components/CommonComponent/Filter/CommonTable/CommonTable";
const queryString = require("query-string");

export default function Examlist() {
  const [departments, setDepartments] = useState([]);
  const [filter, setFilter] = useState({
    page: 1,
    page_size: 10,
    search: "",
    question_type: 0,
    question_status: 0,
    experience_level: 0,
    department: 0,
  });

  const { dataArray } = useSelector((store) => store.filter);
  const { searchData } = useSelector((store) => store.search);
  const { reset } = useSelector((store) => store.table);

  const [options2, setoptions2] = useState("");
  const [pageColor, setPageColor] = React.useState(null);
  const queryBuilder = (url, options) => {
    const options1 = queryString.stringify({
      ...dataArray,
    });

    setoptions2(options1);

    console.log("zzzz", options1);

    if (options === undefined || !options) return url;

    return `${url}?${options.page ? `&page=${options.page}` : ""}${
      searchData ? `&search=${searchData}` : ""
    }&${options1}
  ${
    options.role !== "examiner"
      ? ""
      : options.examiner_dept !== ""
      ? `&department=${options.examiner_dept}`
      : ""
  }
  `;
  };

  const fetchDepartmentList = async () => {
    const resp = await API.get(
      `${ENDPOINTS.DEPARTMENT_LIST}?has_exam=1&page_size=1000`
    );
    if (resp.success) {
      const result = resp.data;
      const res = result.results;
      // setDeptlist(res);
      let temp = res.map((item) => ({
        value: item.id,
        label: item.name,
        id: item.id,
      }));
      temp.unshift({
        label: "Department",
        value: "department",
      });
      setDepartments(temp);
    }
  };
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  useEffect(() => {
    let isCanclled = false;
    const handleChange = async () => {
      await timeout(200);
      if (!isCanclled) {
        fetchRecord();
      }
    };
    handleChange();
    return () => {
      isCanclled = true;
    };
  }, [dataArray, filter, searchData, reset]);

  const fetchRecord = async () => {
    setLoader(true);
    const query = queryBuilder(ENDPOINTS.EXAM, filter);
    console.log("query", query);
    const resp = await API.get(query);

    console.log("ress", resp);
    if (resp.success) {
      let SUB_ADMIN_PERMISSION = localStorage.getItem("SUB_ADMIN_PERMISSION");
      const result = resp.data;
      console.log(result, "chedckThis");
      const count = result.count;
      const examData = result.results.map((item) => {
        console.log("iieeetme", item);
        return {
          0: item.id,
          1: item.exam_name,
          2: item.experience_level,
          3: item.status,
          4: item.department,
          5: convertUTCDateToLocalDate(
            new Date(item.created_at)
          ).toLocaleDateString("en-IN"),

          editAction: {
            editpath: `/${role}/exam/edit`,
            examLevel: item.experience_level,
            examName: item.exam_name,
            examID: item.id,
            examDept: item.department,
            allow: SUB_ADMIN_PERMISSION
              ? SUB_ADMIN_PERMISSION.includes("EDIT_EXAM")
              : true,
          },
          deleteAction: {
            actionID: item.id,
            deleteName: item.exam_name,
            tableType: "Exam",
            allow: SUB_ADMIN_PERMISSION
              ? SUB_ADMIN_PERMISSION.includes("DELETE_EXAM")
              : true,
          },
        };
      });

      dispatch({
        type: TableActions.SET_TABLE_ROW,
        data: examData,
        tableHeading: "Exams",
        profile: `/${role}/exam/question/select-question`,
        count: count,
      });
      dispatch({
        type: TableActions.SET_PAGINATION,
        data: { pages: result.pages, count: result.count },
      });
      setTotalPage(result.pages);
    }
  };

  useEffect(() => {
    const allowActionColumn =
      localStorage.getItem("ROLE") === "SUB-ADMIN" &&
      localStorage.getItem("SUB_ADMIN_PERMISSION") &&
      !localStorage.getItem("SUB_ADMIN_PERMISSION").includes("EDIT_EXAMINER") &&
      !localStorage.getItem("SUB_ADMIN_PERMISSION").includes("DELETE_EXAMINER");

    dispatch({
      type: TableActions.SET_TABLE_COLUMN_HEADING,
      data: [
        "ID",
        "Name",
        "Level",
        "Status",
        "Department",
        "Date Added",
        !allowActionColumn && "Action",
      ],
    });
  }, []);

  const history = useHistory();
  const [data, setAllData] = React.useState({});
  const [deptLists, setDeptlist] = React.useState([]);
  const [deptSelectionVal, setDept] = React.useState("");
  const [totalPage, setTotalPage] = React.useState("");
  const [level, setLevel] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [count, setCount] = React.useState("");
  const [examSearch, setExamSearch] = React.useState("");
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const examiner_dept = localStorage.getItem("EXAMINER_DEPT");
  const [page, setPage] = React.useState(1);
  const [loader, setLoader] = React.useState(false);
  const [topbarVisible, setTopBarVisible] = React.useState(true);

  const handleChangePage = (event, page) => {
    console.log(page, "cvbn");
    dispatch({
      type: TableActions.SET_TABLE_ROW,
      count: null,
    });
    setFilter({ ...filter, page });
    setPageColor(page);
  };

  useEffect(() => {
    setFilter({ ...filter, page: 1 });
    setPageColor(1);
  }, [searchData, dataArray]);

  function createData(ColA, ColB, ColC, ColD, ColE, ColF, ColG) {
    return { ColA, ColB, ColC, ColD, ColE, ColF, ColG };
  }

  const colmConversion = (arr) => {
    AS = [];
    for (var index = 0; index < arr.length; index++) {
      AS.push(
        createData(
          arr[index] && arr[index].id,
          arr[index].exam_name,
          arr[index] && arr[index].experience_level,
          arr[index] && arr[index].status,
          arr[index] && arr[index].department,
          arr[index] &&
            convertUTCDateToLocalDate(
              new Date(arr[index].created_at)
            ).toLocaleDateString("en-IN")
        )
      );
      examIDs.push(arr[index] && arr[index].id);
    }
    processDataList();
  };

  const handleChangeFilter = (e) => {
    setStatus("");
    setDept("");
    setLevel("");
    url.searchParams.delete("status");
    url.searchParams.delete("department");
    url.searchParams.delete("experience_level");
    setExamSearch(e.target.value);
    searchValue = e.target.value;
    url.searchParams.set("search", searchValue);
    if (role === "examiner") {
      url.searchParams.set("department", examiner_dept);
    }
    fetchClient.get(url).then((res) => {
      setCount(res && res.data && res.data.data && res.data.data.count);
      var examList = res && res.data && res.data.data && res.data.data.results;
      setTotalPage(res && res.data && res.data.data && res.data.data.pages);
      colmConversion(examList);
    });
  };

  const examFilterSelection = (e, type) => {
    url.searchParams.delete("search");
    setExamSearch("");
    if (type === "") {
    }
    if (type === "examStatus") {
      setStatus(e.target.value);

      if (e.target.value !== "") {
        url.searchParams.set("status", e.target.value);
      } else {
        url.searchParams.delete("status");
      }
    }
    if (type === "departmentSelect") {
      setDept(e.target.value);

      if (e.target.value !== "") {
        url.searchParams.set("department", e.target.value);
      } else {
        url.searchParams.delete("department");
      }
    }
    if (type === "levelSelect") {
      setLevel(e.target.value);

      if (e.target.value !== "") {
        url.searchParams.set("experience_level", e.target.value);
      } else {
        url.searchParams.delete("experience_level");
      }
    }
    if (role === "examiner") {
      url.searchParams.set("department", examiner_dept);
    }

    fetchClient.get(url).then((res) => {
      var examList = res && res.data && res.data.data && res.data.data.results;
      setCount(res && res.data && res.data.data && res.data.data.count);
      setTotalPage(res && res.data && res.data.data && res.data.data.pages);
      colmConversion(examList);
    });
  };

  const getPermissionsForSubAdmin = () => {
    let permission = {};
  };

  useEffect(() => {
    let url = new URL(`${apiUrl.viewExams}`);
    if (role === "examiner") {
      url.searchParams.set("department", examiner_dept);
    }

    fetchClient.get(url).then((res) => {
      setCount(res && res.data && res.data.data && res.data.data.count);
      setTotalPage(res && res.data && res.data.data && res.data.data.pages);
      const examsArray =
        res && res.data && res.data.data && res.data.data.results;
      colmConversion(examsArray);
      setLoader(true);
    });

    fetchClient.get(`${apiUrl.deptList}?page_size=1000`).then((res) => {
      setDeptlist(res && res.data && res.data.data && res.data.data.results);
    });
    fetchDepartmentList();
  }, []);

  const processDataList = () => {
    const permissions = getPermissionsForSubAdmin();
    const data = {
      columnnames: {
        col1: "ID",
        col2: "Name",
        col3: "Level",
        col4: "Status",
        col5: "Department",
        col6: "Date Added ",
        col8: "Action",
      },
      TableType: "Exam",
      DeptData: deptLists,
      ExamIDs: examIDs,
      permissions: permissions,
      List: AS,
      ListKey: Object.keys(AS),
      EditPath: `/${role}/exam/edit`,
      profile: `/${role}/exam/question/select-question`,
    };

    setAllData(data);
  };

  const EXAM_LEVEL = [
    { label: "Exam Level", value: "experience_level" },
    { label: "Senior", value: "senior" },
    { label: "Associate", value: "associate" },
    { label: "Junior", value: "junior" },
    { label: "Internship", value: "internship" },
  ];

  const EXAM_STATUS = [
    { label: "Status", value: "status" },
    { label: "Publish", value: "publish" },
    { label: "Unpublish", value: "unpublish" },
    { label: "Draft", value: "draft" },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: Actions.SET_FILTER,
      count: 3,
      data: [EXAM_LEVEL, EXAM_STATUS, departments],
    });
  }, [level, status, departments]);
  let SUB_ADMIN_PERMISSION = localStorage.getItem("SUB_ADMIN_PERMISSION");

  return (
    <>
      <HeaderWithBreadcrumb heading="Exams" />

      <TablePageLayout
        heading="Exams"
        loader={loader}
        totalPage={totalPage}
        ButtonText="Create Exam"
        buttonActionPath={`/${role}/exam/create`}
        page={page}
        handleChangePage={handleChangePage}
        count={count}
        allowCreate={
          SUB_ADMIN_PERMISSION
            ? SUB_ADMIN_PERMISSION.includes("CREATE_EXAM")
            : true
        }
        handleChangeFilter={(e) => handleChangeFilter(e)}
        candidateFilter={{
          type: "examlist",
          item: [level, status, deptLists],
          examlevel: { level },
          examFilterSelection: (e) => examFilterSelection(e, "levelSelect"),
          examstatus: { status },
          examStatusSelection: (e) => examFilterSelection(e, "examStatus"),
          deptSelectionVal: { deptSelectionVal },
          deptList: { deptLists },
          examDeptSelection: (e) => examFilterSelection(e, "departmentSelect"),
        }}
      >
        <CommonTable
          Count={totalPage}
          handleChangePage={handleChangePage}
          pageColor={pageColor}
        />
      </TablePageLayout>
    </>
  );
}
