let endPointUrl = ""; //  default set if .env file not set on your local project.

let endPointUrL = process.env.REACT_APP_BACKEND_API_URL;

console.log("enl", process.env.NODE_ENV);

if (endPointUrL) {
  endPointUrl = endPointUrL;
} else {
  endPointUrl = "https://api.dev.nhp.foxlabs.in/api/v1";
}
export const imgBaseUrl = "";
export const imgBaseUrlDev = "";
export const access_token = "";

export const baseUrls = {
  ums: `${endPointUrl}`,
};

export const apiUrl = {
  resendLinkSubAdmin:`${baseUrls.ums}/admin/subadmin-resend/ `,
  resumeCandidate: `${baseUrls.ums}/candidate/resume/`,
  filterCategory: `${baseUrls.ums}/question/`,
  createCategory: `${baseUrls.ums}/question/category`,
  candidatelogin: `${baseUrls.ums}/candidate/login`,
  login: `${baseUrls.ums}/login`,
  logout: `${baseUrls.ums}/logout`,
  resetPassword: `${baseUrls.ums}/reset-password`,
  forgotPassword: `${baseUrls.ums}/forgot-password`,
  viewExaminer: `${baseUrls.ums}/examiner/`,
  searchExaminer: `${baseUrls.ums}/examiner/search`,
  deleteExaminer: `${baseUrls.ums}/examiner`,
  updateExaminer: `${baseUrls.ums}/examiner`,
  UndoExaminer: `${baseUrls.ums}/examiner/`,
  examinerProfile: `${baseUrls.ums}/examiner/profile`,
  deptList: `${baseUrls.ums}/department/`,
  inviteExaminer: `${baseUrls.ums}/admin/invite/`,
  viewExams: `${baseUrls.ums}/exam/`,
  deleteExam: `${baseUrls.ums}/exam/`,
  UndoExam: `${baseUrls.ums}/exam/`,
  updateExam: `${baseUrls.ums}/exam/`,
  publishExam: `${baseUrls.ums}/exam/`,
  removeExamQuestion: `${baseUrls.ums}/exam/`,
  examList: `${baseUrls.ums}/exam/`,
  viewCandidates: `${baseUrls.ums}/candidate/`,
  examinerList: `${baseUrls.ums}/examiner/`,
  viewJobs: `${baseUrls.ums}/job/`,
  viewDepartments: `${baseUrls.ums}/department/`,
  UndoDepartment: `${baseUrls.ums}/department/`,
  candidateProfile: `${baseUrls.ums}/candidate/`,
  addCandidate: `${baseUrls.ums}/candidate/`,
  editCandidate: `${baseUrls.ums}/candidate/`,
  deleteCandidate: `${baseUrls.ums}/candidate/`,
  jobList: `${baseUrls.ums}/job/`,
  jobProfile: `${baseUrls.ums}/job/`,
  addJob: `${baseUrls.ums}/job/`,
  editJob: `${baseUrls.ums}/job/`,
  UndoJob: `${baseUrls.ums}/job/`,
  deleteJob: `${baseUrls.ums}/job/`,
  unpublishJob: `${baseUrls.ums}/job/`,
  publishJob: `${baseUrls.ums}/job/`,
  draftJob: `${baseUrls.ums}/job/`,
  examLink: `${baseUrls.ums}/candidate/exam/`,
  adminProfile: `${baseUrls.ums}/admin/profile/`,
  subadminProfile: `${baseUrls.ums}/subadmin/`,
  tag: `${baseUrls.ums}/tag/`,
  editHr: `${baseUrls.ums}/`,
  changePassword: `${baseUrls.ums}/change-password`,
  AllExamStatus: `${baseUrls.ums}/exam/exam-status/`,
  ExamStatus: `${baseUrls.ums}/candidate/exam-status/`,
  UndoCandidate: `${baseUrls.ums}/candidate/`,
  viewSubadmins: `${baseUrls.ums}/subadmin/`,
  permission: `${baseUrls.ums}/permission/`,
  viewQuestions: `${baseUrls.ums}/question/`,
  getQuestion: `${baseUrls.ums}/question/`,
  deleteQuestion: `${baseUrls.ums}/question/`,
  createQuestion: `${baseUrls.ums}/question/`,
  createOption: `${baseUrls.ums}/question/`,
  addTag: `${baseUrls.ums}/question/`,
  createExam: `${baseUrls.ums}/exam/`,
  mapQuestion: `${baseUrls.ums}/exam/`,
  undoQuestion: `${baseUrls.ums}/question/undo/`,
  getExamQuestion: `${baseUrls.ums}/exam/`,
  viewSubmittedExams: `${baseUrls.ums}/candidate/result/`,
  ExamCandidatelist: `${baseUrls.ums}/candidate/exam/`,
  CandidateResult: `${baseUrls.ums}/candidate/`,
  AnswerScript: `${baseUrls.ums}/candidate/`,
  UpdateExamStatus: `${baseUrls.ums}/candidate/exam/`,
  getCandidateExamId: `${baseUrls.ums}/candidate/`,
  getNotifications: `${baseUrls.ums}/notifications/logs`,
  getHRStatisticValues: `${baseUrls.ums}/dashboard/values/`,
  getExaminerStatisticValues: `${baseUrls.ums}/dashboard/examiner/values/`,
  getDashboardSummary: `${baseUrls.ums}/dashboard/summary/`,
  deleteTag: `${baseUrls.ums}/qtag/`,
  addSubadmin: `${baseUrls.ums}/admin/subadmin/`,
  editSubadmin: `${baseUrls.ums}/subadmin/`,
  viewSubAdmin: `${baseUrls.ums}/subadmin`,
  deleteSubAdmin: `${baseUrls.ums}/subadmin`,

  undoSubAdmin: `${baseUrls.ums}/subadmin/`,
  subAdminActivity: `${baseUrls.ums}/subadmin/`,
  resendLink: `${baseUrls.ums}/candidate/resend-link/`,
  notificationMarkAsRead: `${baseUrls.ums}/notifications/mark-as-read`,
  ckEditorImageUpload: `${baseUrls.ums}/question/image-upload`,
  bulkInvite: `${baseUrls.ums}/candidate/csv/`
};
export default apiUrl;

export const messages = {
  login: {
    success: "You have successfully logged in!",
    error: "",
    confirmation: "",
    acknowledgement: "",
  },

  // commented for future use
  // generic: {
  // 	success: 'Thank you for contacting us.',
  // 	error: 'Something went wrong. Please try again later.',
  // 	confirmation: '',
  // 	acknowledgement: '',
  // 	generic: '',
  // 	request_timeout: 'Request timeout',
  // 	connection_timeout: 'Connection timeout',
  // 	httpErrors: {
  // 		400: 'Oops! Something went wrong. We are working on fixing the problem.',
  // 		500: 'Oops! Something went wrong. We are working on fixing the problem.',
  // 		404: 'Oops! Something went wrong. We are working on fixing the problem.',
  // 		403: "You don't have permission to access this resource",
  // 		409: 'Can not save same record',
  // 		401: 'You are not authorized',
  // 		503: 'Service unavailable',
  // 		801: 'No data found',
  // 		803: 'Incorrect password',
  // 		702: 'Record already exists',
  // 		701: 'Missing validation',
  // 	},
  // 	networkError:
  // 		'Oops! Something went wrong. Please check your network connection and try again.',
  // },
};
