import {
  CssBaseline,
  Box,
  Container,
  Grid,
  makeStyles,
  Paper,
  Button,
  Topbar,
  Sidebar,

  React,
} from "allImport";
import "style/style.scss";
import { commonStyle } from "commonStyle.js";
const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),
  imagestyle: {
    height: "100%",
    width: "100%",
    padding: "5px",
  },
  profile_heading: {
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "50px",
    letterSpacing: "0.25px",
    color: "#18273B",
  },
}));
export default function CandidateGallery() {
  const role = localStorage.getItem("ROLE").toLowerCase();
  const classes = useStyles();
  return (
    <main className={classes.content}>
      
      <Container maxWidth="xl" className={classes.container}>
        <Grid container xs={12} style={{ paddingBottom: "10px" }}>
          <Grid
            item
            xl={6}
            lg={6}
            md={7}
            sm={12}
            align="left"
            justify="left"
            style={{ display: "flex" }}
          >
            <div className={classes.profile_heading}>Peter Underwood</div>
            <img
              className={classes.editIconProfile}
              src="/assets/Icons/editIcon.svg"
              alt=""
              style={{ marginLeft: "19px", marginRight: "28px" }}
            />
            <img
              className={classes.deleteIconProfile}
              src="/assets/Icons/deleteIcon.svg"
              alt=""
            />
          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            md={5}
            sm={12}
            className={classes.actionbutton}
          >
            <div
              className="button_invite_examiner"
              style={{ paddingRight: "80px" }}
            >
              <Button
                onClick={() =>
                  (window.location.href = `/${role}/candidate/profile`)
                }
                className={classes.outlinedButton}
                variant="contained"
                color="primary"
              >
                Back To Profile
              </Button>
            </div>
            <div className="button_invite_examiner">
              <Button
                className={classes.filledButton}
                variant="contained"
                color="primary"
              >
                Disqualify
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={12} sm={6} align="left" justify="left">
            <div className={classes.page_subheading}>
              <span className={classes.goBackURL}>Candidates Lobby</span>{" "}
              <span className="spc"></span> &gt; <span className="spc"></span>
              Candidate Profile <span className="spc"></span> &gt;{" "}
              <span className="spc"></span>Exam Gallery
            </div>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item lg={12} xl={12} md={12} sm={12}>
            <Paper className={classes.paper} style={{ height: "640px" }}>
              <Grid container xs={12}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <img
                    src="/assets/Images/gallery_image.jpg"
                    alt=""
                    className={classes.imagestyle}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <img
                    src="/assets/Images/gallery_image.jpg"
                    alt=""
                    className={classes.imagestyle}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <img
                    src="/assets/Images/gallery_image.jpg"
                    alt=""
                    className={classes.imagestyle}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <img
                    src="/assets/Images/gallery_image.jpg"
                    alt=""
                    className={classes.imagestyle}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <img
                    src="/assets/Images/gallery_image.jpg"
                    alt=""
                    className={classes.imagestyle}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <img
                    src="/assets/Images/gallery_image.jpg"
                    alt=""
                    className={classes.imagestyle}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <img
                    src="/assets/Images/gallery_image.jpg"
                    alt=""
                    className={classes.imagestyle}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <img
                    src="/assets/Images/gallery_image.jpg"
                    alt=""
                    className={classes.imagestyle}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <img
                    src="/assets/Images/gallery_image.jpg"
                    alt=""
                    className={classes.imagestyle}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        
      </Container>
    </main>
  );
}
