import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useHistory } from "allImport";

const useStyles = makeStyles((theme) => ({
  filledButton: {
    height: "2.4rem",
    padding: " 0 2rem",
    fontSize: "0.7rem",
  },
}));

const CustomButton = ({
  type,
  label,
  Buttontype,
  isSubmitting,
  handleButtonClick,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const handleCancel = () => {
    history.goBack();
  };
  return (
    <Button
      type={type}
      className={classes.filledButton}
      variant={Buttontype === "save" ? "contained" : "outlined"}
      color="primary"
      onClick={Buttontype === "cancel" ? handleCancel : handleButtonClick}
      disabled={isSubmitting}
    >
      {label}
    </Button>
  );
};

export default CustomButton;
