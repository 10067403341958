import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Popover,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import DividerIcon from "./assets/divider.svg";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SubHeading from "./SubHeading";
import SearchAppBar from "components/CommonComponent/SearchAppBar";
import Filter from "components/CommonComponent/Filter/Filter";

import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "1rem",
    lineHeight: "32px",
    color: "#383838",
    ["@media (max-width:1030px)"]: {
      // eslint-disable-line no-useless-computed-key
      fontSize: "16px"
    }
  },
  icons: {
    width: "20px",
    height: "20px"
  },
  divider: {
    height: "26px"
  },
  iconButtonGrid: {
    display: "block",
    ["@media (max-width:1030px)"]: {
      // eslint-disable-line no-useless-computed-key
      display: "none"
    }
  },
  IconButton: {
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#FFF"
    }
  },
  filledButton: {
    height: "2.3rem",
    minWidth: "7rem",
    fontSize: "0.7rem"
  },
  fullWidthDivider: {
    margin: "16px 0px",
    height: "1px"
    // transition: "none",
  },
  topbarButtonWrapper: {
    display: "block",
    ["@media (max-width:1030px)"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block"
    }
  },
  mobileAddButton: {
    display: "none",
    ["@media (max-width:1030px)"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      // position: "absolute",
      // bottom: "10px",
      // right: "30px",
      backgroundColor: "#D9001D",
      textDecoration: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // fontSize: "27px",
      fontWeight: "bold",
      // zIndex: "5",
      borderRadius: "4px"
      // height: "50px",
      // width: "50px",
      // color: "#fff",
    }
  },
  subheadingWrapper: {
    display: "flex",
    ["@media (max-width:1030px)"]: {
      // eslint-disable-line no-useless-computed-key
      display: "none"
    }
  }
}));

const TableTopBar = ({
  heading,
  allowCreate,
  subheading,
  buttonText,
  buttonActionPath,
  buttonFunction,
  handleChangeFilter,
  handleChangeFilterisHired,
  candidateFilter,
  searchFunction,
  csvWrapper
}) => {
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const classes = useStyles();
  const { count } = useSelector((store) => store.table);

  console.log("counntttt", count);
  const { candidateStatus } = useSelector(
    (store) => store.candidateStatusReducer
  );
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <>
      <Grid container item xs={6} sm={5} md={7} lg={8} alignItems="center">
        <Grid item xs={10} md="auto">
          {count || count === 0 ? (
            <Typography className={classes.heading}>
              {count + " " + heading}
            </Typography>
          ) : (
            <Skeleton style={{ width: "100px" }} variant="text" />
          )}
        </Grid>
        {heading === "Candidates" && (
          <Grid sx="auto" className={classes.iconButtonGrid}>
            <IconButton className={classes.IconButton} disableRipple>
              <img
                className={[classes.divider]}
                src={DividerIcon}
                alt="divider"
              />
            </IconButton>
          </Grid>
        )}

        <Grid
          className={classes.subheadingWrapper}
          container
          item
          xs={12}
          md
          sm={6}
          lg={6}
          alignItems="center"
        >
          {subheading && (
            <>
              <SubHeading
                count={count}
                handleChangeFilterisHired={handleChangeFilterisHired}
                onCLick={() => {
                  alert(1);
                }}
                title="Qualified"
                data={subheading.hired}
              />
              <SubHeading
                count={count}
                handleChangeFilterisHired={handleChangeFilterisHired}
                title="Disqualified"
                data={subheading.rejected}
              />
              {candidateStatus === "1" || candidateStatus === "0" ? (
                <SubHeading
                  count={count}
                  handleChangeFilterisHired={handleChangeFilterisHired}
                  title="All Candidate"
                  data={subheading.all}
                />
              ) : null}
            </>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={6}
        sm={7}
        md={5}
        lg={4}
        justifyContent="flex-end"
        alignItems="center"
      >
        {matches && (
          <Grid
            item
            xs={4}
            sm={1}
            md={2}
            lg={1}
            container
            justifyContent="center"
          >
            {handleChangeFilter === "null" ? null : <SearchAppBar />}
          </Grid>
        )}
        {candidateFilter.type === "category" ||
        candidateFilter.type === "department" ||
        candidateFilter.type === "categoryQuestion" ? null : (
          <Grid
            item
            xs={4}
            sm={2}
            md={2}
            lg={2}
            container
            justifyContent="flex-end"
          >
            <Button
              className={classes.IconButton}
              disableRipple
              style={{ padding: "4px 2px" }}
            >
              <Filter />
            </Button>
          </Grid>
        )}
        {matches && allowCreate ? (
          <Grid
            xs={2}
            sm={1}
            md={2}
            lg={1}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <IconButton
              className={[classes.IconButton, classes.topbarButtonWrapper]}
              disableRipple
            >
              <img
                className={[classes.divider]}
                src={DividerIcon}
                alt="divider"
              />
            </IconButton>
          </Grid>
        ) : undefined}

        {buttonText && (
          <>
            <Grid
              item
              xs={8}
              sm="auto"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {role === "examiner" && buttonText === "Add Candidate"
                ? null
                : allowCreate &&
                  (!matches ? (
                    <Link
                      onClick={(event) =>
                        buttonFunction && event.preventDefault()
                      }
                      to={buttonActionPath}
                      waves="light"
                      className={classes.mobileAddButton}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={buttonFunction ? buttonFunction : null}
                        style={{ fontSize: "12px" }}
                      >
                        Add
                      </Button>
                    </Link>
                  ) : (
                    <Link
                      style={{ textDecoration: "none" }}
                      onClick={(event) =>
                        buttonFunction && event.preventDefault()
                      }
                      to={buttonActionPath}
                      className={classes.topbarButtonWrapper}
                    >
                      <Button
                        onClick={buttonFunction ? buttonFunction : null}
                        className={classes.filledButton}
                        variant="contained"
                        color="primary"
                      >
                        {buttonText}
                      </Button>
                    </Link>
                  ))}
            </Grid>
          </>
        )}
        {csvWrapper && csvWrapper()}
      </Grid>

      {!matches && (
        <Grid container justifyContent="flex-end">
          {handleChangeFilter === "null" ? null : <SearchAppBar />}
        </Grid>
      )}

      {count || count === 0 ? (
        <Grid item xs={12}>
          <Divider className={classes.fullWidthDivider} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <LinearProgress
            style={{ color: "green", backgroundColor: "#E5E5E5" }}
            className={classes.fullWidthDivider}
          />
        </Grid>
      )}
    </>
  );
};

export default TableTopBar;
