import { makeStyles, withStyles, InputBase } from "allImport";
import { commonStyle } from "commonStyle.js";
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),

  goBackURL: {
    cursor: "pointer",
  },
  paper_heading: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "20px",
    lineHeight: "32px",
    letterSpacing: "0.1px",
    color: "#000000",
    fontFamily: "Mulish",
    paddingTop: "32px",
    paddingLeft: "32px",
   
  },
  select_exam_container: {
    paddingLeft: "32px",
    marginTop: "-148px",
  },
  select_style: {
    marginTop: "10px",
    height: "47px",
    width: "307px",
    fontSize:"14px", 
   fontFamily : 'Mulish',
   fontStyle : 'normal',
   fontWeight : 'normal',
  
   lineHeight : '22px',
   letterSpacing: '0.25px',
   
  },
  divider_position:
  {
    marginLeft:'24px',
    marginRight:'33px',
    marginTop:'10px'
  },
  actionbutton: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  },
  examiner_box: {
    background: "#FFFFFF",
    border: "1px solid #BBBBBB",
    boxSizing: "border-box",
    borderRadius: "4px",
    width: "90%",
    height: "46px",
  },

  examiner_detail_box: {
    marginTop: "10px",
  },

  exam_detail_label_font: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: "#444444",
    fontFamily: "Mulish",
  },

  examiner_detail_label: {
    height: "20px",
  },

  mandatory: {
    color: "#D9001D",
  },
  copyicon:{
    marginLeft:'18px',
    marginBottom:'40px'
  },
 
}));

export { BootstrapInput, useStyles };
