import Actions from "../actions/permissionActions";

let initialState = {
  data: {},
  SUB_ADMIN_PERMISSION: null,
};

const permissionReducer = (state = initialState, action) => {
  console.log("zig", action.data);
  switch (action.type) {
    case Actions.SET_PERMISSIONS:
      return {
        ...state,
        data: action.data,
      };

    case Actions.SET_SUB_ADMIN_PERMISSION:
      return {
        state,
        SUB_ADMIN_PERMISSION: action.data.SUB_ADMIN_PERMISSION,
      };

    default:
      return state;
  }
};

export default permissionReducer;
