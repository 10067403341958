import * as Yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const ExaminerValidation = Yup.object().shape({
  name: Yup.string()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
    .required("Required")
    .min(2, "Too Short!")
    .max(50, "Too Long!"),

  email: Yup.string().email("Invalid email").required("Required"),
  designation: Yup.string()
    .required("Required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),

  department: Yup.string().required("Required"),
  contactNumber: Yup.string()
    .matches(phoneRegExp, "Contact number is not valid")
    .nullable("Required")
    .required("Required")
    .max(10, "Contact number must be 10 digits")
    .min(10, "Contact number must be 10 digits"),
});

export default ExaminerValidation;
