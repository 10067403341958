import {
  clsx,
  makeStyles,
  Paper,
  React,
  useEffect,
  useState,
  Link,
  useHistory,
  apiUrl,
  fetchPost,
  Zoom,
  fetchClient,
} from "allImport";
import { useRef } from "react";

import { commonStyle } from "commonStyle";

import { withSnackbar } from "notistack";

import { convertUTCDateToLocalDate } from "Common/CommonFunction";

import { Box, Step, Stepper, StepLabel } from "@material-ui/core";

import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import { ChevronRightRounded } from "@material-ui/icons";
import { Divider } from "@material-ui/core";
const steps = [
  "Select master blaster campaign settings",

  "Create an ad group",

  "Create an ad",
];

const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),

  root: {
    "& .MuiStepIcon-active": { color: "black" },

    "& .MuiStepIcon-completed": { color: "black" },

    height: 120,
  },

  container: {
    padding: "32px",
  },

  topContainer: {
    display: "flex",

    justifyContent: "space-between",
  },

  heading: {
    fontFamily: "Mulish",

    fontSize: "20px",

    fontStyle: "normal",

    fontWeight: "600",

    lineHeight: "32px",

    letterSpacing: "0.10000000149011612px",

    color: "#000",
  },

  resendLinkButton: {
    height: 23,

    width: 107,

    display: "flex",

    justifyContent: "center",

    alignItems: "center",

    fontFamily: "Mulish",

    fontsize: "12px",

    fontStyle: "normal",

    fontWeight: "700",

    lineHeight: "20px",

    letterSpacing: "0.15000000596046448px",

    color: "#FFFFFF",

    backgroundColor: "#D9001D",

    borderRadius: "4px",

    cursor: "pointer",
  },

  timelineContainer: {
    width: "100%",

    minWidth: 650,
  },

  stepperContainer: {
    width: "100%",

    paddingRight: 195,

    boxSizing: "border-box",

    marginTop: 20,
  },

  stepper: {
    display: "flex",

    height: "4px",

    width: "100%",

    justifyContent: "space-between",

    backgroundColor: "#0000004D",

    position: "relative",
  },

  stepperProgress: {
    position: "absolute",

    height: "100%",

    width: "33.33%",

    backgroundColor: "#000000",
  },

  stepContainer: {
    height: 16,

    width: 16,

    borderRadius: "50%",

    position: "relative",

    top: "-6px",

    backgroundColor: "#ffffff",
  },

  step: {
    height: 16,

    width: 16,

    borderRadius: "50%",
  },

  stepActive: {
    backgroundColor: "#000000",
  },

  stepInactive: {
    backgroundColor: "#0000004D",
  },

  timelineStatusContainer: {
    display: "flex",

    justifyContent: "space-between",

    width: "100%",

    paddingRight: 20,
  },

  timelineStatus: {
    marginRight: 10,

    width: 130,
  },

  statusInactive: {
    color: "#0000004D",
  },

  timelineStatusName: {
    fontFamily: "Open Sans",

    fontSize: "20px",

    fontStyle: "normal",

    fontWeight: "700",

    lineHeight: "20px",

    letterSpacing: "0.15000000596046448px",

    marginTop: 26,

    textTransform: "lowercase",

    textTransform: "capitalize",
  },

  timelineStatusDate: {
    fontFamily: "Mulish",

    fontSize: "14px",

    fontStyle: "normal",

    fontWeight: "400",

    lineHeight: "20px",

    letterSpacing: "0px",

    marginTop: 50,
  },

  buttonContainer: {
    width: "100%",

    display: "flex",

    justifyContent: "center",

    marginTop: 84,
  },

  viewResultButton: {
    border: "1px solid #D9001D",

    borderRadius: 4,

    width: 208,

    height: 45,

    fontFamily: "Mulish",

    fontSize: "18px",

    fontStyle: "normal",

    fontWeight: "700",

    lineHeight: "20px",

    letterSpacing: "0.15000000596046448px",

    color: "#D9001D",

    display: "flex",

    justifyContent: "center",

    alignItems: "center",

    cursor: "pointer",
  },

  green: {
    color: "#29CB1B",
  },

  red: {
    color: "#D9001D",
  },

  copyExamLink: {
    position: "absolue",

    textDecoration: "underline",

    cursor: "pointer",

    color: "red",

    height: 40,
  },
  arrow: {
    width: 34,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    borderRadius: 40,
    border: "2px solid #EFEFEF",
    cursor: "pointer",
  },
}));

const ExamTimeLine = (props) => {
  useEffect(() => {
    const level = Object.keys(props.data)[0];

    const data = props.data[level];

    setExamLevel(level);

    initializeData(data);

    // fetchClient.get(`${apiUrl.AllExamStatus}`).then((res) => {
    //   console.log("res values-----", res);
    // });
  }, []);

  console.log("props-----------x", props);

  let candidateExamUrl = process.env.REACT_APP_CANDIDATE_URL
    ? process.env.REACT_APP_CANDIDATE_URL
    : "https://candidate.dev.nhp.foxlabs.in";

  const classes = useStyles();

  const [stepperProgress, setStepperProgress] = useState(0);

  const [activeSteps, setActiveSteps] = useState(0);

  const [examLevel, setExamLevel] = useState("L1");

  const [data, setData] = useState({ isSet: false });

  const [resendLinkText, setResendLinkText] = useState("Resend Link");

  const history = useHistory();

  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;

  const [step, setStep] = useState([]);
useEffect(()=>{
  console.log("setppp",step)
},[step])
  const SendExamLink = () => {
    console.log("9----------------------");
    console.log("link status --------", activeSteps);
    step.map((step) => {
      if (step.status === "LINK_EXPIRED") {
        const data1 = {
          candidate: props.candidateID,
          exam: data.ExamID,
          exam_link:
            candidateExamUrl +
            `/candidate/examlink/` +
            data.ExamID +
            "/" +
            props.candidateID,
        };
        fetchPost.post(`${apiUrl.resendLink}`, data1);
      } else {
        setResendLinkText("Sending...");

        const data1 = {
          candidate: props.candidateID,

          exam: data.ExamID,

          exam_link: data.examLinkSent.examLink,

          // login_link: candidateExamUrl + `/candidate/login/`,
        };

        console.log("this is data1 ", data1);
        console.log("this is the data to link", data.examLinkSent);

        fetchPost

          .post(`${apiUrl.resendLink}`, data1)

          .then((res) => {
            props.enqueueSnackbar(`Exam link has been resent`, {
              variant: res.data.success ? "success" : "error",

              anchorOrigin: {
                vertical: "top",

                horizontal: "right",
              },

              TransitionComponent: Zoom,

              autoHideDuration: 3000,
            });
          })

          .catch(() => {
            props.enqueueSnackbar("Failed to send email", {
              variant: "error",

              anchorOrigin: {
                vertical: "top",

                horizontal: "right",
              },

              TransitionComponent: Zoom,

              autoHideDuration: 5000,

              resumeHideDuration: 2000,
            });
          })

          .finally(() => {
            setResendLinkText("Resend Link");
          });
      }
    });
  };

  console.log("data---------", data);

  const initializeStepper = (data) => {
    if (data.length === 0) {
      setStepperProgress(0);
    } else if (data.length === 3 && data[2].status === "DISQUALIFY") {
      setStepperProgress(((data.length - 1) / 2) * 100);
    } else {
      setStepperProgress(((data.length - 1) / 3) * 100);
    }

    setActiveSteps(data.length);
  };

  const initializeData = (data) => {
    console.log("HEllo this is a ", data);

    console.log(
      "hello I am ",

      data.map((data) => data.status)
    );

    setStep(data);

    initializeStepper(data);

    const timeLineData = {
      isSet: true,

      ExamID: data[0].exam_id,

      AttemptDate: data[0].created_at,

      examLinkSent: {
        linkSent: data.length < 3,

        examLink: data[0].exam_link,

        time:
          data.length >= 1
            ? `${convertUTCDateToLocalDate(new Date(data[0].created_at))
                .toString()

                .split(" ")

                .slice(0, 5)

                .join(" ")

                .toLocaleString()}`
            : "",
      },

      examAttempted: {
        time:
          data.length >= 2
            ? `${convertUTCDateToLocalDate(new Date(data[1].created_at))
                .toString()

                .split(" ")

                .slice(0, 5)

                .join(" ")

                .toLocaleString()}`
            : "",
      },

      examSubmitted: {
        text:
          data.length >= 3
            ? data[2].status === "DISQUALIFY"
              ? "Disqualified"
              : "Exam Submitted"
            : "Exam Submitted",

        time:
          data.length >= 3
            ? `${convertUTCDateToLocalDate(new Date(data[2].created_at))
                .toString()

                .split(" ")

                .slice(0, 5)

                .join(" ")

                .toLocaleString()}`
            : "",
      },

      evaluation: {
        text: data.length >= 4 ? `${data[3].status}` : "To be evaluated",

        time:
          data.length >= 4
            ? `${convertUTCDateToLocalDate(new Date(data[3].created_at))
                .toString()

                .split(" ")

                .slice(0, 5)

                .join(" ")

                .toLocaleString()}`
            : "",
      },
    };

    setData(timeLineData);
  };

  function tConvert(time) {
    // Check correct time format and split into components

    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct

      time = time.slice(1); // Remove full string match value

      time[5] = +time[0] < 12 ? " AM " : " PM "; // Set AM/PM

      time[0] = +time[0] % 12 || 12; // Adjust hours
    }

    return time.join("");
  }

  const viewResult = (rt) => {
    console.log(props.candidateID, data.ExamID, data.AttemptDate, "Monmster");

    // console.log("fff", rt);

    history.replace({
      pathname: `/${role}/submitted-exam/result`,

      state: {
        ExamCandidateID: props.candidateID,

        ExamIDr: data.ExamID,

        AttemptDate: data.AttemptDate,
      },
    });
  };

  const copyExamLink = () => {
    console.log("data is only data", data);

    navigator.clipboard.writeText(data.examLinkSent.examLink);

    props.enqueueSnackbar(`Exam Link Copied`, {
      variant: "success",

      anchorOrigin: {
        vertical: "top",

        horizontal: "right",
      },

      TransitionComponent: Zoom,

      autoHideDuration: 3000,
    });
  };

  const scroll = (scrollOffset) => {
    // ref.current.scrollLeft += scrollOffset;
    document.getElementById("container").scrollLeft += scrollOffset;
  };

  {
    console.log("props.....is mine", props);
  }

  return (
    <Paper className={clsx(classes.paper, classes.container)}>
      {data.isSet ? (
        <>
          <div className={classes.topContainer}>
            <div className={classes.heading}>{examLevel} Exam Timeline</div>

            {/* data.examLinkSent.linkSent */}
            {console.log("TESTING", step[0])}
            {step[0].status === "LINK_SENT" ||
            step[0].status === "LINK_EXPIRED" ? (
              <div
                onClick={() => SendExamLink()}
                className={classes.resendLinkButton}
              >
                {resendLinkText}
              </div>
            ) : null}

            {/* (   <div
                          className={classes.copyExamLink}
                          onClick={() => copyExamLink()}
                        >
                          Copy Link
                        </div>) */}
          </div>
          {/* <div style={{ display: "flex", justifyContent: "flex-end",marginBottom:20,marginTop:5 }}>
            <button className={classes.arrow} onClick={() => scroll(-500)}>
              <ChevronLeft />
            </button>
            <button
              className={classes.arrow}
              onClick={() => scroll(+500)}
            >
              <ChevronRightRounded />
            </button>
          </div> */}

          <Divider className={classes.divider_position} />
          <div
            id="container"
            style={{ overflowX: "scroll", marginTop: 40, width: "100%" }}
          >
            <Box sx={{ width: "120%", height: 175, userSelect: "none" }}>
              {console.log("this is step", step.length)}

              <Stepper
                className={classes.root}
                activeStep={step.length}
                alternativeLabel
              >
                {step.map((data) => (
                  <Step style={{ maxWidth: "60%" }} key={data.id}>
                    <StepLabel>
                      {data.status.split("_").join(" ")}

                      <br />

                      {convertUTCDateToLocalDate(new Date(data.created_at))
                        .toString()

                        .split(" ")

                        .slice(0, 4)

                        .join(" ")

                        .toLocaleString()}

                      <br />
                      {console.log(
                        "dateded---",
                        data.created_at,
                        typeof data.created_at
                      )}
                      {tConvert(
                        convertUTCDateToLocalDate(new Date(data.created_at))
                          .toString()

                          .split(" ")

                          .slice(4, 5)

                          .join(" ")

                          .toLocaleString()
                      )}

                      {console.log("Helloo ia ma as da", data)}

                      {/* data === step[0] && */}

                      {/* {data.status === "LINK_SENT" ? (
                        <div
                          className={classes.copyExamLink}
                          onClick={() => copyExamLink()}
                        >
                          Copy Link
                        </div>
                      ) : null} */}

                      {data.status === "EXAM_SUBMITTED" ? (
                        <div
                          className={classes.copyExamLink}
                          onClick={() => viewResult()}
                        >
                          View Result
                        </div>
                      ) : null}
                    </StepLabel>
                    : null
                  </Step>
                ))}
              </Stepper>
            </Box>
          </div>
        </>
      ) : null}
    </Paper>
  );
};

export default withSnackbar(ExamTimeLine);
