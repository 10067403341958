import {
  Paper,
  useHistory,
  React,
  fetchClient,
  apiUrl,
  useEffect,
  Link
} from "allImport";
import useStyles from "style/Profilestyle.js";
import {SUB_ADMIN_PERMISSION} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import CommonProfileComponent from "components/Commonprofile/CommonProfileComponent";
export default function Dashboard(props) {
  const classes = useStyles();
  const [examinerName, setName] = React.useState("");
  const [examinerEmail, setEmail] = React.useState("");
  const [contactNo, setContantNo] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [examinerDesignation, setDesignation] = React.useState("");
  const [departmentName, setDepartment] = React.useState("");
  const examinerID =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.profileId;
  const history = useHistory();
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
console.log(examinerID)
  const getPermissionsForSubAdmin = () => {
    let permission = {};
    if (role === "sub-admin") {
      let allPermissions = StorageManager.getJSON(SUB_ADMIN_PERMISSION);
      permission.edit = allPermissions.includes("EDIT_CANDIDATE");
      permission.delete = allPermissions.includes("DELETE_CANDIDATE");
    }
    return permission;
  };

  useEffect(() => {

    fetchClient.get(`${apiUrl.examinerProfile}/` + examinerID).then((res) => {
      setLoader(true);
      setName(res && res.data && res.data.data && res.data.data.full_name);
      setContantNo(
        res && res.data && res.data.data && res.data.data.phone_number
      );
      setDesignation(
        res && res.data && res.data.data && res.data.data.designation
      );
      setEmail(res && res.data && res.data.data && res.data.data.email);
      setDepartment(
        res && res.data && res.data.data && res.data.data.department
      );
    });
  }, []);

  const data = {
    MainHeading: examinerName,
    data : [
      ["Full Name", examinerName],
      ["Contact Number",contactNo],
      ["Designation",examinerDesignation],
      ["Email ID ",examinerEmail],
      ["Department",departmentName],
      ["ID",examinerID]
    ],
    ID: examinerID,
    editPath: `/${role}/examiner/edit-profile`,
    permissions: getPermissionsForSubAdmin(),
    home: `/${role}/examiner`,
    tableType: "Examiner",
    editAction: {
      editpath: `/${role}/examiner/edit-profile`,
      examinerName: examinerName,
      examinerEmail: examinerEmail,
      examinerNo: contactNo,
      examinerDept: departmentName,
      examinerDesignation: examinerDesignation,
      examinerID: examinerID,
      allow: SUB_ADMIN_PERMISSION
        ? SUB_ADMIN_PERMISSION.includes("EDIT_EXAMINER")
        : true,
    },
  };

  return (
    <main className={classes.content}>
      {loader ? (
        <CommonProfileComponent data={data} />
  

      ) : (
        <Paper
          className={classes.paper}
          style={{
            height: "450px",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "200px",
          }}
        >
          <img
            src="/assets/Icons/nickelfox_loader.gif"
            width="100px"
            height="100px"
            alt="nickelfoxlogo"
          />
        </Paper>
      )}
    </main>
  );
}
