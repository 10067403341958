import {
  useHistory,
  clsx,
  CssBaseline,
  Container,
  Grid,
  Paper,
  Topbar,
  Sidebar,
  React,
  makeStyles,
  useEffect,
  apiUrl,
  useState,
  fetchUpdate,
  Typography,
  Button,
  fetchDelete,
  Box,
} from "allImport";
import fetchGet from "apiFetch/fetchGet";
import { useStyles } from "style/Liststyle.js";
import { withSnackbar } from "notistack";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import Emptyscreens from "components/Emptyscreen/emptyscreen";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
const useNotificationStyles = makeStyles((theme) => ({
  notificationContainer: {
    display: "flex",
    padding: "30px",
    borderBottom: "1px solid #E5E5E5",
    "&:hover": {
      backgroundColor: "#D9001D0F",
    },
    ["@media (max-width:1030px)"]: {
      // eslint-disable-line no-useless-computed-key
      padding: "5px",
    },
  },
  leftContainer: {
    height: "60px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
    cursor: "pointer",
  },
  rightContainer: {
    width: 150,
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
  },
  notificationRead: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    // fontWeight: "700",
    lineHeight: "21px",
    letterSpacing: "0.10000000149011612px",
    color: "#000000DE",
    textDecoration: "underline",
  },
  notificationDetail: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    // fontWeight: "700",
    // lineHeight: "32px",
    letterSpacing: "0.10000000149011612px",
    color: "#000000DE",
    textDecoration: "underline",
  },
  notificationDateRead: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    // fontWeight: "700",
    lineHeight: "32px",
    letterSpacing: "0.10000000149011612px",
    textDecoration: "underline",
  },
  notificationDate: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32px",
    letterSpacing: "0.10000000149011612px",
    textDecoration: "underline",
  },
  profileText: {
    color: "#0000005C",
    fontSize: "14px",
    // borderRadius: "4px",
    // border: "1px solid #FF4A4A",
    // color: "#FF4A4A",
    // padding: "1px 0px",
    // backgroundColor: "#FDECED",
  },
  emptyBox: {
    maxWidth: "700px",
    margin: "50px 15px",
  },
  emptyContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "50vh",
  },
  notificationTextExternal: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Mulish",
    fontSize: "16px",
    fontStyle: "normal",
    // fontWeight: "600",
    lineHeight: "22px",
    letterSpacing: "0.25px",
    margin: "0 20px ",
    padding: "20px 0px",
    borderBottom: "1px solid #E5E5E5",
    color: "#383838",
    position: "relative",
  },
  containerExternal: {
    // margin: "5px 0",
    margin: "10px 10px",
    padding: "18px 10px",
    width: "100%",
    cursor: "pointer",
    textDecoration: "none",
    border: "1px solid #fff",
    transition: "all 0.2s",
    "&:hover": {
      backgroundColor: "#D9001D0F",
      borderRadius: "4px",
      border: "1px solid #FF4A4A",
    },
    "&:visited": {
      backgroundColor: "#D9001D0F",
    },
  },
  emptyContainerExternal: {
    width: "100%",
    height: "300px !important",
    display: "flex",
    overflow: "none",
    justifyContent: "center",
    alignItems: "center",
  },
  emptyBoxExternal: {
    width: "90%",
  },
  ExternalNotifiactionWrapper: {
    height: "377px",
    overflowY: "scroll",
    border: "1px solid #E5E5E5",
    borderRadius: "5px",

    ["@media (max-width:1030px)"]: {
      // eslint-disable-line no-useless-computed-key
      margin: "20px 8px 20px 8px",
    },
  },
}));

const NotificationList = (props) => {
  const classes = useStyles();
  const notiClasses = useNotificationStyles();
  const [count, setCount] = useState(0);
  const [notification, setNotification] = useState([]);
  const history = useHistory();
  const [notificationData, setNotificationData] = useState(false);
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const [dashboardNotifications, setDashboardNotifications] = useState(null);

  const markAsRead = (payload, id) => {
    console.log("read");
    const data = {
      logs: [id],
    };
    fetchUpdate.patch(apiUrl.notificationMarkAsRead, data);
    history.push({
      pathname: `/${role}/candidate/profile`,
      state: {
        profileId: JSON.parse(payload.replace(/'/g, '"')).notification
          .candidate_id,
      },
    });
  };

  useEffect(() => {
    fetchGet.get(`${apiUrl.getNotifications}`).then((res) => {
      let notifications = res.data.data;
      notifications?.reverse();
      let dashboardNotifications = [];
      for (let i = 0; i < 10 && i < notifications.length; i++) {
        dashboardNotifications.push(notifications[i]);
      }
      setDashboardNotifications(dashboardNotifications);
      setNotification(notifications);
      setCount(res.data.data.length);
    });
  }, [notificationData]);

  const clearNotification = () => {
    fetchDelete.delete(`${apiUrl.getNotifications}`).then((res) => {
      setNotificationData(!notificationData);
    });
  };

  return (
    <>
      {props.external ? (
        <Grid
          container
          item
          xs={12}
          md={5}
          className={notiClasses.ExternalNotifiactionWrapper}
        >
          <Grid container className={notiClasses.notificationTextExternal}>
            <Grid item xs={6}>
              Notifications
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              Clear All
            </Grid>
          </Grid>
          {notification.length === 0 ? (
            <Paper className={clsx(notiClasses.emptyContainerExternal)}>
              <Emptyscreens absolute />
            </Paper>
          ) : (
            <>
              {dashboardNotifications.map((i, index) => (
                <Grid
                  container
                  item
                  sx={12}
                  key={index}
                  className={notiClasses.containerExternal}
                  style={{ height: "auto" }}
                  onClick={() => markAsRead(i.payload, i.id)}
                >
                  <Grid
                    xs={10}
                    item
                    container
                    alignItems="start"
                    className={
                      i.mark_as_read
                        ? notiClasses.notificationDetail
                        : notiClasses.notificationRead
                    }
                  >
                    {i.title}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={2}
                    justifyContent="center"
                    alignItems="start"
                    className={notiClasses.profileText}
                  >
                    Go to profile
                  </Grid>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      ) : (
        <main className={classes.content} id="main-content">
          <Container maxWidth="xl" className={classes.container}>
            <HeaderWithBreadcrumb
              heading="Notifications"
              breadCrumb
              breadCrumb1="Dashboard"
              breadCrumb2="Notifications"
              link={`/${role}/dashboard`}
            />
            <Grid container>
              <Grid item lg={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Notifications
                  </Typography>
                  <div
                    className={classes.examiner_nos}
                    style={{
                      marginLeft: "20px",
                    }}
                  >
                    {count}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} container justifyContent="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={clearNotification}
                >
                  Clear All
                </Button>
              </Grid>
            </Grid>
            {notification.length === 0 ? (
              <Paper
                className={clsx(classes.paper, notiClasses.emptyContainer)}
              >
                <img
                  className={notiClasses.emptyBox}
                  alt="No Notifications Found"
                  src="/assets/Icons/EmptyIconNotification.svg"
                />
              </Paper>
            ) : (
              <Paper className={classes.paper}>
                {notification.map((i, index) => (
                  <div
                    key={index}
                    onClick={() => markAsRead(i.payload, i.id)}
                    className={notiClasses.notificationContainer}
                  >
                    <div className={notiClasses.leftContainer}>
                      <div
                        className={
                          i.mark_as_read
                            ? notiClasses.notificationDetail
                            : notiClasses.notificationRead
                        }
                      >
                        {i.title}
                      </div>
                      <div className={notiClasses.profileText}>
                        Go to Profile
                      </div>
                    </div>
                    <div
                      className={clsx(
                        notiClasses.rightContainer,
                        i.mark_as_read
                          ? notiClasses.notificationDate
                          : notiClasses.notificationDateRead
                      )}
                    >
                      {new Date(i.created_at).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })}
                    </div>
                  </div>
                ))}
              </Paper>
            )}
          </Container>
        </main>
      )}
    </>
  );
};

export default withSnackbar(NotificationList);
