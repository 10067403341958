import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Typography,
} from "@material-ui/core";
import styles from "./FilterComponent.module.scss";

import CheckboxFilter from "./CheckboxFilter";
import { useDispatch, useSelector } from "react-redux";
import Actions from "redux/actions/filterActions";

function FilterComponent({ handleClose }) {
  const dispatch = useDispatch();

  const clearFilters = () => {
    dispatch({
      type: Actions.CLEAR_FILTER,
    });
  };

  return (
    <ClickAwayListener
      onClickAway={handleClose}
      onMouseDown={false}
      onMouseUp={false}
      onPointerDown={false}
      onPointerUp={false}
    >
      <Grid
        sx={{ position: "relative" }}
        role="presentation"
        style={{
          margin: "9px 3px",
          padding: "10px",
          boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.10)",
          backgroundColor: "transparent",
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item container justifyContent="space-between">
          <Grid>
            <Typography style={{ fontSize: "14px", fontWeight: "700" }}>
              Filter By
            </Typography>
          </Grid>
          <Grid>
            <Typography
              onClick={() => {
                handleClose();
                clearFilters();
              }}
              style={{ fontSize: "14px", fontWeight: "700" }}
              className={styles.filter_filterby}
            >
              Clear All
            </Typography>
          </Grid>
        </Grid>
        <Grid item container justifyContent="space-between" spacing="1">
          <CheckboxFilter handleClose={handleClose} />
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
}
export default FilterComponent;
