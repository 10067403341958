export const commonStyle = (theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.3em",
      height: "0.3em",
    },
    "*::-webkit-scrollbar-track": {
      // '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      // outline: '1px solid slategrey'
    },
  },
  root: {
    display: "flex",
  },
  paper: {
    //padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    boxShadow: "none",
    // border: "1px solid #E5E5E5",
    flexDirection: "column",

    marginTop: "32px",
    borderRadius: "12px",
  },
  checkboxIcon: {
    width: "16px",
    padding: "10px",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    borderRadius: "12px",
  },
  fixedHeight: {
    height: 640,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    // flexGrow: 1,
    // height: "100vh",
    height: "calc(100vh - 120px)",
    overflowY: "auto",
  },
  EditContainer: {
    paddingTop: "110px",
    paddingBottom: theme.spacing(4),
  },
  modalEditContainer: {
    paddingTop: "50px",
    paddingBottom: theme.spacing(4),
  },
  container: {
    // marginTop: "48px",
    // marginRight: 15,
    // paddingBottom: theme.spacing(4),
    border: "1px solid #E5E5E5",
    padding: "16px 16px 0 16px",
    borderRadius: "8px",
    width: "auto !important",
    maxHeight: "auto",
    overflowY: "scroll",
    overflowX: "hidden",
    ["@media (max-width:1030px)"]: {
      padding: "10px 10px 0 10px",

      // height: "calc(100vh - 100px)",
    },
  },
  tableContainer2: {
    // marginTop: "48px",
    // marginRight: 15,
    // paddingBottom: theme.spacing(4),
    border: "1px solid #E5E5E5",
    padding: "16px 16px 0 16px",
    borderRadius: "8px",
    width: "auto !important",
    // maxHeight: "auto",
    // overflowY: "scroll",
    ["@media (max-width:1030px)"]: {
      padding: "10px 10px 0 10px",
      // height: "calc(100vh - 100px)",
    },
  },

  page_heading: {
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "50px",
    letterSpacing: "0.25px",
    marginTop: "-95px",
    color: "#18273B",
    [theme.breakpoints.down("md")]: {
      width: "109%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "120%",
    },
  },
  dropdown_style: {
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.25px",
  },
  page_subheading: {
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.25px",
    color: "#6D6D6D",
    paddingTop: "4px",
    width: "176%",
  },
  all_examiners_heading: {
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "32px",
    letterSpacing: "0.1px",
    color: "#000000",
    paddingRight: "11px",
  },
  filledButton: {
    background: "#D9001D",
    borderRadius: "8px",
    textTransform: "capitalize",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.15px",
    color: "#FFFFFF",
    fontFamily: "Mulish",
    width: "184px",
    height: "48px",
    transform: "translateY(10px)",
  },

  normalButton: {
    background: "#fafafa",
    color: "black",
    textTransform: "capitalize",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.15px",
    fontFamily: "Mulish",
    width: "184px",
    height: "48px",
    transform: "translateY(10px)",
    marginRight: "-54px",
  },

  outlinedButton: {
    background: "#fafafa",
    color: "black",
    border: "1px solid black",
    borderRadius: "4px",
    textTransform: "capitalize",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.15px",
    fontFamily: "Mulish",
    width: "184px",
    height: "45px",
    transform: "translateY(10px)",
    marginRight: "-54px",
  },
  examiner_nos: {
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "10px",
    letterSpacing: "0.15px",
    color: "#FFFFFF",
    width: "25px",
    height: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    background: "red",
    textAlign: "center",
    padding: "3px",
    transform: "translateY(3px)",
  },
  CandidateButton: {
    background: "#D9001D",
    borderRadius: "4px",
    textTransform: "capitalize",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.15px",
    color: "#FFFFFF",
    fontFamily: "Mulish",
    width: "208px",
    height: "45px",
    transform: "translateY(10px)",
  },
  checkboxIcon: {
    width: "16px",
    padding: "10px",
  },
  hr_section_heading: {
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "50px",
    letterSpacing: "0.25px",
    color: "#18273B",
    paddingBottom: "32px",
  },

  hr_detailbox: {
    width: "1150px",
    height: "180px",
  },

  hr_change_pass_heading: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineWeight: "32px",
    letterSpacing: "0.10000000149011612px",
    textAlign: "left",
    color: "#000000",
    width: "213px",
    height: "32px",
    marginTop: "32px",
    marginLeft: "32px",
  },
  textfield: {
    // marginTop:'38px',
    // marginLeft:'32px',
    width: "291px",
    height: "46px",
  },
  leftBoxWrapper: {
    height: "100vh",
    boxSizing: "border-box",
  },
  leftbox: {
    background: `linear-gradient(0deg, rgba(41, 41, 41, 0.7), rgba(41, 41, 41, 0.7)), url(${"/assets/Icons/signInChair.jpg"})`,
    backgroundSize: "cover",
    width: "100%",
    minHeight: "100%",
    maxHeight: "auto",
    // minHeight: "700px",
    borderRadius: 10,
    backgroundRepeat: "no-repeat",
  },
  textField1: {
    height: "50px",
  },
  textFieldResetPassword: {
    height: "70px",
  },

  subtext: {
    marginLeft: "100.23PX",
    width: "100%",
    textWrapping: "wrap",
    color: "#FFFFFF",
    fontWeight: "400",
  },
  leftbigtext: {
    fontFamily: "Mulish",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: "600",
    // lineHeight: "6px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#FFFFFF",

    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },

  lefttext: {
    fontFamily: "Mulish",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "30px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#FFFFFFCC",
    // width: "100%",
    // marginLeft: "49px",
    paddingTop: "24px",
  },
  LinkStyle: {
    textDecoration: "none",
  },
  // nickelfoxlogo: {
  //   marginLeft: "49.02px",
  //   marginTop: "49.87px",
  // },
  sidebar_nickelfoxlogo: {
    marginLeft: "52.02px",
    marginTop: "23.72px",
    width: "31.33px",
  },
  sidebar_subtext: {
    marginLeft: "100.23PX",
    color: "#FFFFFF",
    fontWeight: "400",
    marginBottom: "27px",
    fontSize: "12px",
  },
  sidebar_heading: {
    height: "20px",
    width: "72pxpx",
    marginLeft: "99.04PX",
    marginTop: "-42px",
    fontSize: "16px",
    color: "#FFFFFF",

    fontWeight: "800",
  },
  actionbutton: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
    ButtonPosition: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
    },
  },
  exam_selection: {
    marginLeft: "32px",
    marginTop: "20px",
  },
  loginHeading: {
    fontFamily: "Mulish",
    fontSize: "38px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "63px",
    // width: "430px",
    height: "63px",
    color: "#18273B",
  },
  button: {
    fontFamily: "Mulish",
    fontWeight: 700,
    color: "#FFFFFF",
    background: " #D9001D",
    height: "52px",
    width: "157px",
    left: "0px",
    top: "0px",
    borderRadius: "4px",
  },
  resetPasswordButton: {
    fontFamily: "Mulish",
    fontWeight: 700,
    color: "#FFFFFF",
    background: " #D9001D",
    height: "52px",
    width: "237px",
    left: "0px",
    top: "0px",
    borderRadius: "4px",
  },
  disabledresetPasswordButton: {
    fontFamily: "Mulish",
    fontWeight: 700,
    color: "#FFFFFF",
    background: "#A8A8A8",
    height: "52px",
    width: "237px",
    left: "0px",
    top: "0px",
    borderRadius: "4px",
  },
  signin_description: {
    fontFamily: "Mulish",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: "0.10000000149011612px",
    textAlign: "left",
    color: "#A8A8A8",
    marginTop: "2.6vh",
    // height: "64px",
    // width: "37.5vw",
    // maxWidth: "540px",
  },
});
