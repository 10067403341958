const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },

  getContentAnchorEl: null,
  position: "static",
  scroll: "true",
  maxHeight: "10",
};
const MouseEnter = (e) => {
  e.target.style.color = "white";
  e.target.style.background = "#D9001D";
  e.target.style.fontFamily = "Mulish";
  e.target.style.fontStyle = "normal";
  e.target.style.fontWeight = "normal";
  e.target.style.fontSize = "14px";
  e.target.style.lineHeight = "22px";
  e.target.style.letterSpacing = "0.25px";
};
const MouseLeave = (e) => {
  e.target.style.color = "black";
  e.target.style.background = "white";
  e.target.style.fontFamily = "Mulish";
  e.target.style.fontStyle = "normal";
  e.target.style.fontWeight = "normal";
  e.target.style.fontSize = "14px";
  e.target.style.lineHeight = "22px";
  e.target.style.letterSpacing = "0.25px";
};
const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s[0].toUpperCase() + s.substr(1).toLowerCase();
};
const convertUTCDateToLocalDate = (date) => {
  console.log("daateeeeee-----____", date);
  // date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return date;
};

const convertDateFormat = (date) => {
  const timeStamp = new Date(date);
  if (timeStamp === "Invalid Date") {
    return date;
  }
  const timeString = timeStamp.toString().split(" ");
  const customDate = `${timeString[2]} ${timeString[1]} ${timeString[3]}`;
  return customDate;
};

export {
  MenuProps,
  MouseEnter,
  MouseLeave,
  capitalize,
  convertUTCDateToLocalDate,
  convertDateFormat,
};
