import { React, fetchClient, apiUrl, useEffect, Commontable } from "allImport";

import { convertUTCDateToLocalDate } from "Common/CommonFunction";
import { SUB_ADMIN_PERMISSION } from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import TablePageLayout from "layout/TablePageLayout";

import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import { useDispatch, useSelector } from "react-redux";
import TableActions from "redux/actions/tableActions";
import CommonTable from "components/CommonComponent/Filter/CommonTable/CommonTable";
import { useHistory } from "allImport";
import { DragIndicatorSharp } from "@material-ui/icons";

var searchValue = "";

function createData(ColA, ColB, ColC, ColD, ColE, ColF, ColG, ColH) {
  return { ColA, ColB, ColC, ColD, ColE, ColF, ColG, ColH };
}
var AS = [];
var examinerIDs = [];
var categoryJob = [];
var examinerNo = [];

export default function Dashboard() {
  const history = useHistory();

  const [deptSelectionVal, setDept] = React.useState("");
  const [data, setAllData] = React.useState({});
  const [count, setCount] = React.useState("");
  const [totalPages, setTotalPages] = React.useState(1);
  const [page, setPage] = React.useState(1);
  const [examinerSearch, setExaminerSearch] = React.useState("");
  const [loader, setLoader] = React.useState(true);
  const [pageColor, setPageColor] = React.useState(null);
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const [departments, setDepartments] = React.useState([]);
  const { searchData } = useSelector((store) => store.search);
  const { reset } = useSelector((store) => store.table);

  const handleChangePage = (event, value) => {
    console.log("pageessss", value);
    setPageColor(value);
    setPage(value);
  };

  useEffect(() => {
    setPageColor(1);
    setPage(1);
  }, [searchData]);

  const handleChangeFilter = (e) => {
    searchValue = e.target.value;
    console.log("searchValue", searchValue);
    const url = `${apiUrl.createCategory}?search=${searchValue}`;
    fetchClient.get(url).then((res) => {
      setCount(res && res.data && res.data.data && res.data.data.count);
      const DepartmentsArray =
        res && res.data && res.data.data && res.data.data.results;
      colmConversion(DepartmentsArray);
    });
  };

  const colmConversion = (arr) => {
    AS = [];
    for (var index = 0; index < arr.length; index++) {
      AS.push(
        createData(
          arr[index] && arr[index].id,
          arr[index] && arr[index].name,

          arr[index] && arr[index].department,
          arr[index] && arr[index].job.length,
          arr[index] &&
            convertUTCDateToLocalDate(
              new Date(arr[index].updated_at)
            ).toLocaleDateString("en-IN"),
          arr[index] && arr[index].job
        )
      );
      examinerIDs.push(arr[index] && arr[index].id);
      categoryJob.push(arr[index] && arr[index].job);
    }
    processDataList();
  };

  const getPermissionsForSubAdmin = () => {
    let permission = {};
    if (role === "sub-admin") {
      let allPermissions = StorageManager.getJSON(SUB_ADMIN_PERMISSION);
      permission.view = allPermissions.includes("VIEW_EXAMINER");
      permission.create = allPermissions.includes("CREATE_EXAMINER");
      permission.edit = allPermissions.includes("EDIT_EXAMINER");
      permission.delete = allPermissions.includes("DELETE_EXAMINER");
    }
    return permission;
  };
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  useEffect(() => {
    let isCanclled = false;
    const handleChange = async () => {
      await timeout(200);
      if (!isCanclled) {
        fetchClient
          .get(
            `${apiUrl.createCategory}?${page ? `&page=${page}` : " "}${
              searchData ? `&search=${searchData}` : ""
            }`
          )
          .then((res) => {
            let SUB_ADMIN_PERMISSION = localStorage.getItem(
              "SUB_ADMIN_PERMISSION"
            );

            let result = res.data.data;
            console.log(result);
            const count = result.count;
            const categoryData = result.results.map((item) => {
              return {
                0: item.id,
                1: item.name,
                2: item.job.length.toString(),
                3: convertUTCDateToLocalDate(
                  new Date(item.updated_at)
                ).toLocaleDateString("en-IN"),

                editAction: {
                  editpath: `/${role}/category/edit`,
                  categoryName: item.name,
                  categoryID: item.id,
                  categoryList: item.id,
                  categoryList: item.job,
                  allow: SUB_ADMIN_PERMISSION
                    ? SUB_ADMIN_PERMISSION.includes("EDIT_CATEGORY")
                    : true,
                },
                deleteAction: {
                  actionID: item.id,
                  tableType: "Category",
                  allow: SUB_ADMIN_PERMISSION
                    ? SUB_ADMIN_PERMISSION.includes("DELETE_CATEGORY")
                    : true,
                },
              };
            });

            dispatch({
              type: TableActions.SET_TABLE_ROW,
              data: categoryData,
              tableHeading: "Category",
              profile: `/${role}/category/question`,
              count: count,
            });
            dispatch({
              type: TableActions.SET_PAGINATION,
              data: { pages: result.pages, count: result.count },
            });
            // colmConversion(res.data.data.results);
            setTotalPages(result.pages);
            setCount(result.count);
          })
          .catch((err) => {
            localStorage.clear();
            history.push("/login");
          });
      }
    };
    handleChange();
    return () => {
      isCanclled = true;
    };
  }, [page, searchData, reset]);

  const processDataList = () => {
    const permissions = getPermissionsForSubAdmin();
    const data = {
      columnnames: {
        col1: "ID",
        col2: "Name",
        col5: "Associated Job ",
        col6: "Last Updated ",
        col7: "Last Updated ",

        col8: "Action",
      },

      CategoryId: examinerIDs,
      CategoryJob: categoryJob,
      ExaminerNo: examinerNo,
      permissions: permissions,
      List: AS,
      ListKey: Object.keys(AS),
      TableType: "Category",
      EditPath: `/${role}/category/edit`,
      profile: `/${role}/category/question`,
    };
    setAllData(data);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const allowActionColumn =
      localStorage.getItem("ROLE") === "SUB-ADMIN" &&
      localStorage.getItem("SUB_ADMIN_PERMISSION") &&
      !localStorage.getItem("SUB_ADMIN_PERMISSION").includes("EDIT_CATEGORY") &&
      !localStorage.getItem("SUB_ADMIN_PERMISSION").includes("DELETE_CATEGORY");
    dispatch({
      type: TableActions.SET_TABLE_COLUMN_HEADING,
      data: [
        "ID",
        "Name",
        "Associated Job",
        "Last Updated",
        !allowActionColumn && "Action",
      ],
    });
  }, []);
  let SUB_ADMIN_PERMISSION = localStorage.getItem("SUB_ADMIN_PERMISSION");

  return (
    <>
      <HeaderWithBreadcrumb heading="Category List" />
      <TablePageLayout
        heading="Category"
        loader={loader}
        page={page}
        ButtonText="Create Category"
        handleChangePage={handleChangePage}
        handleChangeFilter={(e) => handleChangeFilter(e)}
        count={count}
        buttonActionPath={`/${role}/category/create`}
        candidateFilter={{
          item: "null",
          type: "category",
        }}
        allowCreate={
          SUB_ADMIN_PERMISSION
            ? SUB_ADMIN_PERMISSION.includes("CREATE_CATEGORY")
            : true
        }
      >
        {console.log("data of table666666666===>", data)}
        <CommonTable
          Count={totalPages}
          handleChangePage={handleChangePage}
          pageColor={pageColor}
        />
      </TablePageLayout>
    </>
  );
}
