import {
  useEffect,
  useHistory,
  React,
  fetchClient,
  apiUrl,
  fetchPost,
  SuccessDialog,
} from "allImport";
// import { useStyles } from "style/Editstyle.js";
import "style/style.scss";
import { Field, Formik, FormikProvider } from "formik";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import { useStyles as useStyles1 } from "style/Liststyle";
import {
  Container,
  Grid,
  InputLabel,
  TextField,
  MenuItem,
  Typography,
} from "@material-ui/core";
import CandidateValidation from "components/Form/validations/CandidateValidation";
import BasicInput from "components/Form/InputBoxes/BasicInput";
import { useFormik } from "formik";
import { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { withSnackbar } from "notistack";

import { makeStyles } from "@material-ui/core/styles";

import CustomButton from "components/Button/CustomButton";

import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";

import VacancyValidation from "components/Form/validations/VacancyValidation";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1);",
    },
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 5,
      paddingRight: "100px",
      width: "100%",
    },
  },
  label: {
    fontFamily: "Mulish",
    fontWeight: "600px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#383838",
  },
}));

function Createvacancy(props) {
  const classes2 = useStyles();
  const classes1 = useStyles1();
  const [departmentList, setDepartmentList] = useState([]);
  const experienceLevel = ["senior", "associate","junior", "internship"];
  const [box5, setBox5] = useState("");

  const [successToggle, setSuccessToggle] = useState(false);
  const [draft, setDraft] = useState(false);

  const history = useHistory();
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;

  const handleButtonClick = () => {
    setDraft(true);
    alert(1);
  };

  // Get Data on Initial Page Load
  useEffect(() => {
    if (
      !StorageManager.get(API_TOKEN) &&
      !StorageManager.get(ADMIN_ID) &&
      !StorageManager.get(ADMIN_NAME) &&
      !StorageManager.get(LOGOUT_TOKEN)
    ) {
      history.push("/login");
    }

    fetchClient.get(`${apiUrl.deptList}?page_size=1000`).then((res) => {
      console.log(res.data.data.results);
      setDepartmentList(res.data.data.results);
      setBox5(
        res.data.data.results?.find(
          (e) => e.name === props.location.state.jobDepts
        )
      );
    });
  }, []);

  const handleSubmit = (values, status) => {
    console.log(values);
    const data = {
      job_title: values.vacancyTitle,
      job_level: values.experienceLevel.toLowerCase(),
      department: box5 ? box5.id : values.department,
      is_publish: status ? false : true,
      status: status ? "unpublish" : "publish",
    };
    fetchPost
      .patch(`${apiUrl.editJob}${props.location.state.jobID}`, data)

      .then((res) => {
        setSuccessToggle(true);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.code === 404) {
            props.enqueueSnackbar(
              "Oops! Something went wrong. We are working on fixing the problem.",
              {
                variant: "error",
                autoHideDuration: 3000,
              }
            );
          } else if (error.response.data.error) {
            error.response.data.error.message.forEach((message) => {
              props.enqueueSnackbar(message, {
                variant: "error",
                autoHideDuration: 3000,
              });
            });
          }
        } else {
          props.enqueueSnackbar(
            "Oops! Something went wrong. Please check your network connection and try again",
            {
              variant: "error",
            }
          );
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      vacancyTitle: props.location.state.jobTitle,
      experienceLevel: props.location.state.jobLevel,
      department: props.location.state.jobDepts,
    },
    validationSchema: VacancyValidation,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Container
      container
      maxWidth="xl"
      className={classes1.container}
      style={{ height: "calc(100vh - 120px)", maxHeight: "auto" }}
    >
      <HeaderWithBreadcrumb
        heading="Edit Vacancy"
        breadCrumb
        breadCrumb1="Vacancy"
        breadCrumb2="Edit Vacancy"
        link={`/${role}/job`}
      />

      {successToggle ? (
        <SuccessDialog
          type="field"
          heading="Vacancy Updated Successfully"
          path={`/${role}/job`}
        />
      ) : null}
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <BasicInput
              label="Vacancy Title*"
              placeholder="Enter vacancy title"
              type="vacancyTitle"
              values={formik.values.vacancyTitle}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.vacancyTitle}
              errors={formik.errors.vacancyTitle}
            />
            <Grid item xs={12} lg={4} md={6}>
              <InputLabel className={classes2.label}>
                Experience Level*
              </InputLabel>

              <Autocomplete
                classes={classes2}
                name="experienceLevel"
                id="experienceLevel"
                disableClearable
                options={experienceLevel}
                getOptionLabel={(option) => option}
                handleChange={formik.handleChange}
                defaultValue={formik.values.experienceLevel}
                onChange={(experienceLevel, value) =>
                  formik.setFieldValue("experienceLevel", value) &&
                  formik.handleChange
                }
                renderInput={(params) => (
                  <TextField
                    defaultValue="asd"
                    placeholder="Enter experience Level"
                    name="experienceLevel"
                    id="experienceLevel"
                    {...params}
                    variant="outlined"
                    error={Boolean(
                      formik.touched.experienceLevel &&
                        formik.errors.experienceLevel
                    )}
                    helperText={
                      formik.touched.experienceLevel &&
                      formik.errors.experienceLevel
                    }
                    touched={formik.touched.experienceLevel}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="normal"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={4} md={6}>
              <InputLabel className={classes2.label}>Department*</InputLabel>

              <Autocomplete
                classes={classes2}
                name="department"
                id="department"
                disableClearable
                value={departmentList?.find((e) => e.id)}
                defaultValue={{
                  name: formik.values.department,
                }}
                options={departmentList}
                getOptionLabel={(option) => option.name}
                handleChange={formik.handleChange}
                onChange={(department, value) =>
                  formik.setFieldValue("department", value.id) &&
                  setBox5(false) &&
                  formik.handleChange
                }
                renderInput={(params) => (
                  <TextField
                    placeholder="Enter department*"
                    name="department"
                    id="department"
                    {...params}
                    variant="outlined"
                    error={Boolean(
                      formik.touched.department && formik.errors.department
                    )}
                    helperText={
                      formik.touched.department && formik.errors.department
                    }
                    touched={formik.touched.department}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="normal"
                  />
                )}
              />
            </Grid>

            <Grid container item xs={12} spacing={3}>
              <Grid item sx={3}>
                <CustomButton
                  handleButtonClick={handleButtonClick}
                  type="cancel"
                  Buttontype="cancel"
                  label="Cancel"
                />
              </Grid>
              <Grid item sx={3}>
                <CustomButton
                  Buttontype="draft"
                  label="Save as draft"
                  handleButtonClick={async () => {
                    await setDraft(true);
                    handleSubmit(formik.values, true);
                  }}
                />
              </Grid>
              <Grid item sx={3}>
                <CustomButton type="submit" Buttontype="save" label="Publish" />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Container>
  );
}

export default withSnackbar(Createvacancy);
