import {
  fetchDelete,
  apiUrl,
  useHistory,
  makeStyles,
  React,
  withStyles,
  MenuItem,
  Menu,
} from "allImport";
import "style/style.scss";
import { Avatar, Box, Grid, Typography } from "@material-ui/core";
import dropDownIcon from "./dropdownIcon.svg";
import userProfile from "./user-profile.svg";
import ToggleSidebar from "components/Sidebar/mobileSidebar";
import { AccountCircle, ExitToApp, LockOutlined } from "@material-ui/icons";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  mobileNavigation: {
    display: "none",

    ["@media (max-width:1030px)"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
    },
  },
  root: {
    display: "flex",
  },
  avatar: {
    backgroundColor: "#d9001d",
    fontSize: "1rem",
  },
  profile_button: {
    marginLeft: "22px",
  },
  toolbar: {
    width: "100%",
    display: "flex",
    paddingBottom: "16px",
    justifyContent: "flex-end",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  topProfileAction: {
    width: "15%",
    marginLeft: "38px",
    justifyContent: "flex-end",
  },

  profileButton: {
    border: "1px solid #E5E5E5",
    boxSizing: "border-box",
    borderRadius: "25px",
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 15px",
    margin: "0",
    height: "auto",
    whiteSpace: "nowrap",
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#FCF1F0",
      // borderColor: " #DA3C20",
    },
  },
  userName: {
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 16,
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    color: "rgba(0, 0, 0, 0.87)",
    marginRight: 20,
  },
  formControl: {
    width: "100%",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `100px`,
    height: "73px",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },

  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  notification: {
    padding: 8,
    marginRight: 32.33,
    borderRadius: "50%",
    height: "50px",
    marginTop: "10px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  topbarWrapper: {
    paddingBottom: "10px",
    height: "70px",
    ["@media (max-width:1030px)"]: {
      // eslint-disable-line no-useless-computed-key
      paddingBottom: "5px",
    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    boxShadow: "rgb(0 0 0 / 10%) 0px 6px 20px",
    marginTop: "0.5rem",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export default function Dashboard(props) {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const adminID = localStorage.getItem("ADMIN_ID");
  const adminName = localStorage.getItem("ADMIN_NAME");

  const initials = adminName
    .split(" ")
    .map((n) => n[0])
    .join("")
    .toUpperCase();

  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const logout = () => {
    console.log("logout", 1);
    fetchDelete.delete(`${apiUrl.logout}`).then(() => {
      localStorage.clear();
      history.push("/login");
    });
  };
  const changepassword = () => {
    history.push(`/${role}/change-password`);
  };
  const myprofile = () => {
    history.push({
      pathname: `/${role}/profile`,
      state: adminID,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const MuiMenuItem = withStyles({
    root: {
      fontSize: "15px",
      margin: "5px 0px",
    },
  })(MenuItem);
  const capitalizeFirstLetter = (string) => {
    if (string) {
      var newStr = string.replace(/_/g, " ");
      return newStr.charAt(0).toUpperCase() + newStr.slice(1).toLowerCase();
    }
  };

  return (
    <Grid
      container
      item
      justifyContent="flex-end"
      alignItems="center"
      className={classes.topbarWrapper}
    >
      <Grid className={classes.mobileNavigation} container item xs={1}>
        <ToggleSidebar />
      </Grid>

      <Grid container xs={11} item position="relative">
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid
            item
            alignItems="center"
            justifyContent="center"
            onClick={() => history.push(`/${role}/notifications`)}
            className={classes.notification}
          >
            {props.notification ? (
              <img
                src="/assets/Icons/active_notification.svg"
                alt=""
                style={{ marginLeft: "15px" }}
              />
            ) : (
              <img src="/assets/Icons/notification.svg" alt="" />
            )}
          </Grid>

          <Grid item onClick={handleClick} className={classes.profileButton}>
            <div className={classes.userName}>
              {" "}
              {capitalizeFirstLetter(adminName?.split(" ")?.[0])}
            </div>
            {initials ? (
              <Avatar className={classes.avatar}>{initials}</Avatar>
            ) : (
              <Avatar src={userProfile} />
            )}
            <Box
              sx={{
                width: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ marginLeft: 10 }}
                src={dropDownIcon}
                alt="dropdownIcon"
              />
            </Box>
          </Grid>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MuiMenuItem onClick={myprofile}>My Profile</MuiMenuItem>
            <MuiMenuItem onClick={changepassword}>Change password</MuiMenuItem>

            <MuiMenuItem onClick={logout}>Logout</MuiMenuItem>
          </StyledMenu>
        </Grid>
      </Grid>
    </Grid>
  );
}
