import ForgotPassword from "pages/hr-examiner/Forgotpassword/Forgotpassword";
import Resetpassword from "pages/hr-examiner/Resetpassword/Resetpassword";
import ResetLinkSent from "pages/hr-examiner/Forgotpassword/ResetLinkSent";
import LandingPage from "pages/hr-examiner/LandingPage/LandingPage";
import Landing from "pages/hr-examiner/LandingPage/Landing";
import LogIn from "pages/hr-examiner/Login/LogIn";

export const publicRoutes = [
  { path: "/login", component: LogIn },
  { path: "/forgot-password", component: ForgotPassword },

  { path: "/reset-link-sent", component: ResetLinkSent },
  { path: "/reset-password/:id", component: Resetpassword },
  { path: "/", component: Landing },
];
