import React from "react";
import { Popover, Typography, Button, Grid } from "@material-ui/core";
import commentIcon from "./chat.png";
import commentIconActive from "./chat1.png";

function PopupData(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeIcon, setActiveIcon] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const mouseHoverHandle = () => {
    setActiveIcon(true);
    props.setChangeText({ [props.hoverIndex]: props.hoverIndex });
  };
  const mouseOutHandle = () => {
    setActiveIcon(false);
    props.setChangeText(false);
  };

  return (
    <Grid>
      <span
        onMouseOver={mouseHoverHandle}
        onMouseOut={mouseOutHandle}
        style={{
          position: "absolute",
          top: "-40px",
          right: "-20px",
          color: "red",
          padding: "5px",
          textDecoration: "underline",
        }}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        {activeIcon || open ? (
          <img
            src={commentIconActive}
            alt="comment-icon"
            style={{ width: "1.2rem", height: "1rem" }}
          />
        ) : (
          <img
            src={commentIcon}
            alt="comment-icon"
            style={{ width: "1.2rem", height: "1rem" }}
          />
        )}
      </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Grid
          style={{
            padding: "4px 1px 4px 4px",
            borderRadius: "3px",
            border: "1px solid rgba(255, 74, 74, 1)",
            backgroundColor: "rgba(255, 74, 74, 0.05)",
            padding: "5px 10px",
            marginTop: "4px",
            overflowWrap: "break-word",
            maxWidth: "300px",
            overflow: "scroll",
            maxHeight: "100px",
          }}
        >
          {props.data}
        </Grid>
      </Popover>
    </Grid>
  );
}

export default PopupData;
