import {
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { fetchPost, apiUrl } from "allImport";
import React, { useEffect, useState } from "react";
import { useStyles as useStyles1 } from "style/Liststyle";
import { Link } from "react-router-dom";

import { useHistory } from "react-router-dom";
import { withSnackbar } from "notistack";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";

const ChooseAddCandidate = (props) => {
  const [role, setRole] = useState(null);
  useEffect(() => {
    setRole(localStorage.getItem("ROLE").toLowerCase());
  }, []);
  const classes1 = useStyles1();
  const history = useHistory();

  const [resume, setResume] = useState(null);

  useEffect(() => {
    if (resume !== null) {
      handleResume();
    }
  }, [resume]);

  const handleResume = () => {
    const formData = new FormData();
    formData.append("upload", resume);

    fetchPost
      .post(`${apiUrl.resumeCandidate}`, formData)
      .then((res) => {
        console.log(res, "setResume");

        history.push({
          pathname: `/${role}/candidate/add`,
          state: {
            candidateName: res.data.full_name,
            candidatePhoneNumber: res.data.phone_number,
            candidateEmail: res.data.email,
            candidateResumeUrl: res.data.resume_url,
          },
        });
      })
      .catch((error) => {
        alert("error");
        // if (error.response) {
        //   if (error.response.data.code === 404) {
        //     props.enqueueSnackbar(
        //       "Oops! Something went wrong. We are working on fixing the problem.",
        //       {
        //         variant: "error",
        //         autoHideDuration: 3000,
        //       }
        //     );
        //   } else if (error.response.data.error) {
        //     error.response.data.error.message.forEach((message) => {
        //       props.enqueueSnackbar(message, {
        //         variant: "error",
        //         autoHideDuration: 3000,
        //       });
        //     });
        //   }
        // } else {
        //   props.enqueueSnackbar(
        //     "Oops! Something went wrong. Please check your network connection and try again",
        //     {
        //       variant: "error",
        //     }
        //   );
        // }
      });
  };
  return (
    <Container
      container
      alignItems="center"
      maxWidth="xl"
      className={classes1.container}
      style={{ height: "calc(100vh - 120px)", maxHeight: "auto" }}
    >
      <HeaderWithBreadcrumb
        heading="Add New Candidate"
        breadCrumb
        breadCrumb1="Candidates"
        breadCrumb2="Add new candidate"
        link={`/${role}/candidate`}
      />
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          xs={12}
          sm={5}
          style={{
            height: "300px",
            borderRadius: "10px",
            border: "1px solid #E5E5E5",
            boxShadow: "rgb(0 0 0 / 10%) 0px 6px 20px",
            border: "1px solid #DA3C20",
          }}
        >
          <Link
            style={{ textDecoration: "none" }}
            to={`/${role}/candidate/add`}
          >
            <Button
              size="large"
              variant="outlined"
              color="primary"
              component="label"
            >
              Add candidate manually
            </Button>
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          container
          justifyContent="center"
          alignItems="center"
          style={{
            height: "300px",
            borderRadius: "10px",
            border: "1px solid #E5E5E5",
            boxShadow: "rgb(0 0 0 / 10%) 0px 6px 20px",
            // backgroundColor: "#FCF1F0",
            border: "1px solid #DA3C20",
          }}
        >
          <Button
            size="large"
            variant="outlined"
            color="primary"
            component="label"
          >
            <input
              hidden
              name="upload-photo"
              type="file"
              onChange={(e) => {
                setResume(e.target.files[0]);
              }}
            />
            Upload Resume
          </Button>
          {/* <button onClick={handleResume}>Upload</button> */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default withSnackbar(ChooseAddCandidate);
