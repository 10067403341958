import React from "react";
import { Container, CssBaseline, Topbar, Sidebar, makeStyles, Emptyscreen } from "allImport";
import { commonStyle } from "commonStyle.js";

const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),
}));
export default function AdminEmptyScreen() {
  const classes = useStyles();
  return (
    
      <main className={classes.content}>
        
        <Container maxWidth="xl" className={classes.container}>
          <Emptyscreen />
        </Container>
      </main>
  
  );
}
