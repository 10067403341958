import {
  Button,
  Divider,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { fetchUpdate, apiUrl } from "allImport";
import CommonPopup from "Common/CommonPopup";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DividerIcon from "../../components/TableTopBar/assets/divider.svg";
const role = localStorage.getItem("ROLE")
  ? localStorage.getItem("ROLE").toLowerCase()
  : null;

const useStyles = makeStyles((theme) => ({
  filledButton: {
    height: "2.5rem",
    minWidth: "7rem",
    marginRight: ".5rem",
  },
  divider: {
    left: 0,
  },
}));

const ProfileDataContainer = ({ data, handleDelete, children }) => {
  const [hire, setHire] = useState(false);
  const [reject, setReject] = useState(false);

  const { popupData } = useSelector((store) => store.popupRedux);
  console.log("dataaaaao", data);
  const classes = useStyles();
  const history = useHistory();
  const assignExam = () => {
    history.push({
      pathname: `/${role}/candidate/examlink`,
      state: {
        candidateID: data.candidateID,
        candidateName: data.candidateName,
        DeptID: data.candidateDept,
      },
    });
  };
  const Reject = () => {
    const data1 = {
      full_name: data.candidateName,
      phone_number: data.candidateNo,
      email: data.candidateEmail,
      is_hired: false,
      hire_comment: popupData,
    };
    fetchUpdate.patch(
      `${apiUrl.viewCandidates + data.candidateID + "/"}`,
      data1
    );
    history.goBack();
  };
  const Hire = () => {
    const data1 = {
      full_name: data.candidateName,
      phone_number: data.candidateNo,
      email: data.candidateEmail,
      is_hired: true,
      hire_comment: popupData,
    };
    fetchUpdate.patch(
      `${apiUrl.viewCandidates + data.candidateID + "/"}`,
      data1
    );
    history.goBack();
  };
  return (
    <>
      {reject ? (
        <CommonPopup
          heading="Are you sure you want to Reject this candidate ?"
          cancel={() => setReject(false)}
          status={() => Reject()}
        />
      ) : null}
      {/* {hire ? <Alert successText="Candidate Hired" heading="Are you sure you want to hire this candidate ?" actionName="Hire" action={() => Hire()} cancel={() => setHire(false)}/> : null} */}
      {hire ? (
        <CommonPopup
          heading="Are you sure you want to hire this candidate ?"
          cancel={() => setHire(false)}
          status={() => Hire()}
        />
      ) : null}
      <Grid
        container
        style={{
          // backgroundColor: "red",
          border: "1px solid #E5E5E5",
          padding: "16px 16px 0 16px",
        }}
      >
        <Grid container alignItems="center" item xs={12} sm={5} md={6}>
          <h3 style={{ margin: 0 }}>Personal Detail</h3>
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-end"
          alignItems="center"
          xs={9}
          sm={7}
          md={6}
          lg={6}
          xl={5}
        >
          {data.tableType == "Admin" ? (
            <Link
              to={{
                pathname: data.editPath,
                state: data.editAction,
              }}
            >
              <img src="/assets/Icons/editIcon.svg" alt="editAction" />
            </Link>
          ) : null}
          {/* {data.tableType != "Admin" ?
          <Link>
            <img src="/assets/Icons/deleteIcon.svg" alt="" />
          </Link> :null } */}
          {role !== "examiner" &&
          (role === "admin" || role === "sub-admin") &&
          (data.latest_status === "PASSED" || !data.latest_status) &&
          data.hiring_status === null ? (
            <Grid
              item
              xs={5}
              sm={2}
              md={6}
              style={{
                display: "flex",
                alignItem: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                className={classes.filledButton}
                onClick={() => assignExam()}
                variant="contained"
                color="primary"
              >
                Assign Exam
              </Button>
            </Grid>
          ) : null}
          {/* <Grid>

                <IconButton  className={classes.divider} disableRipple>
                <img  src={DividerIcon} alt="divider" />
              </IconButton> 
</Grid> */}
          {data.latest_status === "PASSED" && data.hiring_status != true ? (
            <Grid
              item
              xs={8}
              sm={2}
              md={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                marginTop: "1px",
              }}
              justifyContent="center"
            >
              <Button
                className={classes.filledButton}
                onClick={() => setHire(true)}
                variant="contained"
                color="primary"
              >
                Qualify
              </Button>
              <Button
                className={classes.filledButton}
                onClick={() => setReject(true)}
                variant="outlined"
                color="primary"
              >
                Disqualify
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Divider
            style={{
              margin: "16px 0px",
              height: "1px",
            }}
          />
        </Grid>
        <Grid container xs={12}>
          {data.data.map((item) => {
            console.log(item[0], "printThis");
            return (
              <Grid
                container
                style={{ height: "100px", lineHeight: "40px" }}
                xs={12}
                sm={item[0] === "Additional Info" ? 12 : 6}
                md={item[0] === "Additional Info" ? 8 : 4}
                lg={item[0] === "Additional Info" ?  6: 3}
                item
              >
                {item.map((value, index) => {
                  return (
                    <Grid
                      xs={12}
                      style={
                        index == 0
                          ? { fontWeight: "600", fontSize: "14px" }
                          : {
                              fontWeight: "400",
                              fontSize: "14px",
                              maxHeight: "60px",

                              overflow: "scroll",
                              lineHeight:
                                item[0] === "Additional Info" ? "20px" : "auto",
                            }
                      }
                    >
                      {value}
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default ProfileDataContainer;
