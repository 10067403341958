import Actions from "../actions/tableActions";

let initialState = {
  columnHeading: [],
  columnData: [],
  pages: null,
  count: null,
  tableHeading: "",
  profile: "",
  assignExam: {},
  count: null,
  pageNumber: null,
  reset: false,
};

const tableReducer = (state = initialState, action) => {
  console.log("columnData123", state.columnData);
  switch (action.type) {
    case Actions.SET_TABLE_COLUMN_HEADING:
      return {
        ...initialState,
        columnHeading: action.data,
      };

    case Actions.SET_TABLE_ROW:
      return {
        ...state,
        columnData: action.data,
        tableHeading: action.tableHeading,
        profile: action.profile,
        count: action.count,
        pageNumber: action.pageNumber,
      };
    case Actions.RESET_TABLE_COLUMN_HEADING: {
      return {
        ...state,
        reset: action.data === state.reset ? !action.data : action.data,
        count: null,
      };
    }
    default:
      return state;
  }
};

export default tableReducer;
