import {
  useEffect,
  useState,
  apiUrl,
  fetchUpdate,
  Container,
  Grid,
  React,
  useHistory,
  SuccessDialog,
} from "allImport";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import { FormikProvider, useFormik } from "formik";
import AdminValidation from "components/Form/validations/AdminValidation";
import CustomButton from "components/Button/CustomButton";
import BasicInput from "components/Form/InputBoxes/BasicInput";
import { useStyles } from "style/Liststyle";
export default function Edithr(props) {
  const classes = useStyles();
  console.log("prraaappss", props);
  const history = useHistory();
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const adminID = localStorage.getItem("ADMIN_ID");
  const [candidateToggle, setCandidateToggle] = useState(false);

  useEffect(() => {
    if (
      !StorageManager.get(API_TOKEN) &&
      !StorageManager.get(ADMIN_ID) &&
      !StorageManager.get(ADMIN_NAME) &&
      !StorageManager.get(LOGOUT_TOKEN)
    ) {
      history.push("/login");
    }
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      full_name: props.location.state.name,
      designation: props.location.state.designation,
    },
    validationSchema: AdminValidation,
    onSubmit: (values) => {
      console.log(values);
      const data1 = {
        full_name: values.full_name,
        designation: values.designation,
      };
      role === "admin"
        ? fetchUpdate
            .patch(`${apiUrl.editHr}${adminID}`, data1)
            .then((res) => {
              StorageManager.put(ADMIN_NAME, res.data.data.full_name);
              setCandidateToggle(true);
            })
            .catch((error) => {})
        : role === "examiner"
        ? fetchUpdate
            .patch(`${apiUrl.updateExaminer}/${adminID}`, data1)
            .then((res) => {
              StorageManager.put(ADMIN_NAME, res.data.data.full_name);
              setCandidateToggle(true);
            })
            .catch((error) => {})
        : fetchUpdate
            .patch(`${apiUrl.editSubadmin}${adminID}/`, data1)
            .then((res) => {
              StorageManager.put(ADMIN_NAME, res.data.data.full_name);
              setCandidateToggle(true);
            })
            .catch((error) => {});
    },
  });

  return (
    <Container
      container
      maxWidth="xl"
      className={classes.container}
      style={{ height: "calc(100vh - 120px)", maxHeight: "auto" }}
    >
      <HeaderWithBreadcrumb
        heading="Edit Admin"
        breadCrumb
        breadCrumb1="Admin"
        breadCrumb2="Edit Admin"
        link={`/${role}/dashboard`}
      />
      {candidateToggle ? (
        <SuccessDialog
          type="field"
          heading="Profile Updated Succesfully"
          path={`/${role}/dashboard`}
        />
      ) : null}
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container xs={12} spacing={3}>
            <BasicInput
              label="Full Name*"
              placeholder="Enter full name"
              type="full_name"
              values={formik.values.full_name}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.full_name}
              errors={formik.errors.full_name}
            />

            <BasicInput
              label="Designation*"
              placeholder="Enter Designation"
              type="designation"
              values={formik.values.designation}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.designation}
              errors={formik.errors.designation}
            />
          </Grid>
          <Grid container item xs={12} lg={4} md={6} spacing={3}>
            <Grid item sx={3}>
              <CustomButton type="button" Buttontype="cancel" label="Cancel" />
            </Grid>
            <Grid item sx={3}>
              <CustomButton type="submit" Buttontype="save" label="Save" />
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Container>
  );
}
