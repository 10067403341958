import { Button, Grid, makeStyles, TextareaAutosize } from "@material-ui/core";
import CustomButton from "components/Button/CustomButton";
import React from "react";
import { useDispatch } from "react-redux";
import Actions from "redux/actions/popupActions";

const useStyles = makeStyles((theme) => ({
  popupBox: {
    position: "fixed",
    background: "#00000050",
    width: "100%",
    height: "100vh",
    top: 0,
    zIndex: 1000,
    left: 0,
  },
  box: {
    position: "relative",
    width: "20%",
    margin: "0 auto",
    height: "auto",
    maxHeight: "70vh",
    marginTop: "calc(100vh - 70vh)",
    backgroundColor: "#fff",

    borderRadius: "6px",
    padding: "20px",
    border: "1px solid #999",
    overflow: "auto",
  },
  closeIcon: {
    fontSize: "20px",
    color: "red",
  },
  filledButton: {
    height: "2.5rem",
    padding: " 0 2rem",
    margin:" 10px 20px 0 0 ",
    
  },
}));


const CommonPopup = (props) => {
  const dispatch = useDispatch()

  const showData = (event) => {
   dispatch({ type :Actions.SET_POPUP , data : event.target.value})
  };
  
  console.log("kkkkiioo",props)
  const classes = useStyles();
  return (
    <Grid className={classes.popupBox}>
      <Grid className={classes.box}>
        <label className={classes.closeIcon}>{props.heading}</label>
        <TextareaAutosize
          onChange={showData}
          maxRows={4}
          aria-label="maximum height"
          placeholder="Reason..."
          style={{
            width: "100%",
            height: "59%",
            fontSize: "18px",
            overflow: "scroll",
            marginTop: "14px",
            padding:"10px"
          }}
        />

        <Button
          type={"cancel"}
          className={classes.filledButton}
          variant={"outlined"}
          color="primary"
          onClick={props.cancel}
        >
          Cancel
        </Button>
        <Button
          type={"submit"}
          className={classes.filledButton}
          variant={"contained"}
          color="primary"
          onClick={props.status}
        >
          Save
        </Button>

       
      </Grid>
    </Grid>
  );
};

export default CommonPopup;
