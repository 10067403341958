import {
  Emptyscreen,
  useHistory,
  MenuItem,
  OutlinedInput,
  Select,
  CancelRoundedIcon,
  useEffect,
  fetchClient,
  apiUrl,
  CssBaseline,
  Grid,
  Paper,
  IconButton,
  InputAdornment,
  TextField,
  FormControl,
  Topbar,
  Sidebar,
  React,
  SearchIcon,
  Pagination
} from "allImport";
import Commonprofile from "components/Commonprofile/Commonprofile";
import useStyles from "style/Profilestyle.js";
import { useStyles as useListstyles } from "style/Liststyle.js";
import {
  MenuProps,
  MouseEnter,
  MouseLeave,
  convertUTCDateToLocalDate
} from "Common/CommonFunction";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
  SUB_ADMIN_PERMISSION
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import { useDispatch, useSelector } from "react-redux";
import TableActions from "redux/actions/tableActions";
import CommonTable from "components/CommonComponent/Filter/CommonTable/CommonTable";
import TableTopBar from "components/TableTopBar/TableTopBar";
import TableActions2 from "redux/actions/tableActions2";
import Actions from "redux/actions/filterActions";
const queryString = require("query-string");
var jobAS = [];
var jobSearchValue = "";
var jobIDs = [];
var jobExperience = [];
var examinerAS = [];
var examinerIDs = [];
var examinerEmails = [];
var examinerNo = [];
var url_job = new URL(`${apiUrl.viewJobs}`);
export default function ViewDepartment(props) {
  const { dataArray } = useSelector((store) => store.filter);

  const options1 = queryString.stringify({
    ...dataArray
  });

  console.log("daatArrrayayy", options1);
  const classes = useStyles();
  const listClasses = useListstyles();
  const history = useHistory();
  const [jobData, setJobData] = React.useState({});
  // const [count, setCount] = useState(0);
  const [totalJobPage, setTotalJobPage] = React.useState("");
  const [totalExaminerPage, setTotalExaminerPage] = React.useState("");
  const [jobLevel, setJobLevel] = React.useState("");
  const [jobStatus, setJobStatus] = React.useState("");
  const [jobCount, setJobCount] = React.useState("");
  const [jobSearch, setJobSearch] = React.useState("");
  const [jobPage, setJobPage] = React.useState(1);
  const [examinerPage, setExaminerPage] = React.useState(1);
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const [dept, setDept] = React.useState("");
  const [examinerData, setExaminerData] = React.useState({});
  const [examinerCount, setExaminerCount] = React.useState("");
  const [examinerSearch, setExaminerSearch] = React.useState("");
  const [examinerLoader, setExaminerLoader] = React.useState(false);
  const [jobLoader, setJobLoader] = React.useState(false);
  const departmentID = props.location.state.profileId;
  const handleExaminerChangePage = (event, value) => {
    setExaminerPage(value);
    let url = `${apiUrl.viewExaminer}?page=` + value;

    url += departmentID === "" ? "" : `&department=${departmentID}`;
    url += examinerSearch === "" ? "" : `&search=${examinerSearch}`;
    fetchClient.get(url).then((res) => {
      setExaminerCount(res && res.data && res.data.data && res.data.data.count);
      setTotalExaminerPage(
        res && res.data && res.data.data && res.data.data.pages
      );
      const examinerArray =
        res && res.data && res.data.data && res.data.data.results;
      examinerColmConversion(examinerArray);
    });
  };

  const searchExaminer = (e) => {
    setExaminerSearch(e.target.value);
    const searchValue = e.target.value;
    fetchClient
      .get(
        `${apiUrl.viewExaminer}?search=${searchValue}&department=` +
          departmentID
      )
      .then((res) => {
        setExaminerCount(
          res && res.data && res.data.data && res.data.data.count
        );
        setTotalExaminerPage(
          res && res.data && res.data.data && res.data.data.pages
        );
        const examinerArray =
          res && res.data && res.data.data && res.data.data.results;
        examinerColmConversion(examinerArray);
      });
  };

  function createExaminerData(ColA, ColB, ColC, ColD, ColE, ColF, ColG) {
    return { ColA, ColB, ColC, ColD, ColE, ColF, ColG };
  }

  const examinerColmConversion = (arr) => {
    examinerAS = [];
    for (var index = 0; index < arr.length; index++) {
      examinerAS.push(
        createExaminerData(
          arr[index] && arr[index].id,
          arr[index] && arr[index].full_name,
          arr[index] && arr[index].designation,
          arr[index] && arr[index].department,
          arr[index] &&
            convertUTCDateToLocalDate(
              new Date(arr[index].updated_at)
            ).toLocaleDateString("en-IN"),
          arr[index] && arr[index].email,
          arr[index] && arr[index].phone_number
        )
      );
      examinerIDs.push(arr[index] && arr[index].id);
      examinerEmails.push(arr[index] && arr[index].email);
      examinerNo.push(arr[index] && arr[index].phone_number);
    }
    processExaminerDataList();
  };

  const examinerSearchClear = () => {
    setExaminerSearch("");
    fetchClient
      .get(`${apiUrl.viewExaminer}?department=` + departmentID)
      .then((res) => {
        var examinersList =
          res && res.data && res.data.data && res.data.data.results;
        setExaminerCount(
          res && res.data && res.data.data && res.data.data.count
        );
        setTotalExaminerPage(
          res && res.data && res.data.data && res.data.data.pages
        );
        examinerColmConversion(examinersList);
      });
  };

  const processExaminerDataList = () => {
    const data = {
      columnnames: {
        col1: "ID",
        col2: "Name",
        col3: "Designation",
        col5: "Last Updated"
      },
      ExaminerIDs: examinerIDs,
      ExaminerEmails: examinerEmails,
      ExaminerNo: examinerNo,
      List: examinerAS,
      ListKey: Object.keys(examinerAS),
      TableType: "ExaminerD",
      EditPath: `/${role}/examiner/edit-profile`,
      profile: `/${role}/examiner/profile`
    };

    setExaminerData(data);
  };

  const handleJobChangePage = (event, value) => {
    let url = `${apiUrl.viewJobs}?page=` + value;
    url += jobStatus === "" ? "" : `&status=${jobStatus}`;
    url += departmentID === "" ? "" : `&department=${departmentID}`;
    url += jobSearch === "" ? "" : `&search=${jobSearch}`;
    fetchClient.get(url).then((res) => {
      var JobsList = res && res.data && res.data.data && res.data.data.results;
      setTotalJobPage(res && res.data && res.data.data && res.data.data.pages);
      jobColmConversion(JobsList);
    });
    setJobPage(value);
  };

  function createJobData(ColA, ColB, ColC, ColD, ColE, ColF, ColG) {
    return { ColA, ColB, ColC, ColD, ColE, ColF, ColG };
  }

  const jobColmConversion = (arr) => {
    console.log("arrrrr", arr);
    jobAS = [];
    for (var index = 0; index < arr.length; index++) {
      jobAS.push(
        createJobData(
          arr[index] && arr[index].id,
          arr[index].job_title,
          arr[index] && arr[index].department,
          arr[index] && arr[index].job_level,
          arr[index] && arr[index].status,
          arr[index] &&
            convertUTCDateToLocalDate(
              new Date(arr[index].created_at)
            ).toLocaleDateString("en-IN"),
          arr[index] && arr[index].experience
        )
      );

      jobIDs.push(arr[index] && arr[index].id);
      jobExperience.push(arr[index] && arr[index].experience);
    }
    processDataList();
  };

  const filterJobs = (e) => {
    setJobStatus("");
    setJobLevel("");
    setJobSearch(e.target.value);
    jobSearchValue = e.target.value;
    fetchClient
      .get(
        `${apiUrl.viewJobs}?search=${jobSearchValue}&department=${departmentID}`
      )
      .then((res) => {
        setJobCount(res && res.data && res.data.data && res.data.data.count);
        setTotalJobPage(
          res && res.data && res.data.data && res.data.data.pages
        );
        var JobsList =
          res && res.data && res.data.data && res.data.data.results;
        jobColmConversion(JobsList);
      });
  };

  const jobFilterSelection = (e, type) => {
    setJobSearch("");
    url_job.searchParams.set("department", departmentID);
    if (type === "jobStatus") {
      setJobStatus(e.target.value);

      if (e.target.value !== "") {
        url_job.searchParams.set("status", e.target.value);
      } else {
        url_job.searchParams.delete("status");
      }
    }
    if (type === "levelSelect") {
      setJobLevel(e.target.value);
      if (e.target.value !== "") {
        url_job.searchParams.set("job_level", e.target.value);
      } else {
        url_job.searchParams.delete("job_level");
      }
    }

    fetchClient.get(url_job).then((res) => {
      var JobsList = res && res.data && res.data.data && res.data.data.results;
      setJobCount(res && res.data && res.data.data && res.data.data.count);
      setTotalJobPage(res && res.data && res.data.data && res.data.data.pages);
      jobColmConversion(JobsList);
    });
  };

  const jobSearchClear = () => {
    setJobSearch("");
    fetchClient
      .get(`${apiUrl.viewJobs}?department=${departmentID}`)
      .then((res) => {
        var JobsList =
          res && res.data && res.data.data && res.data.data.results;
        setJobCount(res && res.data && res.data.data && res.data.data.count);
        setTotalJobPage(
          res && res.data && res.data.data && res.data.data.pages
        );
        jobColmConversion(JobsList);
      });
  };

  const dispatch = useDispatch();

  const EXAM_LEVEL = [
    { label: "Exam Level", value: "job_level" },
    { label: "Senior", value: "senior" },
    { label: "Junior", value: "junior" },
    { label: "Internship", value: "internship" }
  ];

  const EXAM_STATUS = [
    { label: "Status", value: "status" },
    { label: "Publish", value: "publish" },
    { label: "Unpublish", value: "unpublish" },
    { label: "Draft", value: "draft" }
  ];

  useEffect(() => {
    dispatch({
      type: Actions.SET_FILTER,
      count: 2,
      data: [EXAM_LEVEL, EXAM_STATUS]
    });
  }, []);

  useEffect(() => {
    dispatch({
      type: TableActions.SET_TABLE_COLUMN_HEADING,
      data: ["ID", "Job Title", "Job Level", "Status", "Last Updated"]
    });
  }, []);

  function modifyTimestamp(timestamp) {
    var dtObject = new Date(timestamp);
    var formattedDate =
      ("0" + dtObject.getDate()).slice(-2) +
      "/" +
      ("0" + (dtObject.getMonth() + 1)).slice(-2) +
      "/" +
      dtObject.getFullYear();

    return formattedDate;
  }

  useEffect(() => {
    fetchClient
      .get(`${apiUrl.viewJobs}?department=${departmentID}&${options1}`)
      .then((res) => {
        const result = res.data.data;
        const count = result.count;
        const jobData = result.results.map((item) => {
          return {
            0: item.id,
            1: item.job_title,
            2: item.job_level,
            3: item.status,
            4: modifyTimestamp(item.updated_at),

            editAction: {
              editpath: `/${role}/examiner/edit-profile`,
              examinerName: item.full_name,
              examinerEmail: item.email,
              examinerNo: item.phone_number,
              examinerDept: item.department,
              examinerDesignation: item.designation,
              examinerID: item.id,
              allow: false
            }
          };
        });
        dispatch({
          type: TableActions.SET_TABLE_ROW,
          data: jobData,
          tableHeading: "Examiners",
          profile: `/${role}/job/profile`,
          count: count
        });

        dispatch({
          type: TableActions.SET_PAGINATION,
          data: { pages: result.pages, count: result.count }
        });

        setJobLoader(true);
        setTotalJobPage(
          res && res.data && res.data.data && res.data.data.pages
        );
        setJobCount(result.count);
        const JobsArray =
          res && res.data && res.data.data && res.data.data.results;
        jobColmConversion(JobsArray);
      });
  }, [dataArray]);

  useEffect(() => {
    fetchClient
      .get(`${apiUrl.viewExaminer}?department=` + departmentID)
      .then((res) => {
        setExaminerLoader(true);
        setExaminerCount(
          res && res.data && res.data.data && res.data.data.count
        );
        setTotalExaminerPage(
          res && res.data && res.data.data && res.data.data.pages
        );
        const examinerArray =
          res && res.data && res.data.data && res.data.data.results;
        examinerColmConversion(examinerArray);
      });
    fetchClient.get(`${apiUrl.deptList}` + departmentID).then((res) => {
      setDept(res && res.data && res.data.data && res.data.data.name);
    });
  }, [dataArray]);

  const processDataList = () => {
    const data = {
      columnnames: {
        col1: "ID",
        col2: "Job Title",
        col4: "Job Level",
        col5: "Status",
        col6: "Date Added"
      },

      List: jobAS,
      ListKey: Object.keys(jobAS),
      TableType: "JobD",
      EditPath: `/${role}/job/edit`,
      profile: `/${role}/job/profile`,
      jobIDs: jobIDs,
      jobExperience: jobExperience,
      Type: "jobs"
    };

    setJobData(data);
  };

  const getPermissionsForSubAdmin = () => {
    let permission = {};
    if (role === "sub-admin") {
      let allPermissions = StorageManager.getJSON(SUB_ADMIN_PERMISSION);
      permission.edit = allPermissions.includes("EDIT_JOB");
      permission.delete = allPermissions.includes("DELETE_JOB");
    }
    return permission;
  };

  const departmentDetails = {
    MainHeading: dept,
    MainSubheading1: "Department Lobby",
    MainSubheading2: dept,
    PaperHeading: " Department Details",
    Labels: {
      label1: "Name",
      label2: "Date Added",
      label7: "Status"
    },
    DetailBox: {
      box1: dept,
      box2: props.location.state.dateAdded,
      box7: props.location.state.departmentStatus
    },

    home: `/${role}/department`,
    EditPath: `/${role}/department/edit`,
    ProfileType: "Department",
    permissions: getPermissionsForSubAdmin(),
    departmentID: departmentID
  };

  return (
    <main className={classes.content}>
      {/* <Commonprofile data={departmentDetails}> */}
      <Grid container className={listClasses.container} xs={12}>
        <Grid item lg={12} xl={12} md={12} sm={12}>
          <Grid container xs={12}>
            <Grid item lg={12} xl={12}>
              {/* <Grid
                  container
                  className={listClasses.allExam_container}
                  lg={12}
                  xl={12}
                >
                  <Grid
                    item
                    lg={3}
                    xl={3}
                    md={3}
                    sm={3}
                    style={{ display: "flex" }}
                    align="left"
                    justify="left"
                  >
                    <div className={listClasses.all_examiners_heading}>
                      Jobs Associated
                    </div>
                    <div
                      className={listClasses.examiner_nos}
                      style={{ marginLeft: "-10px" }}
                    >
                      {jobCount}
                    </div>
                  </Grid>

                  <Grid
                    item
                    lg={3}
                    xl={3}
                    md={3}
                    sm={3}
                    align="right"
                    justify="center"
                  >
                    <div className={listClasses.searchExaminers}>
                      <TextField
                        placeholder="Search "
                        id="outlined-basic"
                        variant="outlined"
                        onChange={(e) => filterJobs(e)}
                        value={jobSearch}
                        className={listClasses.searchExaminerfield}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment>
                              <IconButton>
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: jobSearch && (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => jobSearchClear()}
                            >
                              <CancelRoundedIcon />
                            </IconButton>
                          ),
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    xl={6}
                    md={6}
                    sm={6}
                    align="right"
                    justify="right"
                  >
                    <div className={listClasses.multiDropdown}>
                      <FormControl
                        style={{
                          textAlign: "center",
                          marginTop: "-16px",
                          width: "122px",
                        }}
                      >
                        <Select
                          id="demo-customized-select-native"
                          displayEmpty
                          value={jobStatus}
                          onChange={(e) => jobFilterSelection(e, "jobStatus")}
                          MenuProps={MenuProps}
                          input={<OutlinedInput style={{ height: "48px" }} />}
                        >
                          <MenuItem value="">Status</MenuItem>
                          <MenuItem value="publish">Publish</MenuItem>
                          <MenuItem value="unpublish">Unpublish</MenuItem>
                          <MenuItem value="draft">Drafts</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl
                        style={{
                          textAlign: "center",
                          marginTop: "-16px",
                          width: "142px",
                        }}
                      >
                        <Select
                          id="demo-customized-select-native"
                          displayEmpty
                          value={jobLevel}
                          onChange={(e) => jobFilterSelection(e, "levelSelect")}
                          MenuProps={MenuProps}
                          input={<OutlinedInput style={{ height: "48px" }} />}
                        >
                          <MenuItem value="">Level</MenuItem>
                          <MenuItem value="senior">Senior</MenuItem>
                          <MenuItem value="junior">Junior</MenuItem>
                          <MenuItem value="internship">Internship</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid> */}
              <Grid container>
                <TableTopBar
                  heading="Job Associated"
                  candidateFilter={{
                    item: "candidate"
                  }}
                  handleChangeFilter="null"
                />
              </Grid>

              <Grid container xs={12}>
                <div className={listClasses.examiners_table_wrapper}>
                  <CommonTable count={jobCount} />
                </div>
              </Grid>
            </Grid>
          </Grid>

          {/* <div className={listClasses.pagination}>
            <Pagination
              count={totalJobPage}
              page={jobPage}
              onChange={handleJobChangePage}
            />
          </div> */}
        </Grid>
      </Grid>

      {/* <Grid container xs={12}>
        <Grid item lg={12} xl={12} md={12} sm={12}>
          <Paper className={listClasses.paper}>
            <Grid container xs={12}>
              <Grid item lg={12} xl={12}>
                <Grid
                  container
                  className={listClasses.allExam_container}
                  lg={12}
                  xl={12}
                >
                  <Grid
                    item
                    lg={3}
                    xl={3}
                    md={3}
                    sm={3}
                    style={{ display: "flex" }}
                    align="left"
                    justify="left"
                  >
                    <div className={listClasses.all_examiners_heading}>
                      Assigned Examiners
                    </div>
                    <div
                      className={listClasses.examiner_nos}
                      style={{ marginLeft: "-10px" }}
                    >
                      {examinerCount}
                    </div>
                  </Grid>

                  <Grid
                    item
                    lg={3}
                    xl={3}
                    md={3}
                    sm={3}
                    align="right"
                    justify="center"
                  >
                    <div className={listClasses.searchExaminers}>
                      <TextField
                        placeholder="Search"
                        id="outlined-basic"
                        variant="outlined"
                        onChange={(e) => searchExaminer(e)}
                        value={examinerSearch}
                        className={listClasses.searchExaminerfield}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment>
                              <IconButton>
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: examinerSearch && (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => examinerSearchClear()}
                            >
                              <CancelRoundedIcon />
                            </IconButton>
                          ),
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
                {examinerLoader ? (
                  <>
                    {examinerCount ? (
                      <Grid container xs={12}>
                        <div className={listClasses.examiners_table_wrapper}>
                          <CommonTable data={examinerData} />
                        </div>
                      </Grid>
                    ) : (
                      <Emptyscreen image="/assets/Icons/CandidateEmptyIcon.svg" />
                    )}
                  </>
                ) : (
                  <Paper
                    className={classes.paper}
                    style={{
                      height: "380px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/assets/Icons/nickelfox_loader.gif"
                      width="100px"
                      height="100px"
                      alt="nickelfoxlogo"
                    />
                  </Paper>
                )}
              </Grid>
            </Grid>
          </Paper>
          <div className={listClasses.pagination}>
            <Pagination
              count={totalExaminerPage}
              page={examinerPage}
              onChange={handleExaminerChangePage}
            />
          </div>
        </Grid>
      </Grid> */}
      {/* </Commonprofile> */}
    </main>
  );
}
