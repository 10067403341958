import { React, useEffect } from "allImport";
import { API, ENDPOINTS } from "../../../api/apiService";

// Redux imports
import { useDispatch, useSelector } from "react-redux";
import Actions from "redux/actions/filterActions";
import TableActions from "redux/actions/tableActions";

// Components imports
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import TablePageLayout from "layout/TablePageLayout";
import CommonTable from "components/CommonComponent/Filter/CommonTable/CommonTable";

// Others imports
import { convertUTCDateToLocalDate } from "Common/CommonFunction";
const queryString = require("query-string");
const role = localStorage.getItem("ROLE")
  ? localStorage.getItem("ROLE").toLowerCase()
  : null;

export default function Dashboard() {
  const dispatch = useDispatch();
  const { searchData } = useSelector((store) => store.search);
  const [totalPages, setTotalPages] = React.useState(1);
  const [loader, setLoader] = React.useState(false);
  const [departments, setDepartments] = React.useState([]);
  const [pageColor, setPageColor] = React.useState(null);
  const [filter, setFilter] = React.useState({
    page: 1,
    page_size: 10,
    search: "",
    question_type: 0,
    question_status: 0,
    experience_level: 0,
    department: 0,
  });

  useEffect(() => {
    setFilter({ ...filter, page: 1 });
    setPageColor(1);
  }, [searchData]);
  const { dataArray } = useSelector((store) => store.filter);
  const { reset } = useSelector((store) => store.table);

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    let isCanclled = false;
    const handleChange = async () => {
      await timeout(200);
      if (!isCanclled) {
        fetchRecord();
      }
    };
    handleChange();
    return () => {
      isCanclled = true;
    };
  }, [dataArray, filter, searchData, reset]);

  const queryBuilder = (url, options) => {
    const options1 = queryString.stringify({
      ...dataArray,
    });
    console.log("xtrfc", options.page);
    if (options === undefined || !options) return url;
    return `${url}?page_size=10${
      options.page ? `&page=${options.page}` : ""
    }&${options1}${searchData ? `&search=${searchData}` : ""}`;
  };

  const fetchRecord = async () => {
    try {
      setLoader(true);

      const query = queryBuilder(ENDPOINTS.EXAMINER, filter);
      console.log(query, "eskokara");
      const resp = await API.get(query);

      if (resp.success) {
        let SUB_ADMIN_PERMISSION = localStorage.getItem("SUB_ADMIN_PERMISSION");
        const result = resp.data;
        setTotalPages(result.pages);
        console.log("datattt", result.results);
        const count = result.count;
        const examinerData = result.results.map((item) => {
          return {
            0: item.id,
            1: item.full_name,
            2: item.designation,
            3: item.department,
            4: convertUTCDateToLocalDate(
              new Date(item.updated_at)
            ).toLocaleDateString("en-IN"),

            assignExam: {
              link: "abc",
              candidateId: "abc",
              candidateName: "abc",
              deptId: "abc",
            },
            editAction: {
              editpath: `/${role}/examiner/edit-profile`,
              examinerName: item.full_name,
              examinerEmail: item.email,
              examinerNo: item.phone_number,
              examinerDept: item.department,
              examinerDesignation: item.designation,
              examinerID: item.id,
              allow: SUB_ADMIN_PERMISSION
                ? SUB_ADMIN_PERMISSION.includes("EDIT_EXAMINER")
                : true,
            },
            deleteAction: {
              actionID: item.id,
              deleteName: item.full_name,
              tableType: "Examiner",
              allow: SUB_ADMIN_PERMISSION
                ? SUB_ADMIN_PERMISSION.includes("DELETE_EXAMINER")
                : true,
            },
          };
        });
        dispatch({
          type: TableActions.SET_TABLE_ROW,
          data: examinerData,
          tableHeading: "Examiners",
          profile: `/${role}/examiner/profile`,
          count: count,
        });
        // dispatch({
        //   type: TableActions.SET_PAGINATION,
        //   data: { pages: result.pages, count: result.count },
        // });
      }
    } catch (e) {
      const msg =
        typeof e.data.error?.message === "string"
          ? e.data.error?.message
          : e.data.error?.message[0];
      props.enqueueSnackbar(
        msg || "Error fetching examiner list. Please try again",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    } finally {
      setLoader(true);
    }
  };

  const handleChange = (key) => (event) => {
    let tempData = { ...filter };
    tempData[key] = event.target.value;
    tempData["page"] = 1;
    setFilter(tempData);
  };

  const handleChangePage = (event, page) => {
    // Commented part is used to animate page on pagination

    // dispatch({
    //   type: TableActions.SET_TABLE_ROW,
    //   count: null,
    // });
    setPageColor(page);
    setFilter({ ...filter, page });
  };

  useEffect(() => {
    fetchDepartmentList();
  }, []);

  useEffect(() => {
    const allowActionColumn =
      localStorage.getItem("ROLE") === "SUB-ADMIN" &&
      localStorage.getItem("SUB_ADMIN_PERMISSION") &&
      !localStorage.getItem("SUB_ADMIN_PERMISSION").includes("EDIT_EXAMINER") &&
      !localStorage.getItem("SUB_ADMIN_PERMISSION").includes("DELETE_EXAMINER");
    dispatch({
      type: TableActions.SET_TABLE_COLUMN_HEADING,
      data: [
        "ID",
        "Name",
        "Designation",
        "Department",
        "Last Updated",
        !allowActionColumn && "Action",
      ],
    });
  }, []);

  const fetchDepartmentList = async () => {
    try {
      const resp = await API.get(
        `${ENDPOINTS.DEPARTMENT_LIST}?has_examiner=1&page_size=1000`
      );
      if (resp.success) {
        const result = resp.data;
        const res = result.results;
        let temp = res.map((item) => ({
          value: item.id,
          label: item.name,
          id: item.id,
        }));
        temp.unshift({
          label: "Department",
          value: "department",
        });
        setDepartments(temp);
      }
      console.log("temppppppp...........", departments);
    } catch (e) {
      const msg =
        typeof e.data.error?.message === "string"
          ? e.data.error?.message
          : e.data.error?.message[0];
      props.enqueueSnackbar(
        msg || "Error fetching department list. Please try again",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    }
  };

  useEffect(() => {
    dispatch({ type: Actions.SET_FILTER, count: 1, data: [departments] });
  }, [departments]);

  let SUB_ADMIN_PERMISSION = localStorage.getItem("SUB_ADMIN_PERMISSION");

  return (
    <>
      <HeaderWithBreadcrumb heading="Examiners" />
      <TablePageLayout
        heading="Examiners"
        loader={loader}
        ButtonText="Invite Examiner"
        buttonActionPath={`/${role}/examiner/invite`}
        allowCreate={
          SUB_ADMIN_PERMISSION
            ? SUB_ADMIN_PERMISSION.includes("CREATE_EXAMINER")
            : true
        }
        candidateFilter={{
          item: [departments],
          handleChangeDepartmentLevel: handleChange("department"),
          type: "examiner",

          deptSelection: (e) => deptSelection(e),
        }}
      >
        <CommonTable
          Count={totalPages}
          handleChangePage={handleChangePage}
          pageColor={pageColor}
        />
      </TablePageLayout>
    </>
  );
}
