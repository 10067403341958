import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { withSnackbar } from "notistack";
import {
  makeStyles,
  CssBaseline,
  Paper,
  Grid,
  Divider,
  Box,
  AppBar,
  Tabs,
  Tab,
  TextField,
  Button,
} from "@material-ui/core";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import { API, ENDPOINTS } from "../../../api/apiService";
import { convertDateFormat } from "../../../Common/CommonFunction";
import "chart.js/auto";
import Badge from "../../../components/Badge/Badge.js";
import { Doughnut } from "react-chartjs-2";
import Alert from "components/Dialog/alert";
import CsvDownloader from "react-csv-downloader";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { RadioButtonUncheckedRounded } from "@material-ui/icons";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import "../../../style/style.scss";
const useStyles = makeStyles((theme) => ({
  "@global": {
    "*": {
      "scrollbar-width": "thin",
    },
    "*::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
    },
  },

  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  paper: {
    //padding: theme.spacing(2),
    display: "flex",
    width: "100%",
    overflow: "hidden",
    boxShadow: "none",
    border: "1px solid #E5E5E5",
    flexDirection: "column",
    marginTop: "32px",
    borderRadius: "12px",
  },
  appBarSpacer: theme.mixins.toolbar,
  tabPanel: {
    marginTop: "24px",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    ".MuiAppBar-colorPrimary": {
      backgroundColor: "white",
    },
  },
  container: {
    // marginTop: "48px",
    // marginRight: 15,
    // paddingBottom: theme.spacing(4),

    borderRadius: "8px",
    width: "auto !important",
    maxHeight: "auto",
    height: "calc(100vh - 115px)",

    overflowY: "scroll",
    overflowX: "hidden",
    ["@media (max-width:1030px)"]: {
      padding: "10px 10px 0 10px",

      // height: "calc(100vh - 100px)",
    },
  },
  page_heading: {
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1rem",
    lineHeight: "50px",
    letterSpacing: "0.25px",
    color: "#18273B",
    [theme.breakpoints.down("md")]: {
      width: "109%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "120%",
    },
  },
  page_subheading: {
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.25px",
    color: "#6D6D6D",
    paddingTop: "4px",
    width: "176%",
  },
  link: {
    color: "#D9001D",
    textDecoration: "none",
  },
  breadcrumb: {
    padding: "0px 12px",
  },
  submission_details: {
    padding: "15px",
  },
  paper_heading: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "20px",
    lineHeight: "32px",
    letterSpacing: "0.1px",
    color: "#000000",
    fontFamily: "Mulish",
    marginBottom: "10px",
  },
  divider_position: {
    marginBottom: "20px",
  },
  label: {
    fontFamily: "Open sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "0.15px",
    textAlign: "left",
    color: "#000000",
    height: "20px",
    marginTop: "20px",
  },
  detail: {
    fontFamily: "Open sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
    letterSpacing: "0.15px",
    textAlign: "left",
    color: "#000000",
    height: "20px",
    marginTop: "8px",
    marginBottom: "20px",
    textTransform: "capitalize",
  },
  question: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "21px",
    letterSpacing: "0.1px",
    // margin: "30px 0 15px 32px",
  },
  answerContainer: {
    padding: "0 21px 0 12px",
    marginBottom: 15,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "4px",
    // height: "27px",
  },
  answerContainerInner: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "10px",
  },
  checkImage: {
    height: 15,
    width: 15,
    borderRadius: "50%",
    margin: "0px 10px 0px 10px",
  },
  answerText: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "28px",
    letterSpacing: "0.15px",
    wordBreak: "break-word",
  },
  answerStatus: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "23px",
    letterSpacing: "0.15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: "10px",
    height: "50px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  graphText: {
    fontFamily: "Open sans",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "20px",
    color: "#383838",
  },
  total: {
    fontWeight: "700",
    color: "#383838",
    paddingRight: "10px",
  },
  correct: {
    fontWeight: "700",
    color: "#29CB1B",
    paddingRight: "10px",
  },
  incorrect: {
    fontWeight: "700",
    color: "#D9001D",
    paddingRight: "10px",
  },
  unattempted: {
    fontWeight: "700",
    color: "#F7B466",
    paddingRight: "10px",
  },
  filledButton: {
    background: "#D9001D",
    borderRadius: "8px",
    textTransform: "capitalize",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.15px",
    color: "#FFFFFF",
    fontFamily: "Mulish",
    width: "192px",
    height: "48px",
    transform: "translateY(10px)",
  },
}));

const columns = [
  {
    id: "first",
    displayName: "First column",
  },
  {
    id: "second",
    displayName: "Second column",
  },
];

const chartColors = ["#29CB1B", "#D9001D", "#F7B466"];

const SubjectiveChartColors = ["#29CB1B", "#D9001D"];

let data = {
  maintainAspectRatio: false,
  responsive: false,
  labels: ["Correct Answers", "Wrong Answers", "Unattempted"],
  height: 120,

  datasets: [
    {
      data: [30, 30, 40],
      backgroundColor: chartColors,
      hoverBackgroundColor: chartColors,
    },
  ],
};

let SubjectiveChart = {
  maintainAspectRatio: false,
  responsive: false,
  labels: ["Attempted", "Unattempted"],
  height: 170,
  datasets: [
    {
      data: [50, 30],
      backgroundColor: SubjectiveChartColors,
      hoverBackgroundColor: SubjectiveChartColors,
    },
  ],
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  console.log("subjectiveRecord", subjectiveRecord);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const ResultDetails = (props) => {
  const componentRef = useRef();
  const classes = useStyles();
  const history = useHistory();

  const ExamCandidateID = props.location.state.profileId;
  const ExamID = props.location.state.ExamID;
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState([]);
  const [overview, setOverview] = useState([]);
  const [subjectiveRecord, setSubjectiveRecord] = useState([]);
  const [candidateExamId, setCandidateExamId] = useState(null);
  const [correctQuestions, setCorrectQuestions] = useState([]);
  const [incorrectQuestions, setIncorrectQuestions] = useState([]);
  const [unattemptedQuestions, setUnattemptedQuestions] = useState([]);
  const [topbarVisible, setTopBarVisible] = useState(true);
  const [chartData, setChartData] = useState(data);
  const [objectiveData, setObjectiveData] = useState({});
  const [subjectiveData, setSubjectiveData] = useState({});
  const [value, setValue] = useState(0);
  const [evaluateObj, setEvaluateObj] = useState(false);
  const [evaluateSub, setEvaluateSub] = useState(false);

  useEffect(() => {
    console.log(record, "check karo");
  }, [record]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${record.candidate?.user?.full_name + " NFX Result"}`,
    // copyStyles: true,
    // pageStyle: `{ size: A4 landscape }`,
  });

  const options = {
    plugins: {
      legend: {
        display: false,
        position: "right",
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
      lineHeight: 25,
    },
    maintainAspectRatio: false,
  };

  const handleGraph = (data) => {
    const total_questions_obj =
      data.all_question?.total_objective_question || 0;
    const correct_answers = data.all_marks?.objective_obtained_marks || 0;
    const unattempted =
      data.unattempted_question?.objective_unattempted_questions || 0;
    const wrong_answers = total_questions_obj - unattempted - correct_answers;
    let temp = { ...chartData };
    temp.datasets[0].data = [correct_answers, wrong_answers, unattempted];
    setObjectiveData({
      correct: correct_answers,
      incorrect: wrong_answers,
      unattempted: unattempted,
    });
    if (data.exam_question_flag?.is_subjective) {
      const total_questions_sub =
        data.all_question?.total_subjective_question || 0;
      const attempted_questions_sub =
        data.attempted_question?.subjective_attempted_questions || 0;
      const unattempted_questions_sub =
        total_questions_sub - attempted_questions_sub;
      let chart = { ...SubjectiveChart };
      chart.datasets[0].data = [
        attempted_questions_sub,
        unattempted_questions_sub,
      ];
      setSubjectiveData({
        all: total_questions_sub,
        attempted: attempted_questions_sub,
        unattempted: unattempted_questions_sub,
      });
    }
  };

  const splitQuestions = (questions) => {
    let incorrect = [];
    let correct = [];
    let unattempted = [];
    for (let i of questions) {
      if (i.answer_status === "WRONG") {
        incorrect.push(i.question);
      } else if (i.answer_status === "CORRECT") {
        correct.push(i.question);
      } else if (i.answer_status === "UNATTEMPTED") {
        unattempted.push(i.question);
      }
    }
    setIncorrectQuestions([...incorrect]);
    setCorrectQuestions([...correct]);
    setUnattemptedQuestions([...unattempted]);
  };

  const handleSubjectiveQuestions = (questions) => {
    const temp = questions.map((item) => {
      return {
        question: item.question.id,
        obtained_marks: item.obtained_marks || null,
        total_marks: item.question?.total_marks,
        question_text: item.question?.question_name,
        answer_text: item.answer_text,
      };
    });
    setSubjectiveRecord(temp);
  };
  const datas = [
    {
      cell1: "row 1 - cell 1",
      cell2: "row 1 - cell 2",
    },
    {
      cell1: "row 2 - cell 1",
      cell2: "row 2 - cell 2",
    },
  ];
  const handleMarksChange = (e, obj) => {
    console.log("eventValue", e.target.value);
    console.log("eventValueObject", obj);
    const temp = [...subjectiveRecord];
    const foundIndex = temp.findIndex((x) => x.question == obj.question);
    e.target.value > obj.total_marks
      ? (temp[foundIndex].obtained_marks = obj.total_marks)
      : (temp[foundIndex].obtained_marks = e.target.value);

    setSubjectiveRecord(temp);
  };

  const handleSubmit = async () => {
    console.log(subjectiveRecord, "subjectiveRecord");
    try {
      const payload = subjectiveRecord.map((item) => {
        return {
          question: item.question,
          obtained_marks: Number(item.obtained_marks),
        };
      });
      console.log("payload", payload);
      const resp = await API.post(
        `${ENDPOINTS.CANDIDATE_LIST}${candidateExamId}/evaluate/`,
        payload
      );
      if (resp.success) {
        props.enqueueSnackbar("Submitted evaluation!", {
          variant: "success",
          autoHideDuration: 3000,
        });
        //history.goBack();
        fetchRecord();
      }
    } catch (e) {
      props.enqueueSnackbar("Error submitting evaluation. Please try again", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleObjectiveSubmit = async () => {
    try {
      const resp = await API.post(
        `${ENDPOINTS.CANDIDATE_LIST}${candidateExamId}/evaluate/`,
        []
      );
      if (resp.success) {
        props.enqueueSnackbar("Submitted evaluation!", {
          variant: "success",
          autoHideDuration: 3000,
        });
        // history.goBack();
        fetchRecord();
      }
    } catch (e) {
      props.enqueueSnackbar("Error submitting evaluation. Please try again", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const fetchQuestionsAndAnswers = async (ID) => {
    try {
      const resp = await API.get(
        `${ENDPOINTS.CANDIDATE_LIST}${ID}/result/overview`
      );
      if (resp.success) {
        const result = resp.data;
        setOverview(result.results);

        if (result.results?.subjective_questions.length) {
          handleSubjectiveQuestions(result.results?.subjective_questions);
        }
        if (result.results?.objective_questions.length) {
          splitQuestions(result.results?.objective_questions);
        }
      }
    } catch (e) {
      const msg =
        typeof e?.data?.error?.message === "string"
          ? e?.data?.error?.message
          : e?.data?.error?.message[0];
      props.enqueueSnackbar(
        msg || "Error fetching questions. Please try again",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    }
  };

  const fetchRecord = async () => {
    try {
      setLoading(true);
      const resp = await API.get(
        `${ENDPOINTS.CANDIDATE_LIST}${ExamCandidateID}/exam/${ExamID}/result/`
      );
      if (resp.success) {
        const result = resp.data.results;
        const temp = result[0];
        setRecord(temp);
        handleGraph(temp);
        fetchQuestionsAndAnswers(temp.id);
        setCandidateExamId(temp.id);
        if (!temp?.exam_question_flag?.is_objective) {
          //if candidate has only subjective questions
          setValue(1);
        }
      }
    } catch (e) {
      const msg =
        typeof e.data.error?.message === "string"
          ? e.data.error?.message
          : e.data.error?.message[0];
      props.enqueueSnackbar(
        msg || "Error fetching exam details. Please try again",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      !StorageManager.get(API_TOKEN) &&
      !StorageManager.get(ADMIN_ID) &&
      !StorageManager.get(ADMIN_NAME) &&
      !StorageManager.get(LOGOUT_TOKEN)
    ) {
      history.push("/login");
    }
    fetchRecord();
  }, []);

  // Hide and Show Top Bar
  useEffect(() => {
    let scrollPos = 0;
    const listenToScroll = () => {
      const winScroll =
        document.querySelector("#main-content")?.scrollTop ||
        document.documentElement.scrollTop;
      if (winScroll <= 50) {
        setTopBarVisible(true);
      } else {
        setTopBarVisible(false);
      }
      scrollPos =
        document.querySelector("#main-content")?.scrollTop ||
        document.documentElement.scrollTop;
    };
    document
      .querySelector("#main-content")
      ?.addEventListener("scroll", listenToScroll);
    return () =>
      document
        .querySelector("#main-content")
        ?.removeEventListener("scroll", listenToScroll);
  }, []);

  const handleSubmitResult = () => {
    console.log(subjectiveRecord, "subjectiveRecord");
    const checkSubjectiveMarksEntered = subjectiveRecord.map((item) => {
      return item.obtained_marks === null || item.obtained_marks === ""
        ? "cancel"
        : null;
    });
    if (checkSubjectiveMarksEntered.includes("cancel")) {
      props.enqueueSnackbar("Fill all subjective marks.", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      setEvaluateSub(true);
    }
  };

  return (
    <>
      <main className={classes.content} id="main-content">
        <HeaderWithBreadcrumb
          margin={true}
          heading="Exam Submission"
          breadCrumb
          breadCrumb1="Submitted Exams Lobby"
          breadCrumb2={`${
            record.candidate?.user?.full_name
              ? `${record.candidate?.user?.full_name}'s`
              : "User's"
          } Sumbission`}
          link={`/${role}/submitted-exam`}
        />
        {evaluateObj ? (
          <Alert
            successText="Submit Evaluation"
            heading="Are you sure you want to submit the evaluation?"
            actionName="Submit"
            action={() => handleObjectiveSubmit()}
            cancel={() => setEvaluateObj(false)}
            goback={false}
          />
        ) : null}
        {evaluateSub ? (
          <Alert
            successText="Submit Evaluation"
            heading="Are you sure you want to submit the evaluation?"
            actionName="Submit"
            action={() => handleSubmit()}
            cancel={() => setEvaluateSub(false)}
            goback={false}
          />
        ) : null}

        {loading ? (
          <Paper
            className={classes.paper}
            style={{
              height: "380px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/assets/Icons/nickelfox_loader.gif"
              width="100px"
              height="100px"
              alt="nickelfoxlogo"
            />
          </Paper>
        ) : (
          <Grid className={classes.container}>
            {record?.exam_attempt_level?.latest_status?.status === "PASSED" ||
            record?.exam_attempt_level?.latest_status?.status === "FAILED" ? (
              <Grid container justifyContent="flex-end" item xs="auto">
                <Button
                  style={{
                    height: "40px",
                    margin: "0px 20px 0px 0px",
                  }}
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={handlePrint}
                >
                  Download Result
                </Button>
              </Grid>
            ) : null}
            <Grid container ref={componentRef} className="pagePrintWrapper">
              {/* Header */}
              <Grid
                container
                style={{ paddingBottom: "10px" }}
                alignItems="center"
              >
                <Grid container item xs={12} justifyContent="space-between">
                  {record?.exam_attempt_level?.latest_status?.status ===
                    "PASSED" ||
                  record?.exam_attempt_level?.latest_status?.status ===
                    "FAILED" ? (
                    <>
                      <Grid item xs={8} container>
                        <Badge
                          count={
                            record?.exam_attempt_level?.latest_status?.status
                          }
                          color={
                            record?.exam_attempt_level?.latest_status
                              ?.status === "PASSED"
                              ? "#29CB1B"
                              : "#D9001D"
                          }
                          width="100%"
                        >
                          <h2>
                            {`${
                              record.candidate?.user?.full_name
                                ? `${record.candidate?.user?.full_name}'s`
                                : "User's"
                            } Sumbission`}
                          </h2>
                        </Badge>
                      </Grid>

                      {/* <Grid item xs="auto">
                        <CsvDownloader
                          filename="myfile1"
                          datas={datas}
                          style={{
                            justifyContent: "flex-end",
                            padding: "0px 20px 0px 0px",
                          }}
                        >
                          <Button variant="outlined" color="primary">
                            Download Result
                          </Button>
                        </CsvDownloader>
                      </Grid> */}
                    </>
                  ) : (
                    <Grid container>
                      <h2>
                        {`${
                          record.candidate?.user?.full_name
                            ? `${record.candidate?.user?.full_name}'s`
                            : "User's"
                        } Sumbission`}
                      </h2>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {/* Submission Details */}
              <Grid item xs={12} className={classes.paper}>
                <Box className={classes.submission_details}>
                  <div className={classes.paper_heading}>
                    Submission Details
                  </div>
                  <Divider className={classes.divider_position} />
                  <Grid container>
                    <Grid item lg={3} xs={6}>
                      <div className={classes.label}>Exam</div>
                      <div className={classes.detail}>
                        {record.exam?.exam_name || "N/A"}
                      </div>
                    </Grid>
                    <Grid item lg={3} xs={6}>
                      <div className={classes.label}>Exam Level</div>
                      <div className={classes.detail}>
                        {record.exam?.experience_level || "N/A"}
                      </div>
                    </Grid>
                    <Grid item lg={3} xs={6}>
                      <div className={classes.label}>Submitted On</div>
                      <div className={classes.detail}>
                        {record.created_at
                          ? convertDateFormat(record.created_at)
                          : "N/A"}
                      </div>
                    </Grid>
                    <Grid item lg={3} xs={6}>
                      <div className={classes.label}>Attempt Level</div>
                      <div className={classes.detail}>
                        {record.exam_attempt_level?.attempt_level || "N/A"}
                      </div>
                    </Grid>
                    <Grid item lg={3} xs={6}>
                      <div className={classes.label}>Pass Percentage</div>
                      <div className={classes.detail}>
                        {record.pass_percentage
                          ? `${record.pass_percentage}%`
                          : "N.A" || "N/A"}
                      </div>
                    </Grid>
                    {(record?.exam_attempt_level?.latest_status?.status ===
                      "PASSED" ||
                      record?.exam_attempt_level?.latest_status?.status ===
                        "FAILED") && (
                      <>
                        <Grid item lg={3} xs={6}>
                          <div className={classes.label}>
                            Total Marks Obtained
                          </div>
                          <div className={classes.detail}>
                            {record?.all_marks?.obtained_marks +
                              "/" +
                              record?.all_marks?.total_marks}
                          </div>
                        </Grid>
                        <Grid item lg={3} xs={6}>
                          <div className={classes.label}>Total Percentage</div>
                          <div className={classes.detail}>
                            {record?.percentage?.total_percentage >= 0
                              ? record?.percentage?.total_percentage + "%"
                              : "N/A"}
                          </div>
                        </Grid>
                        <Grid item lg={3} xs={6}>
                          <div className={classes.label}>Evaluated By</div>
                          <div className={classes.detail}>
                            {record?.candidate?.examiner?.examiner_name ||
                              "N/A"}
                          </div>
                        </Grid>
                        <Grid item lg={3} xs={6}>
                          <div className={classes.label}>Evaluated On</div>
                          <div className={classes.detail}>
                            {record?.exam_attempt_level?.latest_status
                              ?.created_date
                              ? convertDateFormat(
                                  record?.exam_attempt_level?.latest_status
                                    ?.created_date
                                )
                              : "N/A"}
                          </div>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </Grid>
              {/* Graphical Details */}
              <Grid container spacing={2}>
                {/* Objective */}
                {record.exam_question_flag?.is_objective && (
                  <Grid
                    item
                    xs={12}
                    sm={record.exam_question_flag?.is_subjective ? 6 : 12}
                  >
                    <Paper className={classes.paper}>
                      <Box className={classes.submission_details}>
                        <div className={classes.paper_heading}>
                          {record.exam_question_flag?.is_subjective
                            ? "Objective"
                            : "Graphical Details"}
                        </div>
                        <Divider className={classes.divider_position} />
                        <Grid
                          container
                          justifyContent="center"
                          style={{
                            paddingTop: "22px",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            container
                            justifyContent="center"
                            sm={
                              record.exam_question_flag?.is_subjective ? 6 : 3
                            }
                          >
                            <div style={{ width: "120px" }}>
                              <Doughnut data={chartData} options={options} />
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={
                              record.exam_question_flag?.is_subjective ? 6 : 3
                            }
                            container
                          >
                            <Grid xs={12} className={classes.graphText}>
                              <span className={classes.total}>
                                {record.all_question.total_objective_question}
                              </span>
                              <span>Total Question &nbsp;</span>
                            </Grid>
                            <Grid xs={12} className={classes.graphText}>
                              <span className={classes.correct}>
                                {objectiveData.correct}
                              </span>
                              <span>Correct Answers </span>
                            </Grid>
                            <Grid xs={12} className={classes.graphText}>
                              <span className={classes.incorrect}>
                                {objectiveData.incorrect}
                              </span>
                              <span>Incorrect Answer &nbsp;</span>
                            </Grid>
                            <Grid xs={12} className={classes.graphText}>
                              <span className={classes.unattempted}>
                                {objectiveData.unattempted}
                              </span>
                              <span>Unattempted</span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Grid>
                )}
                {/* Subjective */}
                {record.exam_question_flag?.is_subjective ? (
                  <Grid
                    item
                    xs={12}
                    sm={record.exam_question_flag?.is_objective ? 6 : 12}
                  >
                    <Paper className={classes.paper}>
                      <Box className={classes.submission_details}>
                        <div className={classes.paper_heading}>Subjective</div>
                        <Divider className={classes.divider_position} />
                        <Grid
                          container
                          justifyContent="center"
                          style={{
                            paddingTop: "22px",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            container
                            justifyContent="center"
                          >
                            <div style={{ width: "120px" }}>
                              <Doughnut
                                data={SubjectiveChart}
                                options={options}
                              />
                            </div>
                          </Grid>
                          <Grid
                            container
                            item
                            sm={record.exam_question_flag?.is_objective ? 6 : 3}
                            // direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid item xs={12} className={classes.graphText}>
                              <span className={classes.correct}>
                                {subjectiveData.attempted}
                              </span>
                              <span>Attempted</span>
                            </Grid>
                            <Grid item xs={12} className={classes.graphText}>
                              <span className={classes.incorrect}>
                                {subjectiveData.unattempted}
                              </span>
                              <span>Unattempted</span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Grid>
                ) : null}
              </Grid>
              {/* Question Details */}
              <Box className={[classes.paper, "noBorder"]}>
                {/* Objective */}
                {/* Incorrect Answers */}
                <Box className={[classes.submission_details, "pageBreak"]}>
                  <h2>Objective</h2>

                  <div
                    className={classes.paper_heading}
                  >{`Incorrect Answer (${incorrectQuestions.length})`}</div>
                  <div>
                    {incorrectQuestions.map((i, index) => (
                      <>
                        {console.log(i, "check this answerList")}
                        <Divider className={classes.divider_position} />
                        <Grid
                          container
                          className={classes.question}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // alignItems: "flex-start",
                          }}
                        >
                          <Grid item xs="auto">
                            {" "}
                            Q{index + 1} - &nbsp;
                          </Grid>
                          <Grid item xs="auto">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: i.question_name?.replaceAll(
                                  "<img",
                                  `<img style="max-width: 350px; margin-left:-25px; border-radius: 4px; border: 1px solid #0000001A"`
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                        {i.answer_text.answers.map((answer) => (
                          <Grid
                            container
                            // spacing={2}
                            style={
                              answer.is_correct
                                ? {
                                    backgroundColor: "#29CB1B33",
                                    overflow: "scroll",

                                    marginBottom: "10px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }
                                : answer.is_selected
                                ? {
                                    backgroundColor: "#F1002033",
                                    overflow: "scroll",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "10px",
                                  }
                                : {
                                    overflow: "scroll",
                                    // width: "80vw",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "10px",
                                  }
                            }
                          >
                            <Grid
                              container
                              alignItems="center"
                              item
                              xs={12}
                              sm={10}
                              // className={classes.answerContainerInner}
                            >
                              <Grid
                                item
                                xs="auto"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {answer.is_correct ? (
                                  <img
                                    src="/assets/Icons/check_icon.svg"
                                    className={classes.checkImage}
                                    alt="check"
                                  />
                                ) : answer.is_selected ? (
                                  <img
                                    src="/assets/Icons/uncheck_icon.svg"
                                    className={classes.checkImage}
                                    alt="uncheck"
                                  />
                                ) : (
                                  <RadioButtonUncheckedRounded
                                    className={classes.checkImage}
                                    fontSize="small"
                                  />
                                )}
                              </Grid>
                              <Grid item xs className={classes.answerText}>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: answer.answer_text?.replaceAll(
                                      "<img",
                                      `<img style="max-width: 350px; border-radius: 4px; border: 1px solid #0000001A"`
                                    ),
                                  }}
                                />
                              </Grid>
                            </Grid>
                            {answer.is_correct && answer.is_selected ? (
                              <Grid
                                item
                                xs
                                sm={12}
                                md
                                className={classes.answerStatus}
                              >
                                Choosen & Correct Answer
                              </Grid>
                            ) : answer.is_selected ? (
                              <Grid
                                item
                                xs
                                sm={12}
                                md
                                className={classes.answerStatus}
                              >
                                Chosen Answer
                              </Grid>
                            ) : answer.is_correct ? (
                              <Grid
                                item
                                xs
                                sm={12}
                                md
                                className={classes.answerStatus}
                              >
                                Correct Answer
                              </Grid>
                            ) : null}
                          </Grid>
                        ))}
                      </>
                    ))}
                  </div>
                </Box>
                {/* Correct Answers */}
                <Box className={classes.submission_details}>
                  <div className={classes.paper_heading}>
                    {`Correct Answers (${correctQuestions.length})`}
                  </div>
                  <div>
                    {correctQuestions.map((i, index) => (
                      <>
                        <Divider className={classes.divider_position} />
                        <div
                          className={classes.question}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ paddingRight: "10px" }}>{index + 1}. </p>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: i.question_name?.replaceAll(
                                "<img",
                                `<img style="max-width: 350px; border-radius: 4px; margin-left:-25px; border: 1px solid #0000001A"`
                              ),
                            }}
                          />
                        </div>
                        {i.answer_text.answers.map((answer) => (
                          <Grid
                            container
                            // className={classes.answerContainer}

                            style={
                              answer.is_selected
                                ? {
                                    backgroundColor: "#29CB1B33",
                                    overflow: "scroll",

                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "10px",
                                  }
                                : {
                                    overflow: "scroll",

                                    marginTop: "10px",
                                  }
                            }
                          >
                            <Grid
                              item
                              container
                              xs={10}
                              className={classes.answerContainerInner}
                            >
                              {answer.is_selected ? (
                                <img
                                  src="/assets/Icons/check_icon.svg"
                                  className={classes.checkImage}
                                  alt=""
                                />
                              ) : (
                                <RadioButtonUncheckedRounded
                                  style={{
                                    height: "17px",
                                    marginRight: 4,
                                    marginTop: "17px",
                                  }}
                                  fontSize="small"
                                />
                              )}
                              <Grid item xs className={classes.answerText}>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: answer.answer_text?.replaceAll(
                                      "<img",
                                      `<img style="max-width: 350px; border-radius: 4px; border: 1px solid #0000001A"`
                                    ),
                                  }}
                                />
                              </Grid>
                            </Grid>

                            {answer.is_selected ? (
                              <Grid
                                item
                                container
                                justifyContent="space-around"
                                alignItems="center"
                                xs
                                className={classes.answerStatus}
                              >
                                Correct Answer
                              </Grid>
                            ) : null}
                          </Grid>
                        ))}
                      </>
                    ))}
                  </div>
                </Box>
                {/* Unattempted */}
                <Box className={classes.submission_details}>
                  <div
                    className={classes.paper_heading}
                  >{` Unattempted Answers (${unattemptedQuestions.length})`}</div>
                  <div style={{ width: "100%" }}>
                    {unattemptedQuestions.map((i, index) => (
                      <>
                        <Divider className={classes.divider_position} />
                        <Grid
                          container
                          className={classes.question}
                          style={{
                            display: "flex",
                          }}
                        >
                          <Grid item xs="auto">
                            <p style={{ paddingRight: "10px" }}>
                              Q{index + 1}.{" "}
                            </p>
                          </Grid>
                          <Grid xs={11}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: i.question_name?.replaceAll(
                                  "<img",
                                  `<img style="max-width: 350px; border-radius: 4px; margin-left:-25px; border: 1px solid #0000001A"`
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                        {i.answer_text.answers.map((answer) => (
                          <Grid
                            container
                            justifyContent="center"
                            className={classes.answerContainer}
                          >
                            <Grid
                              item
                              xs="auto"
                              className={classes.checkImage}
                              style={{
                                border: "1px solid #000000",
                              }}
                            ></Grid>
                            <Grid item xs className={classes.answerText}>
                              <Grid
                                dangerouslySetInnerHTML={{
                                  __html: answer.answer_text?.replaceAll(
                                    "<img",
                                    `<img style="max-width: 350px; border-radius: 4px; border: 1px solid #0000001A"`
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    ))}
                  </div>
                </Box>
                {/* <Box>
                  {record.exam_attempt_level?.latest_status.status ===
                  "EXAM_SUBMITTED" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "40px",
                        marginBottom: "100px",
                      }}
                    >
                      <Button
                        onClick={() => setEvaluateObj(true)}
                        className={classes.filledButton}
                        variant="contained"
                      >
                        Submit Evaluation
                      </Button>
                    </div>
                  ) : null}
                </Box> */}

                <Box className={[classes.submission_details, "noBorder"]}>
                  <h2>Subjective</h2>
                  <div
                    className={classes.paper_heading}
                  >{` All Answers (${overview.subjective_questions?.length})`}</div>
                  {subjectiveRecord.map((item, idx) => (
                    <div key={item.id} style={{ paddingBottom: 20 }}>
                      <Divider
                        style={{ marginBottom: "20px" }}
                        className={classes.divider_position}
                      />
                      <div
                        className={classes.question}
                        style={{
                          display: "flex",
                          // alignItems: "center",
                          fontFamily: "Mulish",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                        }}
                      >
                        <p
                          style={{
                            paddingRight: "10px",
                            fontFamily: "Mulish",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                          }}
                        >
                          Q{idx + 1}.{" "}
                        </p>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item.question_text?.replaceAll(
                              "<img",
                              `<img style="max-width: 350px; border-radius: 4px; margin-left:-25px; border: 1px solid #0000001A"`
                            ),
                          }}
                        />
                      </div>
                      <div>
                        <div className={classes.answerContainerInner}>
                          <div className={classes.answerText}>
                            <span style={{ fontWeight: "600" }}>
                              Ans: &nbsp;
                            </span>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item.answer_text?.replaceAll(
                                  "<img",
                                  `<img style="max-width: 350px; border-radius: 4px; border: 1px solid #0000001A"`
                                ),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {record.exam_attempt_level?.latest_status.status ===
                      "EXAM_SUBMITTED" ? (
                        <div style={{ marginTop: 20 }}>
                          <span
                            style={{
                              color: "#D9001D",
                              ontFamily: "Open sans",
                              fontStyle: "normal",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            Enter Marks
                          </span>
                          <input
                            type="number"
                            value={
                              item.obtained_marks
                                ? Math.min(
                                    item.total_marks,
                                    item.obtained_marks
                                  )
                                : null
                            }
                            onChange={(e) => handleMarksChange(e, item)}
                            style={{
                              width: "27px",
                              height: "23px",
                              borderRadius: "4px",
                              paddingLeft: 7,
                              marginRight: "3px",
                              marginLeft: "10px",
                              width: "50px",
                            }}
                          />
                          <span>{`/${item.total_marks}`}</span>
                        </div>
                      ) : (
                        <div>
                          <span style={{ fontWeight: "600" }}>
                            Marks: &nbsp;
                          </span>
                          <span
                            style={{
                              display: "inline",
                              width: "auto",
                              borderRadius: "4px",
                              border: "1px solid black",
                              padding: "2px 8px",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "black",
                              fontWeight: "600",
                              fontSize: "14px",
                              marginTop: "10px",
                            }}
                          >
                            {`${item.obtained_marks || 0}/${item.total_marks}`}
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                  {record.exam_attempt_level?.latest_status.status ===
                  "EXAM_SUBMITTED" ? (
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "100px",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={handleSubmitResult}
                        className={classes.filledButton}
                        variant="contained"
                        // color="primary"
                      >
                        Submit Evaluation
                      </Button>
                    </div>
                  ) : null}
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </main>
    </>
  );
};

export default withSnackbar(ResultDetails);
