import {
  Box,
  InputBase,
  styled,
  alpha,
  useMediaQuery,
} from "@material-ui/core";
import { SearchIcon, useEffect } from "allImport";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "redux/actions/searchAction";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  zIndex: 10,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },

  marginRight: "3%",
  width: "3.6%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto%",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  position: "relative",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 4, 1, 1),
    zIndex: 111,
    // vertical padding + font size from searchIcon

    paddingLeft: `calc(2em + ${theme.spacing(3)})`,

    transition: theme.transitions.create("width"),
    width: "75vw",
    // border: "2px solid rgb(243, 243, 243)",

    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      width: "0ch",
      "&:focus": {
        width: "28ch",
        border: "1px solid #F3F3F3",
        backgroundColor: "#fff",
      },
    },
  },
}));

function SearchAppBar(props) {
  const { tableHeading } = useSelector((store) => store.table);
  const matches = useMediaQuery("(min-width:700px)");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: Actions.SET_SEARCH, heading: tableHeading });
  }, [tableHeading]);

  const searched = (event) => {
    const value = event.target.value;
    dispatch({ type: Actions.SET_SEARCH, data: value });
  };

  return (
    <Search>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row-reverse",
        }}
      >
        <SearchIcon
          style={{
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            marginRight: "10px",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "red",
            },
          }}
        />

        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          onChange={searched}
        />
      </Box>
    </Search>
  );
}

export default memo(SearchAppBar);
