import axios from "axios";

const fetchPost = () => {
  const defaultOptions = {
    baseURL: "",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('API_TOKEN');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
  });

  return instance;
};

export default fetchPost();
