import {
  Box,
  Button,
  CardMedia,
  Container,
  Divider,
  Grid,
  withStyles,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import { useStyles as useStyles1 } from "style/Liststyle";
import TimeLineImage from "../ExamTimeLine/TimeLine/Frame 33815.svg";
import React from "react";
import ProfileDataContainer from "./ProfileDataContainer";
import ExamTimeLineRefactor from "components/ExamTimeLine/ExamTimeLineRefactor";
import checkIcon from "./check_icon.svg";
import unCheckIcon from "./uncheck_icon.svg";
// import { Checkbox } from "formik-material-ui";
const role = localStorage.getItem("ROLE")
  ? localStorage.getItem("ROLE").toLowerCase()
  : null;

const CommonProfileComponent = ({ data, children }) => {
  console.log("role", role);
  const classes1 = useStyles1();
  console.log("datattaaaa", data);
  const timelinesData = data.examTimelineData;

  const permissionData = [
    {
      Permissionlabel: "Candidate",
      data: [
        {
          label: "View Profile",
          value: "VIEW_CANDIDATE",
          onChangeData: [
            "CREATE_CANDIDATE",
            "EDIT_CANDIDATE",
            "DELETE_CANDIDATE",
            "GENERATE_EXAM_LINK",
            "HIRE_REJECT_CANDIDATE",
          ],
        },
        {
          label: "Create",
          value: "CREATE_CANDIDATE",
          onChangeData: "VIEW_CANDIDATE",
        },
        {
          label: "Edit",
          value: "EDIT_CANDIDATE",
          onChangeData: "VIEW_CANDIDATE",
        },
        {
          label: "Delete",
          value: "DELETE_CANDIDATE",
          onChangeData: "VIEW_CANDIDATE",
        },
        {
          label: "Generate Exam Link",
          value: "GENERATE_EXAM_LINK",
          onChangeData: "VIEW_CANDIDATE",
        },
        {
          label: "Reject/Hire",
          value: "HIRE_REJECT_CANDIDATE",
          onChangeData: "VIEW_CANDIDATE",
        },
      ],
    },
    {
      Permissionlabel: "Examiner",
      data: [
        {
          label: "View Profile",
          value: "VIEW_EXAMINER",
          onChangeData: ["CREATE_EXAMINER", "EDIT_EXAMINER", "DELETE_EXAMINER"],
        },
        {
          label: "Create",
          value: "CREATE_EXAMINER",
          onChangeData: "VIEW_EXAMINER",
        },
        {
          label: "Edit",
          value: "EDIT_EXAMINER",
          onChangeData: "VIEW_EXAMINER",
        },
        {
          label: "Delete",
          value: "DELETE_EXAMINER",
          onChangeData: "VIEW_EXAMINER",
        },
      ],
    },
    {
      Permissionlabel: "Exam",
      data: [
        {
          label: "View Profile",
          value: "VIEW_EXAM",
          onChangeData: ["CREATE_EXAM", "EDIT_EXAM", "DELETE_EXAM"],
        },
        {
          label: "Create",
          value: "CREATE_EXAM",
          onChangeData: "VIEW_EXAM",
        },
        {
          label: "Edit",
          value: "EDIT_EXAM",
          onChangeData: "VIEW_EXAM",
        },
        {
          label: "Delete",
          value: "DELETE_EXAM",
          onChangeData: "VIEW_EXAM",
        },
      ],
    },
    {
      Permissionlabel: "Question Bank",
      data: [
        {
          label: "View Profile",
          value: "VIEW_QUESTION_BANK",
          onChangeData: [
            "CREATE_QUESTION_BANK",
            "EDIT_QUESTION_BANK",
            "DELETE_QUESTION_BANK",
          ],
        },
        {
          label: "Create",
          value: "CREATE_QUESTION_BANK",
          onChangeData: "VIEW_QUESTION_BANK",
        },
        {
          label: "Edit",
          value: "EDIT_QUESTION_BANK",
          onChangeData: "VIEW_QUESTION_BANK",
        },
        {
          label: "Delete",
          value: "DELETE_QUESTION_BANK",
          onChangeData: "VIEW_QUESTION_BANK",
        },
      ],
    },
    {
      Permissionlabel: "Category",
      data: [
        {
          label: "View Profile",
          value: "VIEW_CATEGORY",
          onChangeData: ["CREATE_CATEGORY", "EDIT_CATEGORY", "DELETE_CATEGORY"],
        },
        {
          label: "Create",
          value: "CREATE_CATEGORY",
          onChangeData: "VIEW_CATEGORY",
        },
        {
          label: "Edit",
          value: "EDIT_CATEGORY",
          onChangeData: "VIEW_CATEGORY",
        },
        {
          label: "Delete",
          value: "DELETE_CATEGORY",
          onChangeData: "VIEW_CATEGORY",
        },
      ],
    },
    {
      Permissionlabel: "Results",
      data: [
        {
          label: "View Profile",
          value: "VIEW_RESULT",
          onChangeData: ["PASS_FAIL_CANDIDATE"],
        },
        {
          label: "Pass/Fail",
          value: "PASS_FAIL_CANDIDATE",
          onChangeData: "VIEW_RESULT",
        },
      ],
    },
    {
      Permissionlabel: "Vacancies",
      data: [
        {
          label: "View Profile",
          value: "VIEW_JOB",
          onChangeData: ["CREATE_JOB", "EDIT_JOB", "DELETE_JOB"],
        },
        {
          label: "Create",
          value: "CREATE_JOB",
          onChangeData: "VIEW_JOB",
        },
        {
          label: "Edit",
          value: "EDIT_JOB",
          onChangeData: "VIEW_JOB",
        },
        {
          label: "Delete",
          value: "DELETE_JOB",
          onChangeData: "VIEW_JOB",
        },
      ],
    },
    {
      Permissionlabel: "Department",
      data: [
        {
          label: "View Profile",
          value: "VIEW_DEPARTMENT",
          onChangeData: [
            "CREATE_DEPARTMENT",
            "EDIT_DEPARTMENT",
            "DELETE_DEPARTMENT",
          ],
        },
        {
          label: "Create",
          value: "CREATE_DEPARTMENT",
          onChangeData: "VIEW_DEPARTMENT",
        },
        {
          label: "Edit",
          value: "EDIT_DEPARTMENT",
          onChangeData: "VIEW_DEPARTMENT",
        },
      ],
    },
    {
      Permissionlabel: "Sub Admins",
      data: [
        {
          label: "View Profile",
          value: "VIEW_SUBADMIN",
          onChangeData: [
            "CREATE_SUBADMIN",
            "EDIT_SUBADMIN",
            "DELETE_SUBADMIN",
            "GIVE_PERMISSIONS",
          ],
        },
        {
          label: "Create",
          value: "CREATE_SUBADMIN",
          onChangeData: "VIEW_SUBADMIN",
        },
        {
          label: "Edit",
          value: "EDIT_SUBADMIN",
          onChangeData: "VIEW_SUBADMIN",
        },
        {
          label: "Delete",
          value: "DELETE_SUBADMIN",
          onChangeData: "VIEW_SUBADMIN",
        },
        // {
        //   label: "Give Permission",
        //   value: "GIVE_PERMISSIONS",
        //   onChangeData: "VIEW_SUBADMIN",
        // },
      ],
    },
  ];

  const isPermissionAllowed = (permission) => {
    return data.Permissions.includes(permission);
  };

  console.log("dataoooo", data);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div
          container
          maxWidth="xl"
          className={classes1.profileContainer}
          style={{ height: "calc(100vh - 120px)", maxHeight: "auto" }}
        >
          <div
            style={{
              paddingLeft: "16px",
            }}
          >
            <HeaderWithBreadcrumb
              heading={data.MainHeading}
              breadCrumb
              breadCrumb1={data.tableType}
              breadCrumb2="Profile Detail"
              link={data.home}
            />
          </div>
          <ProfileDataContainer data={data} />
          {data.type ? (
            <Grid
              container
              style={{
                // backgroundColor: "red",
                overflow: "scroll",
                border: "1px solid #E5E5E5",
                padding: "16px 16px 0 16px",
                margin: "16px 0 0 0",
              }}
            >
              <Grid xs={12}>
                <Grid
                  style={{
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "32px",
                  }}
                >
                  Permissions
                </Grid>
                <Box
                  sx={{ mt: 3 }}
                  style={{ borderBottom: "1px solid #E5E5E5" }}
                ></Box>
                {permissionData.map((item, index) => {
                  return (
                    <Box sx={{ mb: 5, mt: 3 }} container>
                      <Grid container>
                        <label
                          style={{ fontWeight: "700", paddingBottom: "10px" }}
                        >
                          {item.Permissionlabel}
                        </label>
                      </Grid>
                      <Grid sx={{ mt: 2 }} container item xs={12}>
                        {item.data.map((itm, index) => {
                          return (
                            <Grid item sm={6} md={4} lg={3}>
                              <FormControlLabel
                                label={
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      lineHeight: "18px",
                                    }}
                                  >
                                    {itm.label}
                                  </div>
                                }
                                control={
                                  // <Checkboxblack
                                  //   checked={
                                  //     isPermissionAllowed(itm.value)
                                  //       ? true
                                  //       : false
                                  //   }
                                  //   // onChange={(e) =>
                                  //   //   index == 0
                                  //   //     ? removeSubAdminPermission(
                                  //   //         e,
                                  //   //         itm.onChangeData
                                  //   //       )
                                  //   //     : addSubAdminPermission(e, itm.onChangeData)
                                  //   // }
                                  //   value={itm.value}
                                  // />

                                  isPermissionAllowed(itm.value) ? (
                                    <img
                                      src={checkIcon}
                                      style={{
                                        width: "16px",
                                        margin: "0px 10px",
                                      }}
                                      alt="check"
                                    />
                                  ) : (
                                    <img
                                      src={unCheckIcon}
                                      style={{
                                        width: "16px",
                                        margin: "0px 10px",
                                      }}
                                      alt="check"
                                    />
                                  )
                                }
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  );
                })}
              </Grid>
            </Grid>
          ) : null}
          {data.tableType === "Candidate" ? (
            <Grid
              container
              style={{
                // backgroundColor: "red",
                overflow: "scroll",
                border: "1px solid #E5E5E5",
                padding: "16px 5px 0 16px",
                margin: "16px 0 0 0",
                // ["@media (max-width:1030px)"]: {
                //   // eslint-disable-line no-useless-computed-key
                //   padding: "16px 16px 0 16px",
                // },
              }}
            >
              <Grid item xs={10}>
                <h3>TimeLine</h3>
              </Grid>
              <Grid item xs={12}>
                <Divider
                  style={{
                    height: "1px",
                  }}
                />
              </Grid>
              <Grid xs={12}>
                {data.exam_level != undefined ? (
                  <ExamTimeLineRefactor
                    candidateID={data.candidateID}
                    isLast={true}
                    data={timelinesData}
                  />
                ) : (
                  <Grid style={{ justifyContent: "center", display: "flex" }}>
                    <CardMedia
                      className={classes1.logoImg}
                      component="img"
                      image={TimeLineImage}
                      alt="img"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          ) : null}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default CommonProfileComponent;
