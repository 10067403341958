import {
  withSnackbar,
  useHistory,
  fetchPost,
  Container,
  Grid,
  React,
  fetchClient,
  apiUrl,
  useEffect,
  SuccessDialog,
} from "allImport";
import { Autocomplete } from "@material-ui/lab";
import { useStyles } from "style/Editstyle.js";
import { useStyles as useStyles2 } from "style/Liststyle";
import "style/style.scss";
import { InputLabel, makeStyles, TextField } from "@material-ui/core";

import { Field, FormikProvider, useFormik } from "formik";
import { SendExamLinkInitialValues } from "components/Form/InitialValues/SendExamLinkInitialValues";
import SendExamLinkValidation from "components/Form/validations/SendExamLinkValidation";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import CustomButton from "components/Button/CustomButton";
import BasicInput from "components/Form/InputBoxes/BasicInput";
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1);",
    },
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 5,
      paddingRight: "100px",
      width: "100%",
    },
  },
  label: {
    fontFamily: "Mulish",
    fontWeight: "600px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#383838",
  },
}));
var url = new URL(`${apiUrl.viewExams}`);
const ExamLink = (props) => {
  let classes1 = useStyles1();
  let classes2 = useStyles2();
  let candidateExamUrl = process.env.REACT_APP_CANDIDATE_URL
    ? process.env.REACT_APP_CANDIDATE_URL
    : "https://candidate.dev.nhp.foxlabs.in";
  const [loader, setLoader] = React.useState(true);
  const [examlist, setExamslist] = React.useState([]);
  const role = localStorage.getItem("ROLE").toLowerCase();
  const [exam, setExam] = React.useState("");
  const [examID, setExamID] = React.useState("");
  const [select, setSelect] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [enableButton, setEnableButton] = React.useState(false);
  const [Status, setExamStatus] = React.useState([]);
  const [statusID, setStatusID] = React.useState("");
  const [Level, setExamLevel] = React.useState("");
  const candidateID =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.candidateID;
  const candidateName =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.candidateName;
  const department =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.DeptID;

  console.log(candidateName, "deptt");
  const getDisabled = (val) => {
    if (val) return { disabled: true };
    return {};
  };
  console.log("Sttaus", statusID);
  const handleChange = (e) => {
    setEnableButton(true);
    setExam(e.target.value);
    setExamID(e.currentTarget.getAttribute("id"));
    Status &&
      Status.map((row, index) =>
        row.status === "LINK_SENT" ? setStatusID(row.id) : null
      );
  };
  const handleLevelChange = (value) => {
    // setExamLevel(e.target.value);
    Status.map((row, index) =>
      row.status === "LINK_SENT" ? setStatusID(row.id) : null
    );
    let val = value.toLowerCase();
    console.log("vallllliiee", val);
    setShow(true);
    fetchClient
      .get(
        url +
          `?page_size=1000&status=publish&experience_level=${val}&department=${department}`
      )
      .then((res) => {
        console.log("ress of fetch ------>>>>", res.data);
        setExamslist(res && res.data && res.data.data && res.data.data.results);
      });
  };

  let candidate_exam_id;

  // const SendExamLink = () => {
  //   setLoader(false);
  //   const data1 = {
  //     candidate: candidateID,
  //     exam: examID,
  //     exam_link:
  //       candidateExamUrl + `/candidate/examlink/` + examID + "/" + candidateID,
  //     login_link: candidateExamUrl + `/candidate/login/`,
  //   };
  //   fetchPost
  //     .post(`${apiUrl.examLink}`, data1)
  //     .then((res) => {
  //       candidate_exam_id =
  //         res && res.data && res.data.data && res.data.data.id;
  //       console.log("value of my res", res);
  //     })
  //     .then(() => {
  //       const data = {
  //         candidate: candidateID,
  //         candidate_exam: candidate_exam_id,
  //         exam_status: statusID,
  //       };
  //       fetchPost.post(`${apiUrl.ExamStatus}`, data).then(() => {
  //         setLoader(true);
  //         setSelect(true);
  //       });
  //     })
  //     .catch((error) => {
  //       setLoader(true);
  //       if (error.response) {
  //         if (error.response.data.code === 404) {
  //           props.enqueueSnackbar("Please fill all mandatory fields", {
  //             variant: "error",
  //             autoHideDuration: 3000,
  //           });
  //         } else if (error.response.data.error) {
  //           error.response.data.error.message.forEach((message) => {
  //             props.enqueueSnackbar(message, {
  //               variant: "error",
  //               autoHideDuration: 3000,
  //             });
  //           });
  //         }
  //       } else {
  //         props.enqueueSnackbar(
  //           "Oops! Something went wrong. Please check your network connection and try again",
  //           {
  //             variant: "error",
  //           }
  //         );
  //       }
  //     });
  // };

  const formik = useFormik({
    initialValues: SendExamLinkInitialValues,
    validationSchema: SendExamLinkValidation,
    onSubmit: (values) => {
      console.log("valuestoshow", values);
      let examID = values.examName;
      const data1 = {
        candidate: candidateID,
        exam: examID,
        pass_percentage: values.percentage,
        exam_link:
          candidateExamUrl +
          `/candidate/examlink/` +
          examID +
          "/" +
          candidateID,
        login_link: candidateExamUrl + `/candidate/login/`,
      };


      fetchPost
        .post(`${apiUrl.examLink}`, data1)
        .then((res) => {
          candidate_exam_id =
            res && res.data && res.data.data && res.data.data.id;
          console.log("value of my res", res);
     
        })
        .then(() => {
          const data = {
            candidate: candidateID,
            candidate_exam: candidate_exam_id,
            exam_status: statusID,
          };
          fetchPost.post(`${apiUrl.ExamStatus}`, data).then((res) => {
            console.log(
              "response",res
            )
            setLoader(true);
            setSelect(true);
          });
        })
        .catch((error) => {
          setLoader(true);
          if (error.response) {
            if (error.response.data.code === 404) {
              props.enqueueSnackbar("Please fill all mandatory fields", {
                variant: "error",
                autoHideDuration: 3000,
              });
            } else if (error.response.data.error) {
              error.response.data.error.message.forEach((message) => {
                props.enqueueSnackbar(message, {
                  variant: "error",
                  autoHideDuration: 3000,
                });
              });
            }
          } else {
            props.enqueueSnackbar(
              "Oops! Something went wrong. Please check your network connection and try again",
              {
                variant: "error",
              }
            );
          }
        });
    },
  });

  useEffect(() => {
    fetchClient.get(`${apiUrl.AllExamStatus}`).then((res) => {
      setExamStatus(res && res.data && res.data.data && res.data.data.results);
    });
  }, []);
  useEffect(() => {
    console.log("examlistabc", examlist);
  }, [examlist]);

  const ExamLevel = ["Senior", "Associate","Junior", "Internship"];

  return (
    <>
      {/* <main className={classes.content}>
      {select ? (
        <SuccessDialog type="profile" heading="Exam Link Sent" />
      ) : null}
      <Container maxWidth="xl" className={classes.container}>
        <Grid container xs={12} style={{ paddingBottom: "10px" }}>
          <Grid item md={12} lg={7} align="left" justify="left">
            <div className={classes.page_heading}>
              Send Exam Link - {candidateName}
            </div>
          </Grid>
          <Grid
            item
            md={12}
            lg={5}
            align="right"
            justify="right"
            className={classes.actionbutton}
          >
            <div className="button_candidate">
              <Button
                onClick={() => {
                  SendExamLink();
                }}
                className={classes.CandidateButton}
                variant="contained"
                color="primary"
                {...getDisabled(!enableButton)}
              >
                Send Exam Link
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={12} sm={6} align="left" justify="left">
            <div className={classes.page_subheading}>
              <span className={classes.goBackURL}>
                <Link
                  className={clsx(classes.page_subheading, classes.LinkStyle)}
                  to={{ pathname: `/${role}/candidate` }}
                >
                  Candidates Lobby
                </Link>
              </span>{" "}
              <span className="spc"></span> &#62; <span className="spc"></span>{" "}
              Select Exam
            </div>
          </Grid>
        </Grid>
        {!loader ? (
          <Paper
            className={classes.paper}
            style={{
              height: "240px",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <img
              src="/assets/Icons/nickelfox_loader.gif"
              width="100px"
              height="100px"
              alt="nickelfoxlogo"
            />{" "}
          </Paper>
        ) : (
          <Paper
            className={classes.paper}
            style={{ height: "240px", overflow: "hidden" }}
          >
            <div className={classes.paper_heading}>Choose Exam</div>
            <Divider className={classes.divider_position} />

            <Grid
              container
              xs={12}
              style={{
                marginLeft: "32px",
                marginBottom: "50px",
                marginTop: "32px",
              }}
            >
              <Grid
                item
                xl={3}
                lg={3}
                xs={12}
                sm={3}
                md={3}
                align="left"
                justify="left"
              >
                <div className="examiner_detail_wrapper">
                  <div className={classes.examiner_detail_label}>
                    <label>
                      Exam Level
                      <span className={classes.mandatory}>*</span>
                    </label>
                  </div>

                  <FormControl>
                    <Select
                      className={classes.select_style}
                      displayEmpty
                      id="demo-customized-select-native"
                      value={Level}
                      onChange={(e) => handleLevelChange(e)}
                      MenuProps={MenuProps}
                      input={<OutlinedInput style={{ width: "188px" }} />}
                    >
                      <MenuItem value="">Choose Exam Level</MenuItem>
                      <MenuItem value="senior">Senior</MenuItem>
                      <MenuItem value="junior">Junior</MenuItem>
                      <MenuItem value="internship">Internship</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                xs={12}
                sm={4}
                align="left"
                justify="left"
              >
                <div className="examiner_detail_wrapper">
                  <div className={classes.examiner_detail_label}>
                    <label>
                      Exam Name
                      <span className={classes.mandatory}>*</span>
                    </label>
                  </div>

                  <FormControl>
                    <Select
                      className={classes.select_style}
                      displayEmpty
                      id="demo-customized-select-native"
                      value={exam}
                      onChange={(e) => handleChange(e)}
                      MenuProps={MenuProps}
                      input={
                        <OutlinedInput
                          style={{ width: "220px" }}
                          {...getDisabled(!show)}
                        />
                      }
                    >
                      <MenuItem value="">Choose Exam Name</MenuItem>

                      {examlist &&
                        examlist.map((row, index) => (
                          <MenuItem
                            key={row.id}
                            id={row.id}
                            value={row.exam_name}
                          >
                            {row.exam_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Container>
                        </main>  */}

      <Container
        container
        maxWidth="xl"
        className={classes2.container}
        style={{ height: "calc(100vh - 120px)", maxHeight: "auto" }}
      >
        {select ? (
          <SuccessDialog type="profile" heading="Exam Link Sent" />
        ) : null}
        <HeaderWithBreadcrumb
          heading="Send Exam Link"
          breadCrumb
          breadCrumb1="Candidate"
          breadCrumb2="Send Exam Link"
          link={`/${role}/candidate`}
        />
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <BasicInput
                label="Percentage Required*"
                placeholder="Enter percentage"
                name="percentage"
                type="number"  
                values={formik.values.percentage}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                touched={formik.touched.percentage}
                errors={formik.errors.percentage}
              />
              <Grid item xs={12} lg={4} md={6}>
                <InputLabel className={classes1.label}>Exam Level*</InputLabel>

                <Autocomplete
                  classes={classes1}
                  name="examLevel"
                  id="examLevel"
                  disableClearable
                  options={ExamLevel}
                  getOptionLabel={(option) => option}
                  handleChange={formik.handleChange}
                  onChange={(experienceLevel, value) =>
                    formik.setFieldValue("examLevel", value) &&
                    formik.handleChange &&
                    handleLevelChange(value)
                  }
                  renderInput={(params) => (
                    <TextField
                      placeholder="Enter Exam Level"
                      name="examLevel"
                      id="examLevel"
                      {...params}
                      variant="outlined"
                      error={Boolean(
                        formik.touched.examLevel && formik.errors.examLevel
                      )}
                      helperText={
                        formik.touched.examLevel && formik.errors.examLevel
                      }
                      touched={formik.touched.examLevel}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      margin="normal"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} lg={4} md={6}>
                <InputLabel className={classes1.label}>Exam Name*</InputLabel>

                <Autocomplete
                  classes={classes1}
                  name="examName"
                  id="examName"
                  value={examlist.id}
                  options={examlist}
                  getOptionLabel={(option) => option.exam_name}
                  handleChange={formik.handleChange}
                  onChange={(vacancy, value) =>
                    formik.setFieldValue("examName", value.id) &&
                    formik.handleChange
                  }
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      placeholder="Select Exam Name"
                      name="examName"
                      id="examName"
                      {...params}
                      variant="outlined"
                      error={Boolean(
                        formik.touched.examName && formik.errors.examName
                      )}
                      helperText={
                        formik.touched.examName && formik.errors.examName
                      }
                      touched={formik.touched.examName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      margin="normal"
                    />
                  )}
                />
              </Grid>

              <Grid container item xs={12} spacing={3}>
                <Grid item sx={3}>
                  <CustomButton
                    type="button"
                    Buttontype="cancel"
                    label="Cancel"
                  />
                </Grid>
                <Grid item sx={3}>
                  <CustomButton
                    type="submit"
                    Buttontype="save"
                    label="Send Exam Link"
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormikProvider>
      </Container>
    </>
  );
};
export default withSnackbar(ExamLink);
