import {
  clsx,
  makeStyles,
  Paper,
  React,
  useEffect,
  useState,
  Grid,
} from "allImport";

import { commonStyle } from "commonStyle";

import { withSnackbar } from "notistack";

import StepperTimeLine from "./StepperTimeLine";
const steps = [
  "Select master blaster campaign settings",

  "Create an ad group",

  "Create an ad",
];

const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),

  container: {
    padding: "32px",
  },
}));

const ExamTimeLineRefactor = (props) => {
  console.log("propsss",props.data)
  const [examLevel, setExamLevel] = useState([]);
console.log("examLevel",examLevel)
  useEffect(() => {
    const level = Object.keys(props.data);
    setExamLevel(level);
    level.forEach(
      (data) => {
        const value = props.data[data];

        initializeData(value);
      },
      [level]
    );
  }, []);

  const classes = useStyles();

  const [data, setData] = useState({ isSet: false });

  const initializeData = (data) => {
    const timeLineData = {
      isSet: true,
    };

    setData(timeLineData);
  };

  return (
    <Grid container item xs={12} className={clsx(classes.paper)}>
      {data.isSet
        ? examLevel.map((value) => {
            return (
              <StepperTimeLine
                candidateID={props.candidateID}
                value={value}
                data={props.data[value]}
              />
            );
          })
        : null}
    </Grid>
  );
};

export default withSnackbar(ExamTimeLineRefactor);
