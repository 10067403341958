import React, { Suspense, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useIsLoggedIn } from "hooks";
import PrivateLayout from "../layout/privateLayout";

const AuthWrapper = (props) => {
  useEffect(() => {
    console.log("AMRTAS");
  });
  const isLoggedIn = useIsLoggedIn();
  const Component = props.component;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Route
        exact
        path={props.path}
        key={props.key}
        render={(props) =>
          isLoggedIn === true ? (
            <PrivateLayout {...props}>
              <Component {...props} />
            </PrivateLayout>
          ) : (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    </Suspense>
  );
};

export default AuthWrapper;
