import {
  useEffect,
  useHistory,
  fetchClient,
  apiUrl,
  fetchPost,
  SuccessDialog,
} from "allImport";

import "style/style.scss";
import { FormikProvider } from "formik";
import { useStyles as useStyles1 } from "style/Liststyle";
import { Container, Grid, InputLabel, TextField } from "@material-ui/core";
import BasicInput from "components/Form/InputBoxes/BasicInput";
import { useFormik } from "formik";
import { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { withSnackbar } from "notistack";

import { makeStyles } from "@material-ui/core/styles";

import CustomButton from "components/Button/CustomButton";

import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import { ExaminerInitialValues } from "components/Form/InitialValues/ExaminerInitialValues";
import ExaminerValidation from "components/Form/validations/ExaminerValidation";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1);",
    },
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 5,
      paddingRight: "100px",
      width: "100%",
    },
  },
  label: {
    fontFamily: "Mulish",
    fontWeight: "600px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#383838",
  },
}));

function InviteExaminer(props) {
  const classes2 = useStyles();
  const classes1 = useStyles1();

  const [departmentList, setDepartmentList] = useState([]);

  const [successToggle, setSuccessToggle] = useState(false);

  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;

  // Get Data on Initial Page Load
  useEffect(() => {
    fetchClient.get(`${apiUrl.deptList}?page_size=1000`).then((res) => {
      console.log(res.data.data.results);
      setDepartmentList(res.data.data.results);
    });
  }, []);

  const formik = useFormik({
    initialValues: ExaminerInitialValues,
    validationSchema: ExaminerValidation,
    onSubmit: (values) => {
      console.log(values);
      const data = {
        full_name: values.name,
        phone_number: values.contactNumber,
        email: values.email,
        designation: values.designation,
        department: values.department,
      };
      fetchPost
        .post(`${apiUrl.inviteExaminer}`, data)

        .then((res) => {
          setSuccessToggle(true);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.code === 404) {
              props.enqueueSnackbar(
                "Oops! Something went wrong. We are working on fixing the problem.",
                {
                  variant: "error",
                  autoHideDuration: 3000,
                }
              );
            } else if (error.response.data.error) {
              error.response.data.error.message.forEach((message) => {
                props.enqueueSnackbar(message, {
                  variant: "error",
                  autoHideDuration: 3000,
                });
              });
            }
          } else {
            props.enqueueSnackbar(
              "Oops! Something went wrong. Please check your network connection and try again",
              {
                variant: "error",
              }
            );
          }
        });
    },
  });

  return (
    <Container
      container
      maxWidth="xl"
      className={classes1.container}
      style={{ height: "calc(100vh - 120px)", maxHeight: "auto" }}
    >
      <HeaderWithBreadcrumb
        heading="Invite Examiner - Enter Details"
        breadCrumb
        breadCrumb1="Examiner"
        breadCrumb2="Invite Examiner"
        link={`/${role}/examiner`}
      />

      {successToggle ? (
        <SuccessDialog
          type="field"
          heading="Examiner Invited"
          path={`/${role}/examiner`}
        />
      ) : null}
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <BasicInput
              label="Full Name*"
              placeholder="Enter examiner's full name"
              type="name"
              values={formik.values.name}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.name}
              errors={formik.errors.name}
            />
            <BasicInput
              label="Email ID*"
              placeholder="Enter examiner's email"
              type="email"
              values={formik.values.email}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.email}
              errors={formik.errors.email}
            />
            <BasicInput
              label="Contact Number*"
              placeholder="Enter examiner's contact number"
              type="contactNumber"
              values={formik.values.contactNumber}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.contactNumber}
              errors={formik.errors.contactNumber}
            />
            <BasicInput
              label="Designation*"
              placeholder="Enter designation"
              type="designation"
              values={formik.values.designation}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.designation}
              errors={formik.errors.designation}
            />
            <Grid item xs={12} lg={4} md={6}>
              <InputLabel className={classes2.label}>Department*</InputLabel>

              <Autocomplete
                classes={classes2}
                name="department"
                id="department"
                disableClearable
                value={departmentList?.find((e) => e.id)}
                options={departmentList}
                getOptionLabel={(option) => option.name}
                handleChange={formik.handleChange}
                onChange={(department, value) =>
                  formik.setFieldValue("department", value.id) &&
                  formik.handleChange
                }
                renderInput={(params) => (
                  <TextField
                    placeholder="Enter department*"
                    name="department"
                    id="department"
                    {...params}
                    variant="outlined"
                    error={Boolean(
                      formik.touched.department && formik.errors.department
                    )}
                    helperText={
                      formik.touched.department && formik.errors.department
                    }
                    touched={formik.touched.department}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="normal"
                  />
                )}
              />
            </Grid>

            <Grid container item xs={12} spacing={3}>
              <Grid item sx={3}>
                <CustomButton
                  type="button"
                  Buttontype="cancel"
                  label="Cancel"
                />
              </Grid>
              <Grid item sx={3}>
                <CustomButton type="submit" Buttontype="save" label="Invite" />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Container>
  );
}

export default withSnackbar(InviteExaminer);
