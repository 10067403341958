const COMMON_ROUTES = {
  DEPARTMENT_LIST: "department/",
}

const AUTH_ROUTES = {
  LOGIN: "login",
  LOGOUT: "logout",
};


const CANDIDATES = {
  CANDIDATE_LIST: "candidate/",
  CANDIDATE_COUNT: "candidate/count/",
  ATTEMPT_LEVEL: "candidate/attempt-level/",
};

const QUESTION_BANK = {
  QUESTION: "question/",
};

const EXAM_LIST = {
  EXAM: "exam/",
};

const SUMBITTED_EXAM = {
  CANDIDATE: "candidate/",
};
const EXAMINER = {
  EXAMINER: "examiner/",
};

const SUMBITTED_EXAM_LIST = {
  SUBMITTED :"candidate/result/"
}

const JOB_LIST = {
  JOB :"job/"
}
 
const SUBADMIN_LIST = {
  SUBADMIN :"subadmin/"
}
 






const ENDPOINTS = {
  ...COMMON_ROUTES,
  ...AUTH_ROUTES,
  ...CANDIDATES,
  ...QUESTION_BANK,
  ...SUMBITTED_EXAM,
  ...EXAMINER,
  ...SUMBITTED_EXAM_LIST,
  ...JOB_LIST,
  ...EXAM_LIST,
  ...SUBADMIN_LIST
};

export { ENDPOINTS };
