// import {

//   Zoom,
//   Link,
//   clsx,
//   useHistory,
//   fetchClient,
//   apiUrl,
//   useEffect,
//   FormControlLabel,
//   RadioGroup,
//   Radio,
//   Container,
//   Grid,
//   makeStyles,
//   Paper,
//   Button,
//   TextField,
//   Typography,
//   React,
//   fetchUpdate,
// } from "allImport";
// import { commonStyle } from "commonStyle.js";
// import {
//   ADMIN_ID,
//   API_TOKEN,
//   LOGOUT_TOKEN,
//   ADMIN_NAME,
// } from "Storage/StorageKeys.js";
// import StorageManager from "Storage/StorageManager.js";
// import "style/style.scss";
// import { withSnackbar } from "notistack";
// const useStyles = makeStyles((theme) => ({
//   ...commonStyle(theme),

//   exam_name_label: {
//     fontFamily: "Open Sans",
//     fontSize: "20px",
//     fontStyle: "normal",
//     fontWeight: 600,
//     lineHeight: "32px",
//     letterSpacing: "0.10000000149011612px",
//     textAlign: "left",
//     paddingTop: "34.63px",
//     paddingLeft: "32px",
//     height: "34.63px",
//   },
//   experience_level_label: {
//     fontFamily: "Open Sans",
//     fontSize: "20px",
//     fontStyle: "normal",
//     fontWeight: 600,
//     lineHeight: "32px",
//     letterSpacing: "0.10000000149011612px",
//     textAlign: "left",
//     paddingTop: "32px",
//     paddingLeft: "32px",
//     height: "32px",
//   },
//   exam_name_box: {
//     paddingTop: "45.37px",
//     paddingLeft: "32px",
//   },
// }));

// const Editexam = (props) => {
//   console.log("edit exam", props.location);
//   const classes = useStyles();
//   const history = useHistory();
//   const examLevel = props.location.state.examLevel;
//   const examName = props.location.state.examName;
//   const examID = props.location.state.examID;
//   const examDept = props.location.state.examDept;
//   const [newExamName, setnewExamName] = React.useState(examName);
//   const [deptLists, setDeptlist] = React.useState([]);
//   const [newExperienceLevel, setnewExperienceLevel] = React.useState(examLevel);
//   const [QuestionIDs, setQuestionIDs] = React.useState([]);
//   const role = localStorage.getItem("ROLE")
//     ? localStorage.getItem("ROLE").toLowerCase()
//     : null;

//   const createExamField = (e) => {
//     setnewExamName(e.target.value);
//   };

//   const experienceLevelSelect = (e) => {
//     setnewExperienceLevel(e.target.value);
//   };

//   const Update = () => {
//     var d;
//     deptLists &&
//       deptLists.map((row, index) =>
//         row.name === examDept ? (d = row.id) : null
//       );

//     const data = {
//       exam_name: newExamName,
//       experience_level: newExperienceLevel,
//       department: d,
//     };
//     fetchUpdate
//       .patch(`${apiUrl.updateExam}` + examID, data)
//       .then((res) => {
//         history.replace({
//           pathname: `/${role}/exam/question`,
//           state: {
//             ExamID: res.data.data.id,
//             questionIDs: QuestionIDs ? QuestionIDs : null,
//             Department: res.data.data.department,
//           },
//         });
//       })
//       .catch((error) => {
//         props.enqueueSnackbar(error.response.data.error.message, {
//           variant: "error",
//           anchorOrigin: {
//             vertical: "top",
//             horizontal: "right",
//           },
//           TransitionComponent: Zoom,
//           autoHideDuration: 3000,
//         });
//       });
//   };
  // useEffect(() => {
  //   fetchClient
  //     .get(`${apiUrl.getExamQuestion}` + examID + "/questions/")
  //     .then((res) => {
  //       setQuestionIDs(
  //         res &&
  //           res.data &&
  //           res.data.data &&
  //           res.data.data[0] &&
  //           res.data.data[0].question
  //       );
  //     });
  //   fetchClient.get(`${apiUrl.deptList}?page_size=1000`).then((res) => {
  //     setDeptlist(res && res.data && res.data.data && res.data.data.results);
  //   });
  // }, []);

//   return (
//     <main className={classes.content}>
//       <Container maxWidth="xl" className={classes.container}>
//         <Grid container xs={12}>
//           <Grid item xs={12} sm={6} align="left" justify="left">
//             <div className={classes.page_heading}>Edit Exam</div>
//           </Grid>
//           <Grid item xs={12} sm={6} align="right" justify="right">
//             <div className="button_invite_examiner">
//               <div className="button_invite_examiner">
//                 <Button
//                   onClick={Update}
//                   className={classes.filledButton}
//                   variant="contained"
//                   color="primary"
//                 >
//                   Next
//                 </Button>
//               </div>
//             </div>
//           </Grid>
//         </Grid>
//         <Grid container xs={12}>
//           <Grid item align="left" justify="left">
//             <div className={classes.page_subheading}>
//               <Link
//                 className={clsx(classes.page_subheading, classes.LinkStyle)}
//                 to={{ pathname: `/${role}/exam` }}
//               >
//                 <span className={classes.goBackURL}>Exams Home</span>
//               </Link>{" "}
//               <span className="spc"></span> &gt; <span className="spc"></span>{" "}
//               Edit Exam
//             </div>
//           </Grid>
//         </Grid>
//         <Grid container xs={12}>
//           <Grid container xs={4}>
//             <Grid item>
//               <Paper className={classes.paper} style={{ height: "158px" }}>
//                 <Grid container xs={6}>
//                   <div style={{ display: "flex", flexDirection: "column" }}>
//                     <Typography className={classes.exam_name_label}>
//                       Exam Name
//                     </Typography>
//                     <div className={classes.exam_name_box}>
//                       <TextField
//                         value={newExamName}
//                         onChange={(e) => createExamField(e)}
//                         variant="outlined"
//                         placeholder="Exam Name"
//                         style={{ width: "291px", height: "46px" }}
//                       />
//                     </div>
//                   </div>
//                 </Grid>
//               </Paper>
//             </Grid>
//           </Grid>
//           <Grid container xs={6}>
//             <Grid item>
//               {role === "examiner" ? null : (
//                 <Paper className={classes.paper} style={{ height: "158px" }}>
//                   <Grid container xs={6}>
//                     <div style={{ display: "flex", flexDirection: "column" }}>
//                       <Typography className={classes.exam_name_label}>
//                         Department
//                       </Typography>
//                       <div className={classes.exam_name_box}>
//                         <TextField
//                           value={examDept}
//                           disabled={true}
//                           variant="outlined"
//                           placeholder="Exam Department"
//                           style={{ width: "291px", height: "46px" }}
//                         />
//                       </div>
//                     </div>
//                   </Grid>
//                 </Paper>
//               )}
//             </Grid>
//           </Grid>
//         </Grid>
//         {/* <Grid container xs={12}>
//             <Grid item lg={12} xl={12} xs={12} sm={12}>
//             {role==='examiner' ? null:<Paper className={classes.paper} style={{ height: "158px" }}>
//                <Grid container xs={12}>
//                   <div style={{ display: "flex", flexDirection: "column" }}>
//                     <Typography className={classes.exam_name_label}>Department</Typography>
//                     <div className={classes.exam_name_box}>
//                     <FormControl>
//                       <Autocomplete
//                       disableClearable
//                         input={<OutlinedInput /> }
//                         value={dept}
//                         options={deptLists}
//                         style={{marginTop:'10px',
//                         width: "300px"}}
//                         getOptionLabel={option => option.name || ""}
//                         defaultValue = {dept}
//                         onChange={(e, newValue) => newValue ? setDept(newValue.id):null }

//                         renderInput={params => (
//                           <TextField

//                             {...params}
//                             variant="outlined"
//                          />)}
//                       />
//                     </FormControl>
//                     </div>
//                   </div>
//                 </Grid>
//               </Paper>}
//             </Grid>
//           </Grid> */}
//         <Grid container xs={12} style={{ paddingTop: "20px" }}>
//           <Grid item>
//             <Paper className={classes.paper} style={{ height: "215px" }}>
//               <div style={{ display: "flex", flexDirection: "column" }}>
//                 <Typography className={classes.experience_level_label}>
//                   Exam Level
//                 </Typography>
//                 <div className={classes.exam_name_box}>
//                   <RadioGroup
//                     aria-label="gender"
//                     name="gender1"
//                     value={newExperienceLevel}
//                     onChange={(e) => experienceLevelSelect(e)}
//                   >
//                     <FormControlLabel
//                       value="senior"
//                       control={<Radio />}
//                       label="Senior"
//                     />
//                     <FormControlLabel
//                       value="junior"
//                       control={<Radio />}
//                       label="Junior"
//                     />
//                     <FormControlLabel
//                       value="internship"
//                       control={<Radio />}
//                       label="Internship"
//                     />
//                   </RadioGroup>
//                 </div>
//               </div>
//             </Paper>
//           </Grid>
//         </Grid>
//       </Container>
//     </main>
//   );
// };
// export default withSnackbar(Editexam);

import {
  useEffect,
  useHistory,
  React,
  fetchClient,
  apiUrl,
  fetchPost,
  fetchUpdate,
} from "allImport";

// import { useStyles } from "style/Editstyle.js";
import "style/style.scss";
import { FormikProvider } from "formik";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import { useStyles as useStyles1 } from "style/Liststyle";
import { Container, Grid, InputLabel, TextField, Zoom } from "@material-ui/core";
import BasicInput from "components/Form/InputBoxes/BasicInput";

import { useFormik } from "formik";
import { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { withSnackbar } from "notistack";

import { makeStyles } from "@material-ui/core/styles";

import CustomButton from "components/Button/CustomButton";

import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";

import { VacancyInitialValues } from "components/Form/InitialValues/VacancyInitialValues";
import VacancyValidation from "components/Form/validations/VacancyValidation";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1);",
    },
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 5,
      paddingRight: "100px",
      width: "100%",
    },
  },
  label: {
    fontFamily: "Mulish",
    fontWeight: "600px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#383838",
  },
}));

function EditExam(props) {
  console.log("datattt",props)
const examID = props.location.state.examID
  const classes2 = useStyles();
  const classes1 = useStyles1();
  const adminID = localStorage.getItem("ADMIN_ID");
  const [departmentList, setDepartmentList] = useState([]);
  const [box5, setBox5] = useState("");
 const [QuestionIDs, setQuestionIDs] = React.useState([]);

  const experienceLevel = ["senior", "associate","junior", "internship"];

  const history = useHistory();
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;

  // Get Data on Initial Page Load

  useEffect(() => {
    fetchClient
      .get(`${apiUrl.getExamQuestion}` + examID + "/questions/")
      .then((res) => {
        console.log("esssss",res.data.data)
        setQuestionIDs(
          res &&
            res.data &&
            res.data.data &&
            res.data.data[0] &&
            res.data.data[0].question
        );
      });
    // fetchClient.get(`${apiUrl.deptList}?page_size=1000`).then((res) => {
    //   setDeptlist(res && res.data && res.data.data && res.data.data.results);
    // });
  }, []);

  useEffect(() => {
    if (
      !StorageManager.get(API_TOKEN) &&
      !StorageManager.get(ADMIN_ID) &&
      !StorageManager.get(ADMIN_NAME) &&
      !StorageManager.get(LOGOUT_TOKEN)
    ) {
      history.push("/login");
    }

    fetchClient.get(`${apiUrl.deptList}?page_size=1000`).then((res) => {
      console.log(res.data.data.results);
      setDepartmentList(res.data.data.results);
      setBox5(
        res.data.data.results?.find(
          (e) => e.name === props.location.state.examDept
        )
        
      );
    });
  }, []);

  const handleSubmit = (values) => {

    console.log("valuesssoo",values)
    
        const data = {
      exam_name: values.vacancyTitle,
      experience_level: values.experienceLevel,
      department: box5 ? box5.id : values.department,
    };

    fetchUpdate
      .patch(`${apiUrl.updateExam}` + examID, data)
      .then((res) => {
    console.log("gggggggg",data)
     
        history.replace({
          pathname: `/${role}/exam/question`,
          state: {
            ExamID: res.data.data.id,
            Department: res.data.data.department,
            Level: values.experienceLevel,
          },
        });
      })
      .catch((error) => {
        props.enqueueSnackbar(error.response.data.error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Zoom,
          autoHideDuration: 3000,
        });
      });
    // const data = {
    //   exam_name: values.vacancyTitle,
    //   experience_level: values.experienceLevel,
    //   created_by: adminID,
    //   duration: 10,
    //   department: values.department,
    //   status: "unpublish",
    // };

    // if (!data.department) {
    //   props.enqueueSnackbar("Please select a department", {
    //     variant: "error",
    //     anchorOrigin: {
    //       vertical: "top",
    //       horizontal: "right",
    //     },
    //     TransitionComponent: Zoom,
    //     autoHideDuration: 3000,
    //   });
    //   return;
    // }
    // fetchPost
    //   .post(apiUrl.createExam, data)
    //   .then((res) => {
    //     history.replace({
    //       pathname: `/${role}/exam/question`,
    //       state: {
    //         ExamID: res.data.data.id,
    //         Department: values.department,
    //       },
    //     });
    //   })
    //   .catch((error) => {
    //     props.enqueueSnackbar(error.response.data.error.message, {
    //       variant: "error",
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "right",
    //       },
    //       TransitionComponent: Zoom,
    //       autoHideDuration: 3000,
    //     });
    //   });
  };

  const formik = useFormik({
    initialValues: {
      vacancyTitle:props.location.state.examName,
      department : props.location.state.examDept,
      experienceLevel:props.location.state.examLevel,
    },
    validationSchema: VacancyValidation,
    onSubmit: (values) => {

      handleSubmit(values);
    },
  });

  return (
    <Container
      container
      maxWidth="xl"
      className={classes1.container}
      style={{ height: "calc(100vh - 120px)", maxHeight: "auto" }}
    >
      <HeaderWithBreadcrumb
        heading="Edit Exam"
        breadCrumb
        breadCrumb1="Exam"
        breadCrumb2="Edit Exam"
        link={`/${role}/exam`}
      />

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <BasicInput
              label="Exam Name*"
              placeholder="Enter exam title"
              type="vacancyTitle"
              values={formik.values.vacancyTitle}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.vacancyTitle}
              errors={formik.errors.vacancyTitle}
            />

            <Grid item xs={12} lg={4} md={6}>
              <InputLabel className={classes2.label}>Department*</InputLabel>

              <Autocomplete
                classes={classes2}
                name="department"
                id="department"
                disableClearable
                defaultValue={{
                  name: formik.values.department,
                }}
                value={departmentList?.find((e) => e.id)}
                options={departmentList}
                getOptionLabel={(option) => option.name}
                handleChange={formik.handleChange}
                onChange={(department, value) =>
                 formik.setFieldValue("department", value.id) &&  setBox5(false) && 
                  formik.handleChange 
                }
                renderInput={(params) => (
                  <TextField
                    placeholder="Enter department*"
                    name="department"
                    id="department"
                    {...params}
                    variant="outlined"
                    error={Boolean(
                      formik.touched.department && formik.errors.department
                    )}
                    helperText={
                      formik.touched.department && formik.errors.department
                    }
                    touched={formik.touched.department}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="normal"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={4} md={6}>
              <InputLabel className={classes2.label}>
                Experience Level*
              </InputLabel>

              <Autocomplete
                classes={classes2}
                name="experienceLevel"
                id="experienceLevel"
                disableClearable
                defaultValue={formik.values.experienceLevel}
                options={experienceLevel}
                getOptionLabel={(option) => option}
                handleChange={formik.handleChange}
                onChange={(experienceLevel, value) =>
                  formik.setFieldValue("experienceLevel", value) &&
                  formik.handleChange
                }
                renderInput={(params) => (
                  <TextField
                    placeholder="Enter experience Level"
                    name="experienceLevel"
                    id="experienceLevel"
                    {...params}
                    variant="outlined"
                    error={Boolean(
                      formik.touched.experienceLevel &&
                        formik.errors.experienceLevel
                    )}
                    helperText={
                      formik.touched.experienceLevel &&
                      formik.errors.experienceLevel
                    }
                    touched={formik.touched.experienceLevel}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="normal"
                  />
                )}
              />
            </Grid>

            <Grid container item xs={12} spacing={3}>
              <Grid item sx={3}>
                <CustomButton
                  // type="cancel"
                  Buttontype="cancel"
                  label="Cancel"
                />
              </Grid>

              <Grid item sx={3}>
                <CustomButton type="submit" Buttontype="save" label="Next" />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Container>
  );
}

export default withSnackbar(EditExam);
