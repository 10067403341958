import {
  useEffect,
  Zoom,
  useHistory,
  fetchUpdate,
  apiUrl,
  Paper,
  CssBaseline,
  Box,
  Container,
  Grid,
  Button,
  Topbar,
  Sidebar,
  React,
} from "allImport";
import useStyles from "style/Profilestyle.js";
import { withSnackbar } from "notistack";
import { useStyles as useStyles1 } from "style/Liststyle";
import { ChangePasswordInitialValues } from "components/Form/InitialValues/ChangePasswordInitialValues";
import ChangepasswordValidation from "components/Form/validations/ChangePasswordValidation";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import CustomButton from "components/Button/CustomButton";
import { Input } from "@material-ui/core";
import { FormikProvider, useFormik } from "formik";
import BasicInput from "components/Form/InputBoxes/BasicInput";
const AdminChangePassword = (props) => {
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const classes = useStyles();
  const classes1 = useStyles1();
  const history = useHistory();
  const [box1, setBox1] = React.useState("");
  const [box2, setBox2] = React.useState("");
  const [box3, setBox3] = React.useState("");
  const changeHrPassword = (e, type) => {
    const value = e.target.value;
    if (type === "oldpassword") {
      setBox1(value);
    } else if (type === "newpassword") {
      setBox2(value);
    } else if (type === "confirmnewpassword") {
      setBox3(value);
    }
  };
  const edit = () => {
    const data1 = {
      current_password: box1,
      new_password: box2,
      confirm_password: box3,
    };
    fetchUpdate
      .patch(`${apiUrl.changePassword}`, data1)
      .then((res) => {
        localStorage.removeItem("API_TOKEN");
        localStorage.removeItem("ADMIN_ID");
        localStorage.removeItem("Password");
        history.push("/login");
        props.enqueueSnackbar("Successfully changed password.", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Zoom,
          autoHideDuration: 5000,
          resumeHideDuration: 2000,
        });
        setBox1("");
        setBox2("");
        setBox3("");
      })
      .catch((error) => {
        setBox1("");
        setBox2("");
        setBox3("");
        if (error.response) {
          if (error.response.data.code === 404) {
            props.enqueueSnackbar(
              "Oops! Something went wrong. We are working on fixing the problem.",
              {
                variant: "error",
                autoHideDuration: 3000,
              }
            );
          } else if (error.response.data.error) {
            error.response.data.error.message.forEach((message) => {
              props.enqueueSnackbar(message, {
                variant: "error",
                autoHideDuration: 3000,
              });
            });
          }
        } else {
          props.enqueueSnackbar(
            "Oops! Something went wrong. Please check your network connection and try again",
            {
              variant: "error",
            }
          );
        }
      });
  };
  useEffect(() => {
    if (
      !StorageManager.get(API_TOKEN) &&
      !StorageManager.get(ADMIN_ID) &&
      !StorageManager.get(ADMIN_NAME) &&
      !StorageManager.get(LOGOUT_TOKEN)
    ) {
      history.push("/login");
    }
  }, []);

  const handleSubmit = (values) => {
    const data1 = {
      current_password: values.oldpassword,
      new_password: values.newpassword,
      confirm_password: values.changepassword,
    };
    fetchUpdate
      .patch(`${apiUrl.changePassword}`, data1)
      .then((res) => {
        localStorage.removeItem("API_TOKEN");
        localStorage.removeItem("ADMIN_ID");
        localStorage.removeItem("Password");
        history.push("/login");
        props.enqueueSnackbar("Successfully changed password.", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Zoom,
          autoHideDuration: 5000,
          resumeHideDuration: 2000,
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.code === 404) {
            props.enqueueSnackbar(
              "Oops! Something went wrong. We are working on fixing the problem.",
              {
                variant: "error",
                autoHideDuration: 3000,
              }
            );
          } else if (error.response.data.error) {
            error.response.data.error.message.forEach((message) => {
              props.enqueueSnackbar(message, {
                variant: "error",
                autoHideDuration: 3000,
              });
            });
          }
        } else {
          props.enqueueSnackbar(
            "Oops! Something went wrong. Please check your network connection and try again",
            {
              variant: "error",
            }
          );
        }
      });
  };

  const formik = useFormik({
    initialValues: ChangePasswordInitialValues,
    validationSchema: ChangepasswordValidation,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Container
      container
      maxWidth="xl"
      className={classes1.container}
      style={{ height: "calc(100vh - 120px)", maxHeight: "auto" }}
    >
      <HeaderWithBreadcrumb
        heading="Change Password"
        breadCrumb
        breadCrumb1="Dashboard"
        breadCrumb2="Change Password"
        link={`/${role}/dashboard`}
      />
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <BasicInput
              label="Current Password*"
              placeholder="Enter Current Password"
              type="oldpassword"
              values={formik.values.oldpassword}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.oldpassword}
              errors={formik.errors.oldpassword}
              autofill="off"
            />
            <BasicInput
              label="New Password*"
              placeholder="Enter new Password"
              type="newpassword"
              values={formik.values.newpassword}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.newpassword}
              autocomplete="off"
              errors={formik.errors.newpassword}
            />
            <BasicInput
              label="Confirm Password*"
              placeholder="Enter Confirm Password"
              type="changepassword"
              values={formik.values.changepassword}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.changepassword}
              errors={formik.errors.changepassword}
              autocomplete="off"
            />

            <Grid container item xs={12} spacing={3}>
              <Grid item sx={3}>
                <CustomButton
                  // type="cancel"
                  Buttontype="cancel"
                  label="Cancel"
                />
              </Grid>

              <Grid item sx={3}>
                <CustomButton type="submit" Buttontype="save" label="Submit" />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Container>

    // <main className={classes.content}>

    //   <Container maxWidth="xl" className={classes.container}>
    //   <Grid container xs={12}>
    //   <Grid item xl={6} lg={6} md={6} sm={12} align="left" justify="left">
    //       <div className={classes.page_heading}>Change Password</div>
    //     </Grid>
    //     <Grid item xl={6} lg={6} md={6} sm={12} className={classes.actionbutton}>
    //       <div className="button_invite_examiner" style={{ paddingRight: "80px" }}>
    //         <Button  onClick={()=>{history.goBack()}} className={classes.outlinedButton} variant="contained" color="primary">
    //           Cancel
    //         </Button>
    //       </div>
    //       <div className="button_invite_examiner">
    //         <Button onClick={edit}  className={classes.filledButton} variant="contained" color="primary">
    //           Save
    //         </Button>
    //       </div>
    //     </Grid>
    //   </Grid>
    //   <Paper className={classes.paper} style={{ height: "180px" }}>
    //     <Grid container xs={12}>
    //       <Grid item xs={12} sm={6} align="left" justify="left">
    //         <div className={classes.examiners_select_exam_heading}>Create Password</div>
    //       </Grid>
    //       </Grid>
    //       <Grid container xs={12}>
    //         <div  className="examiner_detail_wrapper" style={{display:'flex',marginLeft:'32px'}}>
    //        < input value={box1} onChange={(e) => changeHrPassword(e, "oldpassword")}  type='password' placeholder='Old Password' className={classes.textfield} style={{marginRight:'32px'}} />
    //        < input  value={box2} onChange={(e) => changeHrPassword(e, "newpassword")} type='password' placeholder='New Password' className={classes.textfield} style={{marginRight:'32px'}} />
    //        < input  value={box3} onChange={(e) =>changeHrPassword(e, "confirmnewpassword")} type='password' placeholder='Confirm Password' className={classes.textfield} />
    //        </div>
    //    </Grid>
    //  </Paper>

    //   </Container>
    // </main>
  );
};
export default withSnackbar(AdminChangePassword);
