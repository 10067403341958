import { React, apiUrl, fetchPost, SuccessDialog } from "allImport";
import { FormikProvider } from "formik";
import { useStyles as useStyles1 } from "style/Liststyle";
import { Container, Grid } from "@material-ui/core";
import BasicInput from "components/Form/InputBoxes/BasicInput";
import { useFormik } from "formik";
import { useState } from "react";
import { withSnackbar } from "notistack";
import CustomButton from "components/Button/CustomButton";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import DepartmentValidation from "components/Form/validations/DepartmentValidation";

function EditDepartment(props) {
  const classes1 = useStyles1();
  const [successToggle, setSuccessToggle] = useState(false);
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;

  const formik = useFormik({
    initialValues: {
      department: props.location.state.departmentName,
    },

    validationSchema: DepartmentValidation,
    onSubmit: (values) => {
      console.log(values);
      const data = {
        name: values.department,
      };

      fetchPost

        .patch(
          `${apiUrl.viewDepartments}${props.location.state.departmentID}/`,
          data
        )

        .then((res) => {
          setSuccessToggle(true);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.code === 404) {
              props.enqueueSnackbar(
                "Oops! Something went wrong. We are working on fixing the problem.",
                {
                  variant: "error",
                  autoHideDuration: 3000,
                }
              );
            } else if (error.response.data.error) {
              error.response.data.error.message.forEach((message) => {
                props.enqueueSnackbar(message, {
                  variant: "error",
                  autoHideDuration: 3000,
                });
              });
            }
          } else {
            props.enqueueSnackbar(
              "Oops! Something went wrong. Please check your network connection and try again",
              {
                variant: "error",
              }
            );
          }
        });
    },
  });

  return (
    <Container
      container
      maxWidth="xl"
      className={classes1.container}
      style={{ height: "calc(100vh - 120px)", maxHeight: "auto" }}
    >
      <HeaderWithBreadcrumb
        heading="Edit Department"
        breadCrumb
        breadCrumb1="Department"
        breadCrumb2="Edit Department"
        link={`/${role}/department`}
      />

      {successToggle ? (
        <SuccessDialog
          type="field"
          heading="Department Updated Successfully"
          path={`/${role}/department`}
        />
      ) : null}
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <BasicInput
              label="Department Title*"
              placeholder="Enter Department Title"
              type="department"
              values={formik.values.department}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.department}
              errors={formik.errors.department}
            />

            <Grid container item xs={12} spacing={3}>
              <Grid item sx={3}>
                <CustomButton
                  type="button"
                  Buttontype="cancel"
                  label="Cancel"
                />
              </Grid>
              <Grid item sx={3}>
                <CustomButton type="submit" Buttontype="save" label="Save" />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Container>
  );
}

export default withSnackbar(EditDepartment);
