import Actions from "redux/actions/candidateStatusAction";

let initialState = {
candidateStatus : ""
};
const candidateStatusReducer = (state = initialState, action) => {
console.log("candidateStatus",state.candidateStatus)
  switch (action.type) {
    case Actions.STATUS_FILTER:
      return {
        ...initialState,
        candidateStatus: action.data
      };
    default:
      return state;
  }
};

export default candidateStatusReducer;