import React, { useState, useEffect } from "react";

import {
  useHistory,
  fetchUpdate,
  Zoom,
  Link,
  OutlinedInput,
  FormControl,
  MenuItem,
  SuccessDialog,
  fetchDelete,
  Divider,
  apiUrl,
  fetchPost,
  clsx,
  Radio,
  RadioGroup,
  FormControlLabel,
  CssBaseline,
  Box,
  Container,
  Grid,
  makeStyles,
  Paper,
  Button,
  TextField,
  Typography,
  Topbar,
  Sidebar,
  fetchClient,
  InputLabel,
} from "allImport";

import { useLocation } from "react-router-dom";
import { withSnackbar } from "notistack";
import ChipInput from "material-ui-chip-input";
import { commonStyle } from "commonStyle.js";
import { capitalize } from "Common/CommonFunction";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import "style/content-styles.css";
import _ from "lodash";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import { Autocomplete } from "@material-ui/lab";

import { API, ENDPOINTS } from "../../../api/apiService";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import PageTopBar from "components/PageTopBar/PageTopBar";
import CustomButton from "components/Button/CustomButton";

var obj1 = [];

const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),
  question_label: {
    fontFamily: "Open Sans",
    width: "681px",
    height: "30px",
    marginBottom: "7px",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "21px",
  },
  select_style: {
    marginTop: "12px",
    width: "307px",
  },
  option_label: {
    fontFamily: "Open Sans",
    marginLeft: "30px",
    marginTop: "7px",
    width: "651px",
    height: "30px",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "21px",
  },
  options_style: {
    display: "flex",
    // width: "1900px",
  },
  question_header: {
    fontFamily: "Mulish",
    fontStyle: "SemiBold",
    marginTop: "25px",
    fontSize: 16,
    fontWeight: 600,
  },
  question_type: {
    paddingLeft: 22,
  },
  radio_grp: {
    marginTop: -5,
  },
  editor_style: {
    marginTop: "7px",
    // width: "651px",
  },

  time_label: {
    width: "27px",
    height: "23px",
    borderRadius: "4px",
  },
  text_label: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "19px",
  },
  total_marks: {
    // marginLeft: 28,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  tag_label: {
    display: "flex",
    marginTop: "9px",
    marginBottom: "36px",
    marginLeft: "28px",
  },
  checkbox_style: {
    height: "18px",
    width: "18px",
    verticalAlign: "middle",
    marginTop: "13px",
    marginRight: "-19px",
    marginLeft: "0px",
  },
  button_style: {
    marginLeft: "30px",
    marginTop: "10px",
  },
  exam_name_label: {
    fontFamily: "Open Sans",
    width: "681px",
    height: "30px",
    marginBottom: "7px",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "21px",
    paddingTop: "32px",
  },
  experience_level_label: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: "0.10000000149011612px",
    textAlign: "left",
    // paddingTop: "32px",
    // paddingLeft: "32px",
    height: "32px",
  },
  exam_name_box: {
    paddingTop: "45.37px",
  },
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1);",
    },
  },

  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 5,
      paddingRight: "100px",
      width: "100%",
    },
  },
  label: {
    fontFamily: "Mulish",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000",
    margin: "15px 1px",
  },
}));

const QuestionBankEditQuestion = (props) => {
  console.log("propss....--->", props.location.state);
  const categoryname = props.location.state.questionCategory;
  const classes = useStyles();
  const classes1 = useStyles1();
  const location = useLocation();

  const questionID = location.state.questionID;
  const questionLevel = location.state.questionLevel;
  const questionType = location.state.questionType;
  var timeDuration = location.state.timeDuration;
  var examDept = location.state.Department;
  const editFromExam = location.state.editFromExam;
  const questionStatus = location.state.questionStatus;
  console.log(timeDuration,"timeDuration");
  var h = Math.floor(timeDuration / (60 * 60));
  timeDuration -= h * (60 * 60);
  var m = Math.floor(timeDuration / 60);
  timeDuration -= m * 60;
  const history = useHistory();
  const [correct_Count, setCorrect_count] = React.useState(0);
  const [tagList, setTaglist] = React.useState([]);
  const [value0, setValue0] = React.useState("");
  const [deptLists, setDeptlist] = React.useState([]);
  const [dept, setDept] = React.useState();
  const [count, setCount] = React.useState();
  const [Level, setLevel] = React.useState(questionLevel);
  const [t1, setT1] = React.useState(h);
  const [t2, setT2] = React.useState(m);
  const [t3, setT3] = React.useState(timeDuration);
  const [question, setQuestion] = React.useState([]);
  const [tag, settag] = React.useState([]);
  const [topbarVisible, setTopBarVisible] = React.useState(true);
  const [tags, setTags] = React.useState([]);
  const [unpublish, setUnpublish] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [obj, setobj] = React.useState({});
  const role = localStorage.getItem("ROLE").toLowerCase();
  const [jobList, setJobList] = useState([]);
  const [value, setValue] = useState(null);
  const [totalMarks, setTotalMarks] = useState("");
  const [categ, setCateg] = useState();

  const handleIncrement = () => {
    setCount((prevcount) => prevcount + 1);
    const obja = _.cloneDeep(obj);
    obja[_.uniqueId()] = { answer_text: "", is_correct: false };

    setobj(obja);
  };
  const checkbox = (e, id) => {
    e.target.checked
      ? setCorrect_count((prevcount) => prevcount + 1)
      : setCorrect_count((prevcount) => prevcount - 1);
    const obja = _.cloneDeep(obj);
    obja[id].is_correct = e.target.checked;
    setobj(obja);
  };

  const handleAddChip = (chip) => {
    setTaglist([]);
    if (!tag.includes(chip)) {
      settag([...tag, chip]);
      setTags([...tags, chip]);
    }
  };
  const handleImageTextInput = (editor, field, id) => {
    var data = editor.getData();
    var s = "";
    if (data.match(/<img[^>]+src="?([^"\s]+)"?\s*>/g)) {
      editor.isReadOnly = true;
      var data1 = data.matchAll(/<img[^>]+src="?([^"\s]+)"?\s*>/g);
      data1 = [...data1];
      data1.map((s1) => (s += s1));
      data = s.split(",")[0];
    }
    handleCkEditor(data, field, id);
  };
  const handleDeleteChip = (chip, index) => {
    tagList &&
      tagList.map((row, index) =>
        row.tag === chip
          ? fetchDelete.delete(apiUrl.deleteTag + row.id + `/delete/`)
          : null
      );
    tag.splice(index, 1);
    tags.splice(index, 1);
    settag([...tag]);
    setTags([...tags]);
  };

  const tagChange = (e) => {
    if (e.target.value) {
      fetchClient.get(`${apiUrl.tag}?search=` + e.target.value).then((res) => {
        setTaglist(res && res.data && res.data.data);
      });
    } else {
      setTaglist([]);
    }
  };
  function handleRemove(id) {
    setCount((prevcount) => prevcount - 1);
    console.log(id);
    const obja = _.cloneDeep(obj);
    delete obja[id];

    setobj(obja);
    console.log("array", obja);
  }
  console.log("deptlist------000000000000000", deptLists);
  function handleCkEditor(data, i, id) {
    obj1 = _.cloneDeep(obj);
    if (id) {
      obj1[i] = { answer_id: id, answer_text: data, is_correct: false };
    } else {
      obj1[i] = { answer_text: data, is_correct: false };
    }

    setobj(obj1);

    console.log("ckeditorarr", obj);
  }

  var setID = (item, index) => {
    var fullname = { tag_name: item };
    return fullname;
  };

  var convert = (tag) => {
    var output = tag.map(setID);

    return output;
  };

  const handlePublish = () => {
    if (questionType === "objective") {
      changeQuestionStatus();
    } else if (questionType === "subjective") {
      handleSubjectivePublish();
    }
  };

  const changeQuestionStatus = () => {
    const data1 = {
      question_name: value0,
      department: dept ? dept.id : "",
      experience_level: Level,
      question_status: questionStatus === "publish" ? "unpublish" : "publish",
      time_duration: Number(t1) * 3600 + Number(t2) * 60 + Number(t3),
      total_marks: totalMarks ? Number(totalMarks) : 1,
      question_category: value.id,
    };
    count >= 2 && correct_Count > 0
      ? fetchUpdate
          .patch(apiUrl.createQuestion + questionID, data1)
          .then((res) => {
            const id = res.data.data.id;
            fetchPost.post(apiUrl.addTag + id + "/tag/", convert(tags));
            fetchPost
              .post(apiUrl.createOption + id + "/answers", Object.values(obj))
              .then((res) => {
                setUnpublish(true);
              })
              .catch((error) => {
                setUnpublish(false);
                props.enqueueSnackbar("option field may not be blank", {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  TransitionComponent: Zoom,
                  autoHideDuration: 5000,
                  resumeHideDuration: 2000,
                });
              });
          })
          .catch((error) => {
            setUnpublish(false);
            if (error.response.status === 404) {
              props.enqueueSnackbar(
                "Oops! Something went wrong. We are working on fixing the problem.",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  TransitionComponent: Zoom,
                  autoHideDuration: 5000,
                  resumeHideDuration: 2000,
                }
              );
            } else {
              props.enqueueSnackbar(error.response.data.error.message, {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                TransitionComponent: Zoom,
                autoHideDuration: 5000,
                resumeHideDuration: 2000,
              });
            }
          })
      : props.enqueueSnackbar(
          "Please Add Atleast Two Options And Select Atleast One Correct Answer ",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Zoom,
            autoHideDuration: 5000,
            resumeHideDuration: 2000,
          }
        );
  };

  const handleSubjectivePublish = async () => {
    try {
      const payload = {
        question_name: value0,
        department: dept.id,
        experience_level: Level,
        question_status: questionStatus === "publish" ? "unpublish" : "publish",
        total_marks: Number(totalMarks),
        time_duration: Number(t1) * 3600 + Number(t2) * 60 + Number(t3),
        question_category: value ? value.id : null,
      };
      const resp = await API.patch(
        `${ENDPOINTS.QUESTION}${questionID}`,
        payload
      );
      if (resp.success) {
        const result = resp.data;
        handleUpdateTags("status");
      }
    } catch (e) {
      const msg =
        typeof e.data.error?.message === "string"
          ? e.data.error?.message
          : e.data.error?.message[0];
      props.enqueueSnackbar(
        msg || "Error publishing question. Please try again",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    } finally {
      // setLoader(false);
    }
  };

  const handleUpdate = () => {
    if (questionType === "objective") {
      Update();
    } else if (questionType === "subjective") {
      handleSubjectiveUpdate();
    }
  };

  const handleUpdateTags = async (type) => {
    try {
      const payload = convert(tags);
      const resp = await API.post(
        `${ENDPOINTS.QUESTION}${questionID}/tag/`,
        payload
      );
      if (resp.success) {
        if (type === "update") {
          setUpdate(true);
        } else if (type === "status") {
          setUnpublish(true);
        }
      }
    } catch (e) {
      const msg =
        typeof e.data.error?.message === "string"
          ? e.data.error?.message
          : e.data.error?.message[0];
      props.enqueueSnackbar(msg || "Error saving tags. Please try again", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      // setLoader(false);
    }
  };

  const handleSubjectiveUpdate = async () => {
    try {
      const payload = {
        question_name: value0,
        department: dept.id,
        experience_level: Level,
        total_marks: Number(totalMarks),
        time_duration: Number(t1) * 3600 + Number(t2) * 60 + Number(t3),
        question_category: value.id ? value.id : null,
      };
      const resp = await API.patch(
        `${ENDPOINTS.QUESTION}${questionID}`,
        payload
      );
      if (resp.success) {
        const result = resp.data;
        handleUpdateTags("update");
      }
    } catch (e) {
      const msg =
        typeof e.data.error?.message === "string"
          ? e.data.error?.message
          : e.data.error?.message[0];
      props.enqueueSnackbar(
        msg || "Error updating question. Please try again",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    } finally {
      // setLoader(false);
    }
  };

  const Update = () => {
    const data1 = {
      question_name: value0,
      department: dept.id,
      experience_level: Level,
      time_duration: Number(t1) * 3600 + Number(t2) * 60 + Number(t3),
      total_marks: 1,
      question_category: value ? value.id : null,
    };
    count >= 2 && correct_Count > 0
      ? fetchUpdate
          .patch(apiUrl.createQuestion + questionID, data1)
          .then((res) => {
            const id = res.data.data.id;
            fetchPost.post(apiUrl.addTag + id + "/tag/", convert(tags));
            fetchPost
              .post(apiUrl.createOption + id + "/answers", Object.values(obj))
              .then((res) => {
                setUpdate(true);
              })
              .catch((error) => {
                setUpdate(false);
                props.enqueueSnackbar("option field may not be blank", {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  TransitionComponent: Zoom,
                  autoHideDuration: 5000,
                  resumeHideDuration: 2000,
                });
              });
          })
          .catch((error) => {
            setUpdate(false);
            if (error.response.status === 404) {
              props.enqueueSnackbar(
                "Oops! Something went wrong. We are working on fixing the problem.",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  TransitionComponent: Zoom,
                  autoHideDuration: 5000,
                  resumeHideDuration: 2000,
                }
              );
            } else {
              props.enqueueSnackbar(error.response.data.error.message, {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                TransitionComponent: Zoom,
                autoHideDuration: 5000,
                resumeHideDuration: 2000,
              });
            }
          })
      : props.enqueueSnackbar(
          "Please Add Atleast Two Options And Select Atleast One Correct Answer",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            TransitionComponent: Zoom,
            autoHideDuration: 5000,
            resumeHideDuration: 2000,
          }
        );
  };

  useEffect(() => {
    if (
      !StorageManager.get(API_TOKEN) &&
      !StorageManager.get(ADMIN_ID) &&
      !StorageManager.get(ADMIN_NAME) &&
      !StorageManager.get(LOGOUT_TOKEN)
    ) {
      history.push("/login");
    }
    if (questionType === "subjective") {
      fetchClient
        .get(`${apiUrl.getQuestion}` + questionID + "/answers")
        .then((res) => {
          setQuestion(
            res && res.data && res.data.data && res.data.data.question
          );
          setValue(
            res &&
              res.data &&
              res.data.data &&
              res.data.data.question.question_category
          );
          setTotalMarks(
            res &&
              res.data &&
              res.data.data &&
              res.data.data.question.total_marks
          );
          setValue0(
            res &&
              res.data &&
              res.data.data &&
              res.data.data.question.question_name
          );
          res.data.data.answers.map((a) =>
            a.is_correct ? setCorrect_count((prevcount) => prevcount + 1) : null
          );
          if (res.data.data.tags != null && res.data.data.tags.length !== 0) {
            const ob = Object.assign(
              ...res.data.data.tags.map((a) => ({ [_.uniqueId()]: a.tag }))
            );
            settag(Object.values(ob));
          }
          setCount(res.data.data.answers.length);
          if (
            res.data.data.answers != null &&
            res.data.data.answers.length !== 0
          ) {
            const obj1 = Object.assign(
              ...res.data.data.answers.map((a) => ({
                [_.uniqueId()]: {
                  answer_id: a.answer_id,
                  answer_text: a.answer_text,
                  is_correct: a.is_correct,
                },
              }))
            );
            setobj(obj1);
          }
        });
      fetchClient.get(`${apiUrl.deptList}?page_size=1000`).then((res) => {
        setDeptlist(res && res.data && res.data.data && res.data.data.results);
        setDept(
          res &&
            res.data &&
            res.data.data &&
            res.data.data.results.find((e) => e.name === examDept)
        );
      });
    } else {
      fetchClient
        .get(`${apiUrl.getQuestion}` + questionID + "/answers")
        .then((res) => {
          setQuestion(
            res && res.data && res.data.data && res.data.data.question
          );
          setValue(
            res &&
              res.data &&
              res.data.data &&
              res.data.data.question.question_category
          );
          setValue0(
            res &&
              res.data &&
              res.data.data &&
              res.data.data.question.question_name
          );
          res.data.data.answers.map((a) =>
            a.is_correct ? setCorrect_count((prevcount) => prevcount + 1) : null
          );
          if (
            res.data.data.tags[0] != null &&
            res.data.data.tags[0].length !== 0
          ) {
            const ob = Object.assign(
              ...res.data.data.tags[0].map((a) => ({ [_.uniqueId()]: a.tag }))
            );
            settag(Object.values(ob));
          }
          setCount(res.data.data.answers.length);
          if (
            res.data.data.answers != null &&
            res.data.data.answers.length !== 0
          ) {
            const obj1 = Object.assign(
              ...res.data.data.answers.map((a) => ({
                [_.uniqueId()]: {
                  answer_id: a.answer_id,
                  answer_text: a.answer_text,
                  is_correct: a.is_correct,
                },
              }))
            );
            setobj(obj1);
          }
        });
      fetchClient.get(`${apiUrl.deptList}?page_size=1000`).then((res) => {
        setDeptlist(res && res.data && res.data.data && res.data.data.results);
        setDept(
          res &&
            res.data &&
            res.data.data &&
            res.data.data.results.find((e) => e.name === examDept)
        );
      });
    }
    fetchClient.get(`${apiUrl.createCategory}`).then((res) => {
      setJobList(res.data.data.results);
      console.log("----------This is blah blah--------", res.data.data.results);
    });
  }, []);

  // Hide and Show Top Bar
  useEffect(() => {
    let scrollPos = 0;
    const listenToScroll = () => {
      const winScroll =
        document.querySelector("#main-content")?.scrollTop ||
        document.documentElement.scrollTop;
      if (winScroll <= 50) {
        setTopBarVisible(true);
      } else {
        setTopBarVisible(false);
      }
      scrollPos =
        document.querySelector("#main-content")?.scrollTop ||
        document.documentElement.scrollTop;
    };
    document
      .querySelector("#main-content")
      ?.addEventListener("scroll", listenToScroll);
    return () =>
      document
        .querySelector("#main-content")
        ?.removeEventListener("scroll", listenToScroll);
  }, []);

  return (
    <Container
      container
      maxWidth="xl"
      className={classes.container}
      style={{
        height: "calc(100vh - 120px)",
        overflow: "auto",
      }}
    >
      <HeaderWithBreadcrumb
        heading="Edit Question"
        breadCrumb
        breadCrumb1="Question"
        breadCrumb2="Edit Question"
        link={`/${role}/question-bank/questions`}
      />

      {unpublish ? (
        <SuccessDialog
          heading={`Question ${
            questionStatus === "publish" ? "Unpublished" : "Published"
          }`}
          path={`/${role}/question-bank/questions`}
        />
      ) : null}
      {update ? (
        <SuccessDialog
          heading="Question Updated"
          path={`/${role}/question-bank/questions`}
        />
      ) : null}

      <PageTopBar heading="Question">
        <Grid item>
          <CustomButton
            handleButtonClick={handleUpdate}
            type="button"
            Buttontype="submit"
            label="Update"
          />
        </Grid>
        <Grid item>
          <CustomButton
            handleButtonClick={handlePublish}
            type="submit"
            Buttontype="save"
            label={questionStatus === "publish" ? "Unpublish" : "Publish"}
          />
        </Grid>
      </PageTopBar>

      <Grid
        container
        sx={12}
        style={{
          // overflow: "scroll",
          height: "100%",
          padding: "10px 0px 120px 0px",
        }}
      >
        <Grid>
          {/* Choose Department */}
          {role === "examiner" || editFromExam ? null : (
            <Grid item xl={6} lg={6} xs={12} sm={6} align="left" justify="left">
              <InputLabel className={classes1.label}>Department*</InputLabel>

              <FormControl>
                <Autocomplete
                  disableClearable
                  input={<OutlinedInput />}
                  value={dept ? deptLists.find((e) => e.id === dept.id) : null}
                  options={deptLists}
                  style={{ marginTop: "10px", width: "300px" }}
                  getOptionLabel={(option) => capitalize(option.name) || ""}
                  size="medium"
                  onChange={(e, newValue) =>
                    newValue ? setDept(newValue) : null
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </FormControl>
            </Grid>
          )}

          <Grid item xl={4} lg={4} xs={12} sm={6} align="left" justify="left">
            <InputLabel className={classes1.label}>Category</InputLabel>

            <FormControl>
              <Autocomplete
                disableClearable
                input={<OutlinedInput />}
                value={value ? jobList.find((e) => e.id === value.id) : null}
                options={jobList}
                style={{ margin: "10px 0px 10px 0px", width: "300px" }}
                size="medium"
                getOptionLabel={(option) => capitalize(option.name) || ""}
                onChange={(e, newValue) =>
                  newValue ? setValue(newValue) : null
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        {/* Question Type */}
        <Grid item xs={12}>
          <div className={classes.editor_style}>
            <div style={{ marginBottom: 20 }}>
              <span className={classes.question_header}>Edit Question</span>
            </div>
            <Grid item xs={7}>
              <CKEditor
                className="ck-content"
                key={question.question_name}
                data={question.question_name}
                editor={Editor}
                config={{
                  simpleUpload: {
                    uploadUrl: apiUrl.ckEditorImageUpload,
                    withCredentials: true,
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "API_TOKEN"
                      )}`,
                    },
                  },
                }}
                onReady={(editor) => {}}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setValue0(data);
                }}
              />
            </Grid>
          </div>
        </Grid>
        {/* Answers */}
        {questionType === "objective" ? (
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            align="left"
            justify="left"
          >
            <h1 className={classes.question_header}>Edit Answers</h1>
            {Object.keys(obj).map((field, index) => {
              return (
                <Grid container style={{ margin: "15px 0px" }}>
                  <Grid
                    item
                    xs={12}
                    container
                    className={clsx("option_wrapper", classes.options_style)}
                  >
                    <label item xs="auto" className="container">
                      <input
                        style={{ width: "100px" }}
                        type="checkbox"
                        checked={obj[field].is_correct}
                        onChange={(e) => {
                          checkbox(e, field);
                        }}
                      />

                      <span className="mark"></span>
                    </label>
                    <Grid item xs={9}>
                      <CKEditor
                        id={field}
                        className="ck-content"
                        editor={Editor}
                        config={{
                          simpleUpload: {
                            uploadUrl: apiUrl.ckEditorImageUpload,
                            withCredentials: true,
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem(
                                "API_TOKEN"
                              )}`,
                            },
                          },
                        }}
                        data={obj[field].answer_text}
                        onReady={(editor) => {}}
                        onChange={(event, editor) => {
                          handleImageTextInput(
                            editor,
                            field,
                            obj[field].answer_id
                          );
                        }}
                        onBlur={(event, editor) => {}}
                        onFocusout={(event, editor) => {}}
                      />
                    </Grid>

                    <Grid item xs="auto" key={field}>
                      <Button
                        style={{ marginTop: "10px" }}
                        onClick={() => handleRemove(field)}
                      >
                        <img src="/assets/Icons/cross_icon.svg" alt="" />
                      </Button>
                    </Grid>
                    <Grid item xs="auto">
                      {" "}
                      {obj[field].is_correct ? (
                        <h4
                          style={{
                            width: "140px",
                            fontSize: "20px",
                            marginTop: "9px",
                            color: "#D9001D",
                          }}
                        >
                          correct answer
                        </h4>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

            <Button
              onClick={() => {
                handleIncrement();
              }}
            >
              <img
                src="/assets/Icons/add_icon.svg"
                alt=""
                style={{ marginRight: "10px" }}
              />
              <span className={classes.page_subheading}>Add Option</span>
            </Button>

            <Divider style={{ marginTop: "23px" }} />
          </Grid>
        ) : null}
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="left"
          justify="left"
        >
          <Grid
            container
            className="timer_wrapper"
            style={{
              display: "flex",

              marginTop: "20px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              className={classes.question_header}
              style={{ marginBottom: "15px" }}
            >
              Timer
            </Grid>
            <Grid item xs="auto">
              <input
                type="number"
                min="0"
                max="10"
                style={{ width: "auto" }}
                onChange={(e) => {
                 

                  console.log("valuesss",e.target.value)
                   setT1(e.target.value > 23 ? 23 : e.target.value);
                }}
                // value={Math.min(23, t1)}
                value = {t1>23 ? setT1(23) : t1}
                className={classes.time_label}
              />
              <span style={{ marginLeft: "5px", marginRight: "6px" }}>h :</span>
            </Grid>
            <Grid item xs="auto">
              <input
                type="number"
                min="0"
                max="59"
                style={{ width: "auto" }}
                onChange={(e) => {
                  setT2(e.target.value > 59 ? 59 : e.target.value);
                }}
                value = {t2>59 ? setT2(59) : t2}
                className={classes.time_label}
              />
              <span style={{ marginLeft: "5px", marginRight: "6px" }}>m :</span>
            </Grid>
            <Grid item xs="auto">
              <input
                type="number"
                min="0"
                max="59"
                style={{ width: "auto" }}
                onChange={(e) => {
                  setT3(e.target.value > 59 ? 59 : e.target.value);
                }}
                value={t3>59 ? setT3(59) : t3}
                className={classes.time_label}
              />
              <span style={{ marginLeft: "5px" }}>s</span>
            </Grid>
            {questionType === "subjective" ? (
              <Grid
                container
                className={classes.total_marks}
                spacing={2}
                style={{ marginTop: "20px" }}
              >
                <Grid item className={classes.text_label}>
                  Total Marks
                </Grid>
                <Grid item>
                  <input
                    type="number"
                    min="0"
                    max="100"
                    style={{ width: "auto" }}
                    onChange={(e) => {
                      setTotalMarks(e.target.value);
                    }}
                    value={totalMarks ? Math.min(100, totalMarks) : null}
                    className={classes.time_label}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Divider style={{ marginTop: "20px" }} />
          <Grid
            container
            className={classes.question_label}
            style={{ marginTop: "20px" }}
          >
            Tags
          </Grid>
          <Grid
            container
            className={classes.tag_label}
            style={{ display: "block" }}
          >
            <ChipInput
              disableUnderline
              placeholder="Type here"
              alwaysShowPlaceholder={true}
              onKeyUp={(e) => tagChange(e)}
              value={tag}
              onAdd={(chip) => handleAddChip(chip)}
              onDelete={(chip, index) => handleDeleteChip(chip, index)}
            />

            {tagList.map((qw) => (
              <MenuItem
                value={qw.tag_name}
                onClick={(e) => {
                  handleAddChip(qw.tag_name);
                }}
              >
                {qw.tag_name}
              </MenuItem>
            ))}
          </Grid>
          <Divider style={{ marginTop: "20px" }} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="left"
          justify="left"
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography className={classes.experience_level_label}>
              Question Level
            </Typography>
            <div
              className={classes.exam_name_box}
              style={{ marginBottom: "20px" }}
            >
              <RadioGroup
                value={Level}
                onChange={(e) => {
                  setLevel(e.target.value);
                }}
                aria-label="gender"
                name="gender1"
              >
                <FormControlLabel
                  value="senior"
                  control={<Radio />}
                  label="Senior"
                />
                   <FormControlLabel
                  value="associate"
                  control={<Radio />}
                  label="Associate"
                />
                <FormControlLabel
                  value="junior"
                  control={<Radio />}
                  label="Junior"
                />
                <FormControlLabel
                  value="internship"
                  control={<Radio />}
                  label="Internship"
                />
              </RadioGroup>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
export default withSnackbar(QuestionBankEditQuestion);
