import { Grid, InputLabel, makeStyles } from "@material-ui/core";
import { Field } from "formik";
import { TextField } from "material-ui-formik-components/TextField";

import React from "react";

const useStyles = makeStyles(() => ({
  label: {
    fontFamily: "Mulish",
    fontWeight: "700px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#383838",
  },
}));

const BasicInput = ({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  name,
  type,
  label,
  placeholder,
  componentType,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={12}
      lg={componentType === "additionalInfo" ? 4 : 4}
      md={6}
      style={{ height: "137px" }}
    >
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Field
        id={type}
        placeholder={placeholder}
        name={name}
        type={type}
        component={TextField}
        value={values}
        variant="outlined"
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched ? errors : ""}
        error={touched && Boolean(errors)}
        margin="normal"
        maxlength="9"
        fullWidth
        disabled={disabled} 
      />
    </Grid>
  );
};

export default BasicInput;
