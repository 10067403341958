import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    ["@media (max-width:1030px)"]: {
      // eslint-disable-line no-useless-computed-key
      display: "none",
    },
  },
}));

const HeaderWithBreadcrumb = ({
  heading,
  breadCrumb,
  breadCrumb1,
  breadCrumb2,
  breadCrumb3,
  breadCrumb4,
  link,
  margin,
}) => {
  const classes = useStyles();
  return (
    <Grid
      item
      className={classes.root}
      container
      xs={6}
      style={{
        position: "absolute",
        top: breadCrumb ? "20px" : "30px",
        marginLeft:
          breadCrumb === null || (breadCrumb && !margin) ? "-13px" : "0",
      }}
    >
      <Grid item xs="auto" align="left" justify="left">
        <h2 style={{ margin: "0", display: "inline-block" }}>{heading}</h2>
      </Grid>
      {breadCrumb && (
        <Grid item xs={12} align="left" justify="left">
          <div style={{ width: "100%" }}>
            <Link
              style={{ color: "red", textDecoration: "none", fontSize: "13px" }}
              to={link}
            >
              {breadCrumb1}
            </Link>{" "}
            <span style={{ color: "#6D6D6D", fontSize: "13px" }}>
              {" "}
              &nbsp; &#62; &nbsp;{" "}
            </span>{" "}
            <span style={{ color: "#6D6D6D", fontSize: "13px" }}>
              {breadCrumb2}
            </span>
            {breadCrumb3 && (
              <>
                <span style={{ color: "#6D6D6D", fontSize: "13px" }}>
                  {" "}
                  &nbsp; &#62; &nbsp;{" "}
                </span>{" "}
                <span style={{ color: "#6D6D6D", fontSize: "13px" }}>
                  {breadCrumb3}
                </span>
              </>
            )}
            {breadCrumb4 && (
              <>
                <span style={{ color: "#6D6D6D", fontSize: "13px" }}>
                  {" "}
                  &nbsp; &#62; &nbsp;{" "}
                </span>{" "}
                <span style={{ color: "#6D6D6D", fontSize: "13px" }}>
                  {breadCrumb4}
                </span>
              </>
            )}
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default HeaderWithBreadcrumb;
