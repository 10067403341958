import {
  useEffect,
  useHistory,
  React,
  fetchClient,
  apiUrl,
  fetchPost,
  SuccessDialog,
} from "allImport";

import "style/style.scss";
import { Field, Formik, FormikProvider } from "formik";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import { useStyles as useStyles1 } from "style/Liststyle";
import { Container, Grid, InputLabel, TextField } from "@material-ui/core";

import BasicInput from "components/Form/InputBoxes/BasicInput";
import { useFormik } from "formik";
import { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { withSnackbar } from "notistack";

import { makeStyles } from "@material-ui/core/styles";

import CustomButton from "components/Button/CustomButton";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";

import CategoryValidation from "components/Form/validations/CategoryValidation";
import { Checkbox } from "@material-ui/core";
import {
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1);",
    },
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 5,
      paddingRight: "100px",
      width: "100%",
    },
  },
  label: {
    fontFamily: "Mulish",
    fontWeight: "600px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#383838",
  },
}));

const icon = <CheckBoxOutlineBlankOutlined fontSize="small" />;
const checkedIcon = <CheckBoxOutlined fontSize="small" />;

function EditCategory(props) {
  console.log("--------------->>>Hell", props);
  const catID = props.location.state.categoryID;
  const catList = props.location.state.categoryList;
  const catJob = [];
  const vacancy =[];

  catList.forEach((value) =>{vacancy.push(value.job_title) ,  catJob.push(value.id)});
  console.log("catjob", catList);

  const classes2 = useStyles();
  const classes1 = useStyles1();
  const [selectedVacancy, setSelectedVacancy] = useState([...vacancy]);

  const [jobList, setJobList] = useState([]);
  const [candidateToggle, setCandidateToggle] = useState(false);

  const history = useHistory();
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;

  const handleJobChange = (newValue) => {
    console.log("newvalue--------", newValue);
  };

  // Get Data on Initial Page Load
  useEffect(() => {
    if (
      !StorageManager.get(API_TOKEN) &&
      !StorageManager.get(ADMIN_ID) &&
      !StorageManager.get(ADMIN_NAME) &&
      !StorageManager.get(LOGOUT_TOKEN)
    ) {
      history.push("/login");
    }
    fetchClient.get(`${apiUrl.jobList}?status=publish`).then((res) => {
      setJobList(res.data.data.results);
      console.log("------------------", res.data.data.results[0].job_title);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      name: props.location.state.categoryName,
      job: catJob,
    },
    validationSchema: CategoryValidation,
    onSubmit: (values) => {
      console.log("value of submit form .........", values);
      const data = {
        name: values.name,
        job: values.job,
      };
      fetchPost
        .patch(`${apiUrl.createCategory}/${catID}`, data)
        .then((res) => {
          setCandidateToggle(true);
          console.log("helloo i am res property -----------", res);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.code === 404) {
              props.enqueueSnackbar(
                "Oops! Something went wrong. We are working on fixing the problem.",
                {
                  variant: "error",
                  autoHideDuration: 3000,
                }
              );
            } else if (error.response.data.error) {
              error.response.data.error.message.forEach((message) => {
                props.enqueueSnackbar(message, {
                  variant: "error",
                  autoHideDuration: 3000,
                });
              });
            }
          } else {
            props.enqueueSnackbar(
              "Oops! Something went wrong. Please check your network connection and try again",
              {
                variant: "error",
              }
            );
          }
        });
    },
  });

  const [Checked, setChecked] = useState(formik.initialValues.job);

  const handleToggle = (item, value) => {
    let currentIndex;
    let newChecked;
    console.log("new value ----->", value.id);
    currentIndex = Checked.indexOf(value.id);

    newChecked = [...Checked];
    console.log("new checked---", newChecked);
    if (currentIndex === -1) {
      newChecked.push(value.id);
      setSelectedVacancy([...selectedVacancy, value.job_title]);

    } else {
      newChecked.splice(currentIndex, 1);
      selectedVacancy.pop(value.job_title)
      setSelectedVacancy([...selectedVacancy]);

    }

    setChecked(newChecked);

    formik.setFieldValue(item, newChecked);
  };
  return (
    <Container
      container
      maxWidth="xl"
      className={classes1.container}
      style={{ height: "calc(100vh - 120px)", maxHeight: "auto" }}
    >
      <HeaderWithBreadcrumb
        heading="Edit Category"
        breadCrumb
        breadCrumb1="Category"
        breadCrumb2="Edit category"
        link={`/${role}/category`}
      />

      {candidateToggle ? (
        <SuccessDialog
          type="field"
          heading="Category Updated Successfully"
          path={`/${role}/category`}
        />
      ) : null}
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <BasicInput
              label="Category*"
              placeholder="Enter Category"
              type="name"
              values={formik.values.name}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              touched={formik.touched.name}
              errors={formik.errors.name}
            />

            <Grid item xs={12} lg={4} md={6}>
              <InputLabel className={classes2.label}>Vacancy*</InputLabel>

              <Autocomplete
                classes={classes2}
                name="job"
                id="job"
                disableCloseOnSelect
                multiple
                value={jobList?.find((e) => e.id)}
                options={jobList}
                getOptionLabel={(option) => option.job_title}
                handleChange={formik.handleChange}
                onChange={(vacancy, value) =>
                  handleToggle("job", value.pop()) &&
                  handleJobChange(value) &&
                  formik.handleChange
                }
                disableClearable
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      // checked={selected}
                      checked={Checked.indexOf(option.id) === -1 ? false : true}
                    />
                    {option.job_title}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <Field
                    component={TextField}
                    placeholder={selectedVacancy}
                    name="job"
                    id="job"
                    {...params}
                    variant="outlined"
                    error={Boolean(formik.touched.job && formik.errors.job)}
                    helperText={formik.touched.job && formik.errors.job}
                    touched={formik.touched.job}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="normal"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={4} md={6}></Grid>
            <Grid container item xs={12} lg={4} md={6} spacing={3}>
              <Grid item sx={3}>
                <CustomButton
                  type="button"
                  Buttontype="cancel"
                  label="Cancel"
                />
              </Grid>
              <Grid item sx={3}>
                <CustomButton type="submit" Buttontype="save" label="Save" />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Container>
  );
}

export default withSnackbar(EditCategory);
