import {
  useHistory,
  DialogContent,
  Dialog,
  MuiDialogTitle,
  IconButton,
  withStyles,
  React,
  Typography,
  CloseIcon,
} from "allImport";
import Lottie from "react-lottie";
import successAnimation from "./success.json";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  ModelSize: {
    height: "300px",
    minWidth: "500px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "90%",
      height: "auto",
      padding: "0px",
      paddingBottom: "40px",
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const CustomizedDialogs = withStyles(styles)((props) => {
  console.log(props, "print this one");
  const pathtoExit = props.path;

  const [open, setOpen] = React.useState(true);
  const { classes } = props;
  const history = useHistory();
  const handleClose = () => {
    setOpen(false);
    pathtoExit ? history.replace(pathtoExit) : history.goBack();
    // props.setResendLink()
  };
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paper: classes.ModelSize }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} />

        <DialogContent>
          <div className="success_bar">
            <Lottie options={defaultOptions} height={180} width={300} />
          </div>

          <Typography gutterBottom style={{ paddingBottom: 0 }}>
            {props.heading}
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
});
export default CustomizedDialogs;
