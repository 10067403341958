import * as Yup from "yup";


const SubadminValidation = Yup.object().shape({
  full_name: Yup.string()
    .required("Required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),

    email: Yup.string().email("Invalid email").required("Required"),


    designation: Yup.string()
    .required("Required")
    .max(50),
});

export default SubadminValidation;
