import React from "react";
import { Paper, makeStyles } from "allImport";
export const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    left: "0",
    right: "0",
  },
  emptyicon: {
    marginTop: "100px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "32px",
    letterSpacing: "0.1px",
    color: "rgba(0, 0, 0, 0.3)",
    fontFamily: "Mulish",
  },

  empty_wrapper: {
    textAlign: "center",
    marginTop: "40px",
  },
}));
export default function Emptyscreens(props) {
  const classes = useStyles();
  return (
    <div className={props.absolute ? undefined : classes.root}>
      <div className={classes.empty_wrapper}>
        <img
          src="/assets/Icons/no_entry.svg"
          className={props.absolute ? undefined : classes.emptyicon}
          alt=""
        />
      </div>
    </div>
  );
}
