import * as Yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const CandidateValidation = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),

  email: Yup.string().email("Invalid email").required("Required"),
  jobAssociated: Yup.string().required("Required"),
  examinerList: Yup.string().required("Required"),
  contactNumber: Yup.string()
    .matches(phoneRegExp, "Contact number is not valid")
    .nullable("Required")
    .required("Required")
    .max(10, "Contact number must be 10 digits")
    .min(10, "Contact number must be 10 digits"),
  totalExperience: Yup.number()
    .required("Total experience is required")
    .typeError("Enter valid experience in years")
    // .positive("Experience can't start with a minus")
    .max(50),
});

export default CandidateValidation;
