import { useEffect } from "react";
import Actions from "redux/actions/searchAction";

let initialState = {
  searchData: null,
  heading: null,
};

const filterReducer = (state = initialState, action) => {
  console.log("CFH134HJp", action.heading);
  switch (action.type) {
    case Actions.SET_SEARCH:
      return {
        ...initialState,
        searchData: action.data,
        heading: action.heading,
      };
    case Actions.RESET_SEARCH:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default filterReducer;
