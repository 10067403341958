import {
  withSnackbar,
  Formik,
  Field,
  Yup,
  Form,
  Grid,
  makeStyles,
  Button,
  Typography,
  React,
  fetchPost,
  apiUrl,
  SuccessDialog,
  DeleteIcon,
} from "allImport";
import { TextField } from "formik-material-ui";
import { commonStyle } from "commonStyle.js";
import LeftBox from "../Login/LeftBox";
import { Box, IconButton, InputLabel } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { KeyboardBackspace } from "@material-ui/icons";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";

import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),
  forgotpassword_heading: {
    fontFamily: "Mulish",
    fontSize: "50px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "63px",
    width: "450px",
    height: "63px",
    color: "#18273B",
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
  buttonbox: {
    paddingTop: "3.55vh",
  },

  emaillabel: {
    marginTop: "2rem",
    color: "#444444",
    fontFamily: "Open Sans",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: " 400",
    lineHeight: " 24px",
    letterSpacing: "0.5px",
    textAlign: "left",
  },

  discription: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    // fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: "0.10000000149011612px",
    textAlign: "left",
    color: "#A8A8A8",
    marginTop: "2.66vh",
    height: "64px",
    width: "500px",
  },

  form: {
    marginTop: "32px",
  },
}));
const ForgotPassword = (props) => {
  const classes = useStyles();
  const [emailSent, setEmailSent] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const history = useHistory();
  return (
    <>
      {/* {emailSent ? (
        <SuccessDialog
          type="field"
          heading="Email has been sent"
          path={`/login`}
        />
      ) : null} */}
      <Grid container wrap="nowrap">
        <LeftBox
          heading="Can’t Recollect your Password?"
          message=" We have got you covered! Enter your registered ID and then check
          your mail for the Password Reset Link."
        />
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={8}
          justifyContent="center"
          alignItems="center"
          style={{ height: "100vh" }}
        >
          <Box
            sx={{
              width: { xs: "80%", sm: "70%", md: "70%", lg: "50%" },
            }}
          >
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Box container flexDirection="row">
                  <Box
                    item
                    sx={{
                      marginLeft: "-70px",
                      marginTop: "6px",
                      position: "absolute",
                    }}
                  >
                    <Link to="/">
                      <IconButton
                        aria-label="delete"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}
                      >
                        <KeyboardBackspace
                          style={{ color: "#18273B" }}
                          size="large"
                        />
                      </IconButton>
                    </Link>
                  </Box>
                  <Box item>
                    <Typography variant="h3" className={classes.loginHeading}>
                      Forgot Password ?
                    </Typography>
                  </Box>
                </Box>

                <Typography className={classes.signin_description}>
                  Please enter your Registered Email ID. A link will be sent to
                  your mail through which you can change your password
                </Typography>
                <Formik
                  initialValues={{ email: "" }}
                  onSubmit={(values, { resetForm, setSubmitting }) => {
                    var data = JSON.stringify({ email: values.email });
                    setLoader(true);
                    fetchPost
                      .post(`${apiUrl.forgotPassword}`, data)
                      .then((response) => {
                        setLoader(false);
                        setEmailSent(true);
                        resetForm();
                        history.push("/reset-link-sent");
                      })
                      .catch((error) => {
                        setLoader(false);
                        if (error.response) {
                          if (error.response.data.code === 404) {
                            props.enqueueSnackbar(
                              "Oops! Something went wrong. We are working on fixing the problem.",
                              {
                                variant: "error",
                                autoHideDuration: 3000,
                              }
                            );
                          } else if (error.response.data.error) {
                            error.response.data.error.message.forEach(
                              (message) => {
                                props.enqueueSnackbar(message, {
                                  variant: "error",
                                  autoHideDuration: 3000,
                                });
                              }
                            );
                          }
                        } else {
                          props.enqueueSnackbar(
                            "Oops! Something went wrong. Please check your network connection and try again",
                            {
                              variant: "error",
                            }
                          );
                        }
                      });
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email("Enter a valid email")
                      .required("Email is required"),
                  })}
                >
                  {(props) => {
                    const {
                      touched,
                      errors,
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    } = props;
                    return (
                      <Form onSubmit={handleSubmit}>
                        <InputLabel
                          htmlFor="email"
                          className={classes.emaillabel}
                        >
                          Email ID*
                        </InputLabel>
                        <Field
                          className={classes.textField1}
                          autoFocus
                          autoComplete="off"
                          placeholder="Enter Your Email"
                          name="email"
                          type="text"
                          component={TextField}
                          variant="outlined"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.email ? errors.email : ""}
                          error={touched.email && Boolean(errors.email)}
                          fullWidth
                          margin="normal"
                        />
                        <div className={classes.buttonbox}>
                          <Button
                            type="submit"
                            variant="contained"
                            className={classes.button}
                          >
                            {loader ? <div className="loader"></div> : "SUBMIT"}
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </motion.div>
            </AnimatePresence>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default withSnackbar(ForgotPassword);
