import { Grid, TableCell, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const role = localStorage.getItem("ROLE")
  ? localStorage.getItem("ROLE").toLowerCase()
  : null;

const useStyles = makeStyles({
  tableCell: {
    fontSize: "14px",
    minWidth: 120,
    maxWidth: 160,
  },
  iconsStyle: {
    transition: "-webkit-transform 0.2s",
    transition: "transform 0.2s",
    transition: "transform 0.2s, -webkit-transform 0.2s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
});

const ActionComponent = ({
  tableHeading,
  latestStatus,
  assignExam,
  editAction,
  deleteAction,
  handleDelete,
  handleSubAdminLink
}) => {
  const classes = useStyles();
  console.log(role, "tableHeading");

  return (
    <>
      <TableCell className={classes.tableCell} align="center">
        <Grid container justifyContent="center" spacing={2} alignItems="center">
          {tableHeading === "Candidates" &&
            (role === "admin" || assignExam.allow) &&
            (latestStatus === "---" || latestStatus === "PASSED" ? (
              <Grid item>
                <Link
                  to={{
                    pathname: assignExam.link,
                    state: {
                      candidateID: assignExam.candidateId,
                      candidateName: assignExam.candidateName,
                      DeptID: assignExam.deptId,
                    },
                  }}
                >
                  <Tooltip title="Invite">
                    <img
                      className={classes.iconsStyle}
                      src="/assets/Icons/assign_exam_active.svg"
                      alt="Assign Exam"
                    />
                  </Tooltip>
                </Link>
              </Grid>
            ) : (
              <Grid item>
                <img
                  className={classes.iconsStyle}
                  src="/assets/Icons/assign_exam_inactive.svg"
                  alt="Assign Exam"
                />
              </Grid>
            ))}
            {(tableHeading === "Sub Admin") && (
          <Grid item>
           
              <Tooltip title="Resend Link">
                <Grid
                onClick={() => {
                        {
                          handleSubAdminLink(
                            deleteAction.actionID,
                         
                          );
                        }
                      }}
                >
                  <img
                    className={classes.iconsStyle}
                    height={25}
                    src="/assets/Icons/resend.png"
                    alt=""
                  />
                </Grid>
              </Tooltip>
          </Grid>
          )}
          <Grid item>
            {(role === "admin" || editAction.allow) && (
              <Tooltip title="Edit">
                <Link
                  to={{
                    pathname: editAction && editAction.editpath,
                    state: editAction,
                  }}
                >
                  <img
                    className={classes.iconsStyle}
                    src="/assets/Icons/editIcon.svg"
                    alt=""
                  />
                </Link>
              </Tooltip>
            )}
          </Grid>
   



          {tableHeading === "Department"
            ? null
            : (role === "admin" || deleteAction.allow) && (
                <Grid item>
                  <Tooltip title="Delete">
                    <Grid
                      // to={`javascript:void(0)`}
                      onClick={() => {
                        {
                          handleDelete(
                            deleteAction.actionID,
                            deleteAction.tableType,
                            deleteAction.deleteName
                          );
                        }
                      }}
                    >
                      <img
                        className={classes.iconsStyle}
                        src="/assets/Icons/deleteIcon.svg"
                        alt=""
                      />
                    </Grid>
                  </Tooltip>
                </Grid>
              )}
        </Grid>
      </TableCell>
    </>
  );
};

export default ActionComponent;
