import { commonStyle } from "commonStyle.js";
import { Divider,React,useState, useEffect, SuccessDialog, clsx, fetchUpdate, apiUrl, makeStyles, DeleteAlert, Link, Box, Container, Grid, Paper, Button, Typography, useHistory } from "allImport";
import ToggleButton from "components/ToggleButton/ToggleButton";
import { capitalize } from "Common/CommonFunction";
import { useBool } from "context/BoolContext";
import ExamTimeLine from "components/ExamTimeLine/ExamTimeLine";
import Alert from "components/Dialog/alert";
import ExamTimeLineRefactor from "components/ExamTimeLine/ExamTimeLineRefactor";
import CommonPopup from "Common/CommonPopup";
import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),
 
  palette: {
   
      color: 'red',
     
    },
    palette: {
   
      color: 'red',
     
    },
  paper_heading: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "20px",
    lineHeight: "32px",
    letterSpacing: "0.1px",
    color: "#000000",
    fontFamily: "Mulish",
    paddingTop: "28px",
    paddingLeft: "24px",
   
  },
  editIconProfile: {
    height: "18px",
    width: "18px",
  },

  deleteIconProfile: {
    height: "18px",
    width: "18px",
  },
  profile_detail_label: {
    fontFamily: "Open sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "0.15000000596046448px",
    textAlign: "left",
    color: "#000000",
    height: "20px",
  },
  profile_detail_content: {
    fontFamily: "Open sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
    letterSpacing: "0.15000000596046448px",
    textAlign: "left",
    color: "#000000",
    height: "20px",
    marginTop: "8px",
  },
  profile_heading: {
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "50px",
    letterSpacing: "0.25px",
    color: "#18273B",
  },
  permission_heading: {
    fontStyle: "normal",
    paddingLeft: "144px",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "17.57px",
    letterSpacing: "0.1px",
    color: "rgba(0, 0, 0, 0.3)",
    fontFamily: "Mulish",
  },
  section_heading: {
    fontStyle: "normal",
    paddingTop: "15px",
    paddingLeft: "32px",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "17.57px",
    letterSpacing: "0.1px",
    color: "#000000",
    fontFamily: "Mulish",
    width: "133.33px",
  },
  permission_heading_box: {
    display: "flex",
    paddingLeft: "102px",
    marginTop: "24px",
    marginBottom: "6px",
  },
  examiner_detail_box: {
    marginTop: "10px",
  },
  examiner_box: {
    background: "#FFFFFF",
    border: "1px solid #BBBBBB",
    boxSizing: "border-box",
    borderRadius: "4px",
    width: "90%",
    height: "46px",
  },
  ProfileHeader: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  IconStyle: {
    width: "24px",
    height: "24px",
    marginTop: "10px",
  },
  permission_paper: {
    backgroundColor: "rgba(196, 196, 196, 0.12)",
    height: "42px",
    marginTop: "14px",
  },
  stepper_style: {
    width: "176px",
    height: "165px",
    marginTop: "30px",
  },
  permission: {
    display: "flex",
    alignItems: "center",
    marginRight: 50,
  },
  permissionLabel: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "0.15000000596046448px",
    marginLeft: "10px",
  },
  innerPermissionContainer: {
    display: "flex",
    padding: "22px 34px 32px",
  },
  permissionContainer: {
    marginTop: "32px",
    borderBottom: "1px solid #0000001A",
  },
  assignExamButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "80px 0 112px",
  },
  divider_position:
  {
    marginLeft:'24px',
    marginRight:'33px',
    marginTop:'10px'
  },
  green:{
    color: "#29CB1B",
},
red: {
    color: "#D9001D",
}
}));

export default function Commonprofile(props) {
  const classes = useStyles();
  const { select, setSelect } = useBool();
 
  const heading = props.data.MainHeading;
  const paperheading = props.data.PaperHeading;
  const subheading1 = props.data.MainSubheading1;
  const subheading2 = props.data.MainSubheading2;
  const label1 = props.data.Labels.label1;
  const label2 = props.data.Labels.label2;
  const label3 = props.data.Labels.label3;
  const label4 = props.data.Labels.label4;
  const label5 = props.data.Labels.label5;
  const label6 = props.data.Labels.label6;
  const label7 = props.data.Labels.label7;
  const label8 = props.data.Labels.label8;
  const box1 = props.data.DetailBox.box1;
  const box2 = props.data.DetailBox.box2;
  const box3 = props.data.DetailBox.box3;
  const box4 = props.data.DetailBox.box4;
  const box5 = props.data.DetailBox.box5;
  const box6 = props.data.DetailBox.box6;
  const box7 = props.data.DetailBox.box7;
  const box8 = props.data.DetailBox.box8;
  const candidateID = props.data.candidateID;
  const examinerID = props.data.examinerID;
  const jobID = props.data.jobID;
  const candidateExperience = props.data.candidateExperience;
  const latest_status = props.data.latest_status;

  const timelinesData = props.data.examTimelineData
  console.log("VALUESCHANGED",props)



  const hiring_status=props.data.hiring_status;
  const [subAdminActive, setSubAdminActive] = useState(false);
  const [subAdminStatus, setSubAdminStatus] = useState("Inactive");
  const [hire, setHire] = useState(false);
  const [reject, setReject] = useState(false);
  const [Publish, setPublish] = useState(false);
  const [unPublish, setunPublish] = useState(false);
  const [Draft, setDraft] = useState(false);
  const [id, setid] = useState();
  const [Type, setType] = useState();
  const role = localStorage.getItem("ROLE")?localStorage.getItem("ROLE").toLowerCase():null;
  const [Name, setName] = useState();
  const history = useHistory();
  var Delete = (table) => {
    if (table === "Examiner") {
      setSelect(true);
      setid(examinerID);
      setType(table);
      setName(capitalize(box1) + " Profile");
    } else if (table === "Candidate") {
      setSelect(true);
      setid(candidateID);
      setType(table);
      setName(capitalize(box1) + " Profile");
    } else if (table === "Job") {
      setSelect(true);
      setid(jobID);
      setType(table);
      setName("Vacancy");
    } else if (table === "Subadmin") {
      setSelect(true);
      setid(props.data.SubAdminID);
      setType(table);
      setName(capitalize(box1) + "'s Profile");
    }
  };
 
  const unpublish = () => {
    setunPublish(true);
    const data1 = {
      status: "unpublish",
      is_publish: "0",
    };
    fetchUpdate.patch(`${apiUrl.unpublishJob + jobID}`, data1);
  };



  const publish = () => {
    setPublish(true);
    const data1 = {
      status: "publish",
      is_publish: "1",
    };
    fetchUpdate.patch(`${apiUrl.publishJob + jobID}`, data1);
  };
  const Reject = () => {
    const data1 = {
      full_name:box1,
      phone_number:box2,
      email:box3,
      is_hired: false,
    };
    fetchUpdate.patch(`${apiUrl.viewCandidates + candidateID + "/"}`, data1);
    history.goBack()
  };
  const Hire = () => {   
 
    const data1 = {
      full_name:box1,
      phone_number:box2,
      email:box3,
      is_hired:true,
      hire_comment : popupData
    };
    fetchUpdate.patch(`${apiUrl.viewCandidates + candidateID + "/"}`, data1);
    history.goBack()
  };
  const assignExam = () => {
    history.push({
      pathname:`/${role}/candidate/examlink`,
      state:{
        candidateID: candidateID,
        candidateName: props.data.candidateName,
        DeptID: props.data.candidateDept,
      }
    })
  }
  const draft = () => {
    setDraft(true);
    const data1 = {
      status: "draft",
      is_publish: "0",
    };
    fetchUpdate.patch(`${apiUrl.draftJob + jobID}`, data1);
  };

  const isPermissionAllowed = (permission) => {
    return props.data.Permissions.includes(permission);
  };

  const toggleStatus = () => {
    if (props.data.ProfileType === "Subadmin") {
      const isSubAdminActive = !subAdminActive;
      const data1 = {
        is_active: isSubAdminActive ? 1 : 0,
      };
      fetchUpdate.patch(`${apiUrl.subAdminActivity}${props.data.SubAdminID}/`, data1).then((res) => {
        setSubAdminActive(isSubAdminActive);
        setSubAdminStatus(isSubAdminActive ? "Active" : "Inactive");
      });
    }
  };

  useEffect(() => {
    if(props.data.ProfileType === "Subadmin") {
      const subAdmStatus = props.data.IsActive;
      setSubAdminActive(subAdmStatus === "Active");
      setSubAdminStatus(subAdmStatus);
    }
  }, [])
 
  
  const { popupData  } =
  useSelector((store) => store.popupRedux);

console.log("datatttttoppp",props.data)
  return (
   
    <main className={classes.content} id="main-content" >
      
      {Publish ? <SuccessDialog type="profile" heading="Job Published" /> : null}
      {reject ? <CommonPopup heading="Are you sure you want to Reject this candidate ?" cancel={()=> setReject(false)}  status={()=>Reject()} /> : null}
      {/* {hire ? <Alert successText="Candidate Hired" heading="Are you sure you want to hire this candidate ?" actionName="Hire" action={() => Hire()} cancel={() => setHire(false)}/> : null} */}
      {hire ? <CommonPopup heading="Are you sure you want to hire this candidate ?" cancel={()=> setHire(false)}  status={()=>Hire()} /> : null }
      {unPublish ? <SuccessDialog type="profile" heading="Job Unpublished" /> : null}
      {Draft ? <SuccessDialog type="profile" heading="Job Saved in Draft" /> : null}
      <Container maxWidth="xl" className={classes.container}>
        {select ? <DeleteAlert type={Type} de="p" heading={`Are you sure you want to Delete the ${Name} ?`} id={id} /> : null}

        <Grid container xs={12}>
          <Grid item md={12} lg={6} align="left" justify="center" className={classes.ProfileHeader}>
            <div className={classes.profile_heading} style={{ marginRight: "10px" }}>
              {heading}
            </div>
            
            <div style={{ cursor: "pointer" }}>
              {(role === "sub-admin" && props.data.permissions && !props.data.permissions.edit) ?null: <>{props.data.ProfileType === "Admin" ? (
                <Link to={{ pathname: `/${role}/edit-profile`, state: { name: box1, designation: box2 } }}>
                  {" "}
                  <img src="/assets/Icons/editIcon.svg" alt="" style={{ marginLeft: "19px", marginRight: "28px", marginTop: "12px" }} />
                </Link>
              ) : props.data.ProfileType === "Candidate" ? (
                <Link to={{ pathname: `/${role}/candidate/edit`, state: { candidateID: candidateID, candidateName: box1, candidateNo: box2, candidateEmail: box3, candidateJob:box5, candidateDept: box6, candidateExperience: candidateExperience, candidateExaminer: box4 } }}>{role === "examiner" ? null : <img src="/assets/Icons/editIcon.svg" alt="" style={{ marginLeft: "19px", marginRight: "28px", marginTop: "12px" }} />}</Link>
              ) : props.data.ProfileType === "Job" ? (
                <Link to={{ pathname: `/${role}/job/edit`, state: { jobID: jobID, jobTitle: box1, jobDepts: box2, jobExperience: box4, jobLevel: box3.toLowerCase() } }}>
                  <img src="/assets/Icons/editIcon.svg" alt="" style={{ marginLeft: "19px", marginRight: "28px", marginTop: "12px" }} />
                </Link>
              ) : props.data.ProfileType === "Department" ? (
                <Link to={{ pathname: `/${role}/department/edit`, state: { departmentName: box1, departmentID: props.data.departmentID } }}>
                  <img src="/assets/Icons/editIcon.svg" alt="" style={{ marginLeft: "19px", marginRight: "28px", marginTop: "12px" }} />
                </Link>
              ) : props.data.ProfileType === "Subadmin" ? (
                <Link to={{ pathname: `/${role}/subadmins/edit-subadmin`, state: { SubAdminID: props.data.SubAdminID } }}>
                  <img src="/assets/Icons/editIcon.svg" alt="" style={{ marginLeft: "19px", marginRight: "28px", marginTop: "12px" }} />
                </Link>
              ) : (
                <Link to={{ pathname: `/${role}/examiner/edit-profile`, state: { examinerID: examinerID, examinerName: box1, examinerNo: box2, examinerEmail: box4, examinerDesignation: box3, examinerDept: box5 } }}>
                  <img src="/assets/Icons/editIcon.svg" alt="" style={{ marginLeft: "19px", marginRight: "28px", marginTop: "12px" }} />
                </Link>
              )}</>}
            </div>
           
            {(role === "sub-admin" && props.data.permissions && !props.data.permissions.delete) ?null: <div style={{ cursor: "pointer" }}>{props.data.ProfileType === "Admin" || props.data.ProfileType === "Department" ? <img alt="" /> :  role==="examiner"? null: box7 ==="EXAM_ATTEMPTED" ? null: <img onClick={() => Delete(props.data.ProfileType)} className={classes.deleteIconProfile} style={{ marginTop: "12px" }} src="/assets/Icons/deleteIcon.svg" alt="" />}</div>}

            {(role === "sub-admin" && props.data.permissions && !props.data.permissions.edit) ?null: 
            <>{props.data.ProfileType === "Subadmin" ? (
              <div style={{ marginLeft: "50px" }}>
                <ToggleButton label={subAdminStatus} handleClick={() => toggleStatus()} isChecked={subAdminActive} />
              </div>
            ) : null}</>}
           
          </Grid>

          {(role === "sub-admin" && props.data.permissions && !props.data.permissions.edit) ?null: 
            <>{props.data.ProfileType === "Job" ? (
            <Grid item xl={6} lg={6} md={6} sm={12} className={classes.actionbutton}>
              <div className="button_invite_examiner" style={{ paddingRight: "80px" }}>
                {box6 === "Draft" ? (
                  <Button onClick={unpublish} className={classes.outlinedButton} variant="contained" color="primary">
                    Unpublish
                  </Button>
                ) : (
                  <Button onClick={draft} className={classes.outlinedButton} variant="contained" color="primary">
                    Save For Later
                  </Button>
                )}
              </div>
              <div className="button_invite_examiner">
                {box6 === "Publish" ? (
                  <Button onClick={unpublish} className={classes.filledButton} variant="contained" color="primary">
                    Unpublish
                  </Button>
                ) : (
                  <Button onClick={publish} className={classes.filledButton} variant="contained" color="primary">
                    Publish
                  </Button>
                )}
              </div>
            </Grid>
          ) : null}</>}
       {console.log("fhsdjfhisdjkfhkjs hello sdasuadha" , props.data)}
       {console.log("dssdsad" , props.data.permissions)}
          {(role === "sub-admin" && props.data.permissions && !props.data.permissions.hire_reject) ?null: 
            <>{props.data.ProfileType === "Candidate" && (props.data.latest_status === "PASSED" || props.data.latest_status === "EXAM_SUBMITTED") && hiring_status===null  ? (
              <Grid item xl={6} lg={6} md={6} sm={12} className={classes.actionbutton}>
                <div className="button_invite_examiner" style={{ paddingRight: "80px" }}>
                  <Button onClick={() => setReject(true)} className={classes.outlinedButton} variant="contained" color="primary">
                    Disqualified
                  </Button>
                </div>
                <div className="button_invite_examiner">
                    <Button onClick={() => setHire(true)} className={classes.filledButton} variant="contained" color="primary">
                      Qualified
                    </Button>
                </div>
              </Grid>
             
            ) : null}</>}
           {/* <div style={{marginLeft:250,marginTop:20}}>
           <Button style={{border:10,height:50,backgroundColor:"red",color:"white",marginRight:20}} variant="contained" size="large">
          Hire Candidate
        </Button>
        <Button className={classes.palette} style={{border:10,height:50,border:"1px solid red",marginRight:20}} variant="outlined"  size="large">
         Reject Candidate
        </Button>

           </div> */}
        </Grid>
        
        {props.data.ProfileType === "Admin" ? (
          <Grid />
        ) : (
          <Grid container xs={12}>
            <Grid item xs={12} sm={6} align="left" justify="left">
              <div style={{ width: "120%" }} className={classes.page_subheading}>
                <span className={classes.goBackURL}>
                  {" "}
                  <Link style={{color:"red"}} className={clsx(classes.page_subheading, classes.LinkStyle)} to={{ pathname: props.data.home }}>
                    {subheading1}
                  </Link>
                </span>{" "}
                <span className="spc"></span> &gt; <span className="spc"></span>
                <span className={classes.goBackURL}>{subheading2}</span>
              </div>
            </Grid>
            
          </Grid>
          
        )}
      
        {props.data.ProfileType === "Department" ? null : <Paper className={classes.paper} style={{  overflow: "hidden" }}>
          <div className={classes.paper_heading}>{paperheading}</div>
          <Divider className={classes.divider_position}/>
          <Grid container style={{ marginTop: "28px", marginLeft: "32px" }}>
            <Grid item xl={3} lg={3} md={3} xs={12} sm={6}  style={{ marginBottom:25 }}>
              <div className={classes.profile_detail_label}>
                <label>{label1} </label>
              </div>
              <div className={classes.profile_detail_content}>{box1}</div>
            </Grid>
            {props.data.ProfileType === "Subadmin" ? null : (
              <Grid item xl={3} lg={3} md={3} xs={12} sm={6}  align="left" justify="left" style={{ marginBottom:25 }}>
                <div className={classes.profile_detail_label}>
                  <label>{label2} </label>
                </div>
                <div className={classes.profile_detail_content}>{box2}</div>
              </Grid>
            )}
            {props.data.ProfileType === "Department" ? null : (
              <>
                <Grid item xl={3} lg={3} md={3} xs={12} sm={6}  align="left" justify="left" style={{ marginBottom:25 }}>
                  <div className={classes.profile_detail_label}>
                    <label>{label3}</label>
                  </div>
                  <div className={classes.profile_detail_content}>{box3}</div>
                </Grid>
                {props.data.ProfileType === "Job" ? null:<Grid item xl={3} lg={3} md={3} xs={12} sm={6}  align="left" justify="left" style={{ marginBottom:25 }}>
                    <div className={classes.profile_detail_label}>
                      <label>{label5}</label>
                    </div>
                    <div className={classes.profile_detail_content}>{box5}</div>
                  </Grid>}
                {props.data.ProfileType === "Job" ? null:<Grid item xl={3} lg={3} md={3} xs={12} sm={6} align="left" justify="left" style={{ marginBottom:25 }}>
                  <div className={classes.profile_detail_label}>
                    <label>{label4}</label>
                  </div>
                  <div className={classes.profile_detail_content}>{box4}</div>
                </Grid>}
                  
                
                {props.data.ProfileType === "Job" || props.data.ProfileType === "Candidate" ? (
                  <>
                    <Grid item xl={3} lg={3} md={3} xs={12} sm={6}  align="left" justify="left" style={{ marginBottom:25 }}>
                      <div className={classes.profile_detail_label}>
                        <label>{label6}</label>
                      </div>
                      <div className={classes.profile_detail_content}>{box6}</div>
                    </Grid>
                  </>
                ) : null}
              </>
            )}

            {props.data.ProfileType === "Candidate" ? (
              <>
              <Grid item xl={3} lg={3} md={3} xs={12} sm={6}  align="left" justify="left" style={{ marginBottom:25 }}>
                  <div className={classes.profile_detail_label}>
                    <label >{label8}</label>
                  </div>
                  <div style={{backgroundColor:"black",display:"flex",justifyContent:"center",paddingTop:4,padding:3,color:"white",width:50,height:30,borderRadius:5,textAlign:"center",fontSize:15}}  className={classes.profile_detail_content}>{box8}</div>
                </Grid>
                <Grid item xl={3} lg={3} md={3} xs={12} sm={6}  align="left" justify="left" style={{ marginBottom:25 }}>
                  <div className={classes.profile_detail_label}>
                    <label >{label7}</label>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {box7 && box7 !== "DISQUALIFY" && box7 !== "FAILED" ? (
                      <>
                       
                        <div className={classes.profile_detail_content} style={{  color: "#000" }}>
                          {box7.split("_").join(" ")}
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                       
                        <div className={classes.profile_detail_content} style={{  color: "#000"}}>
                          {box7 ? `${box7}` : "LINK_NOT_SENT"}
                        </div>
                      </>
                    )}
                  </div>
                </Grid>
               
              </>
            ) : null}
          </Grid>
        </Paper>}

        {props.data.ProfileType === "Subadmin" ? (
          <>
            <Paper className={classes.paper}>
              <div className={classes.paper_heading}>Permissions</div>
              <div>
                <div className={classes.permissionContainer}>
                  <Typography className={classes.section_heading}>Candidates</Typography>
                  <div className={classes.innerPermissionContainer}>
                    <div className={classes.permission}>
                      {isPermissionAllowed("VIEW_CANDIDATE") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>View Profile</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("CREATE_CANDIDATE") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Create</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("EDIT_CANDIDATE") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Edit</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("DELETE_CANDIDATE") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Delete</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("GENERATE_EXAM_LINK") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Generate Exam Link</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("HIRE_REJECT_CANDIDATE") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Reject/Hire</div>
                    </div>
                  </div>
                </div>

                <div className={classes.permissionContainer}>
                  <Typography className={classes.section_heading}>Examiners</Typography>
                  <div className={classes.innerPermissionContainer}>
                    <div className={classes.permission}>
                      {isPermissionAllowed("VIEW_EXAMINER") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>View Profile</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("CREATE_EXAMINER") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Invite</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("EDIT_EXAMINER") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Edit</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("DELETE_EXAMINER") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Delete</div>
                    </div>
                  </div>
                </div>

                <div className={classes.permissionContainer}>
                  <Typography className={classes.section_heading}>Exams</Typography>
                  <div className={classes.innerPermissionContainer}>
                    <div className={classes.permission}>
                      {isPermissionAllowed("VIEW_EXAM") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>View</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("CREATE_EXAM") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Create</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("EDIT_EXAM") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Edit</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("DELETE_EXAM") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Delete</div>
                    </div>
                  </div>
                </div>

                <div className={classes.permissionContainer}>
                  <Typography className={classes.section_heading}>Question Bank</Typography>
                  <div className={classes.innerPermissionContainer}>
                    <div className={classes.permission}>
                      {isPermissionAllowed("VIEW_QUESTION_BANK") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>View</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("CREATE_QUESTION_BANK") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Create Question</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("EDIT_QUESTION_BANK") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Edit Question</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("DELETE_QUESTION_BANK") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Delete Question</div>
                    </div>
                  </div>
                </div>

                <div className={classes.permissionContainer}>
                  <Typography className={classes.section_heading}>Results</Typography>
                  <div className={classes.innerPermissionContainer}>
                    <div className={classes.permission}>
                      {isPermissionAllowed("VIEW_RESULT") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>View</div>
                    </div>
                    
                    <div className={classes.permission}>
                      {isPermissionAllowed("PASS_FAIL_CANDIDATE") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Pass/Fail</div>
                    </div>
                
                  </div>
                </div>

                <div className={classes.permissionContainer}>
                  <Typography className={classes.section_heading}>Vacancies</Typography>
                  <div className={classes.innerPermissionContainer}>
                    <div className={classes.permission}>
                      {isPermissionAllowed("VIEW_JOB") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>View Vacancies </div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("CREATE_JOB") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Create Vacancy </div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("EDIT_JOB") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Edit Vacancy </div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("DELETE_JOB") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Delete Vacancy </div>
                    </div>
                  </div>
                </div>

                <div className={classes.permissionContainer}>
                  <Typography className={classes.section_heading}>Departments</Typography>
                  <div className={classes.innerPermissionContainer}>
                    <div className={classes.permission}>
                      {isPermissionAllowed("VIEW_DEPARTMENT") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>View</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("CREATE_DEPARTMENT") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Create</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("EDIT_DEPARTMENT") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Edit</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("DELETE_DEPARTMENT") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Delete</div>
                    </div>
                  </div>
                </div>

                <div className={classes.permissionContainer}>
                  <Typography className={classes.section_heading}>Sub Admins</Typography>
                  <div className={classes.innerPermissionContainer}>
                    <div className={classes.permission}>
                      {isPermissionAllowed("VIEW_SUBADMIN") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>View</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("CREATE_SUBADMIN") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Create</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("EDIT_SUBADMIN") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Edit</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("DELETE_SUBADMIN") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Delete</div>
                    </div>
                    <div className={classes.permission}>
                      {isPermissionAllowed("GIVE_PERMISSIONS") ? <img src="/assets/Icons/check_icon.svg" alt="" /> : <img src="/assets/Icons/uncheck_icon.svg" alt="" />}
                      <div className={classes.permissionLabel}>Give Permissions</div>
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </>
        ) : null}

        {props.data.ProfileType === "Candidate" ? (
          <>
          {/* {timelinesData && timelinesData.length > 0 && 
            timelinesData.map((data, index, {length}) => (
              length === index+1 ? <ExamTimeLine candidateID={candidateID} isLast={true} data={data}/>
              : <ExamTimeLine candidateID={candidateID} isLast={false} data={data}/>))
          } */}
          {

      
              <ExamTimeLineRefactor candidateID={candidateID} isLast={true} data={timelinesData}/>
         
              
              
          }
          {role !=="examiner" && (role === "admin" || (role === "sub-admin" && props.data.permissions.generateLink)) && (latest_status === "PASSED" || !latest_status) &&(hiring_status===null) ? <div className={classes.assignExamButtonContainer}>
            <div className="button_invite_examiner"><Button onClick={() => assignExam()} className={classes.filledButton} variant="contained" color="primary">
              Assign Exam
            </Button></div>
          </div> : null}
          </>
        ) : null}

        {props.children}

        
      </Container>
    </main>
  );
}
