import {
  useHistory,
  CssBaseline,
  Box,
  Topbar,
  Sidebar,
  React,
  makeStyles,
  Container,
  Grid,
  Paper,
  clsx,
  Link,
  useState,
  useEffect,
  fetchClient,
  apiUrl,
} from "allImport";
import { useStyles } from "style/Liststyle";
// import NotificationList from "../Notification/NotificationList";
import useVH from "react-viewport-height";

import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import DashboardCard from "./DashboardCard";
import cardIcon1 from "./DashboardIcons/1.png";
import cardIcon2 from "./DashboardIcons/2.png";
import cardIcon3 from "./DashboardIcons/3.png";
import cardIcon4 from "./DashboardIcons/4.png";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";

const useDashboardStyles = makeStyles((theme) => ({
  outerContainer: {
    // marginTop: "30px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  leftContainer: {
    width: "70%",
    maxWidth: "1000px",
    marginRight: "30px",
  },
  rightContainer: {
    minWidth: "400px",
    minHeight: "768px",
    margin: 0,
  },
  // topLeftContainer: {
  //   display: "flex",
  //   justifyContent: "space-between",
  //   flexWrap: "wrap",
  // },
  bottomLeftContainer: {
    width: "100%",
    maxWidth: "1000px",
    boxSizing: "border-box",
    marginBottom: "20px",
    overflow: "hidden",
  },
  topLeftBoxes: {
    width: 162,
    height: 162,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 5,
    margin: "0 3px 7px",
  },
  tLBText1: {
    fontFamily: "Mulish",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "50px",
    letterSpacing: "0.25px",
    color: "#18273B",
    margin: 0,
  },
  tLBText2: {
    fontFamily: "Mulish",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "18px",
    letterSpacing: "0.25px",
    color: "#6D6D6D",
    textAlign: "center",
    width: 95,
  },
  tLBText3: {
    fontFamily: "Mulish",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "22px",
    letterSpacing: "0.25px",
    color: "#D9001D",
    width: "113%",
  },
  tLBInner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100px",
    width: "100%",
  },
  tLBCircular: {
    height: 110,
    width: 110,
    borderRadius: "50%",
    backgroundColor: "#383838",
    padding: 0,
    marginBottom: 20,
  },
  tLBContent: {
    height: 100,
    width: 100,
    padding: 5,
    borderRadius: "50%",
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  percent: {
    position: "relative",
    width: "130px",
    height: "130px",
    borderRadius: "50%",
    background: "#ffffff",
    zIndex: "1000",
  },
  svg: {
    position: "relative",
    width: "130px",
    height: "130px",
    zIndex: "1000",
  },
  circle: {
    width: "100%",
    height: "100%",
    fill: "none",
    stroke: "#191919",
    strokeWidth: 6,
    strokeLinecap: "round",
    transform: "translate(5px, 5px)",
  },
  circle2: {
    strokeDasharray: "420",
    strokeDashoffset: "420",
  },
  progress: {
    stroke: "#D9001D",
  },
  tLBCircleInner: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  graphText: {
    fontFamily: "Mulish",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "22px",
    letterSpacing: "0.25px",
    padding: "16px 0 10px 20px",
  },
}));

export default function Dashboard() {
  const vh = useVH();
  const viewPortheight = `${100 * vh}px`;

  const classes = useStyles();
  const history = useHistory();
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const [activeJobs, setActiveJobs] = useState("-");
  let jobsActive = 0;
  let totalJobs = 0;
  const [questionsAdded, setQuestionsAdded] = useState("-");
  const [examinersOnboarded, setExaminersOnboarded] = useState("-");
  const [examsSubmitted, setExamsSubmitted] = useState("-");
  const [activeExams, setActiveExams] = useState("-");
  const [candidatesAdded, setCandidatesAdded] = useState("-");
  const [topbarVisible, setTopBarVisible] = useState(true);
  let totalCandidates = 0;
  const [progress, setProgress] = useState(420);
  const dashStyles = useDashboardStyles();
  let graphData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  const setColumnChartOptions = () => {
    setSeries([
      {
        name: "Passed",
        data: graphData,
      },
    ]);
    let chartOptions = {
      chart: {
        type: "bar",
        height: "500px",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "22px",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 7,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      colors: ["#383838", "#29CB1B"],
      yaxis: [
        {
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
      ],
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    };
    setOptions(chartOptions);
  };

  const getProgressBarValue = () => {
    let percentage = 0;
    if (role === "examiner") {
      percentage = (candidatesAdded / totalCandidates) * 100;
    } else {
      percentage = (jobsActive / totalJobs) * 100;
    }
    return 420 - (420 * percentage) / 100;
  };

  const getStatistics = () => {
    return (
      <>
        {role === "examiner" ? null : (
          <DashboardCard
            icon={cardIcon1}
            count={activeJobs}
            text="Onboarded Vacancies"
            role={role}
            link="job"
          />
        )}

        <DashboardCard
          count={questionsAdded}
          icon={cardIcon2}
          text="New Questions Added"
          role={role}
          link="question-bank/questions"
        />
        {role === "examiner" ? null : (
          <DashboardCard
            count={examinersOnboarded}
            icon={cardIcon3}
            text="New Examiners Onboarded"
            role={role}
            link="examiner"
          />
        )}

        <DashboardCard
          count={examsSubmitted}
          icon={cardIcon4}
          text="Exam Submitted"
          role={role}
          link="submitted-exam"
        />
      </>
    );
  };

  useEffect(() => {
    // if (
    //   !StorageManager.get(API_TOKEN) &&
    //   !StorageManager.get(ADMIN_ID) &&
    //   !StorageManager.get(ADMIN_NAME) &&
    //   !StorageManager.get(LOGOUT_TOKEN)
    // ) {
    //   history.push("/login");
    // }

    if (role === "examiner") {
      fetchClient.get(`${apiUrl.getExaminerStatisticValues}`).then((res) => {
        setActiveExams(res.data.data["Active_Exams"]);
        setCandidatesAdded(res.data.data["Candidates_added"]);
        totalCandidates = res.data.data["all_candidates"];
        setExamsSubmitted(res.data.data["Exams_Submitted"]);
        setQuestionsAdded(res.data.data["New_Questions_Added"]);
        setProgress(getProgressBarValue());
        console.log(res.data.data);
      });
    } else {
      fetchClient.get(`${apiUrl.getHRStatisticValues}`).then((res) => {
        setActiveJobs(res.data.data["active_jobs"]);
        jobsActive = res.data.data["active_jobs"];
        totalJobs = res.data.data["all_jobs"];
        setQuestionsAdded(res.data.data["new_questions_added"]);
        setExamsSubmitted(res.data.data["exams_submitted"]);
        setExaminersOnboarded(res.data.data["new_examiners_onboarded"]);
        setProgress(getProgressBarValue());
      });
    }

    fetchClient.get(`${apiUrl.getDashboardSummary}`).then((res) => {
      console.log("candid summary", res.data.data["candidate summary"]);
      let temp = res.data.data["candidate summary"];
      let newData = [...graphData];
      for (let i of temp) {
        newData[i.month - 1] = i.total;
      }
      graphData = newData;
      setColumnChartOptions();
    });
  }, []);

  // Hide and Show Top Bar

  return (
    <AnimatePresence>
      <Grid
        className={classes.container}
        style={{
          // height: "calc(100vh - 120px)",
          height: `calc(${viewPortheight} - 90px)`,
          maxHeight: "auto",
          padding: "20px 5px",
        }}
      >
        <HeaderWithBreadcrumb
          heading="Dashboard"
          // margin={true}
          breadCrumb={null}
        />
        <Grid container justifyContent="space-around">
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            spacing={2}
            // spacing={{ xs: 2, sm: 2, md: 2 }}
            // columnSpacing={{ xs: 2, sm: 2, md: 2 }}
          >
            {getStatistics()}
          </Grid>

          {/* <NotificationList external={true} /> */}
        </Grid>
      </Grid>
    </AnimatePresence>
  );
}
