import * as Yup from "yup";

const CategoryValidation = Yup.object().shape({
  name : Yup.string()
    .required("Required")
    .min(2, "Too Short!")
    .max(50, "Too Long!"),
  job : Yup.array().min(1).required("At least one skill is required")
});

export default CategoryValidation;
