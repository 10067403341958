import "style/style.scss";
import {
  Zoom,
  apiUrl,
  fetchUpdate,
  Dialog,
  MuiDialogTitle,
  IconButton,
  withStyles,
  React,
  Button,
  Typography,
  CloseIcon,
} from "allImport";
import { DialogContent, Grid } from "@material-ui/core";
import { withSnackbar } from "notistack";
import Lottie from "react-lottie";
import successAnimation from "./success.json";
import { useBool } from "context/BoolContext";
import { useHistory, useLocation } from "react-router-dom";
import TableActions from "redux/actions/tableActions";
import { useDispatch } from "react-redux";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  ModelSize: {
    height: "283px",
    minWidth: "416px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "90%",
      height: "auto",
      padding: "0px",
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DeleteDialog = withStyles(styles)((props) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { classes } = props;
  const dispatch = useDispatch();
  const { setSelect, setSelects } = useBool();
  const [open, setOpen] = React.useState(true);
  const role = localStorage.getItem("ROLE").toLowerCase();
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const handleClose = () => {
    setOpen(false);
    setSelect(false);
    setSelects(false);
    props.type === "Candidate"
      ? props.de === "p"
        ? dispatch({
            type: TableActions.RESET_TABLE_COLUMN_HEADING,
            data: true,
          }) && props.setDeletePopup(false)
        : dispatch({
            type: TableActions.RESET_TABLE_COLUMN_HEADING,
            data: true,
          }) && props.setDeletePopup(false)
      : props.type === "Category"
      ? props.de === "p"
        ? history.push(`/${role}/category`)
        : dispatch({
            type: TableActions.RESET_TABLE_COLUMN_HEADING,
            data: true,
          }) && props.setDeletePopup(false)
      : props.type === "Job"
      ? props.de === "p"
        ? history.push(`/${role}/job`)
        : dispatch({
            type: TableActions.RESET_TABLE_COLUMN_HEADING,
            data: true,
          }) && props.setDeletePopup(false)
      : props.type === "Department"
      ? props.de === "p"
        ? history.push(`/${role}/departments`)
        : dispatch({
            type: TableActions.RESET_TABLE_COLUMN_HEADING,
            data: true,
          }) && props.setDeletePopup(false)
      : props.type === "Examiner"
      ? props.de === "p"
        ? history.push(`/${role}/examiner`)
        : dispatch({
            type: TableActions.RESET_TABLE_COLUMN_HEADING,
            data: true,
          }) && props.setDeletePopup(false)
      : props.type === "Subadmin"
      ? props.de === "p"
        ? history.push(`/${role}/subadmins`)
        : dispatch({
            type: TableActions.RESET_TABLE_COLUMN_HEADING,
            data: true,
          }) && props.setDeletePopup(false)
      : props.type === "Question"
      ? props.de === "p"
        ? history.push(`/${role}/question-bank/questions`)
        : dispatch({
            type: TableActions.RESET_TABLE_COLUMN_HEADING,
            data: true,
          }) && props.setDeletePopup(false)
      : dispatch({
          type: TableActions.RESET_TABLE_COLUMN_HEADING,
          data: true,
        }) && props.setDeletePopup(false);
  };

  const UNDO = (id) => {
    console.log(props, "idchecking");
    setSelect(false);
    setSelects(false);
    setOpen(false);
    props.type === "Candidate"
      ? fetchUpdate
          .patch(`${apiUrl.UndoCandidate}` + id + "/undo/", {
            deleted_at: null,
          })
          .then((res) => {
            console.log("Astropro", res);
            props.de === "p"
              ? history.replace(`/${role}/candidate`)
              : window.location.reload();
          })
      : props.type === "Job"
      ? fetchUpdate
          .patch(`${apiUrl.UndoJob}` + id + "/undo/", { deleted_at: null })
          .then((res) => {
            props.de === "p"
              ? history.push(`/${role}/job`)
              : window.location.reload();
          })
      : props.type === "Department"
      ? fetchUpdate
          .patch(`${apiUrl.UndoDepartment}` + id + "/undo/", {
            deleted_at: null,
            is_active: 1,
          })
          .then((res) => {
            props.de === "p"
              ? history.push(`/${role}/department`)
              : window.location.reload();
          })
      : props.type === "Examiner"
      ? fetchUpdate
          .patch(`${apiUrl.UndoExaminer}` + id + "/undo/", { deleted_at: null })
          .then((res) => {
            props.de === "p"
              ? history.push(`/${role}/examiner`)
              : window.location.reload();
          })
          .catch((error) => {
            if (error.response.status === 404) {
              props.enqueueSnackbar(
                "Oops! Something went wrong. We are working on fixing the problem.",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  TransitionComponent: Zoom,
                  autoHideDuration: 5000,
                  resumeHideDuration: 2000,
                }
              );
            } else {
              props.enqueueSnackbar(error.response.data.error.message, {
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                TransitionComponent: Zoom,
                autoHideDuration: 5000,
                resumeHideDuration: 2000,
              });
            }
          })
      : props.type === "Subadmin"
      ? fetchUpdate
          .patch(`${apiUrl.undoSubAdmin}` + id + "/undo/", { deleted_at: null })
          .then((res) => {
            window.location.reload();
          })
      : props.type === "Question"
      ? fetchUpdate
          .patch(`${apiUrl.undoQuestion}` + id, { deleted_at: null })
          .then((res) => {
            window.location.reload();
          })
      : props.type === "Exam"
      ? fetchUpdate
          .patch(`${apiUrl.UndoExam}` + id + "/undo/", { deleted_at: null })
          .then((res) => {
            window.location.reload();
          })
      : fetchUpdate
          .patch(`${apiUrl.undoQuestion}` + id, { deleted_at: null })
          .then((res) => {
            window.location.reload();
          });
    // window.location.reload();
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paper: classes.ModelSize }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} />
        <DialogContent>
          <div className="success_bar">
            <Lottie options={defaultOptions} height={150} width={250} />
          </div>

          <Typography gutterBottom style={{ paddingBottom: 0 }}>
            {props.type} Deleted
          </Typography>
          <Grid container justifyContent="center">
            <Button
              onClick={() => UNDO(props.id)}
              style={{
                color: "#D9001D",
                textDecoration: "underline",
              }}
            >
              Undo
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
});
export default withSnackbar(DeleteDialog);
