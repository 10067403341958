import {
  CircularProgress,
  Emptyscreen,
  clsx,
  DeleteAlert,
  useHistory,
  fetchDelete,
  Link,
  fetchUpdate,
  apiUrl,
  useEffect,
  fetchClient,
  CssBaseline,
  Box,
  Container,
  Grid,
  makeStyles,
  Paper,
  Button,
  Topbar,
  Sidebar,
  Divider,
  React,
  SuccessDialog,
} from "allImport";
import "style/style.scss";
import { commonStyle } from "commonStyle.js";
import { useLocation } from "react-router-dom";
import { withSnackbar } from "notistack";
import { useBool } from "context/BoolContext";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
  SUB_ADMIN_PERMISSION,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import CustomButton from "components/Button/CustomButton";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),

  exam_name_label: {
    fontFamily: "Mulish",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
  },
  experience_level_label: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: "0.10000000149011612px",
    textAlign: "left",
    paddingTop: "32px",
    paddingLeft: "32px",
    height: "32px",
  },
  exam_name_box: {
    paddingTop: "45.37px",
    paddingLeft: "32px",
  },
  fullWidthDivider: {
    margin: "16px 0px",
    height: "1px",
    // transition: "none",
  },
  topbarButtonWrapper: {
    ["@media (max-width:600px)"]: {
      // eslint-disable-line no-useless-computed-key
      marginTop: "0.5rem",
      display: "flex",
      justifyContent: "space-between",
    },
  },
}));

const SelectedQuestionlist = (props) => {
  console.log("prrroo", props);
  const { setSelect } = useBool();
  const { select } = useBool();
  const [id, setid] = React.useState();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [questions, setQuestions] = React.useState([]);
  const [questionIDs, setQuestionIDs] = React.useState([]);
  const ExamID = props.location.state.ExamID;
  const ExamName = location.state.ExamName;
  //const ExamDept = location.state.ExamDept;
  const [publish, setPublish] = React.useState(false);
  const [permission, setPermission] = React.useState({});
  const [ExamDept, setExamDept] = React.useState();
  const [topbarVisible, setTopBarVisible] = React.useState(true);
  const [loader, setLoader] = React.useState(false);
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;

  const setPermissionsForSubAdmin = () => {
    if (role === "sub-admin") {
      let permission = {};
      let allPermissions = StorageManager.getJSON(SUB_ADMIN_PERMISSION);
      permission.editExam = allPermissions.includes("EDIT_EXAM");
      permission.editQuestion = allPermissions.includes("EDIT_QUESTION_BANK");
      setPermission(permission);
    }
  };

  useEffect(() => {
    if (
      !StorageManager.get(API_TOKEN) &&
      !StorageManager.get(ADMIN_ID) &&
      !StorageManager.get(ADMIN_NAME) &&
      !StorageManager.get(LOGOUT_TOKEN)
    ) {
      history.push("/login");
    }
    fetchClient
      .get(`${apiUrl.getExamQuestion}` + ExamID + "/questions/")
      .then((res) => {
        setLoader(true);
        setQuestions(
          res &&
            res.data &&
            res.data.data &&
            res.data.data[0] &&
            res.data.data[0].questions
        );
        setQuestionIDs(
          res &&
            res.data &&
            res.data.data &&
            res.data.data[0] &&
            res.data.data[0].question
        );
        setExamDept(
          res && res.data && res.data.data && res.data.data.department_id
        );
      });
    setPermissionsForSubAdmin();
  }, []);
  const ExamPublish = () => {
    if (questions && questions.length) {
      fetchUpdate.patch(`${apiUrl.publishExam}` + ExamID, {
        status: "publish",
      });
      setPublish(true);
    } else {
      props.enqueueSnackbar("Please Add Atleast One Question", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };
  const handleEdit = (u, department) => {
    history.push({
      pathname: `/${role}/question-bank/questions/edit`,
      state: {
        questionID: u.id,
        timeDuration: u.time_duration,
        questionType: u.question_type,
        questionLevel: u.experience_level,
        Department: department,
        editFromExam: true,
      },
    });
  };
  const AddMoreQuestion = () => {
    history.push({
      pathname: `/${role}/exam/question`,
      state: {
        ExamID: ExamID,
        questionIDs: questionIDs,
        Department:
          questions && questions.length ? questions[0].department.id : ExamDept,
        Level: props.location.state.Level,
      },
    });
  };
  const DeleteQuestion = (id) => {
    setid(id);

    fetchDelete
      .delete(`${apiUrl.removeExamQuestion}` + ExamID + "/question/map", {
        data: { question: [id] },
      })
      .then((res) => {
        setSelect(true);
      })

      .catch((error) => {
        setSelect(false);
      });
  };

  // Hide and Show Top Bar

  return (
    <main className={classes.content} id="main-content">
      <div style={{ marginLeft: "16px" }}>
        <HeaderWithBreadcrumb
          heading="Question List"
          breadCrumb
          breadCrumb1="Exam Home"
          breadCrumb2="Create Exam"
          breadCrumb3="Question Bank"
          breadCrumb4="Exam Editor"
          link={`/${role}/exam`}
        />
      </div>
      {select ? (
        <DeleteAlert
          heading={`Are you sure you want to Remove this question ? `}
          id={id}
          setDeletePopup={setSelect}
        />
      ) : null}

      {publish ? (
        <SuccessDialog
          type="exam"
          heading="Exam Published"
          path={`/${role}/exam`}
        />
      ) : null}
      <Container
        maxWidth="xl"
        className={classes.container}
        style={{
          height: "calc(100vh - 125px)",
          maxHeight: "auto",
          position: "relative",
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={12} sm={4} className={classes.all_examiners_heading}>
            {ExamName}
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm
            spacing={1}
            justifyContent="flex-end"
            className={classes.topbarButtonWrapper}
          >
            {/* <Link
              style={{ textDecoration: "none" }}
              onClick={(event) => buttonFunction && event.preventDefault()}
              to={buttonActionPath}
            > */}
            <Grid item xs="auto">
              <CustomButton
                handleButtonClick={AddMoreQuestion}
                type="submit"
                Buttontype="save"
                label="Add More Question"
              />
            </Grid>
            <Grid item xs="auto">
              <CustomButton
                handleButtonClick={ExamPublish}
                type="submit"
                Buttontype="save"
                label="Publish"
              />
            </Grid>

            {/* </Link> */}
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.fullWidthDivider} />
          </Grid>
        </Grid>
        {/* <Grid container xs={8} style={{ paddingBottom: "10px" }}>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            align="left"
            justify="left"
            style={{ display: "flex" }}
          >
            <div
              className={classes.all_examiners_heading}
              style={{ fontSize: "34px" }}
            >
              {ExamName}
            </div>
            <div
              className={classes.examiner_nos}
              style={{ marginLeft: "-10px" }}
            >
              {questions ? questions.length : "0"}
            </div>
          </Grid>

          {role === "sub-admin" && !permission.editExam ? null : (
            <>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                className={classes.actionbutton}
              >
                <div
                  className="button_invite_examiner"
                  style={{ paddingRight: "80px" }}
                >
                  <Button
                    onClick={() => AddMoreQuestion()}
                    className={classes.outlinedButton}
                    style={{ width: "204px" }}
                    variant="contained"
                    color="primary"
                  >
                    Add More Question
                  </Button>
                </div>
                <div className="button_invite_examiner">
                  <Button
                    onClick={ExamPublish}
                    className={classes.filledButton}
                    variant="contained"
                    color="primary"
                  >
                    Publish
                  </Button>
                </div>
              </Grid>
            </>
          )}
        </Grid> */}
        {/* <Grid container xs={12}>
          <Grid item xs={12} sm={6} align="left" justify="left">
            <div className={classes.page_subheading}>
              <Link
                className={clsx(classes.page_subheading, classes.LinkStyle)}
                to={{ pathname: `/${role}/exam` }}
              >
                Exams Home
              </Link>{" "}
              <span className="spc"></span> &gt; <span className="spc"></span>{" "}
              <Link
                className={clsx(classes.page_subheading, classes.LinkStyle)}
                to={{ pathname: `/${role}/exam/create` }}
              >
                Create Exam{" "}
              </Link>{" "}
              <span className="spc"></span> &gt; <span className="spc"></span>{" "}
              Question Bank <span className="spc"></span> &gt;{" "}
              <span className="spc"></span> Exam Editor
            </div>
          </Grid>
        </Grid> */}
{console.log("questionsss",questions)}
        {loader ? (
          <>
            {questions && questions.length > 0 ? (
              questions.map((u, index) => (
                <Paper className={classes.paper} style={{ overflow: "hidden" }}>
                  <Grid container xs={12}>
                    <Grid
                      container
                      item
                      xs
                      align="left"
                      justify="left"
                      style={{ marginBottom: "10px" }}
                    >
                      <Grid
                        item
                        xs={9}
                        sm={11}
                        md={11}
                        className={classes.exam_name_label}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <p style={{ paddingRight: "10px" }}>{index + 1}. </p>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: u.question_name.replaceAll(
                              "<img",
                              `<img style="max-width: 250px; border-radius: 4px;  border: 1px solid #0000001A" /`
                            ),
                          }}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs
                        // spacing={2}
                        // sm={6}
                        // md={6}
                        // lg={6}
                        // xl={6}
                        align="center"
                        justify="space-around"
                        // style={{ paddingTop: "19px", paddingRight: "21px" }}
                      >
                        {role === "sub-admin" &&
                        !permission.editQuestion ? null : (
                          <img
                            onClick={() => handleEdit(u, u.department.name)}
                            className={classes.editIconProfile}
                            src="/assets/Icons/editIcon.svg"
                            alt=""
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        )}
                        {role === "sub-admin" && !permission.editExam ? null : (
                          <img
                            onClick={() => {
                              DeleteQuestion(u.id);
                            }}
                            className={classes.deleteIconProfile}
                            src="/assets/Icons/deleteIcon.svg"
                            alt=""
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </Grid>
                      {u.answers.map((v) => (
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", marginBottom: "2px" }}
                        >
                          {v.is_correct ? (
                            <img
                              style={{ paddingLeft: "35px" }}
                              src="/assets/Icons/right_option.svg"
                              alt=""
                            />
                          ) : (
                            <img
                              style={{ paddingLeft: "35px" }}
                              src="/assets/Icons/blank_option.svg"
                              alt=""
                            />
                          )}

                          <Grid
                            item
                            xs
                            style={{
                              marginLeft: "15px",
                              wordBreak: "break-word",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: v.answer_text.replaceAll(
                                "<img",
                                `<img  style="max-width: 350px; border-radius: 4px; border: 1px solid #0000001A"`
                              ),
                            }}
                            zeroMinWidth
                          ></Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Divider />
                </Paper>
              ))
            ) : (
              <Emptyscreen />
            )}
          </>
        ) : (
          <Paper
            className={classes.paper}
            style={{
              height: "380px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <img
              src="/assets/Icons/nickelfox_loader.gif"
              width="100px"
              height="100px"
              alt="nickelfoxlogo"
            />{" "}
          </Paper>
        )}
      </Container>
    </main>
  );
};
export default withSnackbar(SelectedQuestionlist);
