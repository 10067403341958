import React from "react";
import ReactDOM from "react-dom";
// import { Provider } from "react-redux";
import { Zoom, Button, CloseIcon } from "allImport";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { SnackbarProvider } from "notistack";
import BoolContextProvider from "context/BoolContext";
import { initializeFirebase } from "pushNotification";
require("dotenv").config();
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

if (window.safari) {
  console.log("safari browser detected");
} else {
  initializeFirebase();
}
console.log("FIRED")
ReactDOM.render(
  <BoolContextProvider>
    <SnackbarProvider
      hideIconVariant
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
        width: "400px",
      }}
      preventDuplicate
      ref={notistackRef}
      action={(key) => (
        <CloseIcon
          onClick={onClickDismiss(key)}
          style={{ color: "white", cursor: "pointer" }}
        />
      )}
    >
      <App />
    </SnackbarProvider>
  </BoolContextProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
