import React, { useState, useEffect } from "react";

import {
  withSnackbar,
  DeleteAlert,
  fetchClient,
  apiUrl,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useHistory,
  Paper,
  IconButton,
} from "allImport";
import { useStyles } from "style/Liststyle.js";
import { capitalize, convertUTCDateToLocalDate } from "Common/CommonFunction";
import { useDebounce } from "hooks";

import "style/style.scss";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
  SUB_ADMIN_PERMISSION,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";

import { API, ENDPOINTS } from "../../../api/apiService";
import TablePageLayout from "layout/TablePageLayout";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import { useDispatch, useSelector } from "react-redux";
import Actions from "redux/actions/filterActions";
import CommonTable from "components/CommonComponent/Filter/CommonTable/CommonTable";
import TableActions from "redux/actions/tableActions";
const queryString = require("query-string");

var AS = [];
var questionIDs = [];

const QuestionList = (props) => {
  console.log("---000props00--->", props.history.location.state.candidateID);
  let categoryid = props.history.location.state.profileId;
  console.log("categoryid", categoryid);

  const classes = useStyles();
  const history = useHistory();
  const [loader, setLoader] = React.useState(true);
  const [open, setOpen] = React.useState([]);
  const [totalPage, setTotalPage] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [Arr, setArr] = React.useState([]);
  const [questionSelect, setquestionSelect] = React.useState(false);
  const [id, setID] = React.useState("");
  const examiner_dept = localStorage.getItem("EXAMINER_DEPT");

  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;

  const [filter, setFilter] = React.useState({
    page: 1,
    page_size: 5,
    search: "",
    question_type: 0,
    question_status: 0,
    experience_level: 0,
    department: 0,
    role: role,
    examiner_dept: examiner_dept,
  });
  const debouncedSearchTerm = useDebounce(filter.search, 500);

  const handlePage = (event, page) => {
    setFilter({ ...filter, page });
  };

  function createData(
    ColA,
    ColB,
    ColC,
    ColD,
    ColE,
    ColF,
    ColG,
    ColH,
    ColI,
    ColJ
  ) {
    return { ColA, ColB, ColC, ColD, ColE, ColF, ColG, ColH, ColI, ColJ };
  }

  const colmConversion = (arr) => {
    console.log("-----------this array", arr);
    AS = [];
    let isExpanded = [];
    for (var index = 0; index < arr.length; index++) {
      console.log("this bad idea", arr[index].question_category);
      AS.push(
        createData(
          arr[index] && arr[index].id,
          arr[index] && arr[index].question_name,
          arr[index].question_status,
          arr[index] && arr[index].department.name,
          arr[index] && arr[index].experience_level,
          arr[index].question_category === null
            ? "----"
            : arr[index].question_category.name,
          arr[index] &&
            new Date(arr[index].created_at).toLocaleDateString("en-IN"),
          arr[index] && arr[index].time_duration,
          arr[index] && arr[index].ongoing_exam,
          arr[index] && arr[index].question_type
        )
      );
      questionIDs.push(arr[index] && arr[index].id);
      isExpanded.push(false);
    }
    console.log("this is my as ----------", AS);
    setArr(AS);
    setOpen(isExpanded);
  };

  const handleExpand = (index) => {
    let temp = open;
    temp[index] = !temp[index];
    setOpen([...temp]);
  };

  // const setPermissionsForSubAdmin = () => {
  //   if (role === "sub-admin") {
  //     let permission = {};
  //     let allPermissions = StorageManager.getJSON(SUB_ADMIN_PERMISSION);
  //     permission.create = allPermissions.includes("CREATE_QUESTION_BANK");
  //     permission.view = allPermissions.includes("VIEW_QUESTION_BANK");
  //     permission.edit = allPermissions.includes("EDIT_QUESTION_BANK");
  //     permission.delete = allPermissions.includes("DELETE_QUESTION_BANK");
  //     setPermission(permission);
  //   }
  // };

  const { dataArray } = useSelector((store) => store.filter);

  console.log("dataArray", dataArray);

  const queryBuilder = (url, options) => {
    console.log("urrrll", url);

    const options1 = queryString.stringify({
      ...dataArray,
    });
    console.log("7788", options1);

    console.log("options1", options1);

    // if (options === undefined || !options) return url;
    console.log("finalll", `${url}?&${options1}`);
    return `${url}?&${options1}`;
  };

  const fetchRecord = async () => {
    fetchClient
      .get(`${apiUrl.filterCategory}?question_category=${categoryid}`)
      .then((res) => {
        const result = res.data.data;
        const count1 = result.count;
        console.log("this is my res=---->", res.data.data);
        const examinerData = result.results.map((item) => {
          return {
            0: "dropdownIcon",
            1: item.question_name,
            2: item.department.name,
            3: item.question_type,
            4: item.experience_level,
            5: convertUTCDateToLocalDate(
              new Date(item.updated_at)
            ).toLocaleDateString("en-IN"),
          };
        });
        dispatch({
          type: TableActions.SET_TABLE_ROW,
          data: examinerData,
          tableHeading: "Questions",
          profile: `/${role}/examiner/profile`,
          count: count1,
        });
        dispatch({
          type: TableActions.SET_PAGINATION,
          data: { pages: result.pages, count: result.count },
        });
        setTotalPage(result.pages);
      });
  };

  useEffect(() => {
    dispatch({
      type: TableActions.SET_TABLE_COLUMN_HEADING,
      data: [
        "",
        "Questions",
        "Department",
        "Question Type",
        "Question Level",
        "Date Added",
        // !allowActionColumn && "Action",
      ],
    });
  }, []);

  useEffect(() => {
    fetchRecord();
  }, [dataArray]);

  const QUESTION_TYPE = [
    { label: "Question Type", value: "question_type" },
    { label: "Subjective", value: "subjective" },
    { label: "Objective", value: "objective" },
  ];

  const EXAM_LEVEL = [
    { label: "Exam Level", value: "experience_level" },
    { label: "Senior", value: "senior" },
    { label: "Junior", value: "junior" },
    { label: "Internship", value: "internship" },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: Actions.SET_FILTER,
      count: 1,
      data: [QUESTION_TYPE, EXAM_LEVEL],
    });
  }, []);

  return (
    <>
      <HeaderWithBreadcrumb heading="Question Category" />

      {questionSelect ? (
        <DeleteAlert
          type="Question"
          heading="Are you sure you want to Delete this Question ? "
          id={id}
        />
      ) : null}

      <TablePageLayout
        heading="Question"
        loader={!loader}
        // ButtonText="New Question"
        buttonCategoryText="Create Category"
        buttonActionPath={`/${role}/question-bank/questions/add`}
        // page={page}
        handleChangeFilter="null"
        count={count}
        handleChangePage={handlePage}
        totalPage={totalPage}
        candidateFilter={{
          item: "null",
          type: "categoryQuestion",
          // handleChangeExamLevel: handleChangeFilter("experience_level"),
          // handleChangeTypeLevel: handleChangeFilter("question_type"),
          // handleChangeDepartmentLevel: handleChangeFilter("department"),
          // handleChangeStatusLevel: handleChangeFilter("question_status"),
          // type: "questionlist",
        }}
      >
        {/* <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="sticky table">
            <TableHead>
              <TableRow
                style={{ height: "60px" }}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell />
                <TableCell align="left">Questions</TableCell>

           
                {role === "examiner" ? null : (
                  <TableCell align="left">Department</TableCell>
                )}
                <TableCell align="left">Question Type</TableCell>
                <TableCell align="left">Question Level</TableCell>
                <TableCell align="left">Date Added</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Arr.map((row, index) => (
                <TableRow
                  style={{ height: "60px" }}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  key={row.ColA}
                >
                  <TableCell>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleExpand(index)}
                      >
                        {open[index] ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </div>
                  </TableCell>
                  <TableCell align="left" className={classes.table_cell_text}>
                    <div
                      className={!open[index] ? classes.collapseRow : ""}
                      style={{ maxWidth: 260 }}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: (
                            row.ColB?.charAt(3).toUpperCase() +
                            row.ColB.slice(4)
                          ).replace(
                            "<img",
                            `<img style="max-width: 350px; margin-left:-75px; border-radius: 4px; border: 1px solid #0000001A"`
                          ),
                        }}
                      ></span>
                    </div>
                  </TableCell>

                  {role === "examiner" ? null : (
                    <TableCell align="left" className={classes.table_cell_text}>
                      {capitalize(row.ColD)}
                    </TableCell>
                  )}
                  <TableCell align="left" className={classes.table_cell_text}>
                    {capitalize(row.ColJ)}
                  </TableCell>
                  <TableCell align="left" className={classes.table_cell_text}>
                    {capitalize(row.ColE)}
                  </TableCell>
                  <TableCell align="left" className={classes.table_cell_text}>
                    {capitalize(row.ColG)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}

        <CommonTable Count={totalPage} handleChangePage={handlePage} />
      </TablePageLayout>
    </>
  );
};
export default withSnackbar(QuestionList);
