import { Box, Container, Grid, Paper, React, Pagination } from "allImport";
import { useStyles } from "style/Liststyle";
import TableTopBar from "components/TableTopBar/TableTopBar";

const TablePageLayout = ({
  count,
  subheading,
  ButtonText,
  buttonActionPath,
  buttonFunction,
  children,
  heading,
  handleChangeFilter,
  handleChangeFilterisHired,
  item,
  value,
  allowCreate,
  handleFilter,
  searchFunction,
  candidateFilter,
  buttonCategoryText,
  csvWrapper
}) => {
  const classes = useStyles();

  return (
    <Grid container maxWidth="auto" className={classes.tableContainer2}>
      <TableTopBar
        allowCreate={allowCreate}
        heading={heading}
        subheading={subheading}
        count={count}
        buttonText={ButtonText}
        buttonActionPath={buttonActionPath}
        buttonFunction={buttonFunction}
        handleFilter={handleFilter}
        searchFunction={searchFunction}
        item={item}
        value={value}
        handleChangeFilterisHired={handleChangeFilterisHired}
        handleChangeFilter={handleChangeFilter}
        candidateFilter={candidateFilter}
        buttonCategoryText={buttonCategoryText}
        csvWrapper={csvWrapper}
      />

      {/* {loader ? ( */}
      <Grid item md={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default TablePageLayout;
