import { Hidden } from "@material-ui/core";
import { makeStyles, withStyles, InputBase } from "allImport";
import { commonStyle } from "commonStyle.js";
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  ...commonStyle(theme),

  logoImg: {
    height: "10rem",
    margin: "3rem 0px",
    width: "auto !important",
  },
  table: {
    minWidth: 650,
    overflow: "hidden",
  },
  tableContainer: {
    overFlow: "hidden",
    minWidth: "70vw",
    minHeight: "calc(100vh - 268px)",
    maxHeight: "calc(100vh - 268px)",
  },
  root1: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },

  table_cell_text: {
    fontFamily: "Mulish",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "0.15px",
  },

  pagination: {
    position: "absolute",
    right: "3%",
    bottom: "1rem",

    // backgroundColor: "#ffffff",
    // paddingTop: "4px",
    // marginTop: "12px",
    height: "40px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  deleteAction: {
    height: "18px",
    width: "14px",
    marginLeft: "28px",
  },
  assignAction: {
    marginRight: "28px",
    marginTop: "-8px",
  },

  searchExaminerfield: {
    width: "65%",
    marginTop: "8px",
    marginRight: "40%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
  },

  allExam_container: {
    padding: "22px 0",
    paddingLeft: "20px",
  },
  multiDropdown: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "24px",
    marginTop: "5px",
    // marginLeft: "10px",
  },
  tableIcons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  collapseRow: {
    //whiteSpace: "nowrap",
    overflow: "hidden",
    maxHeight: 20,
  },
  collapseRowExam: {
    //whiteSpace: "nowrap",
    overflow: "hidden",
    maxHeight: 32,
  },
  tagsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tag: {
    fontFamily: "Mulish",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "0.15000000596046448px",
    height: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#C4C4C44D",
    borderRadius: "25px",
    margin: 5,
    padding: "2px 23px",
  },
  reject_style: {
    color: "#FFFFFF",
    borderRadius: "4px",
    width: "90px",
    height: "24px",
    paddingLeft: "7px",
    paddingTop: "3px",
    backgroundColor: "#D9001D",
  },
  hire_style: {
    color: "#FFFFFF",
    borderRadius: "4px",
    width: "70px",
    height: "24px",
    paddingLeft: "7px",
    paddingTop: "3px",
    backgroundColor: "#29CB1B",
  },
  level_style: {
    color: "#FFFFFF",
    borderRadius: "4px",
    width: "40px",
    height: "56px",
    paddingLeft: "12px",
    paddingTop: "18px",
    backgroundColor: "#383838",
  },
  searchExaminers: {
    height: "48px",
  },
  // Candidate Styles
  candidate_list_container: {
    paddingTop: "32px",
    // paddingLeft: '20px',
  },
}));
export { BootstrapInput, useStyles };
