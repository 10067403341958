import CategoryList from "pages/hr-examiner/Category/CategoryList";
import CreateCategory from "pages/hr-examiner/Category/CreateCategory";

import Dashboard from "pages/hr-examiner/Dashboard/dashboard";
import Examiner from "pages/hr-examiner/Examiner/examiner";
import Invite from "pages/hr-examiner/Examiner/inviteExaminer";
import ExaminerProfile from "pages/hr-examiner/Examiner/examinerProfile";
import EditProfile from "pages/hr-examiner/Examiner/editExaminer";
import AdminProfile from "pages/hr-examiner/Admin/AdminProfile";
import EditAdmin from "pages/hr-examiner/Admin/editAdmin";
import AdminChangePassword from "pages/hr-examiner/Admin/AdminChangePassword";
import Examlist from "pages/hr-examiner/Exams/Examlist";
import Createexam from "pages/hr-examiner/Exams/Createexam";
import ExamquestionList from "pages/hr-examiner/Exams/ExamquestionList";
import SelectedQuestionlist from "pages/hr-examiner/Exams/SelectedQuestionlist";
import Questionbank from "pages/hr-examiner/Questionsbank/questionsbank";
import Editjob from "pages/hr-examiner/Jobs/Editjob";
import Createvacancy from "pages/hr-examiner/Jobs/Createvacancy";
import Joblist from "pages/hr-examiner/Jobs/Joblist";
import Jobprofile from "pages/hr-examiner/Jobs/Jobprofile";
import AdminEmptyScreen from "pages/hr-examiner/Admin/AdminEmptyScreen";
import CandidateList from "pages/hr-examiner/candidates/CandidateList";
import CandidateProfile from "pages/hr-examiner/candidates/CandidateProfile";
import AddCandidate from "pages/hr-examiner/candidates/AddCandidate";
import EditCandidate from "pages/hr-examiner/candidates/EditCandidate";
import ExamLink from "pages/hr-examiner/candidates/ExamLink";
import CandidateGallery from "pages/hr-examiner/candidates/CandidateGallery";
import ExamCandidatelist from "pages/hr-examiner/Results/ExamCandidateList";
import ResultDetails from "pages/hr-examiner/Results/ResultDetails";
import SubmittedExamlist from "pages/hr-examiner/Results/SubmittedExamsList";
import QuestionList from "pages/hr-examiner/Questionsbank/QuestionList";
import QuestionBankEditQuestion from "pages/hr-examiner/Questionsbank/QuestionBankEditQuestion";
import AddNewQuestion from "pages/hr-examiner/Questionsbank/AddNewQuestion";
import SubadminList from "pages/hr-examiner/Subadmins/SubadminList";
import CreateSubadmin from "pages/hr-examiner/Subadmins/CreateSubadmin";
import EditSubadmin from "pages/hr-examiner/Subadmins/EditSubadmin";
import SubadminProfile from "pages/hr-examiner/Subadmins/SubadminProfile";
import CreateDepartment from "pages/hr-examiner/Department/createDepartment";
import EditDepartment from "pages/hr-examiner/Department/editDepartment";
import DepartmentList from "pages/hr-examiner/Department/DepartmentList";
import ViewDepartment from "pages/hr-examiner/Department/viewDepartment";
import Editexam from "pages/hr-examiner/Exams/EditExam";
import NotificationList from "pages/hr-examiner/Notification/NotificationList";
import EditCategory from "pages/hr-examiner/Category/EditCategory";
import QuestionCategory from "pages/hr-examiner/Category/QuestionCategory";
import ChooseAddCandidate from "pages/hr-examiner/candidates/ChooseAddCandidate";

export const menuItem = [
  { path: `/category/create`, component: CreateCategory },
  { path: `/category/question`, component: QuestionCategory },
  { path: `/category/edit`, component: EditCategory },
  { path: `/category`, component: CategoryList },
  { path: `/candidate/choose`, component: ChooseAddCandidate },

  { path: `/dashboard`, component: Dashboard },
  { path: "/examiner/invite", component: Invite },
  { path: "/examiner/profile", component: ExaminerProfile },
  { path: "/examiner/edit-profile", component: EditProfile },
  { path: "/examiner", component: Examiner },
  { path: "/edit-profile", component: EditAdmin },
  { path: "/profile", component: AdminProfile },
  { path: `/exam/create`, component: Createexam },
  { path: `/exam/edit`, component: Editexam },
  { path: `/exam/question/select-question`, component: SelectedQuestionlist },
  { path: `/exam/question`, component: ExamquestionList },
  { path: "/change-password", component: AdminChangePassword },
  { path: `/exam`, component: Examlist },

  {
    path: "/question-bank/questions/edit",
    component: QuestionBankEditQuestion,
  },

  { path: "/question-bank/questions/add", component: AddNewQuestion },
  { path: "/question-bank/questions", component: QuestionList },
  { path: "/question-bank", component: Questionbank },
  { path: "/job/edit", component: Editjob },
  { path: "/job/create", component: Createvacancy },
  { path: "/job/profile", component: Jobprofile },
  { path: "/job", component: Joblist },

  { path: "/department/create", component: CreateDepartment },
  { path: "/department/edit", component: EditDepartment },
  { path: "/department/view", component: ViewDepartment },
  { path: "/department", component: DepartmentList },
  { path: "/subadmins/create-subadmin", component: CreateSubadmin },
  { path: "/subadmins/edit-subadmin", component: EditSubadmin },
  { path: "/subadmins/profile", component: SubadminProfile },
  { path: "/subadmins", component: SubadminList },

  { path: "/emptyscreen", component: AdminEmptyScreen },
  { path: "/candidate/profile/gallery", component: CandidateGallery },
  { path: "/candidate/profile", component: CandidateProfile },
  { path: "/candidate/add", component: AddCandidate },
  { path: "/candidate/edit", component: EditCandidate },
  { path: "/candidate/examlink", component: ExamLink },
  { path: "/candidate", component: CandidateList },

  { path: "/submitted-exam/result", component: ResultDetails },
  { path: "/submitted-exam", component: ExamCandidatelist },

  // { path: "/submitted-exam/result", component: CandidateResult},
  // { path: "/submitted-exam/details/:id", component: ResultDetails},
  { path: "/result/exam/list", component: SubmittedExamlist },
  { path: "/notifications", component: NotificationList },
];

export const menuItemExaminer = [
  { path: `/category/create`, component: CreateCategory },
  { path: `/category/question`, component: QuestionCategory },
  { path: `/category/edit`, component: EditCategory },
  { path: `/category`, component: CategoryList },

  { path: `/dashboard`, component: Dashboard },
  { path: "/profile", component: AdminProfile },
  { path: "/edit-profile", component: EditAdmin },
  { path: "/change-password", component: AdminChangePassword },
  { path: `/exam/create`, component: Createexam },
  { path: `/exam/edit`, component: Editexam },
  { path: `/exam/question/select-question`, component: SelectedQuestionlist },
  { path: `/exam/question`, component: ExamquestionList },
  { path: `/exam`, component: Examlist },

  {
    path: "/question-bank/questions/edit",
    component: QuestionBankEditQuestion,
  },
  { path: "/question-bank/questions/add", component: AddNewQuestion },
  { path: "/question-bank/questions", component: QuestionList },
  { path: "/question-bank", component: Questionbank },

  { path: "/emptyscreen", component: AdminEmptyScreen },

  { path: "/candidate/add", component: AddCandidate },
  { path: "/candidate/edit", component: EditCandidate },
  { path: "/candidate/examlink", component: ExamLink },
  { path: "/candidate/profile/gallery", component: CandidateGallery },
  { path: "/candidate/profile", component: CandidateProfile },
  { path: "/candidate", component: CandidateList },

  { path: "/submitted-exam/result", component: ResultDetails },
  { path: "/submitted-exam", component: ExamCandidatelist },
  { path: "/result/exam/list", component: SubmittedExamlist },
  { path: "/notifications", component: NotificationList },
];
