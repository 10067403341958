import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { AuthContext } from "auth/AuthContext";
import { menuItem1, publicRoutes } from "./routes/publicRoutes";
import { menuItem, menuItemExaminer } from "./routes/privateRoutes";

import Error404 from "pages/hr-examiner/Error404";
import AppLoader from "components/Loader/Loader";
import PublicWrapper from "../hoc/PublicWrapper";
import AuthWrapper from "../hoc/AuthWrapper";
import { useIsLoggedIn } from "hooks";
import Login from "pages/hr-examiner/Login/LogIn";
require("dotenv").config();

const Router = () => {
  const isLoggedIn = useIsLoggedIn();
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  console.log("menuitem----------.....................", menuItem);
  return (
    <AuthContext.Provider value={isLoggedIn}>
      <Suspense fallback={AppLoader} />
      <BrowserRouter>
        {/* <Redirect to="/login" /> */}

        <Switch>
          {role === "examiner"
            ? menuItemExaminer.map((route) => (
                <AuthWrapper
                  path={`/${role}` + route.path}
                  key={`/${role}` + route.path}
                  component={route.component}
                />
              ))
            : menuItem.map((route) => {
                return (
                  <AuthWrapper
                    path={`/${role}` + route.path}
                    key={`/${role}` + route.path}
                    component={route.component}
                  />
                );
              })}
          {publicRoutes.map((route) => (
            <PublicWrapper key={`Route-${route.path}`} {...route} />
          ))}
          <Route component={Login} />
          {/* 404 page route */}
          <Route exact path="*" component={Error404} />
        </Switch>
      </BrowserRouter>
    </AuthContext.Provider>
  );
};

export default Router;
