import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";

import { Button } from "@material-ui/core";

import FilterComponent from "./FilterComponent";
import FilterIcon from "../../TableTopBar/FilterIcon.svg";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function Filter() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { dataArray } = useSelector((store) => store.filter);
  const [active, setActive] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const isEmpty = Object.values(dataArray).every(
      (x) => x === null || x === "" || x.length < 1
    );
    setActive(!isEmpty);
  }, [dataArray]);

  return (
    <div
      style={
        active
          ? {
              backgroundColor: "rgba(0, 0, 0, 0.071)",
              borderRadius: "4px",
            }
          : null
      }
    >
      <span
        style={
          active
            ? {
                height: "6px",
                width: "6px",
                borderRadius: "100%",
                backgroundColor: "red",
                position: "absolute",
                right: "0px",
                top: "4px",
              }
            : null
        }
      ></span>
      <Button
        size="small"
        disableRipple
        onClick={handleClick}
        style={{
          position: "relative",
        }}
      >
        <img
          src={FilterIcon}
          size="medium"
          style={{ color: "black", height: "14px", margin: "8px 0px" }}
        />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <FilterComponent handleClose={handleClose} />
      </Popover>
    </div>
  );
}
