import {
  useEffect,
  fetchClient,
  apiUrl,
Box,
  React,
  Container,
  Grid,
} from "allImport";
import Commonprofile from "components/Commonprofile/Commonprofile";
import useStyles from "style/Profilestyle.js";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  ADMIN_ID,
  API_TOKEN,
  LOGOUT_TOKEN,
  ADMIN_NAME,
  SUB_ADMIN_PERMISSION,
} from "Storage/StorageKeys.js";
import StorageManager from "Storage/StorageManager.js";
import HeaderWithBreadcrumb from "components/HeaderWithBreadcrumb/HeaderWithBreadcrumb";
import CommonProfileComponent from "components/Commonprofile/CommonProfileComponent";

export default function HrProfile(props) {
  const classes = useStyles();
  const location = useLocation();
  const [Hrdetails, setHrdetails] = React.useState({});
  const history = useHistory();
  const role = localStorage.getItem("ROLE")
    ? localStorage.getItem("ROLE").toLowerCase()
    : null;
  const data = {
    MainHeading: Hrdetails.full_name,
    PaperHeading: "Personal Details",
    data : [
      ["Full Name",  Hrdetails.full_name,],
      ["Designation",Hrdetails.designation,],
    ],
    editPath: `/${role}/edit-profile`,
    editAction: {
      name: Hrdetails.full_name,
      designation: Hrdetails.designation,
      allow: SUB_ADMIN_PERMISSION
        ? SUB_ADMIN_PERMISSION.includes("EDIT_SUBADMIN")
        : true,
    },
    tableType: "Admin",
  };
  props;
  const adminID = location.state;
  useEffect(() => {
    if (
      !StorageManager.get(API_TOKEN) &&
      !StorageManager.get(ADMIN_ID) &&
      !StorageManager.get(ADMIN_NAME) &&
      !StorageManager.get(LOGOUT_TOKEN)
    ) {
      history.push("/login");
    }
    console.log(adminID);
    role === "admin"
      ? fetchClient.get(`${apiUrl.adminProfile}${adminID}`).then((res) => {
          setHrdetails(res && res.data && res.data.data);
        })
      : role === "examiner"
      ? fetchClient.get(`${apiUrl.examinerProfile}/${adminID}`).then((res) => {
          setHrdetails(res && res.data && res.data.data);
        })
      : fetchClient.get(`${apiUrl.subadminProfile}${adminID}`).then((res) => {
          setHrdetails(res && res.data && res.data.data);
        });
  }, []);

  return (
    <CommonProfileComponent data={data}/>
   
  );
}
