import Actions from "../actions/popupActions";

let initialState = {
popupData :""
};
const popupReducer = (state = initialState, action) => {
    console.log("popupData",state.popupData)
  switch (action.type) {
    case Actions.SET_POPUP:
      return {
        ...initialState,
        popupData: action.data
      };
    default:
      return state;
  }
};

export default popupReducer;
