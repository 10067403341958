import firebase from 'firebase/app'

const config = {
    apiKey: "AIzaSyBHArfh7rORlzonz0xnVfGpxy4k38tz96s",
    authDomain: "hiring-portal-3327d.firebaseapp.com",
    projectId: "hiring-portal-3327d",
    storageBucket: "hiring-portal-3327d.appspot.com",
    messagingSenderId: "1023411089931",
    appId: "1:1023411089931:web:813184852aa1c502f50d0e",
    measurementId: "G-FP8GYL4HLQ",
    databaseURL: "https://hiring-portal-3327d-default-rtdb.firebaseio.com"
}

export const initializeFirebase = () => {
    if (firebase.messaging?.isSupported()) {
        if (!firebase.apps.length) {
          firebase.initializeApp(config);
        } else {
          firebase.app(); // if already initialized, use that one
        }
      }
}

export const askForPermissioToReceiveNotifications = async () => {
    try {
        const messaging = firebase.messaging();
        await messaging.requestPermission();
        const token = await messaging.getToken();
        return token;
    } catch (error) {
        console.log("firebase Error", error)
    }
}