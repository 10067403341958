import React, { useEffect, useState } from "react";

import {
  FormControlLabel,
  Checkbox,
  Box,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import styles from "./SelectCheck.module.scss";
import Actions from "redux/actions/filterActions";
import { Skeleton } from "@material-ui/lab";
import TableActions from "redux/actions/tableActions";

const MenuProps = {
  // keepMounted: true,
  disablePortal: true,
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 175,
      maxWidth: "auto",
      marginTop: 58,

      height: "auto",
      // maxHeight: "450px",

      boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.10)",
    },
  },
};

export default function CheckboxFilter() {
  const { filter } = useSelector((store) => store.filter);
  const { countFilter } = useSelector((store) => store.filter);
  const { dataArray } = useSelector((store) => store.filter);

  const [Checked, setChecked] = useState([]);
  const dispatch = useDispatch();

  const handleToggle = (value, name, e) => {
    dispatch({
      type: TableActions.SET_TABLE_ROW,
      filterCountsadasdsa: null,
    });
    const currentIndex = Checked.indexOf(value);
    const newChecked = [...Checked];
    currentIndex === -1
      ? newChecked.push(value)
      : newChecked.splice(currentIndex, 1);

    setChecked(newChecked);

    e.target.checked
      ? dispatch({ type: Actions.GET_FILTER, data: value, key: [name] })
      : dispatch({ type: Actions.REMOVE_FILTER, data: value, key: [name] });
  };

  return (
    <>
      {filter.map((val, indexValue) => {
        return (
          <Grid item justifyContent="center" key={indexValue}>
            <Select
              style={{
                border: "1px solid #E5E5E5",
                width: "200px",
                height: "40px",
                borderRadius: "4px",
              }}
              MenuProps={MenuProps}
              disableUnderline
              value={[]}
              displayEmpty
              multiple
              renderValue={() => {
                return (
                  <Box
                    style={{ padding: "10px" }}
                    className={styles.title_container}
                  >
                    {val[0] === undefined ? <Skeleton /> : val[0].label}
                  </Box>
                );
              }}
              className={styles.select_container}
            >
                {console.log("itemmm",val)}
            
              {val.slice(1, val.length).map((item) => (
                <MenuItem
                  disableRipple
                  disableTouchRipple
                  key={item.id}
                  value={item.id ? item.id : item.name}
                  className={styles.select_item}
                  style={{ marginLeft: "-20px" }}
                >
                  <FormControlLabel
                    value={item.val}
                    control={
                      <Checkbox
                        size="small"
                        onChange={(e) =>
                          handleToggle(
                            item.id ? item.id : item.value,
                            val[0].value,
                            e
                          )
                        }
                        defaultChecked={
                          dataArray[val[0].value]
                            ? dataArray[val[0].value].includes(
                                item.id ? item.id : item.value
                              )
                            : false
                        }
                        color="primary"
                        sx={{
                          transform: "scale(0.5)",
                        }}
                      />
                    }
                   
                    label={
                      <span className={styles.filter_popup_label}>
                        {item.label || item.name}
                      </span>
                    }
                    labelPlacement="start"
                    className={styles.filter_popup_checkbox}
                  />
                </MenuItem>
              ))}
            </Select>
          </Grid>
        );
      })}
    </>
  );
}
